import React, { useState, useEffect } from 'react';
import BootstrapTableNext from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Form} from "react-bootstrap";
import { getInsightsTable, getDateFilterOptions } from '../../actions';
import { ExcelfileIcon, RightArrowDownIcon, CarretIconUp, CarretIconDown } from '../../components/Icons';
import { convertToKMB } from '../../helper';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import NoImageeCompass from "./NoImageeCompass.jpg";
import BarLoader from "react-spinners/BarLoader";
// import * as XLSX from 'xlsx';
 
const NewInsightsTopMoversTable = (props) => {
    let dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageNumberLoser, setPageNumberLoser] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState();
    const [totalSizeTopLoser, setTotalSizeTopLoser] = useState();
    const [topMoversTableData, setTopMoversTableData] = useState([]);
    const [toploserTableData, setToploserTableData] = useState([]);
    const [displayDropdown, setDisplayDropdown] = useState(false);
    const [ firstDropDownData, setFirstDropDownData ] = useState("");
    const { filterDateRange } = props;
    
    let { selectedCustomerKey, selectedGlobalFilters, selectedRetailerCurrency, dynamicPriceFormatting, project, subproject} = useSelector((state) => state.globalReducer);
    let { selectedSelectionAndMetricName , insightsTabsDetailsData  } = useSelector((state) => state.insightReducer);
    let {finalData, tabDetails } = insightsTabsDetailsData ? insightsTabsDetailsData : "";
    let tabDetailsindex = tabDetails[selectedSelectionAndMetricName?.sectionName]?.length > 0 ? tabDetails[selectedSelectionAndMetricName.sectionName][0] : "";   
    let tabDetailsTimeframeDefault = tabDetailsindex?.TIMEFRAME;    
    let timeFramearray = tabDetailsTimeframeDefault && tabDetailsTimeframeDefault ? tabDetailsTimeframeDefault.split(',') : '';
    const [topGainersOption, setTopGainersOption] = useState(tabDetailsindex?.METRIC_NAME);
    
    let topMoverstext = "Top Movers";
    let toplosertext = "Top Losers";
    let filternamesarray = finalData["FILTER_NAMES"] && finalData["FILTER_NAMES"] ? finalData["FILTER_NAMES"].split(',') : '';
    let filtervaluesarray = finalData["FILTER_COLUMNS"] && finalData["FILTER_COLUMNS"] ? finalData["FILTER_COLUMNS"].split(',') : '';
    let finalfilterarray = [];
    
    if(filternamesarray?.length && filtervaluesarray?.length) {
        filternamesarray.forEach(function (item, index) {       
         finalfilterarray.push({name:item,value:filtervaluesarray[index]})
        });     
    }

    let filterarrayresult = finalfilterarray[0]?.value ? finalfilterarray[0]?.value : "";
    let filterarrayname = finalfilterarray[0]?.name ? finalfilterarray[0]?.name : "";
    const [topLoserOption, setTopLoserOption] = useState(filterarrayresult);
    const [typeValuecheck, setTypeValuecheck] = useState(tabDetailsindex?.TYPE);
    const [displayNameDropdown, setDisplayNameDropdown] = useState(filterarrayname);
    const [productTitleSortDirection, setProductTitleSortDirection] = useState();
    const [changePpSortDirection, setChangePpSortDirection] = useState();
    const [changePySortDirection, setChangePySortDirection] = useState();
    const [topLoserSortDirection, setTopLoserSortDirection] = useState();
    const [topLoserSortDirectionCPP, setTopLoserSortDirectionCPP] = useState();
    const [topLoserSortDirectionPpy, setTopLoserSortDirectionPpy] = useState();
    const [topLoserFinalSort,setTopLoserFinalSort]=useState('asc');
    const [topGainerFinalSort,setTopGainerFinalSort]=useState('desc');
    const [topGaineColumnSort,setTopGaineColumnSort]=useState('CHANGE_PP');
    const [topLoserCoulmnSort,setTopLoserCoulmnSort]=useState('CHANGE_PP');
    const responseHandlerupdate = (response) => {       
        let resTabledata = response?.data?.responseData?.insightsTableData;        
        if (resTabledata && (Object.keys(resTabledata[[topMoverstext]]).length > 0)) {
            setTopMoversTableData(response.data.responseData.insightsTableData[[topMoverstext]]);
            setTotalSize(response.data.responseData.insightsTableData[[topMoverstext]].length)
        } else {
            setTopMoversTableData([]);
        }
        if (resTabledata && (Object.keys(resTabledata[[toplosertext]]).length > 0)) {
            setToploserTableData(response.data.responseData.insightsTableData[[toplosertext]]);
            setTotalSizeTopLoser(response.data.responseData.insightsTableData[[toplosertext]].length)
        } else {
            setToploserTableData([]);
        } 
        setLoading(false);
    }  
    const [displayNameFdp, setDisplayNameFdp] = useState(tabDetailsindex?.DISPLAY_NAME);
    const [timeframeCheck, setTimeframeCheck] = useState(tabDetailsindex?.TIMEFRAME);
    const getTimeFrame = (timeFrame) => {
        let timeFrameArr = '';
        tabDetails[selectedSelectionAndMetricName.sectionName].forEach(tab => {
            if(tab?.METRIC_NAME === tabDetailsindex?.METRIC_NAME) {
                timeFrameArr = timeframeCheck.replace(/"|'/g, '').split(',');
            }
        })
        let timeFramObj = {};
        for(let i=0; i < timeFrameArr.length; i++ ) {
            let temp = timeFrameArr[i].trim();
            if( temp== 'L1CW') {
                timeFramObj['Last 1 week'] = temp;
            } else if( temp == 'L4CW' ) {
                timeFramObj['Last 4 weeks'] = temp;
            } else if( temp == 'L13CW' ){
                timeFramObj['Last 13 weeks'] = temp;
            } else if( temp == 'L26CW' ){
                timeFramObj['Last 26 weeks'] = temp;
            } else if( temp == 'L52CW' ){
                timeFramObj['Last 52 weeks'] = temp;
            } else if( temp.includes('MTD')) {
                timeFramObj['MTD'] = temp
            } else if( temp.includes('QTD')) {
                timeFramObj['QTD'] = temp;
            }else if( temp.includes('YTD')) {
                timeFramObj['YTD'] = temp;
            }
        }        
        return timeFramObj[timeFrame];        
    } 

    useEffect(() => {
        setLoading(true);
        if(selectedSelectionAndMetricName?.sectionName === "Top Movers" && topLoserOption !== '') {
            dispatch(getInsightsTable({
                customerKey: selectedCustomerKey,
                filterValues: selectedGlobalFilters,
                sectionName: selectedSelectionAndMetricName.sectionName,
                metricNames: topGainersOption,
                timeframe: getTimeFrame(filterDateRange.selectedDateRange),
                sectiongroupValue: topLoserOption,
                sortColumn: [topGaineColumnSort,topLoserCoulmnSort],
                sortOrder: [topGainerFinalSort,topLoserFinalSort]
            },
            responseHandlerupdate));
        }
        dispatch({type: "SAVE_TOPMOVERS_OPTIONS", payload: displayNameFdp})
        
    }, [selectedGlobalFilters, filterDateRange?.selectedDateRange, topLoserOption,topGainersOption]); 
   

    const options = {
        totalSize: totalSize,sizePerPage: sizePerPage,
        page: pageNumber,showTotal: true, disablePageTitle: true, hideSizePerPage: true,paginationShowsTotal: totalSize
      };
    const toploseroptions = {
        totalSize: totalSizeTopLoser,
        sizePerPage: sizePerPage,
        page: pageNumber,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationShowsTotal: totalSize
    };
    function getSheetData(data, header) {
        let fields = Object.keys(data[0]);
        let sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
    }
    
    const downloadData = () => {
        dispatch(getInsightsTable({
            customerKey: selectedCustomerKey,
            filterValues: selectedGlobalFilters,
            sectionName: selectedSelectionAndMetricName.sectionName,
            metricNames: topGainersOption,
            timeframe: getTimeFrame(filterDateRange.selectedDateRange),
            sectiongroupValue: topLoserOption,
            sortColumn: [topGaineColumnSort,topLoserCoulmnSort],
            sortOrder: [topGainerFinalSort,topLoserFinalSort],
            isExport: true
        },
        responseHandlerupdatedownload));
    }
    const responseHandlerupdatedownload = (response) => {       
        let headers = []
        let bodyData = [];
        let topMovercombinedata = [];
        let topMoversTableDataDownloadnw = [];
        let toploserTableDataDownloadnw = [];
        if(response?.data?.responseData?.insightsTableData){
            if(response.data.responseData.insightsTableData[[topMoverstext]].length >=0){
                topMoversTableDataDownloadnw.push(response.data.responseData.insightsTableData[[topMoverstext]]);
            }
            if(response.data.responseData.insightsTableData[[topMoverstext]].length >=0){
                toploserTableDataDownloadnw.push(response.data.responseData.insightsTableData[[toplosertext]]);
            }
           
        }
        if (topMoversTableDataDownloadnw.length || toploserTableDataDownloadnw.length) {
            headers = ["Type",displayNameFdp, "Change compared to previous period","POP","Change compared to same period last year", "YOY",
            "Sellout current period","Sellout POP%","Sellout YOY%","Content Health","Content Health Score POP%","Content Health Score YOY",
            "Current Rating","Current Rating POP","Current Rating YOY","Count of Reviews", "Reviews POP", "Reviews YOY",
            "In-stock percentage","In-stock POP","In-stock YOY","Search rank"
            ]
            let rpcupcHeader = subproject === 'MagazineLuiza-BR' ? 'UPC Id' : 'RPC Id';
            if (topLoserOption === "ITEM_ID") {
                headers.splice(1,0,"SKU Image", " SKU Title", "ITEM_ID", rpcupcHeader);
            } else {
                headers.splice(1,0,displayNameDropdown);
            }
            let multilehund = 200;
            let tablefirst = topMoversTableDataDownloadnw[0].map(v => ({...v, topGainers: "Top Gainers"}));
            let tablesecond = toploserTableDataDownloadnw[0].map(v => ({...v, topGainers: "Top Losers"}));
            
            topMovercombinedata = [...tablefirst, ...tablesecond];
            bodyData = topMovercombinedata.map(item => {              
                let typecheck = typeValuecheck.toLowerCase();
                let imageurl = item.IMAGE_URL ? item.IMAGE_URL : NoImageeCompass;
                let productTitle = item.PRODUCT_TITLE ? item.PRODUCT_TITLE : 'N/A';
                let itemId = item.ITEM_ID ? item.ITEM_ID : '';
                let rpcId = item.RPC_ID ? item.RPC_ID : 'N/A';
                let upcId = item.UPC ? item.UPC : 'N/A';
                let sellOutCurrent = item.sellOutCurrent ? item.sellOutCurrent : 'N/A';
                let selloutPOP = item.selloutPOP ? item.selloutPOP + '%' : 'N/A';
                let sellOutYOY = item.sellOutYOY ? item.sellOutYOY + '%': 'N/A';
                let contentHealth = item.contentHealth ? item.contentHealth : 'N/A';
                let contentHealthPOP = item.contentHealthPOP ? item.contentHealthPOP + '%' : 'N/A';
                let contentHealthYOY = item.contentHealthYOY ? item.contentHealthYOY + '%': 'N/A';
                let currentRating = item.currentRating ? item.currentRating : 'N/A';
                let currentRatingPOP = item.currentRatingPOP ? item.currentRatingPOP : 'N/A';
                let currentRatingYOY = item.currentRatingYOY ? item.currentRatingYOY : 'N/A';
                let countOfReview = item.countOfReview ? item.countOfReview : 'N/A';
                let countOfReviewPOP = item.currentReviewPOP ? item.currentReviewPOP : 'N/A';
                let countOfReviewYOY = item.currentReviewYOY ? item.currentReviewYOY : 'N/A';
                let instockPercentage = item.instockPercentage ? item.instockPercentage + '%' : 'N/A';
                let instockPercentagePOP = item.instockPercentagePOP ? item.instockPercentagePOP : 'N/A';
                let instockPercentagePOY = item.instockPercentagePOY ? item.instockPercentagePOY : 'N/A';
                let searchRank = item.searchRank ? item.searchRank : 'N/A';
                let searchRankPOP = item.searchRankPOP ? item.searchRankPOP : 'N/A';
                let searchRankPOY = item.searchRankPOY ? item.searchRankPOY : 'N/A';
                let price = item.price ? item.price : 'N/A';
                let pricePOP = item.pricePOP ? item.pricePOP + '%' : 'N/A';
                let pricePOY = item.pricePOY ? item.pricePOY + '%' : 'N/A';
                let skuHealth = item.pricePOY ? item.pricePOY : 'N/A';
                let skuHealthPOP = item.skuHealthPOP ? item.skuHealthPOP : 'N/A';
                let skuHealthPOY = item.skuHealthPOY ? item.skuHealthPOY : 'N/A'
                let rpcupcId = subproject === 'MagazineLuiza-BR' ? upcId : rpcId;
                let toploser = item[[topLoserOption]] ? item[[topLoserOption]] : "";
                let roundValue = item.CP !== null ? item.CP !== 0 ? Math.round(item.CP) : `${item.CP}` : 'N/A';
                let newcellroundPP = item.CHANGE_PP !== null ? item.CHANGE_PP !== 0 ? Math.round(item.CHANGE_PP) : `${item.CHANGE_PP}` : 'N/A';
                let newcelldecimalPP = item.CHANGE_PP !== null ? item.CHANGE_PP !== 0 ? (item.CHANGE_PP).toFixed(1) : `${item.CHANGE_PP}` : 'N/A';
                let newcellroundPY = item.CHANGE_PY !== null ? item.CHANGE_PY !== 0 ? Math.round(item.CHANGE_PY) : `${item.CHANGE_PY}` : 'N/A';
                let newcelldecimalPY = item.CHANGE_PY !== null ? item.CHANGE_PY !== 0 ? (item.CHANGE_PY).toFixed(1) : `${item.CHANGE_PY}` : 'N/A';
                 if (topLoserOption === "ITEM_ID") {
                    return [item.topGainers,imageurl, productTitle, itemId, rpcupcId, item?.CP !== null ? typecheck === 'number' ? roundValue : typecheck === 'decimal' ? item.CP !== 0 ? (item.CP).toFixed(1) : item.CP : typecheck === 'percentage' ? item.CP !== 0 ? ((item.CP) * multilehund).toFixed(0) + '%' : `${item.CP}%` : typecheck === 'text' ? convertToKMB((item.CP), selectedRetailerCurrency) : item.CP !== 0 ? (item.CP).toFixed(2) : item.CP : 'N/A',
                        item?.CHANGE_PP !== null ? typecheck === 'number' ? newcellroundPP : typecheck === 'decimal' ? newcelldecimalPP : typecheck === 'percentage' ? item.CHANGE_PP !== 0 ? ((item.CHANGE_PP) * multilehund).toFixed(0) + '%' : `${item.CHANGE_PP}%` : typecheck === 'text' ? convertToKMB((item.CHANGE_PP), selectedRetailerCurrency) : item.CHANGE_PP !== 0 ? (item.CHANGE_PP).toFixed(2) : item.CHANGE_PP : 'N/A', item.POP !== null ? ((item.POP) * 100).toFixed(0) + '%' : 'N/A',
                        item?.CHANGE_PY !== null ? typecheck === 'number' ? newcellroundPY : typecheck === 'decimal' ? newcelldecimalPY : typecheck === 'percentage' ? item.CHANGE_PY !== 0 ? ((item.CHANGE_PY) * multilehund).toFixed(0) + '%' : `${item.CHANGE_PY}%` : typecheck === 'text' ? convertToKMB((item.CHANGE_PY), selectedRetailerCurrency) : item.CHANGE_PY !== 0 ? (item.CHANGE_PY).toFixed(2) : item.CHANGE_PY : 'N/A', item.YOY !== null ? ((item.YOY) * 100).toFixed(0) + '%' : 'N/A',
                        sellOutCurrent, selloutPOP, sellOutYOY,
                        contentHealth, contentHealthPOP, contentHealthYOY,
                        currentRating, currentRatingPOP, currentRatingYOY,
                        countOfReview, countOfReviewPOP, countOfReviewYOY,
                        instockPercentage ,instockPercentagePOP, instockPercentagePOY,
                        searchRank
                    ]
                } else {
                    return [item.topGainers,toploser, item?.CP !== null ? typecheck === 'number' ? roundValue : typecheck === 'decimal' ? item.CP !== 0 ? (item.CP).toFixed(1) : item.CP : typecheck === 'percentage' ? item.CP !== 0 ? ((item.CP) * multilehund).toFixed(0) + '%' : `${item.CP}%` : typecheck === 'text' ? convertToKMB((item.CP), selectedRetailerCurrency) : item.CP !== 0 ? (item.CP).toFixed(2) : item.CP : 'N/A',
                        item?.CHANGE_PP !== null ? typecheck === 'number' ? newcellroundPP : typecheck === 'decimal' ? newcelldecimalPP : typecheck === 'percentage' ? item.CHANGE_PP !== 0 ? ((item.CHANGE_PP) * multilehund).toFixed(0) + '%' : `${item.CHANGE_PP}%` : typecheck === 'text' ? convertToKMB((item.CHANGE_PP), selectedRetailerCurrency) : item.CHANGE_PP !== 0 ? (item.CHANGE_PP).toFixed(2) : item.CHANGE_PP : 'N/A', item.POP !== null ? ((item.POP) * 100).toFixed(0) + '%' : 'N/A',
                        item?.CHANGE_PY !== null ? typecheck === 'number' ? newcellroundPY : typecheck === 'decimal' ? newcelldecimalPY : typecheck === 'percentage' ? item.CHANGE_PY !== 0 ? ((item.CHANGE_PY) * multilehund).toFixed(0) + '%' : `${item.CHANGE_PY}%` : typecheck === 'text' ? convertToKMB((item.CHANGE_PY), selectedRetailerCurrency) : item.CHANGE_PY !== 0 ? (item.CHANGE_PY).toFixed(2) : item.CHANGE_PY : 'N/A', item.YOY !== null ? ((item.YOY) * 100).toFixed(0) + '%' : 'N/A']
                }
            });
            XlsxPopulate.fromBlankAsync().then(async (workbook) => {
                const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(bodyData, headers);
                const totalColumns = sheetData[0].length;
                sheet1.cell("A1").value(sheetData);
                const range = sheet1.usedRange();
                const endColumn = String.fromCharCode(64 + totalColumns);
                sheet1.row(1).style("bold", true);
                sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
                let fileName = "DownloadKPIS.xlsx"
                return workbook.outputAsync().then((res) => {
                    saveAs(res, fileName);
                });
            });
        }
    };
        const skuProductTitleFormatter = (cell, row) => { 
            let upcId = row.UPC ? row.UPC : 'N/A'; 
            let rpcId = row.RPC_ID ? row.RPC_ID : 'N/A',
            productImage = row?.IMAGE_URL ? row?.IMAGE_URL : NoImageeCompass;        
            return (            
             <tr className="">
               <td className='img_cls'><img src={productImage} alt=""/></td> 
               <td className='title_cls'>{row?.PRODUCT_TITLE ? row?.PRODUCT_TITLE : 'N/A' }{subproject === "MagazineLuiza-BR" ? <> - UPC: {upcId} </> : <> - RPC: {rpcId}</> }</td> 
            </tr>            
            )
        } 
        
        const sortCareticon = (order, column) => {
            if(column.dataField == 'CP') {
                order === 'desc' ? setProductTitleSortDirection('desc') : order === undefined ? setProductTitleSortDirection('asc') : setProductTitleSortDirection('asc');
            } else if(column.dataField == 'CHANGE_PP'){
                order === 'desc' ? setChangePpSortDirection('desc') : order === undefined ? setChangePpSortDirection('asc') : setChangePpSortDirection('asc');
            }else if(column.dataField == 'CHANGE_PY'){
                order === 'desc' ? setChangePySortDirection('desc') : order === undefined ? setChangePySortDirection('asc') : setChangePySortDirection('asc');
            }
            if (order === 'desc') {          
              return (<i className="fas fa-sort-up  default-sort-icon active"></i>)
           } else if (order === 'asc') {          
             return (<i className="fas fa-sort-down  default-sort-icon active"></i>)
           } else {
              return(<i className="fas fa-sort default-sort-icon"></i>) 
           }
          }
          const sortingEvents = {    
            onClick: (e, column, columnIndex,row) => {
              let colSort = column.dataField == 'CP' ? productTitleSortDirection : column.dataField == 'CHANGE_PP' ? changePpSortDirection : column.dataField == 'CHANGE_PY'? changePySortDirection : "";
              setTopGainerFinalSort(colSort);
              setTopGaineColumnSort(column.dataField);
              dispatch(getInsightsTable({customerKey: selectedCustomerKey,
                filterValues: selectedGlobalFilters,
                sectionName: selectedSelectionAndMetricName.sectionName,
                metricNames: topGainersOption,
                timeframe: getTimeFrame(filterDateRange.selectedDateRange),
                sectiongroupValue: topLoserOption,
                sortColumn: [column.dataField,topLoserCoulmnSort],
                sortOrder: [colSort,topLoserFinalSort]},responseHandlerupdate));  
            } 
          };
          const toplosersortCareticon = (order, column) => {
            if(column.dataField == 'CP') {
                order === 'desc' ? setTopLoserSortDirection('desc') : order === undefined ? setTopLoserSortDirection('asc') : setTopLoserSortDirection('asc');
            } else if(column.dataField == 'CHANGE_PP'){
                order === 'desc' ? setTopLoserSortDirectionCPP('desc') : order === undefined ? setTopLoserSortDirectionCPP('asc') : setTopLoserSortDirectionCPP('asc');
            }else if(column.dataField == 'CHANGE_PY'){
                order === 'desc' ? setTopLoserSortDirectionPpy('desc') : order === undefined ? setTopLoserSortDirectionPpy('asc') : setTopLoserSortDirectionPpy('asc');
            }
            if (order === 'desc') {          
              return (<i className="fas fa-sort-up  default-sort-icon active"></i>)
           } else if (order === 'asc') {          
             return (<i className="fas fa-sort-down  default-sort-icon active"></i>)
           } else {
              return(<i className="fas fa-sort default-sort-icon"></i>) 
           }
          }
          const toplosersortingEvents = {    
            onClick: (e, column, columnIndex,row) => {
              let colSort = column.dataField == 'CP' ? topLoserSortDirection : column.dataField == 'CHANGE_PP' ? topLoserSortDirectionCPP : column.dataField == 'CHANGE_PY'? topLoserSortDirectionPpy : "";
              setTopLoserFinalSort(colSort);
              setTopLoserCoulmnSort(column.dataField);
              dispatch(getInsightsTable({customerKey: selectedCustomerKey,
                filterValues: selectedGlobalFilters,
                sectionName: selectedSelectionAndMetricName.sectionName,
                metricNames: topGainersOption,
                timeframe: getTimeFrame(filterDateRange.selectedDateRange),
                sectiongroupValue: topLoserOption,
                sortColumn: [topGaineColumnSort,column.dataField],
                sortOrder: [topGainerFinalSort,colSort]},responseHandlerupdate));  
            } 
          }

        const changeComparedtoPreviousPeriod = (cell, row) => {
            if(cell !== null && cell !== undefined){
                let result = (cell - Math.floor(cell)) !== 0; 
                let poppercent = row.POP !== 0 ? ((row.POP)*100).toFixed(0) + '%' : `${row.POP}%`  
                let typeValue = typeValuecheck.toLowerCase();
                let newcelldfault = cell !== 0 && result ? cell.toFixed(2) : cell;
                let newcellprecent = cell !== 0 ? (cell*100).toFixed(0) + '%' : `${cell}%`;
                let newcellround = cell !== 0 ? Math.round(cell) : cell;
                let newcelldecimal = cell !== 0 ? cell.toFixed(1) : cell;
                return (<>
                    <span className={row.PP < row.CP ? 'clr-grn d-inline-flex':'clr-rd d-inline-flex'}>
                        {row.PP < row.CP ?
                            <CarretIconUp /> : <CarretIconDown />                        
                        }
                        <span>
                        {typeValue === 'percentage' ? newcellprecent : typeValue === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : typeValue === 'number' ? newcellround : typeValue === 'decimal' ? newcelldecimal : newcelldfault }
                        </span>
                    </span>
                    <div>{poppercent}</div>
                    </>
                )
            }else{ 
                return 'N/A'            
            }            
        }
        const changeComparedtSamePeriodLastYear = (cell, row) => {
            if (cell !== null && cell !== undefined) {
                let result = (cell - Math.floor(cell)) !== 0;
                let poppercent = ((row.YOY) * 100).toFixed(0) + '%'
                let typeValue = typeValuecheck.toLowerCase();
                let newcelldfault = cell !== 0 && result ? cell.toFixed(2) : cell;
                let newcellprecent = cell !== 0 ? (cell * 100).toFixed(0) + '%' : `${cell}%`;
                let newcellround = cell !== 0 ? Math.round(cell) : cell;
                let newcelldecimal = cell !== 0 ? cell.toFixed(1) : cell;
                return (<>
                    <span className={row.PY < row.CP ? 'clr-grn d-inline-flex' : 'clr-rd d-inline-flex'}>
                        {row.PY < row.CP ?
                            <CarretIconUp /> : <CarretIconDown />
                        }
                        {typeValue === 'percentage' ? newcellprecent : typeValue === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : typeValue === 'number' ? newcellround : typeValue === 'decimal' ? newcelldecimal : newcelldfault}
                    </span>
                    <div>{poppercent}</div>
                </>
                )
            } else {
                return 'N/A'
            }
        }
        const orderedProductFormatter = (cell, row) => {
            if(cell !== null && cell !== undefined){
                let result = (cell - Math.floor(cell)) !== 0; 
                let typeValue = typeValuecheck.toLowerCase();
                let newcelldfault = cell !== 0 && result ? cell.toFixed(2) : cell;
                let newcellprecent = cell !== 0 ? (cell*100).toFixed(0) + '%' : `${cell}%`;
                let newcellround = cell !== 0 ? Math.round(cell) : cell;
                let newcelldecimal = cell !== 0 ? cell.toFixed(1) : cell;
                return (
                    <>
                    {typeValue === 'percentage' ? newcellprecent : typeValue === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : typeValue === 'number' ? newcellround : typeValue === 'decimal' ? newcelldecimal : newcelldfault }
                    </>
                )
            }else{ 
                return 'N/A'            
            }
        };
        const handleTableChange = (
            type,
            { page, sizePerPage }
          ) => {     
            if (type === "pagination") {
              setSizePerPage(sizePerPage);
              setPageNumber(page);
            }
          };
          const handleTableChangeloser = (
            type,
            { page, sizePerPage }
          ) => {     
            if (type === "pagination") {
              setSizePerPage(sizePerPage);
              setPageNumberLoser(page);
            }
          };
        const NoDataIndication = () => (
            <div className="spinner text-center p-2">
                No Data Available
            </div>
        );
        let columns = [{
            text: `${displayNameFdp}`,
            dataField: "CP",  
            formatter: orderedProductFormatter,
            sort: true,
            sortCaret: sortCareticon,
            headerEvents: sortingEvents,
        },{
            text: "Change compared to previous period",
            dataField: "CHANGE_PP",
            sort: true,
            sortCaret: sortCareticon,
            headerEvents: sortingEvents,
            formatter: changeComparedtoPreviousPeriod
        },{
            text: "Change compared to same period last year",
            dataField: "CHANGE_PY",
            sort: true,
            sortCaret: sortCareticon,
            headerEvents: sortingEvents,
            formatter: changeComparedtSamePeriodLastYear
        }
        ];
        let topLosercolumns = [{
            text: `${displayNameFdp}`,
            dataField: "CP",  
            formatter: orderedProductFormatter,
            sort: true,
            sortCaret: toplosersortCareticon,
            headerEvents: toplosersortingEvents,
        },{
            text: "Change compared to previous period",
            dataField: "CHANGE_PP",
            sort: true,
            sortCaret: toplosersortCareticon,
            headerEvents: toplosersortingEvents,
            formatter: changeComparedtoPreviousPeriod
        },{
            text: "Change compared to same period last year",
            dataField: "CHANGE_PY",
            sort: true,
            sortCaret: toplosersortCareticon,
            headerEvents: toplosersortingEvents,
            formatter: changeComparedtSamePeriodLastYear
        }
        ];
        

        if(topLoserOption === "ITEM_ID"){
            columns.unshift({
                text: "SKU",
                formatter: skuProductTitleFormatter,
                classes : 'skutitle-cls'                         
            });
            topLosercolumns.unshift({
                text: "SKU",
                formatter: skuProductTitleFormatter,
                classes : 'skutitle-cls'                         
            })
        }else {
            columns.unshift({
                text: `${displayNameDropdown}`,
                dataField: `${topLoserOption}`                                     
            }) ;
            topLosercolumns.unshift({
                text: `${displayNameDropdown}`,
                dataField: `${topLoserOption}`                                     
            }) 
        }
    
    return (
        <div className='pl-4 pr-4 pb-5 mt-5'> 
        <div className='row mar-tp'>
       { topGainersOption?.length > 0 || topLoserOption?.length > 0 ?
        <div class="col-2">
            <span className='titletp'> Top Movers</span> 
        </div>
        : null}      
            { topGainersOption?.length > 0 && 
                <div className="col-3">
                <Form.Group controlId="formBasicSelect" className='topmovers_dropdwon'>        
                    <Form.Control as="select"  value={topGainersOption}
                        onChange={e => {                            
                            var selectedoption = document.getElementById("formBasicSelect");
                            var option= selectedoption.options[selectedoption.selectedIndex];
                            setTopGainersOption(e.target.value);
                            setTypeValuecheck(option.getAttribute("data-type"));
                            setTimeframeCheck(option.getAttribute("data-time"));
                            setDisplayNameFdp(option.getAttribute("data-display"));                              
                        }}>
                        {tabDetails[selectedSelectionAndMetricName?.sectionName] && tabDetails[selectedSelectionAndMetricName?.sectionName].map((firstData, index)=>{ 
                            if(firstData === null) {
                                return false;
                            } else {             
                                return  <option key={index} data-time={firstData?.TIMEFRAME} data-display={firstData?.DISPLAY_NAME}  data-type={firstData?.TYPE} value={(firstData?.METRIC_NAME).trim()}>{firstData?.DISPLAY_NAME}</option>
                            }
                        })}
                    </Form.Control>
            </Form.Group>
            </div> }
            
            { topLoserOption?.length > 0 && 
                <div className="col-3">
                <Form.Group className='topmovers_dropdwon'>        
                    <Form.Control as="select" id="TopLoserDropdown" value={topLoserOption}
                        onChange={e => {
                            var selectedoption = document.getElementById("TopLoserDropdown");
                            var option= selectedoption.options[selectedoption.selectedIndex];           
                            setTopLoserOption(e.target.value);
                            setDisplayNameDropdown(option.getAttribute("data-name"));
                        }}>
                        {finalfilterarray && 
                            finalfilterarray.map((firstData)=>{                
                            return  <option data-name={firstData?.name} value={(firstData.value).trim()}>{firstData.name}</option>
                        })}
                    </Form.Control>
            </Form.Group> 
            </div>}
            <div className='col-3'>
                    <span className='downloadfile' onClick={(e) => downloadData()}> Download full report <ExcelfileIcon /></span>
                </div>
            </div>
            {loading ? (<div id="strategy_wrapper">
                <div className="container-fluid  default-top loading_insights loading_wrapper bg-w p-5">
                    <div className="sweet-loading">
                    <span><b>Processing...</b></span>
                    <BarLoader style={{display: 'block', margin:'0 auto', borderColor: 'red'}} size={150} color={"#123abc"}
                        height={4} width={100} loading={loading} />
                    </div>
                </div>
                </div>) :
            <div className='d-flex mt-5'>
                <div className='row'>
                <div className='col-6 topMoversTablecls'>
                    <div className='topMoverheader'>
                        <span className='topgainorloser'>Top Gainers</span>
                    </div>
                                      
                    <div className={`${topMoversTableData.length >= 4 ? "top_moverstablescls" : ""}`}>
                    <BootstrapTableNext remote bodyClasses="topmvtablestle" headerClasses="topMvtable" pagination={ paginationFactory(options)} data={ topMoversTableData } 
                            columns={ columns } keyField="updates" onTableChange={ handleTableChange } noDataIndication={ () => <NoDataIndication /> }  /> 
                    </div>
                </div>
                <div className='col-6 topMoversTablecls'>
                    <div className='topMoverheader'>
                        <span className='topgainorloser'>Top Losers</span>
                    </div>
                    <div className={`${toploserTableData.length >= 4 ? "top_moverstablescls" : ""}`}>
                    <BootstrapTableNext remote bodyClasses="topmvtablestle" headerClasses="topMvtable" pagination={ paginationFactory(toploseroptions)} data={ toploserTableData }
             columns={ topLosercolumns } keyField="updates" onTableChange={ handleTableChangeloser } noDataIndication={ () => <NoDataIndication /> }  /> 
                    </div>
              
                </div>
            </div>
            </div>
            }
        </div>
    )

}

export default NewInsightsTopMoversTable;