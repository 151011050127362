import React, { useState, useEffect } from "react";
import "./Subscriptions.scss";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import {
  getSubscriptionsForUser,
  getDetailsForAllStrategies,
  saveSubscriptionsForUser,
} from "../../actions";

import { bindActionCreators } from "redux";
import _get from "lodash/get";
import { useDispatch, useSelector,connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import Filters from "../../components/Filters";
import { TickIcon, CloseIcon } from "../../components/Icons";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { RenderSelectedFilters , addCommonActivityCaptureDetail, getCookie} from "../../helper";
import SubscriptionFilters from "../../components/subscriptionFilter";

const Subscriptions = (props) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { authState, authService } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [displayEditCard, setDisplayEditCard] = useState(false);
  const [callFilterComponent, setCallFilterComponent] = useState("");
  const [editedSubscriptionItem, setEditedSubscriptionItem] = useState("");
  const [listOfStrategies, setListOfStrategies] = useState([]);
  const [filters, setFilters] = useState({});
  let { globalFilter, selectedGlobalFilters } = useSelector(
    (state) => state.globalReducer
  );
  let {  selectedStrategies } = useSelector(
    (state) => state.strategyReducer
  );
  useEffect(() => {
    document.title = `Subscriptions`;
    addCommonActivityCaptureDetail({page : "Subscriptions",globalFilters:selectedGlobalFilters ,strategyFilters: selectedStrategies})
    props.getDetailsForAllStrategiesAPI({
      projectName: "Walmart",
      strategyStatus: "active",
    });
  }, []);
  useEffect(() => {
    if (props.allStrategyDetails) {
      const { allStrategyDetails } = props;
      let arr = [];
      allStrategyDetails.map((strategy, index) => {
        arr.push(strategy.strategyName);
      });
      setListOfStrategies(arr);
    }
  }, [props.allStrategyDetails]);
  useEffect(() => {
    if (props.userSubscriptions && props.userSubscriptions.length) {
      props.userSubscriptions.forEach(subscription => {
        let allFilterValues = props.globalFilter.filterValues
        let filterObject = subscription.filters
        let alertName = subscription.recommendationType ? subscription.recommendationType.toLowerCase().replace(/_/g, '-').replace(/ /g, '-') : ''
        let selectedParentFilter = []
        let selectedFilterName = getCookie(`selected-filter-name-${alertName}`) ? getCookie(`selected-filter-name-${alertName}`) : []
        let selectedFilterObj = getCookie(`selected-filter-object-${alertName}`) ? getCookie(`selected-filter-object-${alertName}`) : []
        Object.keys(filterObject).forEach(filterName1 => {
          if(filterObject[filterName1] && filterObject[filterName1].length && allFilterValues && allFilterValues[filterName1] && allFilterValues[filterName1].length) {
            if(!selectedFilterName.includes(filterName1)){
              selectedFilterName.push(filterName1)}
            document.cookie = `selected-filter-name-${alertName}=${JSON.stringify(selectedFilterName)};path=/;max-age=28800;`;
            allFilterValues[filterName1].forEach((filterValue, index)  => {
              if(filterObject[filterName1].includes(filterValue.value)) {
                selectedFilterObj.push({val: filterValue.value, filterName: filterName1, parent: filterValue.parents})
                document.cookie = `selected-filter-object-${alertName}=${JSON.stringify(selectedFilterObj)};path=/;max-age=28800;`;
                selectedParentFilter = []
                let filteredObj = selectedFilterObj.filter(filterVal1 => filterVal1.filterName === filterName1)
                filteredObj.forEach(values => {
                  selectedParentFilter = selectedParentFilter.concat(values.parent)
                })
                document.cookie = `selected-parent-filter-${alertName}=${JSON.stringify(selectedParentFilter)};path=/;max-age=28800;`;
              }
            })
          }
        })
      })
    }
  });
  useEffect(() => {
    if (userInfo) {
      Promise.all([props.getSubscriptionsForUserAPI(userInfo.email)]).then(
        function (res) {
          if (res[0].data.responseData && res[0].data.responseData.subscriptions){
            setSubscriptions(res[0].data.responseData.subscriptions);
          }
          setLoading(false);
        }
      );
    }
  }, [userInfo]);
  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      authService.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, authService]); // Update if authState changes
  

  const handleSusbscribe = (item) => {
    let itemExists = false;
    for (let i = 0; i < subscriptions.length; i++) {
      if (subscriptions[i].recommendationType === item) {
        itemExists = true;
        break;
      }
    }
    if (!itemExists) {
      let arr = cloneDeep(subscriptions);
      let obj = {
        recommendationType: item,
        filters: props.globalFilter.subscriptionFilters,
      };
      arr.push(obj);

      setSubscriptions(arr);
    }
  };

  const renderListOfSubscriptions = () => {
    return listOfStrategies.map((item, index) => {
      let arr = subscriptions.filter((subscription) => {
        return subscription.recommendationType === item;
      });
      let exists = arr.length > 0 ? true : false;
      if (exists) {
        return (
          <div key={index}>
            <div key={index} className="option option_hover">
              <span className="selected_option">{item}</span>
              <span className="selected_option">
                {" "}
                <TickIcon />
              </span>
            </div>
          </div>
        );
      } else {
        return (
          <div key={index}>
            <div
              key={index}
              className="option option_hover flex"
              onClick={(e) => {
                handleSusbscribe(item);
              }}
            >
              <span className="unselected_option">{item}</span>
              <span className="user_option"> Subscribe</span>
            </div>
          </div>
        );
      }
    });
  };
  const renderListOfSubscribed = () => {
      return subscriptions.map((subscription, index) => {
        return (
          <>
            {renderSubscriptionCard(subscription, index)}
          </>
        );
      });
       
  };
  function handleUnsubscribeStrategy(index) {
    let subscriptionState = cloneDeep(subscriptions);
    subscriptionState.splice(index, 1);
    setSubscriptions(subscriptionState);
   
  }
  function renderSubscriptionCard(subscription, index) {
    const { filters } = subscription;
    let count = 0;
    Object.keys(filters).map((key) => {
      count += filters[key].length;
    });
    let filterObj = count > 0 ? filters : props.globalFilter.subscriptionFilters;
    let alertName = subscription.recommendationType ? subscription.recommendationType.toLowerCase().replace(/_/g, '-').replace(/ /g, '-') : ''
    let filterObject = getCookie(`selected-global-filters-${alertName}`) ? getCookie(`selected-global-filters-${alertName}`) : filterObj
   
    return (
      <div className="subscribed">
        <div onClick={(e) => {
              onClickFilter(subscription.recommendationType);
            }}>
          <SubscriptionFilters
            title={subscription.recommendationType}
            handleUnsubscribeStrategy={handleUnsubscribeStrategy}
            strategyIndex={index}
            displayUnsubscribeButton={true}
            getFilteredResults={getFilteredResults}
            subscriptionFilterValues={filterObject}
            loadFilterComp={callFilterComponent}
          />
        </div>
        <div className="horizontal_divider my-2 "></div>
      </div>
    );
  }

  function onClickFilter(alertName) {
    setCallFilterComponent(alertName)
  }

  function getFilteredResults(index,obj) {
      let subscriptionObj = cloneDeep(subscriptions);
      let subscriptionItem = subscriptionObj[index];
      if(subscriptionItem){
          subscriptionItem.filters = obj;
      }
        setSubscriptions(subscriptionObj);
  }
  
  function handleSave(){
    let obj = {
        user: userInfo.email,
        frequency: "daily",
        subscriptionData: subscriptions,
        subscriptionStatus: subscriptions.length > 0 ? "active" : "inactive"

    }
    props.saveSubscriptionsForUserAPI(obj, (res, rej) => {
        if (res) {
            toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
        } else {
            
        }
    });
  }

  return (
    <div id="subscription_wrapper">
      <ToastContainer />

      {/* main content starts */}
      <div className="container-fluid  default-top  bg-gray p-5">
        

        <div className="row">
          <div className="col-12 n-padding d-flex justify-content-between">
            <div>
              <div className="header">Subscriptions</div>
              <div>
                Subscription email will be generated at the end of each working
                day.
              </div>
            </div>
            <div>
                <button className="btn btn-apply" onClick={handleSave}>Save Changes<TickIcon/></button>
            </div>
          </div>
        </div>
        {/* recommenation subscription */}
        <div className="row my-5">
          <div className="col-12">
            <div className="row">
              <div className="col-3">
                <h3>All Strategies/Recommendations</h3>
                <div className="list_wrapper">
                  {renderListOfSubscriptions()}
                </div>
              </div>
              <div className="col-9">
                <h3>Your subscriptions </h3>
                <div className="list_wrapper">
                  {renderListOfSubscribed()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  let userSubscriptions = _get(
    state,
    "subscriptionReducer.getUserSubscription.responseData.subscriptions",
    []
  );
  let saveUserSubscription = _get(
    state,
    "subscriptionReducer.saveUserSubscription.responseData",
    []
  );
  let allStrategyDetails = _get(
    state,
    "strategyReducer.detailsForAllStrategies",
    []
  );
  let globalFilter = _get(state, "globalReducer.globalFilter.data", []);
  return {
    userSubscriptions: userSubscriptions,
    allStrategyDetails: allStrategyDetails,
    saveUserSubscription: saveUserSubscription,
    globalFilter
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSubscriptionsForUserAPI: getSubscriptionsForUser,
      getDetailsForAllStrategiesAPI: getDetailsForAllStrategies,
      saveSubscriptionsForUserAPI: saveSubscriptionsForUser,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
