import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { updateViewBuilderJSON } from '../../../actions';
import cloneDeep from 'lodash/cloneDeep';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }


  handleViewBuilderJSONChange(data){
      let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
      let id =  this.props.id
      tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]['innerhtml']=data;
      this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
  }
  renderEditor(){
       return(
            <div className="formContainer">
            <CKEditor
               editor={ ClassicEditor }
               data={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['innerhtml']}
               onInit={ editor => {
               } }
               onChange={ ( event, editor ) => {
                   const data = editor.getData();
                   this.handleViewBuilderJSONChange(data)
               } }
               onBlur={ ( event, editor ) => {

               } }
               onFocus={ ( event, editor ) => {
                   
               } }innerhtml
           />
          </div>
       )
   }


    render() {
        return (
           this.renderEditor()
      );
    }
}
function mapStateToProps(state) {
	return ({
		viewBuilder:state.viewBuilderObj,

	})
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateViewBuilderJSONAPI:updateViewBuilderJSON
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Editor);
