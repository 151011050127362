import React,{useEffect} from 'react';
import Navbar from './Navbar'
import { hideNavbarPages } from '../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

 function ComingSoon(props){
     useEffect(()=>{
        props.hideNavbarPages({value:true});
        return ()=>{
         props.hideNavbarPages({value:false});
        }
     },[]);
    return (
        <React.Fragment>
        <div className="container-fluid  default-top loading_wrapper  bg-gray">
            <h3 className="mr-2">Coming soon ...</h3> 
        </div>
        </React.Fragment>
    )
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		hideNavbarPages
	}, dispatch)
}


export default connect(null, mapDispatchToProps)(ComingSoon);