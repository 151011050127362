import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom'
import './ProjectsPage.scss';
import { Link } from 'react-router-dom';
import {addCommonActivityCaptureDetail, generateStrategyNameList} from '../helper'
import AddNewSubProjectModal from './AddNewSubProjectModal'
import {
    setSubprojectInfo, strategySelectedDateRange, strategyFilterAndOrCondition, getOrUpdateLatestUpdateAvailability,
    resetViewBuilderData, setSubproject,getTabList, setRetailerCurrency, setCustomerKey, saveDateRangeFilter, storeAdoptionSelectedStrategies, getDateFilterOptions
} from '../actions'

const SubprojectsPage: React.FC = () => {
    const [displayAddNewSubProject, setDisplayAddNewSubProject] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isSecure, setIsSecure] = useState("");
    interface Project {
        name: string,
        oktaGroupAdmin: string,
        subProjectInfo: [],
        logo: string
    }
    interface Subproject {
        name: string,
        logo: string,
        oktaGroupAdmin: string,
        oktaGroupRead: string,
        currencyCode: string,
        customerKey: string
    }
    useEffect(() => {
        if(window.location.hostname !== "localhost"){
            setIsSecure('secure')    
          }
        addCommonActivityCaptureDetail({page : "Sub-Projects",globalFilters:selectedGlobalFilters ,strategyFilters: selectedStrategies})
    }, []);
    let projectAndSubprojects: Array<Project> = useSelector((state: any) => state.globalReducer.projectAndSubprojects);
    let isAppAdmin: boolean = useSelector((state: any) => state.globalReducer.isAppAdmin);
    let isProjectAdmin: boolean = useSelector((state: any) => state.globalReducer.isProjectAdmin);
    let project: string = useSelector((state: any) => state.globalReducer.project);
    let selectedGlobalFilters: Object = useSelector((state: any) => state.globalReducer.selectedGlobalFilters);
    let selectedStrategies: Array<String> = useSelector((state: any) => state.strategyReducer.selectedStrategies);

    let selectedProjectInfo: Project = useSelector((state: any) => state.globalReducer.selectedProjectInfo);
    let detailsForAllStrategies: [] = useSelector((state: any) => state.strategyReducer.detailsForAllStrategies);
    const projectDetails: Array<Project> = projectAndSubprojects.filter((individualProject) => {
        return individualProject.name === project;
    });
    const listOfSubprojects: Array<Subproject> = projectDetails[0].subProjectInfo;

    interface UserInfo {
        name: string;
        sAMAccountName: number;
        email: string;
      }
      let userStore = localStorage.getItem("user-info");
      let userInfo: UserInfo | null= null;
      if(userStore) {
         userInfo = JSON.parse(userStore) as UserInfo;  
      }
      

    function hideAddNewSubProjectModal() {
            setDisplayAddNewSubProject(false);
    }
    function displayAddNewSubProjectModal() {
            setDisplayAddNewSubProject(true);
    }
    return (
        <div className="wrappers-with-min-height">
             {displayAddNewSubProject ? <AddNewSubProjectModal
                displayAddNewSubProjectModal={displayAddNewSubProjectModal}
            hideAddNewSubProjectModal={hideAddNewSubProjectModal}
            />:null}
            <div className="container-fluid  default-top  bg-gray full-screen-height" >
                <div className="p-5">
                    <div className="row d-flex align-items-center justify-content-between  mb-4">
                        <h3 className="header">Sub-Projects</h3>
                        {isAppAdmin || isProjectAdmin ?
                        <button className="btn btn-cta"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => { setDisplayAddNewSubProject(true) }}>

                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="mr-2 bi bi-plus-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                        </svg>
                        <span>New Sub-Project</span>
                    </button>
                        : null}

                    </div>
                    <div className="row bg-white tile-list">

                        {listOfSubprojects.map((individualSubproject, index) => {
                            return (
                                <div key={index}
                                    className="col-3 tile-wrapper">
                                    <div className="tile">
                                        <div>
                                            <div className="subproject_logotitle">
                                            <img src={individualSubproject.logo} height="30" />
                                            <span className="tile-name">{individualSubproject.name}</span>
                                            </div>
                                        </div>
                                        <div className="title-subtext">
                                            <div className="btn"  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                                                document.cookie = "selected-global-filters= ; path=/; max-age=0; isSecure;"
                                                document.cookie = "selected-date-range-filters= ; path=/; max-age=0; isSecure;"
                                                document.cookie = "selected-parent-filter=; path=/; max-age=0; isSecure;"
										        document.cookie = "selected-filter-name=; path=/; max-age=0; isSecure;"
                                                document.cookie = "selected-filter-object=; path=/; max-age=0; isSecure;"
                                                dispatch(saveDateRangeFilter({
                                                    'dateRange': '',
                                                      'compareTo': ''
                                                }));
                                                dispatch(setSubprojectInfo(individualSubproject));
                                                dispatch(setRetailerCurrency(individualSubproject.currencyCode))
                                                dispatch(setCustomerKey(individualSubproject.customerKey))
                                                dispatch(setSubproject({ subproject: individualSubproject.name }));
                                                localStorage.setItem("subproject", individualSubproject.name);
                                                dispatch(getTabList());
                                                dispatch(resetViewBuilderData());
                                                dispatch(storeAdoptionSelectedStrategies(generateStrategyNameList(detailsForAllStrategies)));
                                                dispatch(getDateFilterOptions());
                                                dispatch(strategySelectedDateRange('Default'));
                                                dispatch(getOrUpdateLatestUpdateAvailability({userId:userInfo?.sAMAccountName,userName:userInfo?.name,userEmail:userInfo?.email,isUpdate:'FALSE', Retailer:individualSubproject?.name})); 
                                                dispatch(strategyFilterAndOrCondition(''));
                                                history.push({
                                                    pathname:`/${project.toLocaleLowerCase()}/${individualSubproject.name.toLocaleLowerCase()}/`,
                                                     });
                                            }}>Select<svg width="1em" height="1em" viewBox="0 0 16 16" className="ml-2 bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z" />
                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SubprojectsPage;
