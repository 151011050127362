import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from "react-bootstrap-table2-paginator";
import { getSummaryByAlert , getUseCaseList } from '../../actions/index';
import {convertToKMB, generateStrategyNameList, generateUseCaseNameList, dynamicRetailerimpactPriceFormat } from "../../helper";
import { CarretIconUp, CarretIconDown } from '../../components/Icons';

const AlertSummaryTable = (props) => {
  const [alertSummaryData, setAlertSummaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState();
  const dispatch = useDispatch();
  let {selectedCustomerKey, selectedRetailerCurrency, dynamicPriceFormatting, selectedSubprojectInfo } = useSelector((state) => state.globalReducer);
  
  const [tempAlertSummaryData, setTempAlertSummaryData] = useState([]);

  let allStrategyDetails = useSelector(
    (state) => state.strategyReducer.detailsForAllStrategies
  );
  let [selectedStrategies, setSelectedStrategies] = useState(generateStrategyNameList(allStrategyDetails));

  let {
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedStrategies,
    alertSummary,
    allUseCases,
    adoptionSelectedUseCases,
    retailerCurrency
  } = useSelector((state) => state.adoptionReducer);

  let retailerCurrencySign = retailerCurrency === undefined || retailerCurrency === '' || retailerCurrency === 'USD' ? selectedRetailerCurrency : '$'; 

  const options = {
    totalSize: totalSize,
    sizePerPage: sizePerPage,
    page: pageNumber,
    showTotal: true,
    disablePageTitle: true,
  };

  let responseHandler = (response) => {
  if(response?.data){
     if(response.data.responseData.length <= 0) {
      setLoading(false)
     }else {
      setAlertSummaryData(response.data.responseData);
      setTempAlertSummaryData(response.data.responseData); 
      setTotalSize(response.data.responseData.length)
      setLoading(false)
    }
  } else {
    setLoading(false)
  }
  };

  let requestObject = () => {
    return {
      userNames: adoptionSelectedUsers ? adoptionSelectedUsers : [],
      strategies: adoptionSelectedStrategies ? adoptionSelectedStrategies : [],
      dropDownSelectionPeriod: selectedDateRange
        ? selectedDateRange
        : "Prior Week",
      additionalParametersInterval: adoptionCustomDates
        ? adoptionCustomDates
        : {},
      additionalParametersPoint: adoptionAdditionalParamaters,
      pageNo: pageNumber,
      totalRows: sizePerPage,
      useCase: adoptionSelectedUseCases,
      currency: retailerCurrency === undefined || retailerCurrency === '' ? 'USD' : retailerCurrency,
      customerKey: selectedCustomerKey
    };
  }; 


  useEffect(() => {
    if(alertSummary && alertSummary.responseData){
      setAlertSummaryData(alertSummary.responseData) 
      setTempAlertSummaryData(alertSummary.responseData); 
      setTotalSize(alertSummary.responseData.length)
      setLoading(false);
    }
  }, [alertSummary]);

  useEffect(() => {
    setLoading(true)
    if (selectedDateRange) {
    dispatch(getSummaryByAlert(requestObject(), responseHandler));
    }
  },[
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedStrategies,
    sizePerPage,
    pageNumber,
    retailerCurrency,
    adoptionSelectedUseCases,
    adoptionSelectedStrategies
  ]);
  const estimatedRevenueFormatter = (cell) => {
    if(cell !== undefined && cell !== null && cell !== 'NaN') {
    return (
          <>
            {Number(cell) && dynamicPriceFormatting && dynamicPriceFormatting === 'dot' ? dynamicRetailerimpactPriceFormat(convertToKMB(Number(cell), retailerCurrencySign)) : Number(cell) ? convertToKMB(Number(cell), retailerCurrencySign) : retailerCurrencySign + cell}
          </>
    )
    } else {
      return retailerCurrencySign + 0
    }
  }
  const percentValue = (cell) => {
    if(cell !== undefined && cell !== null && cell !== 'NaN' && cell !== 'NA') {
      let finalVal = parseFloat(cell).toFixed(2);
      let finalValfixed = parseFloat(cell).toFixed(0);
      if(finalVal == 0) {
        return <span className="clr-grn"><CarretIconUp />{finalValfixed}%</span>
      } else if (finalVal > 0) {
        return <span className="clr-grn"><CarretIconUp />{finalVal}%</span>
      }  else {
        return  <span className="clr-rd"><CarretIconDown />{finalVal}%</span>
      }     
    } else {
      return  <span className="clr-grn"><CarretIconUp />0%</span>
    } 
  }
  const percentValuenew = (cell) => {
    return(<>{cell != undefined ? cell + '%' : '0%'}</>)
  }
  const actionTime = (cell) => {
    return(<>{cell == 1 ? cell + 'day' : cell != undefined ? cell + ' days' : '0 days'}</>)
  }
  
  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {

    if (type === "pagination") {
      setSizePerPage(sizePerPage);
      setPageNumber(page);
    }
  };

  const textoptions = {
    noDataText: 'No Alert Data Available'
  };
  if (loading) {
    return (
      <div id="no-data-available" style={{ marginTop: "5%", height: '80px' }}>
        Loading....
      </div>
    );
  } else {
  return (
      <div className='alert_summarytable alert-bysummary'>
        <div className="row ml-0">
          <div style={{fontSize: 18, marginBottom: 10,marginTop: 35, fontWeight: "bold" }}>
            Summary By Alert
          </div>              
        </div>
        <BootstrapTable data={ alertSummaryData } options={textoptions} pagination={ paginationFactory(options)} onTableChange={ handleTableChange }> 
        <TableHeaderColumn row='0' width='85' dataField='useCase' dataSort={ true } rowSpan='2' isKey={ true }>Use Case</TableHeaderColumn>
        <TableHeaderColumn row='0' width='85' dataField='alertName' dataSort={ true } rowSpan='2'>Alert </TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='2' headerAlign='center'>New</TableHeaderColumn>
        <TableHeaderColumn row='1' width='70' dataField='percentageOfPendingEstimatedValue' dataFormat={percentValuenew} dataSort={ true } dataAlign='center'>%Total</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='newEstimatedValue' dataSort={true} dataFormat={estimatedRevenueFormatter} dataAlign='center'>Estimated Value</TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='2' headerAlign='center'>Actions Taken</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='actionedEstimatedValue' dataSort={true} dataFormat={estimatedRevenueFormatter} dataAlign='center'>Estimated Value</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='avgActionTime' dataFormat={actionTime} dataSort={true} dataAlign='center'>Avg Action time</TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='1' headerAlign='center'>Unresolved Actions</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='UnResolvedEstimatedValue' dataFormat={estimatedRevenueFormatter} dataSort={ true } dataAlign='center'>Estimated Value</TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='2' headerAlign='center'>Resolved Actions</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='resolvedEstimatedValue' dataSort={true}  dataFormat={estimatedRevenueFormatter} dataAlign='center'>Estimated Value</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='avgResolutionTime' dataFormat={actionTime} dataSort={true} dataAlign='center'>Avg Resol. time</TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='2' headerAlign='center'>Sales Impact</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='salesImnpactValue' dataSort={true}  dataFormat={estimatedRevenueFormatter} dataAlign='center'>Value</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='salesImnpactPercent' dataFormat={percentValue} dataSort={true} dataAlign='center'>PoP</TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='6' headerAlign='center'>Effectiveness</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='effectivenessVsTriggered' dataFormat={percentValuenew} dataSort={true} dataAlign='center'>VS. Triggered</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='effectivenessVsTriggeredPOP' dataFormat={percentValue} dataSort={true} dataAlign='center'>PoP</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='effectivenessVsTriggeredYOY' dataFormat={percentValue} dataSort={true} dataAlign='center'>YoY</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='effectivenessVsActioned' dataFormat={percentValuenew} dataSort={true} dataAlign='center'>VS. Actioned</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='effectivenessVsActionedPOP' dataFormat={percentValue} dataSort={true} dataAlign='center'>PoP</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='effectivenessVsActionedYOY' dataFormat={percentValue} dataSort={true} dataAlign='center'>YoY</TableHeaderColumn>
        </BootstrapTable>
      </div>  
    )
  }
}

export default AlertSummaryTable;