import React, { useEffect, useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './IdleTimeOutModal.scss';
import { useSelector } from "react-redux";
const IdleModal = (props) => {
    const refreshPage = ()=>{window.location.reload();}
    return (
        <Modal show={true} onHide={props.displayModalOff} id="IdleTimeOut-modal" keyboard={ false } backdrop="static">
            <Modal.Header>
                <Modal.Title>eCompass</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3 idle-plr">You've been inactive for more than {props.retailerIdleTime ? props.retailerIdleTime : 10} minutes.</div>
                <div>Please Refresh the page to see the latest changes.</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={refreshPage}> Refresh </Button>            
            </Modal.Footer>
        </Modal>
    )
}

const IdleTimeOutModal = () => {
    const [displayModal, setDisplayModal] = useState(false);
    let { retailerIdleTime } = useSelector((state) => state.globalReducer);
    let idleTime = retailerIdleTime * 60 * 1000;
    let allownumber = isNaN(Number(idleTime)) ? 10 * 60 * 1000 : idleTime;
    const displayModalOff = () => {
        setDisplayModal(false);
    }  

    let warnTimeout;
    const warn = () => {
        setDisplayModal(true);
    };
    const setTimeouts = () => {
        warnTimeout = setTimeout(warn, allownumber);
    };

    const clearTimeouts = () => {
        if (warnTimeout) clearTimeout(warnTimeout);
    };
    useEffect(() => {        
        const events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        const resetTimeout = () => {
            clearTimeouts();
            setTimeouts();
        };

        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }

        setTimeouts();
        return () => {
            for(let i in events){
                window.removeEventListener(events[i], resetTimeout);
                clearTimeouts();
            }
        }
    },[]); 

    return (
        <div>
            {displayModal && <IdleModal retailerIdleTime={retailerIdleTime} display={displayModal} displayModalOff={displayModalOff} /> }
        </div>        
    )
}

export default IdleTimeOutModal;