import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { DropDownIcon } from "../../components/Icons";
import "../Recommendations/Recommendations.scss";
import {
  getAdoptionEstimatedValueNew,
  getSummaryByAlert,
  getSummaryByUser,
  storeAdoptionSelectedUseCases,
  retailerCurrencyCode,
  getEngagementSummary
} from "../../actions/index";
import { generateUseCaseNameList } from "../../helper";

export default function UserCaseFilter(props) {
  const [userNameList, setUserList] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [searchString, setSearchString] = useState("");
  let { allUseCases, adoptionSelectedUseCases,adoptionSelectedStrategies, adoptionSelectedUsers, selectedDateRange, adoptionCustomDates, adoptionAdditionalParamaters } = useSelector(
    (state) => state.adoptionReducer
  );
  let { selectedSubprojectInfo } = useSelector((state) => state.globalReducer);
  const [selectedUsesCases, setSelectedUsesCases] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  let dispatch = useDispatch();

  let requestObject = () => {
    return {
      userNames: adoptionSelectedUsers ? adoptionSelectedUsers : [],
      strategies: adoptionSelectedStrategies ? adoptionSelectedStrategies : [],
      dropDownSelectionPeriod: selectedDateRange
        ? selectedDateRange
        : "Prior Week",
      additionalParametersInterval: adoptionCustomDates
        ? adoptionCustomDates
        : {},
      additionalParametersPoint: adoptionAdditionalParamaters,
      useCase: selectedUsesCases
    };
  };

  useEffect(() => {
    if (allUseCases) {
      setUserList([...generateUseCaseNameList(allUseCases)]);
      dispatch(storeAdoptionSelectedUseCases(generateUseCaseNameList(allUseCases)));
    }
  }, [allUseCases]);

  function setCheckBox(option) {
    if (allUseCases && allUseCases.length > 0) {
      if (option === 'Select All') {
        return [...generateUseCaseNameList(allUseCases)].length === userNameList?.length ? true : false
      } else {
        return userNameList && userNameList?.indexOf(option) > -1 ? true : false
      }
    }
  }

  function renderOptions(data) {
    if (data) {
      data.sort(function (a, b) {
        return a?.localeCompare(b);
      });
      data = data.filter((username) => {
        return username?.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
      });
      data.unshift("Select All");
      return data.map((option, index) => {
        return (
          <Form.Check
            checked={setCheckBox(option)}
            type="checkbox"
            key={index}
            required
            name="multiSelectFilter"
            label={option}
            onChange={(e) => {
              handleCheckboxClick(e, option);
            }}
            id={index}
          />
        );
      });
    } else {
      return null;
    }
  }

  function handleCheckboxClick(event, option) {
    let finalUserList = [...userNameList]
    if (event.target.checked) {
      option === 'Select All' ? finalUserList = [...generateUseCaseNameList(allUseCases)] : finalUserList.push(option)
    } else {
      option === 'Select All' ? finalUserList = [] : finalUserList.splice(userNameList.indexOf(option), 1)
    }
    setUserList(finalUserList);
    setSelectedUsesCases(finalUserList);
  }

  function handleApplyFilter(e) {
    e.preventDefault();
    dispatch(storeAdoptionSelectedUseCases(userNameList));
    onToggle(false, true);
  }

  function onToggle(e, apply) {
    !apply && setUserList(adoptionSelectedUseCases)
    setDisplayDropdown(e);
  }

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    dispatch({type: "RETAILER_CURRENCY_CODE",payload: currency})
  };

  return (
    <>
    <div className="ml-auto mr-1">   
    <Form className="currencycls">
       <Form.Group>
          <Form.Check inline
            type="radio"
            label="Local Currency"
            value='USD'
            checked={selectedCurrency === 'USD'}
            onChange={() => handleCurrencyChange('USD')}
            className={selectedCurrency === 'USD' ? 'currencyClr' : ''}
          />
          <Form.Check inline            
            type="radio"
            label="USD"
            value={selectedSubprojectInfo?.currencyCode}
            checked={selectedCurrency === selectedSubprojectInfo?.currencyCode}
            onChange={() => handleCurrencyChange(selectedSubprojectInfo?.currencyCode)}
            className={selectedCurrency === selectedSubprojectInfo?.currencyCode ? 'currencyClr' : ''}
          />          
        </Form.Group>
      </Form>
    </div>
    <div className="p-1">
    <div id="UserNameFilter">
      <Dropdown
        className=""
        show={displayDropdown}
        onToggle={onToggle}
        id="user-dropdown"
      >
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="btn-cta"
        >
          UseCase Filter
          {`(${userNameList?.length})`}
          <DropDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Form>
            <Form.Group className="filter_dropdown_wrapper">
              <input
                type="text"
                placeholder="Search"
                className="form-control mb-3"
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                  setUserList([]);
                }}
              />
              <div className="strategy_dropdown_wrapper">
                {allUseCases ? renderOptions([...generateUseCaseNameList(allUseCases)]) : "No Data Available"}
              </div>
              <div className="apply_button_wrapper">
                <div className="horizontal_divider"></div>
                {userNameList?.length <= 0 ? <div className="error">Please select a use case</div> : null}
                <div className="d-flex justify-content-between">
                  <button
                    className={`btn btn-disabled ${userNameList?.length > 0 ? null : "btn-disabled-clear-all"
                      } my-2`}
                    onClick={(e) => {
                      e.preventDefault();
                      setUserList([]);
                      setSearchString("");
                    }}
                  >
                    Clear All
                  </button>
                  <button
                    className={`btn btn-apply  ${userNameList?.length > 0 ? null : "btn-disabled-apply"
                      } my-2`}
                    onClick={(e) => {
                      handleApplyFilter(e);
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </div>
    </div>
    </>
  );
}
