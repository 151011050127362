import React, { useState, useEffect } from "react";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  insightsDatesParameters,
  insightsStartEndDates,
  getSOYearsList
} from "../actions";
import MonthSelector from "./MonthSelector";

function returnFormatedDate(date) {
  return moment(date).format("YYYY-M-D");
}
function Datepicker(props) {
  if (props.isSpecificMonthView)
    return (
      <MonthSelector
        selectMonthHandler={props.selectMonthHandler}
        selectedSpecificMonth={props.selectedSpecificMonth}
        selectedSpecificYearHandler={props.selectedSpecificYearHandler}
        selectedSpecificYear={props.selectedSpecificYear}
        spefificMonthList={true}
      />
    );  
}

export default function InsightsDateFilter(props) {
  const dispatch = useDispatch();
  let {selectedSelectionAndMetricName, insightsDatesParamaterdata, insightsStartEndDatesdata, insightsYearsList } = useSelector((state) => state.insightReducer);   
  const [selectedSpecificMonth, setSelectedSpecificMonth] = useState(insightsStartEndDatesdata ? insightsStartEndDatesdata-1 : undefined);
  let yearsListData = insightsYearsList?.responseData;
  const [selectedSpecificYear, setSelectedSpecificYear] = useState();
  let { selectedCustomerKey } = useSelector((state) => state.globalReducer);
  const [displayDatepicker, setDisplayDatepicker] = useState(false);
  const [isSpecificMonth, setIsSpecificMonth] = useState(true);
  const [dateRangeConfigs, setDateRangeConfigs] = useState({
    from: undefined,
    to: undefined,
  });

  let selectMonthHandler = (selectedMonth) => {
    setSelectedSpecificMonth(selectedMonth);
  };
  let selectedSpecificYearHandler = (selectedYear) => {
    setSelectedSpecificYear(selectedYear);
  };

  const onToggleDatepicker = (e) => {
    setDisplayDatepicker(e);
  };
  useEffect(() => {   
    dispatch(getSOYearsList({customerKey: selectedCustomerKey},(res)=>{
      if(res?.data?.responseData?.length) {
        let yearsList = res?.data?.responseData; 
        let yearssort = yearsList.sort((a, b) => b - a);
        setSelectedSpecificYear(yearssort[0])
      }
    }));
  },[])  

  useEffect(() => {
    if(selectedSpecificMonth >= 0) {
        let startDate = moment([selectedSpecificYear, selectedSpecificMonth]),
        endDate = moment(startDate).endOf("month");      
        dispatch(insightsDatesParameters(selectedSpecificMonth + 1));
        dispatch(
          insightsStartEndDates({
            start: returnFormatedDate(startDate.toDate()),
            end: returnFormatedDate(endDate.toDate()),
          })
        );
        onToggleDatepicker(false);  
      }     
  },[selectedSpecificMonth,selectedSpecificYear])

  const renderSelectedDates = () => {
      return (
        <span className="date-range">
          {insightsStartEndDatesdata?.start === undefined ? 'Current Month' : 
          `${moment(insightsDatesParamaterdata, "MM").format("MMMM")} - ${moment(insightsStartEndDatesdata?.start,"YYYY-MM-DD").year()}`
          }
          </span>
      );     
  };
  const handleResetClicked = () => {
    setDisplayDatepicker(false);
    setSelectedSpecificMonth(-1);
    setSelectedSpecificYear(yearsListData?.length ? yearsListData[0] : new Date().getFullYear());
    dispatch(
      insightsStartEndDates({
        start:undefined,
        end: undefined
      }));
    setDateRangeConfigs({
      from: undefined,
      to: undefined,
    });
  };

  useEffect(() => { 
    if(insightsStartEndDatesdata?.start !== undefined) {
      handleResetClicked(); 
    }                 
  }, [selectedSelectionAndMetricName?.sectionName,selectedCustomerKey]);

  return (
    <Dropdown
      onToggle={onToggleDatepicker}
      show={displayDatepicker} className="mr-4 specificDate">
      <Dropdown.Toggle variant="light" id="dropdown-datepicker">
        <div id="date-range-picker" className="date-range-picker">
          {renderSelectedDates()}
          <i className="far fa-calendar-alt clander-icon"></i>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu id="datepicker-menu" toggle={false}>
        <div className="" style={{padding:'10px'}}>
          <Datepicker      
            dateRange={dateRangeConfigs}
            isSpecificMonthView={isSpecificMonth}
            selectMonthHandler={selectMonthHandler}
            selectedSpecificYearHandler={selectedSpecificYearHandler}
            selectedSpecificYear={selectedSpecificYear}
            selectedSpecificMonth={selectedSpecificMonth}
          /> 
          {insightsStartEndDatesdata?.start !== undefined ?
           <div style={{cursor:'pointer',color:'#1a73e8'}} onClick={(e) => {
                    handleResetClicked();
                  }} className='pt-4 d-block ml-2'>Back to Current Month</div> 
            : <div style={{color:'#ddd'}} className='pt-4 d-block ml-2'>Back to Current Month</div>
            }      
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
