export default function(state={},action){
    switch(action.type){
        case "INSERT_NEW_VIEW_DATA":return {...state,insertNewViewData:action.payload}
        case "ALL_ADMIN_VIEWS" : 
         return {...state,allAdminViews:action.payload}
        case "GET_SELECTED_VIEW" : {
            return {...state,selectedViewDetails:action.payload, 
            selectedDateRange: action?.payload?.responseType ==='success' ? action.payload.responseData[0].defaultDateRange : []}
        }
        case "UPDATE_SELECTED_VIEW" : return {...state,updateSelectedViewDetails:action.payload}
        case "UPDATE_HTML_TEMPLATE_VIEW" : return {...state,updateHtmlTemplateViewDetails:action.payload}
        case "RESET_DASHBOARD_DETAILS" :  return {...state,selectedViewDetails:action.payload}
        case "SAVE_VIEW_BUILDER" :  return {...state,viewBuilderDetails:action.payload}
        case "UPDATE_JSON_FOR_VIEW" :  return {...state,updateJsonForView:action.payload}
        case "DISPLAY_ALL_KPIREPORTS" :  return {...state,displayAllKPIReports:action.payload,allKPIReportsdata:action.payload.responseData.listData,totalcount:action.payload.responseData.totalCount }
        default:
            return state;
    }
}