import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { latestUpdatesModalData, getReleaseById } from '../actions/index';
import moment from "moment";
import parse from 'html-react-parser';
import ReactPaginate from 'react-paginate';
import './CustomPagination.scss';


function LatestModal(props) {
  const [latestData, setLatestData] = useState([]);
  const [preModalBodyText, setPreModalBodyText] = useState("Loading...");
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState();
  const [releaseContent,setReleaseContent] = useState({});
  let releaseByid = props.releaseByid;
  const [postsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0)

  const getAllPosts = () => {
    const responseHandlerupdate = (response) => {
      if (
        response.data &&
        response.data.responseType &&
        response.data.responseType.toLowerCase() === "success"
      ) {
       if(response.data.responseData.data.length <= 0) {
        setLoading(true)
        setPreModalBodyText("No Data Available")
       } else
           {
             const data = response.data.responseData.data;
             const slice = data.slice(pageNumber - 1 , pageNumber - 1 + postsPerPage)
              const postData = textdisplayData(data);
              setLatestData(postData)
              setPageCount(Math.ceil(response.data.responseData.totalCount / postsPerPage))
             setLoading(false)
          }
      } else {
        setLoading(true)
        setPreModalBodyText("No Data Available")
      }
    }

    const releaseDataResponseHandler = (res) => {
      if(res?.data?.responseData?.releaseData) {
        setReleaseContent(res.data.responseData.releaseData);
        setLoading(false)
      }else {
        setLoading(true)
        setPreModalBodyText("This version is not available for this retailer")
      }
    }
    if(releaseByid){
      getReleaseById({releaseByid: releaseByid},releaseDataResponseHandler);
    }else {
      latestUpdatesModalData({pageNo: pageNumber},responseHandlerupdate );
    }
    
  }

  useEffect(() => {
    setLoading(true)
    setPreModalBodyText("Loading...")
    getAllPosts();
  }, [pageNumber]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPageNumber(selectedPage)
  };
  
  function releaseDateFormat(date) {
    return moment.utc(date).format("MMMM DD, YYYY");
  }
 
  function textdisplayData(data){    
    return (
      <div>
      {data.map((option, index) =>{
        return(
          <div key={index} className="mb-5">
            <div className="mb-1" style={{color:"#007bff"}}> <strong>{option.ReleaseNumber}</strong></div>
            <div className="mb-1" style={{color:"#007bff"}}> <strong>{releaseDateFormat(option.ReleaseDate)}</strong></div>
            <h4 className="mb-2"><strong>{option.Title} </strong> </h4>
            <ul className="mb-5"><li>{parse(option.Content)}</li></ul>
          </div>
        )
      })}
      
      </div>
    )   
  };
  
  return (
    <Modal size={'lg'} show={true} onHide={props.displayLatestModalOff} id="latestUpdatemodal" >
    <Modal.Header closeButton>
        <Modal.Title><strong className="ml-4">Latest Updates</strong></Modal.Title>
    </Modal.Header>
    <Modal.Body className="py-4 latestmodal-ht">
    {loading ? (
        <div className="mt-5" style={{ height: "400px", textAlign: "center" }}>
          {preModalBodyText}
        </div>
      ) : (  
        <div>
          {Object.keys(releaseContent).length > 0  ? 
          <div className="mb-5">
         {releaseContent?.ReleaseNumber && <div className="mb-1" style={{color:"#007bff"}}> <strong>{releaseContent.ReleaseNumber}</strong></div> } 
         {releaseContent?.ReleaseDate && <div className="mb-1" style={{color:"#007bff"}}> <strong>{releaseDateFormat(releaseContent.ReleaseDate)}</strong></div>}
          {releaseContent?.Title && <h4 className="mb-2"><strong>{releaseContent.Title} </strong> </h4>}
          {releaseContent?.Content ? <ul className="mb-5"><li>{parse(releaseContent.Content)}</li></ul>:null}
          </div> : latestData}
          
        </div>       
      )}
    </Modal.Body>
  {!releaseByid &&
	<Modal.Footer>
    <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
    </Modal.Footer>
    }
    </Modal>
  );          

}

export default LatestModal;
