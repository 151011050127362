import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEmbedToken, updateViewBuilderJSON } from '../../actions';
import Report from 'powerbi-report-component';
import _get from 'lodash/get';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";

class NewInsightsDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            embedToken: "",
            table: this.props.pbiTables,
            pbicolumn: this.props.pbiColumns,
            reportId: this.props.reportId,
            mqyToValue: this.props.mqyToValue,
            customerkeyPbi: this.props.customerkeyPbi,
            displaydetailsModal : this.props.displaydetailsModal,
            filterArr: []
        }
        
    }
    
        componentDidMount() {
            this.props.getEmbedTokenAPI({
                groupId: process.env.REACT_APP_GROUP_ID,
                dashboardId: this.props.reportId
            }, (res) => {
                if (res.data.hasOwnProperty("embedToken")) {
                    this.setState({ loading: false, embedToken: res.data.embedToken });
                }
            });
        }

    renderPowerBi() {
        let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${this.state.reportId ? this.state.reportId :this.props.reportId }&groupId=${process.env.REACT_APP_GROUP_ID}`;
        const embedToken = this.state.embedToken;
        embedUrl = embedUrl.replace("watch?v=", "v/");
        let startendDates =  this.props.insightsStartEndDatesdata;       

        const reportStyle = {
            // height: "48rem"
        };
        const extraSettings = {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            hideErrors: false
        };
        const tableList = [...this.state.table.split(",")];
        
        let filterArr = [];
        const columnList = [];
        let timeframeVal = this.state.mqyToValue;
        if(startendDates?.start === undefined) {
            filterArr.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "calendar_date",
                    column: "date_range",
                },
                operator: "In",
                values: [timeframeVal?.includes('MTD') ? 'MTD' : timeframeVal?.includes('QTD') ? 'QTD' 
                        : timeframeVal?.includes('YTD') ? 'YTD' : timeframeVal]
            });
        } else if(tableList.includes('Period To Month Date Ranges') && startendDates?.start !== undefined) {
            
            filterArr.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: 'Period To Month Date Ranges',
                    column: 'YEAR'
                },
                operator: "In",
                values: [moment(startendDates?.start).format("YYYY")].map(Number)
            }); 
            filterArr.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: 'Period To Month Date Ranges',
                    column: 'MONTH'
                },
                operator: "In",
                values: [moment(startendDates?.start).format("M")].map(Number)
            });
            filterArr.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: 'Period To Month Date Ranges',
                    column: 'TIMEFRAME'
                },
                operator: "In",
                values: [timeframeVal?.includes('MTM') ? 'MTM' : timeframeVal?.includes('QTM') ? 'QTM' 
                : timeframeVal?.includes('YTM') ? 'YTM' : timeframeVal?.includes('Last Day') ? 'LD' : timeframeVal]
            });
        }                   

        this.state.pbicolumn.split(",").forEach((columnName)=>{
            columnList.push({            
                column: columnName,
                values: [this.state.customerkeyPbi]
            });
        })
        
        const updatedObject = { ...this.props.selectedGlobalFilters }; 
        const removeValueFromObjectArray = (obj) => {
            const key = 'rpln_flag';
            if (obj.hasOwnProperty(key) && obj[key].length > 0) {
             delete obj[key];
            }
            return obj;
        }
        const updatedDataObj = this.props.selectedSelectionAndMetricName?.sectionName === 'Digital Shelf' ? removeValueFromObjectArray(updatedObject) : this.props.selectedGlobalFilters;
        Object.keys(updatedDataObj)
            .forEach((key)=>{
                if(updatedDataObj[key].length > 0) {
                    if(key == 'special_filter'){
                        updatedDataObj[key].forEach((spFilterObj)=>{
                            columnList.push({
                                column: spFilterObj,
                                values: ["Y"]
                            })
                        })
                    } else if(key == 'rpln_flag') {
                        let flags = [];
                        updatedDataObj[key].forEach((spFilterObj)=>{
                            if(spFilterObj == 'Replenishable' || spFilterObj === 'Y') {
                                flags.push('Y')   
                            }
                            if(spFilterObj == 'Non Replenishable') {
                                flags.push('N')   
                            }
                        })
                        columnList.push({
                            column: 'RPLN_FLAG',
                            values: flags
                        })
                    } else {
                        columnList.push({
                            column: key,
                            values: updatedDataObj[key]
                        }) 
                    }
                }
            });
        
        
        for (let i = 0; i < tableList.length; i++) {
            for (let j = 0; j < columnList.length; j++) {
                let finaltable = tableList[i].trim();                
                if(finaltable === 'Period To Month Date Ranges'){
                
                }else if(finaltable.includes('Previous Month')) {
                    if(startendDates?.start !== undefined) {                        
                        filterArr.push({
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: finaltable,
                                column: columnList[j].column
                            },
                            operator: "In",
                            values: columnList[j].values
                        });                     
                        
                    }
                } else {           
                    filterArr.push({
                        $schema: "http://powerbi.com/product/schema#basic",
                        target: {
                            table: finaltable,
                            column: columnList[j].column
                        },
                        operator: "In",
                        values: columnList[j].values
                    });
                }                
            }
        }

        console.log("filterArr...:", filterArr);

        return (
            <Report
                embedType="report" // "dashboard"
                tokenType="Embed" // "Aad"
                accessToken={embedToken} // accessToken goes here
                embedUrl={embedUrl} // embedUrl goes here
                embedId={this.state.reportId}
                groupId={`${process.env.REACT_APP_GROUP_ID}`}
                permissions="All" // View
                style={reportStyle}
                extraSettings={extraSettings}
                onLoad={(report) => {
                    this.report = report;                                      
                    this.report.setFilters(filterArr).then((req, res) => {
                    }).catch(function (errors) {
                    })}
                }
            />
        )
    }    
    
    render() {
        return (<Modal size={'xl'} show={this.props.showInsightCellModal} onHide={this.props.displayColumnDialogOff} id="newinsight-modal" >
            <ToastContainer />
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row newInsights-dtscreen">
                        <div className="col-12" style={{ textAlign: "center" }}>
                            {this.state.displaydetailsModal <= 0 ? <div style={{height:"380px",padding:"20px" }}> No Data available  </div> : this.renderPowerBi()}
                        </div> 
                    </div>
                </div>
            </Modal.Body>
        </Modal>);
    }
}

function mapStateToProps(state) {
    let viewBuilder = _get(state, 'viewBuilderObj', { enableDatepicker: false });
    let selectedGlobalFilters = _get(state, 'globalReducer.selectedGlobalFilters', {});
    let selectedSelectionAndMetricName = _get(state, 'insightReducer.selectedSelectionAndMetricName');
    let insightsStartEndDatesdata = _get(state, 'insightReducer.insightsStartEndDatesdata');

    return ({
        viewBuilder: viewBuilder,
        selectedGlobalFilters,
        selectedSelectionAndMetricName,
        insightsStartEndDatesdata       
    })
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getEmbedTokenAPI: getEmbedToken,
        updateViewBuilderJSONAPI: updateViewBuilderJSON
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(NewInsightsDetailsModal);