import axios from "axios";
import store from "../store";
import { getStrategyNames ,generateFinalGlobalFilter} from "../helper";
import getSymbolFromCurrency from 'currency-symbol-map';

const ROOT_URL = process.env.REACT_APP_ROOT_URL;


axios.interceptors.request.use(
  function (config) {
    if (config.url.indexOf("getAllProjectAndSubprojects") === -1) {
      config.headers.projectName = store.getState().globalReducer.project;
      config.headers.subprojectName = store.getState().globalReducer.subproject;
    }
    let obj = JSON.parse(localStorage.getItem("okta-token-storage"));
    if (obj && obj.hasOwnProperty("idToken")) {
      let idToken = obj.idToken.idToken;
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export const hideNavbarPages = (selections) => {
  return {
    type: "HIDE_NAVBAR_PAGES",
    payload: selections.value,
  };
};
// Reco APIs
export const getAllRecommendations = (selections) => async (dispatch) => {
 
  let request = axios
    .post(`${ROOT_URL}/recommendations/getRecommendationByIndex`, selections)
    .then((res) => {
      dispatch({
        type: "GET_ALL_RECOMMENDATIONS",
        payload: res.data,
      });
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  return request;
};
export const getAllPriorities = () => async (dispatch) => {
 
  let request = axios
    .get(`${ROOT_URL}/recommendations/getAllPriorities`)
    .then((res) => {
      dispatch({
        type: "GET_ALL_PRIORITIES",
        payload: res.data,
      });
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  return request;
};
//updateActionTakenInfo
export const updateActionTakenInfo = (selections, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/recommendations/updateActionTakenInfo`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_ACTION_TAKEN_INFO",
        payload: res.data,
      });
      callback(res);
    })
    .catch((error) => {
      callback(error);
    });
};
export const updateActionTakenInfoNew = (selections, callback, apiToBeCalled) => async (
  dispatch
) => {
  //hard code for snooze
  let selectedAlerts = store.getState().strategyReducer?.selectedStrategies[0];
  let focusStrategy = store.getState().strategyReducer.recommendationSelectedStrategy && store.getState().strategyReducer.recommendationSelectedStrategy.strategyName ? store.getState().strategyReducer.recommendationSelectedStrategy.strategyName : ""
  let finalApiToBeCalled =  store.getState().globalReducer.isSnoozeSubProject ?  selections.recomendationTable ? focusStrategy === 'Share of Search' || selectedAlerts === 'Share of Search' ? 'updateActionInfoForSos' : "updateActionInfo2": "updateActionInfoByDC" : "updateActionTakenInfoNewPOC" 
  
  let request = axios
    .post(`${ROOT_URL}/recommendations/${finalApiToBeCalled}`, selections)
    .then((res) => {
      dispatch({
        type: "IS_ACTION_TAKEN",
        payload: true,
      });
      dispatch({
        type: "RECOMMENDATION_TABLE_CONTENT_SELECTED_LOCATION",
        payload: {},
      });
      dispatch({
        type: "RECOMMENDATION_TABLE_SELECT_ALL_PRODUCT_LIST",
        payload: [],
      });
      dispatch({type: "SELECTED_SKU_IDS", payload: []})
      callback(res);
    })
    .catch((error) => {
      callback(error);
    });
};
export const storeActionTakenInfo = (selections) => {
  return {
    type: "IS_ACTION_TAKEN",
    payload: selections,
  };
};
export const storeActionTakenInfoForProductTable = (selections) => {
  return {
    type: "IS_ACTION_TAKEN_PERSIST",
    payload: selections,
  };
};
export const storeExpandButtonClickedInfo = (selections) => {
  return {
    type: "IS_TABLE_EXPAND_BUTTON_CLICKED",
    payload: selections,
  };
};
export const selectRecommendation = (selections) => {
  return {
    type: "SELECTED_RECOMMENDATION",
    payload: selections,
  };
};
export const storeTableAllDataLoaded = (selections) => {
  return {
    type: "IS_LOADED_ALL",
    payload: selections,
  };
};
export const storeIsFetching = (selections) => {
  return {
    type: "IS_FETCHING",
    payload: selections,
  };
};
export const selectedAdoptionStrategies = (selections) => {
  return {
    type: "SELECTED_ADOPTION_STRATEGIES",
    payload: selections,
  };
};

// Filters APIs
export const getSavedFilters = () => async (dispatch) => {
  let request = axios
    .get(`${ROOT_URL}/misc/getSavedFilter`)
    .then((res) => {
      dispatch({
        type: "GET_SAVED_FILTERS",
        payload: res.data,
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};


// get global filter for each sub-project
export const getGlobalFilter = () => async (dispatch) => {
  let request = axios
    .get(`${ROOT_URL}/misc/getGlobalFilters`)
    .then((res) => {
        dispatch({
          type: "GET_GLOBAL_FILTER",
        payload: res.data,
      });
        dispatch({
          type: "SELECTED_GLOBAL_FILTERS",
          payload: generateFinalGlobalFilter(res.data.data.defaultUserFilters),
        });
        dispatch({
          type: "SUBSCRIPTION_SELECTED_GLOBAL_FILTERS",
          payload: res.data.data.subscriptionFilters,
        });
        dispatch({
          type: "PRESELECTED_GLOBAL_FILTERS",
          payload: res.data.data.defaultOptions
        });
        dispatch({
          type: "NO_DEP_VALUE_GLOBAL_FILTERS",
          payload: res.data.data.noDepValueFilters
        });
        dispatch({
          type: "ALERT_PRECISION_VALUE",
          payload: res.data.data.dynamicValues ? res.data.data.dynamicValues.alert_precision_value : 0,
        });
        dispatch({
          type: "DYNAMIC_COLUMN_PRECISION_VALUE",
          payload: res.data.data.dynamicColumnPrecisions ? res.data.data.dynamicColumnPrecisions : 0,
        });
        dispatch({
          type: "DYNAMIC_COLUMN_PRICE_FORMAT",
          payload: res.data.data.dynamicPriceFormatting ? res.data.data.dynamicPriceFormatting : '',
        });
        dispatch({
          type: "SELECTED_FILTER_VALUES",
          payload: res.data.data.filterValues ? res.data.data.filterValues : {}
        });
        dispatch({
          type: "DEFAULT_ALERT_FILTER_CONDITION",
          payload: res.data.data.defaultAlertFilterCondition ? res.data.data.defaultAlertFilterCondition : 'AND',
        });
        dispatch({
          type: "RETAILER_IDLE_TIME",
          payload: res.data.data.retailerIdleTime ? res.data.data.retailerIdleTime : '10',
        });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const storeGlobalFilterSelections = (selections) => {
  return { type: "SELECTED_GLOBAL_FILTERS", payload: selections };
};

export const storeGlobalFilterValues = (selections) => {
  return { type: "SELECTED_FILTER_VALUES", payload: selections };
};

//on-click of insert and explorer views, sometimes old data remains, so to clear them
export const resetViewBuilderData = () => {
  return {
    type: "ALL_ADMIN_VIEWS",
    payload: [],
  };
};
export const storeSaveFilterClicked = (selection) => {
  return {
    type: "SAVE_FILTER_CLICKED",
    payload: selection,
  };
};
//save global filters
export const saveFilters = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/misc/saveFilters`, selections)
    .then((res) => {
      dispatch({
        type: "SAVE_FILTERS",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const exportAdoptionData = (selections, emailid) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/download/getExportAdoptionData `, selections)
    .then((res) => {
      
      window.open(
        `${process.env.REACT_APP_ROOT_URL}/download/exportAdoptionData2/${emailid}`,
        "_blank"
      ); 
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};
// delete a saved global filter
export const deleteFilter = (selections, callback) => async (dispatch) => {
  let request = axios
    .delete(
      `${ROOT_URL}/misc/deleteSavedFilter?filterName=${selections}`,
      selections
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
};
//get embed token for a power bi report
export const getEmbedToken = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/misc/getEmbedToken`, selections)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
};

export const getLastDataRefreshDate = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/misc/getLastDataRefreshDate`, selections)
    .then((res) => {
      dispatch({
        type: "GET_LAST_REFRESH_DATE",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};
// for future implementation to configure date filter option
export const getDateFilterOptions = () => async (dispatch) => {
  let subProjName = store.getState().globalReducer.subproject;
  if(subProjName && subProjName.indexOf('USA') == -1) {
    dispatch({
      type: "DATE_FILTER_OPTIONS",
      payload: [
        "Last 1 week",
        "Last 4 weeks",
        "Last 13 weeks",
        "Last 26 weeks",
        "Last 52 weeks",
        "MTD",
        "QTD",
        "YTD",
      ],
    });
  } else {
    dispatch({
      type: "DATE_FILTER_OPTIONS",
      payload: [
        "Last 1 week",
        "Last 4 weeks",
        "Last 13 weeks",
        "Last 26 weeks",
        "Last 52 weeks",
        "YTD",
      ],
    });
  }
  
};

export const getDateFilterKeyValueOptions = () => async (dispatch) => {
  dispatch({
    type: "DATE_FILTER_KEY_VALUE",
    payload: [{key: "Last 1 week", value: "L1CW"},
    {key: "Last 4 weeks", value: "L4CW"},
    {key: "Last 13 weeks", value: "L13CW"},
    {key: "Last 26 weeks", value: "L26CW"},
    {key: "Last 52 weeks", value: "L52CW"},
    {key: "MTD", value: "MTD"},
    {key: "QTD", value: "QTD"},
    {key: "YTD", value: "YTD"}
    ],
  });
};

export const getComparisonIdValues = () => async (dispatch) => {
  dispatch({
    type: "COMPARISON_ID_VALUES",
    payload: [{key: "Prior period", value: 1},
    {key: "Prior year", value: 2}
    ],
  });
};

// get all projects and subprojects
export const getAllProjectAndSubprojects = () => async (dispatch) => {
  let request = axios
    .get(`${ROOT_URL}/getAllProjectAndSubprojects`)
    .then((res) => {
      dispatch({
        type: "GET_PROJECTS_AND_SUBSETS",
        payload: res.data.responseData,
      });
      Promise.resolve(res);
    })
    .catch((err) => {
      Promise.reject(err);
    });
  return request;
};
//add new project
export const addNewProject = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/addNewProject`, selections)
    .then((res) => {
      dispatch({
        type: "ADD_NEW_PROJECT",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
//add new subproject
export const addNewSubproject = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/addNewSubproject`, selections)
    .then((res) => {
      dispatch({
        type: "ADD_NEW_SUBSET",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
// set user-info
export const setUserinfo = (selections) => async (dispatch) => {
  dispatch({
    type: "SET_USER_INFO",
    payload: selections.userinfo,
  });
};
// set the project
export const setProject = (selections) => async (dispatch) => {
  dispatch({
    type: "SELECT_PROJECT",
    payload: selections.project,
  });
};
// set the subproject
export const setSubproject = (selections) => async (dispatch) => {
  dispatch({
    type: "SELECT_SUBSET",
    payload: selections.subproject,
  });
};
// set the subproject related info
export const setSubprojectInfo = (selections) => async (dispatch) => {
  dispatch({
    type: "SET_SELECTED_SUBPROJECT_INFO",
    payload: selections,
  });
};

//set default currency for selected retailer
export const setRetailerCurrency = (selections) => async (dispatch) => {
  let currencySymbol = getSymbolFromCurrency(selections ? selections : 'USD')
  dispatch({
    type: "SET_RETAILER_CURRENCY",
    payload: currencySymbol,
  });
};

//set customer key for selected retailer
export const setCustomerKey = (selections) => async (dispatch) => {
  dispatch({
    type: "SET_CUSTOMER_KEY",
    payload: selections,
  });
};

export const setInsightsDateValues = (selections) => async (dispatch) => {
  dispatch({
    type: "INSIGHTS_DATE_VALUES",
    payload: selections,
  });
};

export const setProjectInfo = (selections) => async (dispatch) => {
  dispatch({
    type: "SET_SELECTED_PROJECT_INFO",
    payload: selections,
  });
};
export const setIsAppAdmin = (selections) => async (dispatch) => {
  dispatch({
    type: "SET_IS_APP_ADMIN",
    payload: selections,
  });
};
export const setIsProjectAdmin = (selections) => async (dispatch) => {
  dispatch({
    type: "SET_IS_PROJECT_ADMIN",
    payload: selections,
  });
};
export const setIsSubprojectAdmin = (selections) => async (dispatch) => {
  dispatch({
    type: "SET_IS_SUBPROJECT_ADMIN",
    payload: selections,
  });
};
// Admin APIs
export const deleteDashboard = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/admin/deleteSelectedView`, selections)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
};

export const insertNewPage = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/admin/insertNewViewData`, selections)
    .then((res) => {
      dispatch({
        type: "INSERT_NEW_VIEW_DATA",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const resetDashboardDetails = () => {
  return {
    type: "RESET_DASHBOARD_DETAILS",
    payload: [
      { viewName: "", viewDescription: "", categoryName: "", viewId: "" },
    ],
  };
};

export const storeSelectedStrategies = (selectedStrategies) => {
  return {
    type: "SELECTED_STRATEGIES",
    payload: selectedStrategies,
  };
};
export const storeRecommendationSelectedStrategies = (
  selectedStrategiesDetail
) => {
  return {
    type: "RECOMMENDATION_SELECTED_STRATEGY",
    payload: selectedStrategiesDetail,
  };
};

export const saveViewBuilder = (selections) => {
  return {
    type: "SAVE_VIEW_BUILDER",
    payload: selections,
  };
};

export const getAllPagesForAdmin = (selections, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/admin/getAllViews`, selections)
    .then((res) => {
      dispatch({
        type: "ALL_ADMIN_VIEWS",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getSelectedView = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/admin/getSelectedView`, selections)
    .then((res) => {
      dispatch({
        type: "GET_SELECTED_VIEW",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const updateSelectedView = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/admin/updateSelectedView`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_SELECTED_VIEW",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const updateHtmlTempForView = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/admin/updateHtmlTempForView`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_HTML_TEMPLATE_VIEW",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const updateJsonForView = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/admin/updateHtmlTempForView`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_JSON_FOR_VIEW",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

// Strategy APIs
export const insertNewStrategy = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/strategy/insertNewStrategyData`, selections)
    .then((res) => {
      dispatch({
        type: "INSERT_NEW_STRATEGY",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const updateStrategy = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/strategy/updateSelectedStrategy`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_SELECTED_STRATEGY",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const deleteStrategy = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/strategy/deleteSelectedStrategies`, selections)
    .then((res) => {
      dispatch({
        type: "DELETE_SELECTED_STRATEGIES",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const displayAllStrategy = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/strategy/getAllStrategies`, selections)
    .then((res) => {
      dispatch({
        type: "DISPLAY_ALL_STRATEGIES",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getDetailsForAllStrategies = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/strategy/getStatusBasedStrategies`, selections)
    .then((res) => {
      dispatch({
        type: "ALL_STRATEGY_DETAILS",
        payload: getStrategyNames(res.data),
      });
      dispatch({
        type: "DETAILS_FOR_ALL_STRATEGIES",
        payload: res.data,
      });

      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getRecommendationActionedAndActiveData = (selections) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/recommendations/getRecommendationProductAndEstimationCount`,selections)
    .then((res) => {
      dispatch({
        type: "RECOMMENDATION_ACTIONED_AND_ACTIVE_DATA",
        payload: res.data,
      });

      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getDetailsForAllUsers = () => async (dispatch) => {
  let request = axios
    .get(`${ROOT_URL}/report2/getDistinctUserList`)
    .then((res) => {
      dispatch({
        type: "DETAILS_FOR_ALL_USERS",
        payload: res.data.responseData[0].distinctUsers,
      });
      let userList= res.data.responseData[0].distinctUsers.indexOf(JSON.parse(localStorage.getItem("user-info")).name) >-1 ? [JSON.parse(localStorage.getItem("user-info")).name]:  res.data.responseData[0].distinctUsers
      const adoptionfilterUser = userList.filter(option => {
        if(option === 'eCompass') {
          return false;
        } else {
          return true;
        }
      });
      dispatch(adoptionSelectedUSers(adoptionfilterUser));

      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getDailyUsageValue = (data, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/report2/getDailyUsagePercentage2`, data)
    .then((res) => {
      dispatch({
        type: "DAILY_USAGE_VALUE",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const getAverageVolumeDailyActionsValue = (data, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/report2/avgVolumeDailyActions2`, data)
    .then((res) => {
      dispatch({
        type: "AVERAGE_VOLUME_DAILY_ACTIONS",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const getAgeofRecommendationsBeforeActioned = (data, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/report2/getAvgAgeOfRecommendationBeforeActioned2`, data)
    .then((res) => {
      dispatch({
        type: "AVERAGE_AGE_OF_RECOMMENDATIONS_BEFORE_ACTIONED",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const getAgeofRecommendationsByStrategy = (data, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/report2/getAgeOfActiveRecommendationsByStrategy2`, data)
    .then((res) => {
      dispatch({
        type: "AGE_OF_ACTIVE_RECOMMENDATIONS_BY_STRATEGY",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const getAgeofRecommendationsBeforeResolution = (
  data,
  callback
) => async (dispatch) => {
  let request = axios
    .post(
      `${ROOT_URL}/report2/getAvgAgeOfRecommendationBeforeResolution2`,
      data
    )
    .then((res) => {
      dispatch({
        type: "AVERAGE_AGE_OF_RECOMMENDATIONS_BEFORE_RESOLUTION",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const getRecommendationsResolvedwithoutBeingActioned = (
  data,
  callback
) => async (dispatch) => {
  let request = axios
    .post(
      `${ROOT_URL}/report2/getRecommendationsResolvedWithoutActioned2`,
      data
    )
    .then((res) => {
      dispatch({
        type: "RECOMMENDATION_RESOLVED_WITHOUT_BEING_ACTIONED",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const getAdoptionEstimatedValue = (data, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/report2/getEstimatedValue2`, data)
    .then((res) => {
      dispatch({
        type: "ESTIMATED_VALUE",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const getAdoptionEstimatedValueNew = (data, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/report2/getEstimatedRevenueSec`, data)
    .then((res) => {
      dispatch({
          type: "ESTIMATED_VALUE_NEW",
          payload: res.data.responseType === "failed" ? [] : res.data,
        });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const strategyFilterAndOrCondition = (selections) => {
  return {
    type: "SELECTED_FILTER_CONDITION",
    payload: selections,
  };
};

export const strategySelectedDateRange = (selections) => {
  return {
    type: "SELECTED_STRATEGYDATE_RANGE",
    payload: selections,
  };
};

export const alertStrategyCustomDates = (dates) => {
  return {
    type: "STRATEGY_START_END_DATE",
    payload: dates,
  };
};

export const strategyAdditionalParameters = (selections) => {
  return {
    type: "STRATEGY_ADDITIONAL_PARAMETERS",
    payload: selections,
  };
};

export const adoptionSelectedDateRange = (selections) => {
  return {
    type: "SELECTED_DATE_RANGE",
    payload: selections,
  };
};

export const adoptionAgeByStrategy = (selections) => {
  return {
    type: "AGE_OF_RECOMMENDATION_BY_STRATEGY",
    payload: selections,
  };
};

export const adoptionAdditionalParameters = (selections) => {
  return {
    type: "ADOPTION_ADDITIONAL_PARAMETERS",
    payload: selections,
  };
};

export const storeAdoptionCustomDates = (dates) => {
  return {
    type: "CUSTOM_START_END_DATE",
    payload: dates,
  };
};

export const storeAdoptionSelectedStrategies = (strategies) => {
  return {
    type: "ADOPTION_SELECTED_STRATEGIES",
    payload: strategies,
  };
};

export const storeAdoptionSelectedUseCases = (useCases) => {
  return {
    type: "ADOPTION_SELECTED_USECASES",
    payload: useCases,
  };
};

export const adoptionSelectedUSers = (users) => {
  return {
    type: "ADOPTION_SELECTED_USERS",
    payload: users,
  };
};

// Details
export const getDetailsSidebarOptions = () => async (dispatch) => {
  let request = axios
    .get(`${ROOT_URL}/details/getAllColumnMappings`)
    .then((res) => {
      dispatch({
        type: "GET_SIDEBAR_OPTIONS",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getDetailsData = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/details/getAllSkusFromBlob`, selections)
    .then((res) => {
      dispatch({
        type: "GET_DETAILS_DATA",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

/* Added By Manoj for View Builder */
export const updateViewBuilderJSON = (selections, callback) => async (
  dispatch
) => {
  dispatch({
    type: "UPDATE_VIEW_BUILDER_JSON",
    payload: selections,
  });

  return true;
};

// Subscriptions
export const getSubscriptionsForUser = (selection) => async (dispatch) => {
  let request = axios
    .get(`${ROOT_URL}/subscriptions/getUserSubscription?user=${selection}`)
    .then((res) => {
      dispatch({
        type: "GET_USER_SUBSCRIPTION",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const saveSubscriptionsForUser = (selections, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/subscriptions/addSubscription`, selections)
    .then((res) => {
      dispatch({
        type: "SAVE_USER_SUBSCRIPTION",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
};

// feedback API
export const submitFeedback = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/feedback/sendEmailWithImageAttachment`, selections, {})
    .then((res) => {
      dispatch({
        type: "SAVE_FEEDBACK",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
};
//added by manoj for versionControll --

export const getVersionsList = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/versionHistory/getAllVersionHistories`, selections)
    .then((res) => {
      dispatch({
        type: "GET_VERSIONS_LIST",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const getVersionData = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/versionHistory/getAllVersionHistories`, selections)
    .then((res) => {
      dispatch({
        type: "GET_VERSION_DATA",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const createNewVersionHistory = (selections, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/versionHistory/createNewVersionHistory`, selections)
    .then((res) => {
      dispatch({
        type: "GET_VERSION_DATA",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const updateVersionHistory = (selections, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/versionHistory/updateVersionHistory`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_VERSION_DATA",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const deleteVersionHistory = (selections, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/versionHistory/deleteVersionHistory`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_VERSION_DATA",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const updateCurrentVersionData = (selections) => async (dispatch) => {
  dispatch({
    type: "UPDATE_CURRENT_VERSION_DATA",
    payload: selections,
  });
  return true;
};

export const updateUserActivity = (activityInfo,callback) => {
  axios.post(`${ROOT_URL}/logger/createNewLogNewRecomm`, activityInfo)
  .then((res)=>{
    
    if(callback) return callback(res)
   })
};

export const latestUpdatesModalData =(selection,callback)=>{
  let request = axios
    .post(`${ROOT_URL}/versionHistory/getLatestUpdates`, selection)
    .then((res) => {
      return callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getReleaseById =(selection,callback)=>{
  let request = axios
    .get(`${ROOT_URL}/versionHistory/getReleaseById/${selection.releaseByid}`)
    .then((res) => {
      return callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getOrUpdateLatestUpdateAvailability = (selection) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/misc/getOrUpdateLatestUpdateAvailability`,selection)
    .then((res) => {
      dispatch({
        type: "GET_LATESTUPDATE_AVAILABILITY",
        payload: res.data.responseData,
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const updateLatestUpdateForAllUsers =(selection)=> async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/misc/updateLatestUpdateForAllUsers`,selection)
  return request;
};

//added by manoj
//For Dynamic Tabs
// Subscriptions
export const getTabList = (selection) => async (dispatch) => {
  let request = axios
    .get(`${ROOT_URL}/misc/getNavbarTabsForSubproject`)
    .then((res) => {
      dispatch({
        type: "GET_TAB_LIST_FOR_ACTIVE_PROJECT",
        payload: res.data.responseData.navbarItems,
      });
      dispatch({
        type: "GET_TAB_KEY_MAP_LIST",
        payload:res.data.responseData.keyMaps
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getTabListForSubProjectCreation = () => async (dispatch) => {
  let tabList=["Recommendations","Insights","Strategies","Adoption","Details","Scorecard"]
  if(store.getState().globalReducer.project.toLowerCase() === "ecompass")
    tabList =["Alerts","Insights","FAQs","Impact","Self-Service","Details","Scorecard"]
      dispatch({
        type: "GET_TAB_LIST_FOR_SUB_PROJECT_CREATION",
       // payload: res.data.responseData,
       payload:tabList,

      });
     
    
};

export const getRecommendationTableContent = (selections) => async (
  dispatch
) => {
  let selectedAlerts = store.getState().strategyReducer?.selectedStrategies[0];
  let focusStrategy = store.getState().strategyReducer.recommendationSelectedStrategy && store.getState().strategyReducer.recommendationSelectedStrategy.strategyName ? store.getState().strategyReducer.recommendationSelectedStrategy.strategyName : ""
  let db_type  =store.getState().newRecommendationTableData.isActionTakenPersist ? "read-write" : "read-only"
  selections['alertInFocus'] = selectedAlerts ? selectedAlerts : focusStrategy;
  selections['dbType'] = db_type;
  let filtrdArr = [];
  if(store.getState().strategyReducer?.selectedStrategies && store.getState().strategyReducer?.selectedStrategies?.length == 1) {
    filtrdArr = store.getState().recommendationsReducer?.actionedAndActiveData?.responseData.filter((actAndActv) => {
      return store.getState().strategyReducer?.selectedStrategies?.some((strName) => {
        return actAndActv?.RECOMMENDATION_NAME === strName && (actAndActv.DISPLAY_TYPE === 'DC/Cluster' || actAndActv.DISPLAY_TYPE === 'Total' );
      });
    });
  } else {
    filtrdArr = store.getState().recommendationsReducer?.actionedAndActiveData?.responseData.filter((actAndActv) => {
      return store.getState().strategyReducer?.selectedStrategies?.some((strName) => {
        return actAndActv?.RECOMMENDATION_NAME === strName && ( actAndActv.DEFAULT_TYPE === 'DC/Cluster' || actAndActv.DEFAULT_TYPE === 'Total');
      });
    });
  }
  let finalApiToBeCalled = store.getState().globalReducer.isSnoozeSubProject ? (focusStrategy || (selections.recommendationFilters && selections.recommendationFilters.length)) ? focusStrategy == "Share of Search" || selectedAlerts === 'Share of Search' ? "getSosRecommendationForAlertTable" : filtrdArr?.length > 0 ? "getRecommendationTableForDC" : "getRecommendationForAlertTable" : "getRecommendationDataWithNoAlerts" : "getRecommendationByIndexNewPOC"
  
  let request = axios
    .post(`${ROOT_URL}/recommendations/${finalApiToBeCalled}`, selections)
    .then((res) => { 
      dispatch({
        type: 'ALL_SKU_IDS',
        payload: res.data.responseData.allSkuIds
      })
      if(focusStrategy == "Share of Search" || selectedAlerts === 'Share of Search'){
        let productList = res.data.responseData.keywordList.map((product)=> {          
          return product;
        });
        res.data.responseData.keywordList = productList;    
        dispatch(updateSkuCountData(  {currentCount: res.data.responseData.keywordList ? res.data.responseData.keywordList.length : 0,totalCount: res.data.responseData.totalCount  ? res.data.responseData.totalCount : 0 }))
        dispatch({
          type: "RECOMMENDATION_TABLE_CONTENT",
          payload: res.data,
        });
        store.getState().newRecommendationTableData.isActionTakenPersist && dispatch(storeActionTakenInfoForProductTable(false) );
        return Promise.resolve(res);
      } else {
      
      let productList = res.data.responseData.productList.map((product,index)=> {
        let productKey = Object.keys(product);
        res.data.responseData.productList[index][productKey]['productdetails'] = {'selectedProduct': false, ...res.data.responseData.productList[index][productKey]['productdetails']}
        product[productKey]['storeList'].map(store => {
          store['locationSelected'] = false
        })
        return res.data.responseData.productList[index];
      });
      res.data.responseData.productList = productList;    
      dispatch(updateSkuCountData(  {currentCount: res.data.responseData.productList ? res.data.responseData.productList.length : 0,totalCount: res.data.responseData.totalCount  ? res.data.responseData.totalCount : 0 }))
      dispatch({
        type: "RECOMMENDATION_TABLE_CONTENT",
        payload: res.data,
      });
      store.getState().newRecommendationTableData.isActionTakenPersist && dispatch(storeActionTakenInfoForProductTable(false) );
      return Promise.resolve(res);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};
export const recommendationTableSelectedRows = (selections) => async (
  dispatch
) => {
  dispatch({
    type: "RECOMMENDATION_TABLE_CONTENT_SELECTED_LOCATION",
    payload: selections,
  });
  return true;
};

export const recommendationTableSelectAllProductList = (selections) => async (
  dispatch
) => {
  dispatch({
    type: "RECOMMENDATION_TABLE_SELECT_ALL_PRODUCT_LIST",
    payload: selections,
  });
  return true;
};

export const storeNewRecomSearchText = (selections) => async (dispatch) => {
  dispatch({
    type: "NEW_RECO_SEARCH_TEXT",
    payload: selections,
  });
  return true;
};
export const storeExpandSelectedRows = (selections) => async (dispatch) => {
  dispatch({
    type: "NEW_RECO_EXPAND_SELECTED_ROWS",
    payload: selections,
  });
  return true;
};

export const updateRecommendationTableContent = (selections) => async (
  dispatch
) => {
  dispatch({
    type: "UPDATE_RECOMMENDATION_TABLE_CONTENT",
    payload: selections,
  });
};


export const updateDynamicColumnTableContent = (selections) => async (
  dispatch
) => {
  dispatch({
    type: "UPDATE_DYNAMIC_COLUMNS_DCDATA",
    payload: selections,
  });
};

export const updateDynamicTriggeredColumnTableContent = (selections) => async (
  dispatch
) => {
  dispatch({
    type: "DYNAMIC_TRIGGERED_DC_COLUMNS_DCDATA",
    payload: selections,
  });
};

export const recommendationTableContent = (selections) => async (dispatch) => {
  dispatch({
    type: "RECOMMENDATION_TABLE_CONTENT",
    payload: selections
  })
}

export const updateSkuCountData = (selections) => async (
  dispatch
) => {
  dispatch({
    type: "SKUS_COUNT_OF_TABLE",
    payload: selections,
  });
};
// hard code for snooze
export const updateSnoozeSubProject = (selections) => async (
  dispatch
) => {

  dispatch({
    type: "IS_SNOOZE_SUBPROJECT",
    payload: selections,
  });
};
export const getDetailsForUsers=()=> async (dispatch) => {
  let request = axios
    .get(`${ROOT_URL}/report2/getAllUsersList` )
  return request;
};

export const updateUserListData =(selection)=> async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/report2/updateUserFlag` ,selection)
  return request;
};
export const getAlertLogData =(selection,callback)=>{
  let selectedAlerts = store.getState().strategyReducer?.selectedStrategies[0];
  let focusStrategy = store.getState().strategyReducer.recommendationSelectedStrategy && store.getState().strategyReducer.recommendationSelectedStrategy.strategyName ? store.getState().strategyReducer.recommendationSelectedStrategy.strategyName : ""
  let finalAlertlogcall = focusStrategy === 'Share of Search' || selectedAlerts === 'Share of Search' ? 'getAlertLogForSos' : 'getAlertLog'
  let request = axios
    .post(`${ROOT_URL}/recommendations/${finalAlertlogcall}` ,selection)
    .then((res) => {
      return callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getAlertsActionReasonAndSnooze =(selection)=> async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/recommendations/getReasonAndSnoozeTime` ,selection)
    .then((res) => {
      dispatch({
        type: "ALERTS_ACTION_REASON_SNOOZE",
        payload: res.data.responseData,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};
export const saveDateRangeFilter = (selections) => async (
  dispatch
) => {
  dispatch({
    type: "SAVE_DATE_RANGE_FILTER",
    payload: selections,
  });
};
export const getEtlStatus =(selection,callback)=>{
  let request = axios
    .post(`${ROOT_URL}/snowflake/getEtlStatus`,selection)
    .then((res) => {
      return callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getEngagementSummary = (selection, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/report2/getEngagementSummary`, selection)
    .then((res) => {      
      dispatch({
        type: "ENGAGEMENT_SUMMARY",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const getUseCaseList = (data) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/report2/getUseCaseList`, data)
    .then((res) => {
      dispatch({
        type: "USE_CASE_FILTER",
        payload: res?.data?.responseData,
      });
    })
    .catch((err) => {
     
    });
  return request;
};

export const getSummaryByAlert = (data, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/report2/getSummaryByAlert`, data)
    .then((res) => {
      dispatch({
        type: "ALERT_SUMMARY",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const getSummaryByUser = (data, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/report2/getSummaryByUser`, data)
    .then((res) => {
      dispatch({
        type: "USER_SUMMARY",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const getDynamicsColumnData = (data, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/recommendations/getLocationDetailsByDc`, data)
    .then((res) => {
      let productList = res.data.responseData.productList.map((product,index)=> {
        let productKey = Object.keys(product);
        res.data.responseData.productList[index][productKey]['productdetails'] = {'selectedProduct': false, ...res.data.responseData.productList[index][productKey]['productdetails']}
        product[productKey]['storeList'].map(store => {
          store['locationSelected'] = false
        })
        return res.data.responseData.productList[index];
      });
      res.data.responseData.productList = productList; 

      dispatch({
        type: "DYNAMIC_COLUMNS_DCDATA",
        payload: res?.data?.responseData,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};


export const getTriggeredDCDynamicsColumnData = (data, callback) => async (
  dispatch
) => {
  let request = axios
    .post(`${ROOT_URL}/recommendations/getLocationDetailsByDc`, data)
    .then((res) => {
      let productList = res.data.responseData.productList.map((product,index)=> {
        let productKey = Object.keys(product);
        res.data.responseData.productList[index][productKey]['productdetails'] = {'selectedProduct': false, ...res.data.responseData.productList[index][productKey]['productdetails']}
        product[productKey]['storeList'].map(store => {
          store['locationSelected'] = false
        })
        return res.data.responseData.productList[index];
      });
      res.data.responseData.productList = productList; 

      dispatch({
        type: "DYNAMIC_TRIGGERED_DC_COLUMNS_DCDATA",
        payload: res?.data?.responseData,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const createNewRelease = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/versionHistory/createNewRelease`, selections)
    .then((res) => {
      dispatch({
        type: "CREATE_NEW_RELEASE",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};


export const displayAllReleases =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/versionHistory/getAllReleases` ,selection)
    .then((res) => {
      dispatch({
        type: "DISPLAY_ALL_RELEASES",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const updateReleaseById = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/versionHistory/updateReleaseById`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_SELECTED_RELEASE",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const deleteReleaseId = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/versionHistory/deleteRelease`, selections)
    .then((res) => {
      dispatch({
        type: "DELETE_SELECTED_RELEASE",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const displayAllkpiReport = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/insights/getAllKpiReportDetails`, selections)
    .then((res) => {
      dispatch({
        type: "DISPLAY_ALL_KPIREPORTS",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};
export const insertNewKpiReport = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/insights/addKpiReportDetails`, selections)
    .then((res) => {
      dispatch({
        type: "INSERT_NEW_KPIREPORT",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const updateKPIReport = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/insights/updateKpiReportDetails`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_SELECTED_KPI",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const deleteKPIReport = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/insights/deleteKpiReportDetails`, selections)
    .then((res) => {
      dispatch({
        type: "DELETE_SELECTED_KPI",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const getInsightsTable =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getInsightsTable`,selection)
    .then((res) => {
      dispatch({
        type: "NEW_INSIGHTS_TABLE",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getPriceIndexGraph =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPriceIndexGraph`,selection)
    .then((res) => {
      dispatch({
        type: "NEW_INSIGHTS_GRAPH",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getInsightsTabDetails =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getInsightsTabDetails`,selection)
    .then((res) => {
      dispatch({
        type: "NEW_INSIGHTS_TABDETAILS",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getReportDetailsByMetricName =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getReportDetailsByMetricName`,selection)
    .then((res) => {
      dispatch({
        type: "NEW_INSIGHTS_METRICNAME",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getInsightsTabs =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getInsightsTabsNew`,selection)
    .then((res) => {
      dispatch({
        type: "GET_NEWINSIGHTS_TABS",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getInsightsTabDetailsNew =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getInsightsTabDetailsNew`,selection)
    .then((res) => {
      dispatch({
        type: "GET_NEWINSIGHTS_TABDETAILSNEW",
        payload: res.data,
      });
      callback(res)     
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getCrispLink =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getCrispLink`,selection)
    .then((res) => {
      dispatch({
        type: "GET_NEWINSIGHTS_CRISPLINK",
        payload: res.data,
      });
      callback(res)      
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const displayAllSelfService =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/selfService/getAllSelfService` ,selection)
    .then((res) => {
      dispatch({
        type: "DISPLAY_ALL_SELFSERVICE",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const createNewSelfservice = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/selfService/createNewSelfService`, selections)
    .then((res) => {
      dispatch({
        type: "CREATE_NEW_SELFSERVICE",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const updateSelfserviceById = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/selfService/updateSelfServiceById`, selections)
    .then((res) => {
      dispatch({
        type: "UPDATE_SELECTED_SELFSERVICE",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};

export const deleteSelfserviceById = (selections, callback) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/selfService/deleteSelfService`, selections)
    .then((res) => {
      dispatch({
        type: "DELETE_SELECTED_SELFSERVICE",
        payload: res.data,
      });
      callback(res);
    })
    .catch((err) => {
      callback(err);
    });
  return request;
};
export const newInsightLeftNameClicked = () => {
  return {
    type: "GET_NEWINSIGHTS_TABDETAILSNEW",
    payload: {},
  };
};

export const newInsightSectionName = () => {
  return {
    type: "SAVE_SECTION_NAME",
    payload: {},
  };
};

export const getSkuHealthChart =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getSkuHealthChart`,selection)
    .then((res) => {
      dispatch({
        type: "NEW_INSIGHTS_GRAPH",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const RankTypeAlertFilter = (selections) => {
  return {
    type: "RANKTYPE_FILTER",
    payload: selections,
  };
};

export const getRatingReviewsSummary =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getRatingReviewsSummary`,selection)
    .then((res) => {
      dispatch({
        type: "GET_RATINGREVIEWS_SUMMARY",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getSentimentAnalysisReviewCount =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getSentimentAnalysisReviewCount`,selection)
    .then((res) => {
      dispatch({
        type: "GET_REVIEWCOUNT_SUMMARY",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getSentimentCountChart =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getSentimentCountChart`,selection)
    .then((res) => {
      dispatch({
        type: "SENTIMENT_COUNT_CHART",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getSentimentAVGChart =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getSentimentAVGChart`,selection)
    .then((res) => {
      dispatch({
        type: "SENTIMENT_AVG_CHART",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getSentimentTrends =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getSentimentTrends`,selection)
    .then((res) => {
      dispatch({
        type: "SENTIMENT_TRENDS_CHART",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getSentimetTableCount =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getSentimetTableCount`,selection)
    .then((res) => {
      dispatch({
        type: "SENTIMENT_TABLE_COUNT",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getPricingCountOfSKU =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPricingCountOfSKU`,selection)
    .then((res) => {
      dispatch({
        type: "PRICING_COUNT_OFSKU",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getPricingBC =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPricingBC`,selection)
    .then((res) => {
      dispatch({
        type: "PRICING_BUSINESS_CONT",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getPricingFluctSellout =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPricingFluctSellout`,selection)
    .then((res) => {
      dispatch({
        type: "PRICING_SELLOUT",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getPriceFluctSummaryTable =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPriceFluctSummaryTable`,selection)
    .then((res) => {
      dispatch({
        type: "PRICING_FLUCTSUMMARY_TABLE",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getPriceFluctTopContTable =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPriceFluctTopContTable`,selection)
    .then((res) => {
      dispatch({
        type: "PRICING_TOPCONTRIBUTION_TABLE",
        payload: res.data,
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const retailerCurrencyCode = () => {
  return {
    type: "RETAILER_CURRENCY_CODE",
    payload: ''
  };
};

export const getPricingCountOfSKUPCompetiton =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPricingCountOfSKUPCompetiton`,selection)
    .then((res) => {
      dispatch({
        type: "PRICING_COUNT_COMPETITION",
        payload: res.data.responseType === "failed" ? [] : res.data
      }); 
    callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getPricingBCompetition =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPricingBCompetition`,selection)
    .then((res) => {      
      dispatch({
        type: "PRICING_BUSINESS_COMPETATION",
        payload: res.data.responseType === "failed" ? [] : res.data
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getPricingCountOfSKUPW =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPricingCountOfSKUPW`,selection)
    .then((res) => {
      dispatch({
        type: "PRICING_COUNT_OFSKUPW",
        payload: res.data.responseType === "failed" ? [] : res.data
      }); 
    callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getPricingBCPW =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPricingBCPW`,selection)
    .then((res) => {      
      dispatch({
        type: "PRICING_BUSINESS_CONTPW",
        payload: res.data.responseType === "failed" ? [] : res.data
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getPricingSalesPW =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPricingSalesPW`,selection)
    .then((res) => {      
      dispatch({
        type: "PRICING_SELLOUT_PW",
        payload: res.data.responseType === "failed" ? [] : res.data
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getPricingSalesPCompetition =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getPricingSalesPCompetition`,selection)
    .then((res) => {      
      dispatch({
        type: "PRICECOMPETITION_SELLOUT",
        payload: res.data.responseType === "failed" ? [] : res.data
      });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getManufactoresList =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getManufactoresList`,selection)
    .then((res) => {     
        dispatch({
          type: "PRICE_COMPETITOR_LIST",
          payload: res.data.responseType === "failed" ? [] : res.data
        });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getCompetitorKeyList =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getCompetitorKeyList`,selection)
    .then((res) => {     
        dispatch({
          type: "PRICEWAR_COMPETITORLIST",
          payload: res.data.responseType === "failed" ? [] : res.data
        });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};
export const getSummaryTablePW =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getSummaryTablePW`,selection)
    .then((res) => {     
        dispatch({
          type: "PRICEWAR_SUMMARY_TABLE",
          payload: res.data.responseType === "failed" ? [] : res.data
        });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getSummaryTablePriceCompetition =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getSummaryTablePriceCometition`,selection)
    .then((res) => {     
        dispatch({
          type: "PRICECOMPETITION_SUMMARY_TABLE",
          payload: res.data.responseType === "failed" ? [] : res.data
        });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getTopContTablePW =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getTopContTablePW`,selection)
    .then((res) => {     
        dispatch({
          type: "PRICEWAR_TOPCONTRIBUTION_TABLE",
          payload: res.data.responseType === "failed" ? [] : res.data
        });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getTopContTablePCompetition =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getTopContTablePriceCompetition`,selection)
    .then((res) => {     
        dispatch({
          type: "PRICECOMPETION_TOPCONTRIBUTION_TABLE",
          payload: res.data.responseType === "failed" ? [] : res.data
        });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const insightsDatesParameters = (selections) => {
  return {
    type: "INSIGHTS_DATES_PARAMETERS",
    payload: selections
  };
};

export const insightsStartEndDates = (dates) => {
  return {
    type: "INSIGHTS_START_END_DATE",
    payload: dates?.start === 'Invalid date' ? undefined : dates
  };
};

export const getSOYearsList =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/insights/getSOYearsListP`,selection)
    .then((res) => {     
        dispatch({
          type: "GET_SOYEAR_LIST",
          payload: res.data
        });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};

export const getLastDataRefreshDatePF = (selections) => async (dispatch) => {
  let request = axios
    .post(`${ROOT_URL}/misc/getLastDataRefreshDate_PF`, selections)
    .then((res) => {
      dispatch({
        type: "GET_LASTREFRESH_DATEPF",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getDecimalForRetailers =(selection,callback)=> async (dispatch) =>{
  let request = axios
    .post(`${ROOT_URL}/misc/getDecimalForRetailers`,selection)
    .then((res) => {     
        dispatch({
          type: "GET_DECIMALFOR_RETAILERS",
          payload: res.data
        });
      callback(res)
    })
    .catch((err) => {
      callback(err)
    });
  return request;
};


