import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './Strategy.scss';
import Modal from 'react-bootstrap/Modal';
import _get from 'lodash/get';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { displayAllStrategy, insertNewStrategy, updateStrategy, deleteStrategy } from '../../actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import { useOktaAuth } from '@okta/okta-react';
import {addCommonActivityCaptureDetail} from '../../helper'

const AddStrategyModal = (props) => {
    const { propName, propLink, propDescription, propReportId, propStatus, propStrategyId } = props.modalDetails;
    const [addStrategyError, setStrategyError] = useState("");
    const [name, setName] = useState(propName);
    const [description, setDescription] = useState(propDescription);
    const [link, setLink] = useState(propLink);
    const [status, setStatus] = useState(propStatus);
    const [reportId, setReportId] = useState(propReportId);
    const [strategyId, setStrategyId] = useState(propStrategyId);

    const saveStrategy = (e, modalMode) => {
        e.preventDefault();
        if (name === "" || description === "" || link === "" || status === "") {
            setStrategyError("Please fill all values");
        } else {
            setStrategyError("");
            props.displayModalOff();
            if (modalMode === "Add") {
                props.insertNewStrategyAPI({ strategyName: name, strategyDesc: description, strategyUrl: link, strategyStatus: status, strategyReportId: reportId }, (res, rej) => {
                    if (res) {
                        toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                        props.displayAllStrategy();
                    }
                });
            } else {
                props.updateStrategyAPI({  strategyId: strategyId, strategyName: name, strategyDesc: description, strategyUrl: link, strategyStatus: status, strategyReportId: reportId }, (res, rej) => {
                    if (res) {
                        toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                        props.displayAllStrategy();
                    }
                });

            }

        }
    }

    let modalText = "Edit";
    if (props.modalMode === "Add") {
        modalText = "Add";
    }
    return (
        <Modal size={'md'} show={true} onHide={props.displayModalOff} id="recommendations-modal" >
            <Modal.Header closeButton>
                <Modal.Title><i className="far fa-file-alt mr-3"></i> Strategy</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">
                <div className="container ">
                    <div className="row recommendations-table">

                        <div className="col-12 my-2">
                            <form >
                                <div className="form-group">
                                    <label> Name</label>
                                    <input type="text" value={name} className="form-control" onChange={(e) => { setName(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Description</label>
                                    <input type="text" value={description} className="form-control" onChange={(e) => { setDescription(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> ReportID <small>(Optional)</small></label>
                                    <input type="text" value={reportId} className="form-control" onChange={(e) => { setReportId(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Link</label>
                                    <input type="text" value={link} className="form-control" onChange={(e) => { setLink(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Status</label>
                                    <select className="form-control" value={status} onChange={(e) => { setStatus(e.target.value); setStrategyError("") }}>
                                        <option value="" disabled>Please select a status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <p className="error">{addStrategyError}</p>
                                <button class="btn btn-apply" onClick={(e) => { saveStrategy(e, props.modalMode) }}>Save</button>
                            </form>
                        </div>



                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const Strategies = (props) => {

    const [displayModal, setDisplayModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const allStrategiesData = props.allStrategies;
    const [modalMode, setModalMode] = useState("Add");
    const [modalDetails, setModalDetails] = useState({
        propName: "",
        propDescription: "",
        propStatus: "",
        propLink: "",
        propReportId: "",
        propStrategyId: ""
    });
    const { authState, authService } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const environment = process.env.REACT_APP_OKTA_ENV;

    const isAppAdmin = useSelector((state) => state.globalReducer.isAppAdmin);
    const isProjectAdmin = useSelector((state) => state.globalReducer.isProjectAdmin);
    const isSubprojectAdmin = useSelector((state) => state.globalReducer.isSubprojectAdmin);
    let { globalFilter, selectedGlobalFilters ,keyMapTabList} = useSelector(
        (state) => state.globalReducer
      );
      let {  selectedStrategies } = useSelector(
        (state) => state.strategyReducer
      );
    let hasAdminAccess = isAppAdmin || isProjectAdmin || isSubprojectAdmin ? true : false;

    useEffect(() => {
     
        addCommonActivityCaptureDetail({page : "Strategies",globalFilters:selectedGlobalFilters ,strategyFilters: selectedStrategies})
        Promise.all([
            props.displayAllStrategyAPI()
        ]).then(function (values) {

            setLoading(false);
        });
    }, []);
    useEffect(() => {
        document.title = keyMapTabList ? keyMapTabList.strategiesTab : "";
    }, [keyMapTabList]);
    useEffect(() => {
        if (!authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            authService.getUser().then((info) => {
                setUserInfo(info);
            });
        }
    }, [authState, authService]); // Update if authState changes

    const login = async () => {
        authService.login('/');
    };
    const displayModalOff = () => {
        setDisplayModal(false);
    }
    const displayAllStrategy = () => {
        props.displayAllStrategyAPI()
    }
    const handleAddStrategy = () => {
        setDisplayModal(true);
        setModalMode("Add");
        let obj = {
            propName: "",
            propDescription: "",
            propLink: "",
            propReportId: "",
            propStatus: "",
            propStrategyId: ""
        }
        setModalDetails(obj);
    }
    const handleDelete = (e, row) => {

        Swal.fire({
            text: 'Are you sure you want to delete this strategy ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                props.deleteStrategyAPI({ strategyIds: [row.strategyId] }, (res, rej) => {
                    if (res) {
                        props.displayAllStrategyAPI();
                        toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                    } else {
                        
                    }
                });

            }
        })
    }
    const handleEditStrategy = (e, row) => {

        setDisplayModal(true);
        setModalMode("Edit");
        let obj = {
            propName: row.strategyName,
            propDescription: row.strategyDesc,
            propLink: row.strategyUrl,
            propReportId: row.strategyReportId,
            propStatus: row.strategyStatus,
            propStrategyId: row.strategyId
        }
        setModalDetails(obj);
    }

    const AddStrategy = () => {
        return (
            <div className="mb-3" >
                <button className="btn btn-light" onClick={(e) => { handleAddStrategy(e) }}>
                    <i className="fas fa-plus mr-2"></i>Add a new strategy
                    </button>
            </div>
        )
    }
    const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div className="strategyRow">
                <span className="name"> {row.strategyName} </span>
                <p >{row.strategyDesc}</p>
            </div>
        )
    }
    const statusFormatter = (cell, row, rowIndex, formatExtraData) => {

        return (
            <div style={{ width: "8rem" }}>
                <i class={`fas ${row.strategyStatus === "Active" ? 'green' : 'red'} fa-dot-circle mr-2`}></i>
                {row.strategyStatus}
            </div>
        )
    }
    const linkFormatter = (cell, row, rowIndex, formatExtraData) => {

        return (
            <div style={{ maxWidth: "16rem", overflow: "hidden" }}>
                <a href={row.strategyUrl} target="_blank" download={row.strategyUrl}>
                    {row.strategyUrl}
                </a>

            </div>
        )
    }
    function renderAddModalButton() {

        if (hasAdminAccess) {
            return <AddStrategy />;
        }
        else {
            return <></>;
        }
    }
    const editColumnFormatter = (cell, row, rowIndex, formatExtraData) => {

        return (
            <div className="flex editColumn">
               <span onClick={(e) => { handleEditStrategy(e, row) }}><i className="fas fa-edit mr-3" ></i></span> 
               <span  onClick={(e) => { handleDelete(e, row) }}><i className="fas fa-trash"></i></span> 
            </div>
        )
    }

    let columns = [{
        text: "Name",
        dataField: "strategyName",
        formatter: nameFormatter
    }, {
        text: "Link",
        dataField: "strategyUrl",
        formatter: linkFormatter
    }, {
        text: "Status",
        dataField: "strategyStatus",
        formatter: statusFormatter
    }
    ]
    if (hasAdminAccess) {
        columns.push({
            text: "Edit",
            formatter: editColumnFormatter
        });
    }
    if (loading) {
        const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
      `;
        return (
            <div id="strategy_wrapper">
                <div className="container-fluid  default-top loading_wrapper  bg-gray p-5">
                    <div className="sweet-loading">
                        <BarLoader
                            css={override}
                            size={150}
                            color={"#123abc"}
                            height={4}
                            width={100}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (authState.isPending) {
        return (
            <div>Loading...</div>
        );
    }
    const { SearchBar } = Search;
    return (
        <div id="strategy_wrapper">
            <ToastContainer />
            {displayModal ?
                <AddStrategyModal
                    modalDetails={modalDetails}
                    modalMode={modalMode}
                    displayAllStrategy={displayAllStrategy}
                    insertNewStrategyAPI={props.insertNewStrategyAPI}
                    updateStrategyAPI={props.updateStrategyAPI}
                    display={displayModal} displayModalOff={displayModalOff} /> : <div></div>}
            <div className="container-fluid  default-top  bg-gray p-5">
                <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                        <div className="header">{keyMapTabList ? keyMapTabList.strategiesTab: ""}</div>
                        {renderAddModalButton()}

                    </div>
                </div>

                <ToolkitProvider
                    keyField="id"
                    data={allStrategiesData}
                    columns={columns}
                    search

                >

                    {
                        props => (
                            <div>

                                <div className="d-flex justify-content-between align-items-center">

                                    <div><SearchBar {...props.searchProps} /></div>
                                </div>
                                <BootstrapTable
                                    pagination={paginationFactory()}
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>

            </div>
        </div>
    )
}

function mapStateToProps(state) {

    let allStrategies = _get(state, 'strategyReducer.allStrategies', []);
   
    return ({
        allStrategies: allStrategies,
   
    })
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        displayAllStrategyAPI: displayAllStrategy,
        insertNewStrategyAPI: insertNewStrategy,
        updateStrategyAPI: updateStrategy,
        deleteStrategyAPI: deleteStrategy

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Strategies);