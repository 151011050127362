import React, { useEffect, useState } from "react";
import Filters from "../../components/Filters";
import TakeActionModal from "../../components/TakeActionModal";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import cloneDeep from "lodash/cloneDeep";
import "./new-recommendation.css";
import {
  storeSelectedStrategies,
  updateActionTakenInfoNew,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import ProductTable from "./ProductTable";
import ProductTableDcLocation from "./ProductTableDcLocation";
import ProductTableSosAlert from "./ProductTableSosAlert";
import { addCommonActivityCaptureDetail, getCookie } from "../../helper";
import { ToastContainer, toast } from "react-toastify";
import {
  getRecommendationTableContent,
  storeIsFetching,
  storeTableAllDataLoaded,
  storeActionTakenInfo,
  recommendationTableSelectedRows,
  updateSkuCountData,
  updateRecommendationTableContent,
  storeActionTakenInfoForProductTable
} from "../../actions/index";

const NoStatregyRecommendationPage = () => {
  let dispatch = useDispatch();
  let [displayActionModal, setDisplayActionModal] = useState(false);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  let { selectedStrategies, recommendationSelectedStrategy, selectedStrategyDateRange,
    strategyCustomDates, selectedFilterCondition } = useSelector(
    (state) => state.strategyReducer
  );

  let { selectedGlobalFilters, project, subproject, isSnoozeSubProject, defaultAlertFilterCondition } =
    useSelector((state) => state.globalReducer);
  let handleStrategyClick = (recommendationFilters) => {
    dispatch(storeSelectedStrategies(recommendationFilters));
  };
  let {
    loadStatus,
    responseData,
    responseDesc,
    responseType,
    newRecoSearchText,
    isLoadedAll,
    isFetchingData,
    selectedRows,
    sortDirecetion,
    sortedColumn,
    curentIndex,
    isExpandTableButtonClicked,
    dynamicColumns,
    setOfDynamicColumns,
    selectAllProductList,
    selectedSkuIds,
  } = useSelector((state) => {
    return  state.newRecommendationTableData
  });
  let { actionedAndActiveData } = useSelector(
    (state) => state.recommendationsReducer
  );
  let [ enableCluster, setEnableCluster ] = useState(false);
  let sosRecomendationName = recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === 'Share of Search' || selectedStrategies?.length && selectedStrategies[0] === 'Share of Search' ? 'Share of Search' : '';
  
  let filtrdArr = [];
  if(selectedStrategies && selectedStrategies?.length) {
    filtrdArr = actionedAndActiveData?.responseData.filter((actAndActv) => {
      return selectedStrategies?.some((strName) => {
        if(selectedStrategies && selectedStrategies?.length === 1){
        return actAndActv?.RECOMMENDATION_NAME === strName && (actAndActv.DISPLAY_TYPE === 'DC/Cluster' || actAndActv.DISPLAY_TYPE === 'Total' );
        } else {
          return actAndActv?.RECOMMENDATION_NAME === strName && (actAndActv.DEFAULT_TYPE === 'DC/Cluster' || actAndActv.DEFAULT_TYPE === 'Total');
        }
      });
    });
  } 
  else if(selectedStrategies?.length === 0 || selectedStrategies === undefined){
    filtrdArr = actionedAndActiveData?.responseData.filter((actAndActv) => {
      if(actAndActv.DISPLAY_TYPE !== 'Location' && (actAndActv.DEFAULT_TYPE === 'DC/Cluster' || actAndActv.DEFAULT_TYPE === 'Total')){
      return true;
    }
    });
  }  

  const handleActionButtonClick = (data) => {
    dispatch(storeActionTakenInfo(true));
    dispatch(storeActionTakenInfoForProductTable(true));

    let filteredObject1 = [];
    Object.keys(selectedRows).map((key) => {
      filteredObject1.push(selectedRows[key]);
    });
    let filteredObject2 = [];
    filteredObject1.map((item) => {
      Object.keys(item).map((key) => {
        filteredObject2.push(item[key]);
      });
    });
    filteredObject2.map((item) => {
      if(item?.estimatedVal){
        Object.keys(item.estimatedVal).map((key) => {
          if (key.toLowerCase() === selectedStrategies[0].toLowerCase())
            item.estimatedValue = item.estimatedVal[key];
        });
      }
    });
    let dcids = []
    filteredObject2.forEach((item) => {
      if(item?.location){
        dcids.push(item.location);
      }
      });
      let alertType = "";
      if(selectedStrategies && selectedStrategies?.length == 1) {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DISPLAY_TYPE"];
      } else {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DEFAULT_TYPE"];
      }
    
    let obj = {};
    if(sosRecomendationName === 'Share of Search'){
      obj.alertName = 'Share Of Search'
      obj.keywordAndRankList = selectedSkuIds
    }else {
      obj.productDetails = filteredObject2;
      obj.type = alertType
      obj.dcId = dcids    
      obj.selectAllProductList = selectedSkuIds
    }
    let newObj = {};
    if(getCookie("selected-sku-id"))
      selectedSkuIds = getCookie("selected-sku-id")

    // hard code for snooze
    if (isSnoozeSubProject) {
      newObj.snoozeTime =
        data.snoozeTime || data.snoozeTime == 0 ? data.snoozeTime : "N/A";
      newObj.reason = data.reason
        ? data.reason
        : "Moved back to Active by User";
      newObj.modeOfAction = data.scheduleAuto
    } else {
      newObj.comment = data.comments;
      newObj.asPerRecommendation = data.checkBoxSelection;
    }

    newObj.action =
      recommendationSelectedStrategy.selection === "active"
        ? "actioned"
        : "active";
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    newObj.user = userInfo.name;
    newObj.userMailId = userInfo !== null ? userInfo.email : "";
    newObj.sAMAccountName = userInfo.sAMAccountName
      ? userInfo.sAMAccountName
      : "";
    newObj.globalFilters = selectedGlobalFilters;

    let date = new Date();
    newObj.updatedDate = date.toDateString();
    let updatedInfo = {};
    let key = selectedStrategies[0];

    updatedInfo[key] = newObj;
    obj.updatedInfo = updatedInfo;
    obj.recommendationFilters = selectedStrategies
    obj.focusAlert = recommendationSelectedStrategy
    obj.selectedDateRange = selectedStrategyDateRange !== 'Default' ? strategyCustomDates : ''
    obj.alertFilterCondition = selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition
    obj.filterValues = selectedGlobalFilters

    let commonInfo = {
      page: "Recommendations",
      strategies: selectedStrategies[0],
      globalFilters: selectedGlobalFilters,
      strategyFilters: selectedStrategies,
      comment: isSnoozeSubProject
        ? data
          ? data.reason
          : "N/A"
        : data.comments,
      activity:
        recommendationSelectedStrategy.selection === "active"
          ? "Action Taken"
          : "Open",
      asPerRecommendation: data.checkBoxSelection ? 1 : 0,
      selectAllProductList: selectedSkuIds,
      recommendationFilters: selectedStrategies,
      focusAlert: recommendationSelectedStrategy
    };

    if (!isSnoozeSubProject)
      addCommonActivityCaptureDetail(
        { ...commonInfo, ...{ listOfProducts: filteredObject2 } },
        (response) => {
          obj.recomendationTable = true;
          dispatch(
            updateActionTakenInfoNew(obj, (res, rej) => {
              if (res) {
                //hide modal
                toast.success(res.data.responseDesc, {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
                dispatch(storeActionTakenInfo(false));
              } else {
                toast.error(res.data.responseDesc, {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
              }
            })
          );
        }
      );
    else {
      obj.recomendationTable = true;
      dispatch(
        updateActionTakenInfoNew(obj, (res, rej) => {
          if (res) {
            //hide modal
            
            if(res?.data?.responseDesc === "Some of the SKUs have been actioned already. Refresh the page to see the updated data" ||
            res?.data?.responseDesc === "Some of the SKUs have been moved back to active already. Refresh the page to see the updated data") {
                
                toast.warning("Some of the alerts have been updated by another user already; hence the page has been refreshed with the latest status.", {
                  position: toast.POSITION.BOTTOM_LEFT,
                  autoClose: 15000,
                  className:'toastwarning-cls',
                });
            }else {                
                toast.success(res.data.responseDesc, {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
            }
            dispatch(storeActionTakenInfo(false));
            
          } else {
            toast.error(res.data.responseDesc, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        })
      );
    }
  };

  function handleScrollForTable(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 20;
    if (
      bottom &&
      isExpandTableButtonClicked &&
      responseData.productList.length !== responseData.totalCount
    ) {
      if (!isFetchingData && !isLoadedAll) {
        dispatch(storeIsFetching(true));
        let requestObject = {
          filterValues: selectedGlobalFilters,
          recommendationFilters: selectedStrategies,
          currentIndex: curentIndex,
          selectedTab: recommendationSelectedStrategy && recommendationSelectedStrategy.selection ? recommendationSelectedStrategy.selection : 'active',
          searchText: newRecoSearchText,
          sortDirecetion: sortDirecetion,
          sortedColumn: sortedColumn,
          filterName: "",
        };
        dispatch(getRecommendationTableContent(requestObject))
          .then((data) => {
            if (data.data) {
              if (Object.keys(data.data.responseData).length > 0) {
                let tempResponseData = cloneDeep(responseData);
                data.data.responseData.productList.map((item) => {
                  tempResponseData.productList.push(item);
                });
                tempResponseData.curentIndex =
                  data.data.responseData.curentIndex;
                dispatch(
                  updateSkuCountData({
                    currentCount: tempResponseData.productList.length,
                    totalCount: tempResponseData.totalCount,
                  })
                );
                dispatch(
                  updateRecommendationTableContent({
                    curentIndex: data.data.responseData.curentIndex,
                    responseData: tempResponseData,
                  })
                );
              } else {
                dispatch(storeTableAllDataLoaded(true));
              }
            }
            dispatch(storeIsFetching(false));
          })
          .catch((err) => {});
      }
    }
  }

  let toggleActionModal = () => {
    let toggle = displayActionModal;
    setDisplayActionModal(!toggle);
  };
  useEffect(() => {
    addCommonActivityCaptureDetail({
      page: "Recommendations",
      globalFilters: selectedGlobalFilters,
      strategyFilters: selectedStrategies,
    });
  }, [selectedGlobalFilters, selectedStrategies]);

  return (
    <div
      className={
        !isExpandTableButtonClicked
          ? "outer-wrapper"
          : "full-screen-outer-wrapper"
      }
      onScroll={(e) => {
        handleScrollForTable(e);
      }}
    >
      {displayActionModal ? (
        <TakeActionModal
          displayActionModalOff={toggleActionModal}
          displayActionModal={displayActionModal}
          handleActionButtonClick={handleActionButtonClick}
          dcLocation={false}
        />
      ) : null}
      <Filters
        displayStrategyFilter={true}
        handleStrategyClick={handleStrategyClick}
        title="Recommendations"
        isNewRecommendationPage={true}
        handleActionButtonClick={handleActionButtonClick}
        displayActionModalOn={toggleActionModal}
      />    

      {selectedStrategies?.length && selectedStrategies[0] === 'Share of Search' ? <ProductTableSosAlert />  : filtrdArr?.length > 0 ? <ProductTableDcLocation/> : <ProductTable/> }

    </div>
  );
};

export default NoStatregyRecommendationPage;
