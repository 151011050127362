import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import './Selfservice.scss';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { createNewSelfservice, displayAllSelfService, updateSelfserviceById, deleteSelfserviceById } from '../../actions';
import moment from "moment";

const AddUpdateModal = (props) => {
    let dispatch = useDispatch(); 
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    const { propName, propLink, propDescription, propStatus, propsId, propsCreatedOn, propsCreatedBy, propsCreatedByEmail, propsProjectId,
        propsSubprojectId, propsUpdatedBy, propsUpdatedByEmail, propsUpdatedOn } = props.modalDetails;
    const [addStrategyError, setStrategyError] = useState("");
    const [name, setName] = useState(propName);
    const [description, setDescription] = useState(propDescription);
    const [link, setLink] = useState(propLink);
    const [status, setStatus] = useState(propStatus); 
    function releaseDateFormat(date) {
        return moment(date).utc().format("YYYY-MM-DD");
    };    
    const saveUpdate = (e, modalMode) => {
        e.preventDefault();
        if (name === "" || description === "" || link === "" || status === "") {
            setStrategyError("Please fill all values");
        } else {
            props.displayModalOff();
            if (modalMode === "Add") {
                dispatch(createNewSelfservice({datasetName:name,description:description,link:link, status:status, createdByEmail: userInfo?.email, createdByName: userInfo?.name }, (res, rej) => {
                    if (res) {
                        toast.success(res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });  
                        dispatch(displayAllSelfService({pageNo:1,sortColumn: "id",
                        sortOrder: "asc"},(res) => { }));                  
                    }
                }));
            } else {
                dispatch(updateSelfserviceById({selfServiceData:{datasetName:name,description:description,link:link, status:status, createdByEmail: propsCreatedByEmail, createdByName: propsCreatedBy, createdOn: propsCreatedOn,projectId:propsProjectId,
                    SubprojectId:propsSubprojectId,updatedBy: userInfo?.name, updatedByEmail:userInfo?.email,updatedOn:releaseDateFormat(new Date())},selfServiceId:propsId}, (res, rej) => {
                    if (res) {
                        toast.success(res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });  
                        dispatch(displayAllSelfService({pageNo:1,sortColumn: "id",
                        sortOrder: "asc"},(res) => { }));                   
                    }
                })); 
            }
        }
    }       
    
    let modalText = "Manage";
    if (props.modalMode === "Add") {
        modalText = "New";
    }
    return (
        <Modal size={'md'} show={true} onHide={props.displayModalOff}>
            <Modal.Header closeButton>
                <Modal.Title><i className="far fa-file-alt mr-3"></i> Self-Service</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">
                <div className="container ">
                    <div className="row recommendations-table">
                        <div className="col-12 my-2">
                            <form >
                                <div className="form-group">
                                    <label> Name</label>
                                    <input type="text" placeholder='Name' value={name} className="form-control" onChange={(e) => { setName(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Description</label>
                                    <input type="text" placeholder='Description' value={description} className="form-control" onChange={(e) => { setDescription(e.target.value); setStrategyError("") }} />
                                </div>
                               
                                <div className="form-group">
                                    <label> Link</label>
                                    <input type="text" placeholder='Link' value={link} className="form-control" onChange={(e) => { setLink(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Status</label>
                                    <select className="form-control" value={status} onChange={(e) => { setStatus(e.target.value); setStrategyError("") }}>
                                        <option value="" disabled>Please select a status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <p className="error">{addStrategyError}</p>
                                <button class="btn btn-apply" onClick={(e) => { saveUpdate(e, props.modalMode) }}>Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const Selfservice = () => {
    let dispatch = useDispatch();   
    const [loading, setLoading] = useState(true);
    const [modalMode, setModalMode] = useState("Add");
    const [allreasesData, setAllreasesData] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [modalDetails, setModalDetails] = useState({
        propName: "",
        propDescription: "",
        propStatus: "",
        propLink: "",
        propStrategyId: ""
    });
    const [pageNumber, setPageNumber] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState();
    const options = {
        totalSize: totalSize,
        sizePerPage: sizePerPage,
        page: pageNumber,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
    };
    const handleTableChange = (
        type,
        { page, sizePerPage }
      ) => {     
        if (type === "pagination") {
          setSizePerPage(sizePerPage);
          setPageNumber(page);
        }
      };
    let { allSelfservicedata } = useSelector((state) => state.versionReducer);
    let { isAppAdmin, isProjectAdmin, isSubprojectAdmin} = useSelector((state) => state.globalReducer);
    let hasAdminAccess = isAppAdmin || isProjectAdmin || isSubprojectAdmin ? true : false;
    const [titleSortDirection, setTitleSortDirection] = useState();
    const [descriptionSort, setDescriptionSort] = useState();
    const sortCareticon = (order, column) => {
        if(column.dataField == 'Dataset_Name') {
            order === 'desc' ? setTitleSortDirection('asc') : setTitleSortDirection('desc') ;
        } else if(column.dataField == 'Description'){
            order === 'desc' ? setDescriptionSort('asc') : setDescriptionSort('desc');
        }
        if (order === 'asc') {          
          return (<i className="fas fa-sort-up  default-sort-icon active"></i>)
       } else if (order === 'desc') {          
         return (<i className="fas fa-sort-down  default-sort-icon active"></i>)
       } else {
          return(<i className="fas fa-sort default-sort-icon"></i>) 
       }
      }
      const sortingEvents = {    
        onClick: (e, column, columnIndex) => {
          let colSort = column.dataField == 'Dataset_Name' ? titleSortDirection : column.dataField == 'Description' ? descriptionSort : "";
          if(allSelfservicedata?.responseData?.releaseList?.length > 0){
          dispatch(displayAllSelfService({pageNo: pageNumber,
            totalRows: sizePerPage,sortOrder: colSort,
            sortColumn: column.dataField,},responseHandlerupdate));
          }  
        } 
      }
    const displayModalOff = () => {
        setDisplayModal(false);
    }
    const handleAddUpdates = (e) => {
        setDisplayModal(true);
        setModalMode("Add");
        let obj = {
            propName: "",
            propDescription: "",
            propLink: "",
            propStatus: ""
        }
        setModalDetails(obj);
    }

    const responseHandlerupdate = (response) => {
        if(response?.data?.responseData){
            if(response.data.responseData.length <= 0) {
                setLoading(false)
            }else {
                if(response?.data?.responseData?.releaseList){
                    setAllreasesData(response.data.responseData.releaseList) 
                    setTotalSize(response.data.responseData.totalRows)            
                    setLoading(false);
                }
            }
        } else {
            setLoading(false)
        } 
    }

    useEffect(() => {
        if(allSelfservicedata?.responseData?.releaseList){
          setAllreasesData(allSelfservicedata.responseData.releaseList) 
          setTotalSize(allSelfservicedata.responseData.totalRows)
          setLoading(false);
        }
      }, [allSelfservicedata]);

    useEffect(() => {
        setLoading(true)
        dispatch(displayAllSelfService({pageNo: pageNumber,
        totalRows: sizePerPage,sortColumn: "id",
        sortOrder: "asc"},responseHandlerupdate));       
    }, [sizePerPage, pageNumber]);

    const NoDataIndication = () => (
        <div className="spinner text-center p-2">
            No Data Available
        </div>
    );   

    const handleEditRelease = (e, row) => {
        setDisplayModal(true);
        setModalMode("Edit");
        let obj = {
            propName: row.Dataset_Name,
            propDescription: row.Description,
            propLink: row.Link,
            propStatus: row.Status,
            propsId : row.id,
            propsCreatedByEmail: row.CreatedByEmail,
            propsCreatedBy: row.CreatedBy,
            propsCreatedOn: row.CreatedOn,
            propsProjectId: row.ProjectId,
            propsSubprojectId: row.SubprojectId,
            propsUpdatedBy: row.UpdatedBy,
            propsUpdatedByEmail: row.UpdatedByEmail,
            propsUpdatedOn: row.UpdatedOn
        }
        setModalDetails(obj);
    }

    const handleDelete = (e, row) => {
        Swal.fire({
            title: 'Are you sure you want to delete this Self-Service ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.value) {
                dispatch(deleteSelfserviceById({ selfServiceId: row.id}, (res, rej) => {
                    if (res) {
                        dispatch(displayAllSelfService({pageNo:1,sortColumn: "id",
                       sortOrder: "asc"},(res) => { })); 
                        toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                    } 
                }));
            }
        })
    }

    const editColumnFormatter = (cell, row) => {
        return (
            <div className="flex editColumn">
               <span onClick={(e) => { handleEditRelease(e, row) }}><i className="fas fa-edit mr-3" ></i></span> 
               <span  onClick={(e) => { handleDelete(e, row) }}><i className="fas fa-trash"></i></span> 
            </div>
        )
    }

    const statusFormatter = (cell, row) => {
        return (
            <div style={{ width: "8rem" }}>
                <i class={`fas ${row.Status === "Active" ? 'green' : 'red'} fa-dot-circle mr-2`}></i>
                {row.Status}
            </div>
        )
    }

    const datasetFormatter = (cell, row) => {
        return (
            <div>
            {row.Status === "Active" ?
               <a href={row.Link} target="_blank">{row.Dataset_Name}</a>
            : <>{row.Dataset_Name}</>
            }
            </div>
        )
    }

    let columns = [{
        text: "Power BI Dataset ",
        dataField: "Dataset_Name",
        sort: true,
        sortCaret: sortCareticon,
        headerEvents: sortingEvents,
        formatter: datasetFormatter
    }, {
        text: "Description",
        dataField: "Description",
        sort: true,
        sortCaret: sortCareticon,
        headerEvents: sortingEvents,
    }
    
    ]
    if (hasAdminAccess) {
        columns.push({
            text: "Status",
            dataField: "Status",
            formatter: statusFormatter
        },{
            text: "Edit",
            formatter: editColumnFormatter
        });
    }

    return(
        <div>
        {loading ?  (
        <div id="no-data-available" style={{ marginTop: "5%" }}> Loading....</div>) :
           (<div>
            <ToastContainer />
             {displayModal ?
                <AddUpdateModal
                    modalDetails={modalDetails}
                    modalMode={modalMode}
                    display={displayModal} displayModalOff={displayModalOff} /> : <div></div>}
       
            <div className="container-fluid default-top bg-gray p-5">
                <div className="row">
                    <div className="col-12 d-flex justify-content-between mb-3">
                        <div className="header-self"> Self-Service</div>                           
                        {hasAdminAccess &&
                        <button className="btn btn-light" onClick={(e) => { handleAddUpdates(e) }}>
                        <i className="fas fa-plus mr-2"></i> Add a Dataset
                        </button> }                        
                    </div>
                </div>

                <BootstrapTable remote bodyClasses="Selfservicebcls" headerClasses="Selfservicetable" pagination={ paginationFactory(options)} data={ allreasesData }
              columns={ columns } keyField="updates" onTableChange={ handleTableChange } noDataIndication={ () => <NoDataIndication /> } />

            </div>
        </div>
        )}    
        </div>
    )

}

export default Selfservice;