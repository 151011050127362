import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { updateViewBuilderJSON } from '../../../actions';
import cloneDeep from 'lodash/cloneDeep';
import ContentOptions from './ContentOptions';
import DesignOptions from './DesignOptions';

class Rows extends Component {
    constructor(props) {
        super(props);
        this.state = {
          displayColorPicker:false,
          colorCode:'',
          displayColorPickerId:'',
        }
    }
 renderContentOptions(){
   return(<ContentOptions currentObj = {this.props.currentObj} id={this.props.id} />)
  }


  renderDesignOptions(){
    return(<DesignOptions currentObj = {this.props.currentObj} id={this.props.id} />)
  }
    render() {
        return (
            <Tabs>
              <TabList>
               <Tab>Content</Tab>
              <Tab>Design</Tab>
              </TabList>
              <TabPanel>
                {this.renderContentOptions()}
              </TabPanel>
               
              <TabPanel>
                {this.renderDesignOptions()}
              </TabPanel>
              </Tabs>
      );
    }
}
function mapStateToProps(state) {
	return ({
		viewBuilder:state.viewBuilderObj,

	})
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateViewBuilderJSONAPI:updateViewBuilderJSON
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Rows);
