import React, { Component, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getVersionsList } from '../actions';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function HelpModal(props){

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        Promise.all([
          props.getVersionsListAPI(),
        ]).then(function (values) {
          setLoading(false);
        });
      }, []);
      function renderBody(){
        if (props.versionList.length > 0) {
            return props.versionList.map((item, index) => {
                return (
               <div>
                    <h4>Version - {item.versionHistoryName} </h4>
                    <div>
                        <small>
                            Date - {item.updatedOn}
                        </small>
                    </div>
                    <div>
                    <div className="mb-5" dangerouslySetInnerHTML={{ __html: item.versionHistoryHtml }} />
                    </div>
               </div>
                )
            })
        }else {
          if(loading){
            return null
          }
          return (
            <div>No data available.</div>
          )
        }
      }
    return (
        <Modal size={'lg'} show={true} onHide={props.displayHelpModalOff} id="" >
            <Modal.Header closeButton>
                <Modal.Title>Version History</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4 version-history">
           { renderBody()}
            </Modal.Body>
        </Modal>
    )
}

function mapStateToProps(state) {
    let versionList = _get(state, 'versionReducer.versionsList.responseData', []);
  
    return ({
      versionList: versionList
  
    })
  
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      getVersionsListAPI: getVersionsList,
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(HelpModal);