export default function(state={loadStatus:'default',dynamicColumns:[], setOfDynamicColumns:[],sortedColumn:[],sortDirecetion:'',curentIndex:'',responseData:{productList:[],keywordList:[]}, responseDesc:'', responseType:'',selectedRows:{}, selectAllProductList :[], dateFilterAndRange: {},},action){
      switch(action.type){
          case "RECOMMENDATION_TABLE_CONTENT":
                return {...state, loadStatus:action.payload.loadStatus,sortedColumn: action.payload.responseData.sortedColumn,sortDirecetion:action.payload.responseData.sortDirecetion,
                    curentIndex:action.payload.responseData.curentIndex, responseData:action.payload.responseData, responseDesc:action.payload.responseDesc, responseType:action.payload.responseType, setOfDynamicColumns:action.payload.responseData.setOfDynamicColumns, dynamicColumns:action.payload.responseData.dynamicColumns}         
          case "RECOMMENDATION_TABLE_CONTENT_SELECTED_LOCATION":
                return {...state,selectedRows:action.payload}
      case "RECOMMENDATION_TABLE_SELECT_ALL_PRODUCT_LIST":
                return {...state,selectAllProductList:action.payload}
                
          case "IS_ACTION_TAKEN":
                return {...state,isActionTaken:action.payload}
          case "IS_ACTION_TAKEN_PERSIST":
                return {...state,isActionTakenPersist:action.payload}
          case "IS_TABLE_EXPAND_BUTTON_CLICKED":
                  return {...state,isExpandTableButtonClicked:action.payload}
          case "IS_LOADED_ALL":
                  return {...state,isLoadedAll:action.payload}
          case "IS_FETCHING":
                  return {...state,isFetchingData:action.payload}                  
         case "NEW_RECO_SEARCH_TEXT":
                return {...state,newRecoSearchText:action.payload}
         case "NEW_RECO_EXPAND_SELECTED_ROWS":
                    return {...state,expandSelectedRows:action.payload}
         case "UPDATE_RECOMMENDATION_TABLE_CONTENT":
                return {...state,curentIndex:action.payload.curentIndex, responseData:action.payload.responseData}
         case "SKUS_COUNT_OF_TABLE":
                return {...state,currentCount:action.payload.currentCount, totalCount:action.payload.totalCount}
         case "ALERTS_ACTION_REASON_SNOOZE":
                return {...state,alertReasonAndSnooze:action.payload}
         case "SAVE_DATE_RANGE_FILTER":
                return {...state,dateFilterAndRange:action.payload}
         case "ALL_SKU_IDS":
               return {...state, allSkuIds: action.payload}
         case "SELECTED_SKU_IDS":
               return {...state, selectedSkuIds: action.payload}
         default:
              return state;
      }
  }
