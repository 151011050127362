import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import { EditIcon,PlusCircleFillIcon } from '../../components/Icons'
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getVersionsList, getVersionData, updateCurrentVersionData } from '../../actions';
import BarLoader from "react-spinners/BarLoader";
import './Versions.scss';
import {addCommonActivityCaptureDetail} from '../../helper'

const Versions = (props) => {


  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedTabDetails, setSelectedTabDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewType, setViewType] = useState(true);
  let {  selectedStrategies } = useSelector(
    (state) => state.strategyReducer
  );
  let {  selectedGlobalFilters } = useSelector(
    (state) => state.globalReducer
  );


  useEffect(() => {
		addCommonActivityCaptureDetail({page : "Version History",globalFilters:selectedGlobalFilters ,strategyFilters: selectedStrategies})
    Promise.all([
      props.getVersionsListAPI(),
    ]).then(function (values) {
      setLoading(false);
    });
  }, []);



  useEffect(()=>{
    let versionDetails = props.versionList.filter((version) => {
      return version.versionHistoryName === selectedTab;
    });
    setSelectedTabDetails(versionDetails[0]);
    props.updateCurrentVersionDataAPI(versionDetails[0]);
    localStorage.setItem("versionData",JSON.stringify(versionDetails[0]));
  },[selectedTab]);


  const renderVersionData = () => {
    if (selectedTabDetails !== null) {
      return <div className="my-4" dangerouslySetInnerHTML={{ __html: selectedTabDetails['versionHistoryHtml'] }} />
    }
  }
  const editData = () => {
    props.history.push(`/${props.project.toLowerCase()}/${props.subproject.toLowerCase()}/admin/edit-version`);
  }
  function renderSidebar() {
    if (props.versionList.length > 0) {
      let sidebarOptions = [];
      props.versionList.map((version, index) => {
        if (index === 0 && !selectedTab) {
          setSelectedTab(version.versionHistoryName);
        }
        sidebarOptions.push({ icon: "", title: version.versionHistoryName, data: version })
      });


      return (
        <div id="versions_sidebar" className="bg-white">
         <div className="new_version_button_wrapper">
         <button className="btn btn-apply" onClick={()=>{
           props.updateCurrentVersionDataAPI('');
           localStorage.setItem("versionData",JSON.stringify({}));
           props.history.push(`/${props.project.toLowerCase()}/${props.subproject.toLowerCase()}/admin/create-version`);
         }}> <PlusCircleFillIcon/> New Version</button>
         </div>
         <div className="horizontal_divider"></div>
          <Sidebar setSelectedTab={setSelectedTab} selectedTab={selectedTab} sidebarOptions={sidebarOptions} />
        </div>
      )
    }
  }
  const renderContent = () => {
    if (selectedTabDetails) {
      return (
        <div className="container-fluid mt-5 padd-left bg-gray " id="version_history_content_wrapper">
          <div className="row">
            <div className="col-12">
              <div>Versions</div>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="header">Version {selectedTabDetails.versionHistoryName}</div>
              <div>
                <button className="btn btn-light" onClick={(e)=>{
                  editData();
                }}>< EditIcon /> Edit Version</button>
              </div>
            </div>
            <div className="col-12 my-4 bg-white n-padding">
              <div>Last Modified On - {selectedTabDetails.updatedOn}</div>
              <div>Created By - {selectedTabDetails.createdBy}</div>
              {renderVersionData()}
            </div>
          </div>
        </div >
      )
    } else {
      return (
        <div className="container-fluid  default-top   bg-gray">
          <div className="row py-4">
            <div className="col-12">
              <p>No data available. </p>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div id="versions_wrapper">

      {loading ?
        <div className=" loading_wrapper">
          <div className="sweet-loading">
            <BarLoader
              size={150}
              color={"#123abc"}
              height={4}
              width={100}
              loading={loading}
            />
          </div>

        </div> :
        <div>
          {renderSidebar()}
          <>
            {renderContent()}
          </>
        </div>

      }


    </div>

  )
}
function mapStateToProps(state) {
  let project = _get(state, 'globalReducer.project', "");
	let subproject = _get(state, 'globalReducer.subproject', "");
  let versionList = _get(state, 'versionReducer.versionsList.responseData', []);

  return ({
    versionList: versionList,
    project,
    subproject

  })

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getVersionsListAPI: getVersionsList,
    updateCurrentVersionDataAPI: updateCurrentVersionData
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Versions);
