import React, { Component } from 'react';

class RecommendationAction extends Component{
    state={
        action:this.props.actionTakenInfo.action,
        comments:this.props.actionTakenInfo.comment,
        emptyObj:this.props.emptyObj,
        user:"",
        updatedDate:"",
        error:""
    }
    handleSave(){
        const {comments,action} = this.state;
        if(!comments){
            this.setState({error:"Please enter a comment"});
        } else if(!action){
            this.setState({error:"Please select an action"});
        }else{
            this.setState({error:""});
            this.props.callUpdateActionTakenInfo({
                action,
                comments
            });
        }   
    }
    handleActionClick(action){
        if(this.props.actionTakenInfo.action && this.props.actionTakenInfo.action!==null){
            this.setState({comments:''});
        }
        this.setState({action:action});
    }
    render(){
        const {action} = this.state;
        const {actionTakenInfo} = this.props;
        return (
            <div className="col-12 modify-status my-1" >
            <div className="mb-3">
        {this.state.emptyObj?null:<label htmlFor="validationTextarea">Last updated by : {actionTakenInfo.user} on {actionTakenInfo.updatedDate}</label>}
                <textarea className="form-control" id="validationTextarea" placeholder="Your comment..." value={this.state.comments} onChange={(e)=>{this.setState({comments:e.target.value,error:""})}}></textarea>
            </div>
            <div className="flex justify-content-between">
               <div>
               <button type="button" className={`btn ${action==="Open"?'btn-open':null}  btn-action`} onClick={(e)=>{this.handleActionClick("Open")}}>Open</button>
                <button type="button" className={`btn ${action==="Approve"?'btn-approve':null}  btn-action`} onClick={(e)=>{this.handleActionClick("Approve")}} >Approve</button>
                <button type="button" className={`btn ${action==="Action Taken"?'btn-action-taken':null}  btn-action`} onClick={(e)=>{this.handleActionClick("Action Taken")}}  >Action Taken</button>
                <button type="button" className={`btn ${action==="Remove"?'btn-removed':null}  btn-action`} onClick={(e)=>{this.handleActionClick("Remove")}}  >Removed</button>
               </div>
               <div>
        <span className="error mr-3">{this.state.error}</span>
                   <button className="btn btn-apply" onClick={(e)=>{
                       this.handleSave()
                   }}><i className="fas fa-save mr-2"></i> Save</button>
               </div>
                

            </div>

        </div>
        )
    }
}

export default RecommendationAction;