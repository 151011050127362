import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  storeRecommendationSelectedStrategies,
  storeSelectedStrategies,
  storeExpandSelectedRows,
  updateSkuCountData,
} from "../../actions";
import {generateFormattedEstimateValue} from "../../helper"

const RecommendationActionedAndActiveView = () => {
  const [isSecure, setIsSecure] = useState("");
  let { actionedAndActiveData } = useSelector(
    (state) => state.recommendationsReducer
  );
  let { expandSelectedRows } = useSelector(
    (state) => state.newRecommendationTableData
  );
  const [allStrategyNames, setAllStrategyNames] = useState([]);

  const [expandAllText, setExpandAllText] = useState("Expand All");

  let dispatch = useDispatch();
  let { recommendationSelectedStrategy, selectedStrategies } = useSelector(
    (state) => state.strategyReducer
  );
  useEffect(() => {
    if(window.location.hostname !== "localhost"){
      setIsSecure('secure')    
    }
    let allStrategyNames = [];
    actionedAndActiveData &&
    actionedAndActiveData.responseData && 
    actionedAndActiveData.responseData.map((item) => {
      item && allStrategyNames.push(item.RECOMMENDATION_NAME);
    });
    setAllStrategyNames(allStrategyNames);
  }, []);

  
  let handleStrategyClick = (item, selection, strategyName,estimatedValue ) => {
    document.cookie = "selected-sku-id=; path=/; max-age=0; isSecure;"
    localStorage.removeItem("triggerlocation");
    dispatch(updateSkuCountData({currentCount:0,totalCount:0 }))
    dispatch(
      storeRecommendationSelectedStrategies({
        activeRowDetails:item,
        recommendationName: item.RECOMMENDATION_NAME,
        ...selection,
        strategyName: strategyName,
        estimatedValue:estimatedValue,
      })
    );
    dispatch(storeSelectedStrategies([strategyName]));
    dispatch({type: "SELECTED_SKU_IDS", payload: []})
  };

  let handleExpand = (item) => {
    let selectedRows = expandSelectedRows ? [...expandSelectedRows] : [];
    if (item === "Expand All") {
      selectedRows = [];
    } else if (item === "Collapse All") {
      selectedRows = [...allStrategyNames];
    } else {
      let selectedItemIndex = selectedRows.indexOf(item);
      selectedRows.indexOf(item) > -1
        ? selectedRows.splice(selectedItemIndex, 1)
        : selectedRows.push(item);
    }

    dispatch(storeExpandSelectedRows(selectedRows));
  };

  let renderNoDataAvailableView = ()=>{
    return (<div id="no-data-available">No Alert available</div>)
  }
  let renderActiveRows = () => {
    return (
      <div className="alerts_activerows">
        {actionedAndActiveData &&
          actionedAndActiveData.responseData &&
          actionedAndActiveData.responseData.map((item) => {
            if (item === null) return null;
            if (item.ACTIVE_COUNT !== 0 && item.ACTUAL_ACTIVE_COUNT !== 0) {
              return (
                <div
                  className={`d-flex p-2  recommendation-border-top ${
                    recommendationSelectedStrategy &&
                    item.RECOMMENDATION_NAME ===
                      recommendationSelectedStrategy.recommendationName &&
                    recommendationSelectedStrategy.selection === "active"
                      ? "row-highlighter"
                      : ""
                  }`}
                >
                  <span className="recommendation-value">{`${item.ACTIVE_COUNT}`}</span>
                  <div className="flex-fill" style={{ paddingLeft: 10 }}>
                    {item.ACTIVE_TEXT.map((eachSpan) => (
                      <span
                        className={`${eachSpan.clickable && "clickable-span"}`}
                        onClick={(e) => {
                          eachSpan.clickable &&
                            handleStrategyClick(
                              item,
                              { selection: "active" },
                              eachSpan.string,
                              item.ACTIVE_ESTIMATED_VALUE
                            );
                        }}
                      >
                        {eachSpan?.string === 'Competitor OOS' ? 'Competition Out Of Stock' : eachSpan.string}
                      </span>
                    ))}

                    {expandSelectedRows &&
                      expandSelectedRows.indexOf(item.RECOMMENDATION_NAME) >
                        -1 && (
                        <div>
                          {" "}
                          {item.INFO && (
                            <div>
                              {" "}
                              <i
                                class="fas fa-info-circle"
                                style={{ marginRight: "4px"}}
                              ></i>
                              {` ${item.INFO}`}
                            </div>
                          )}
                          {item.PRIMARY_ACTION && (
                            <div>
                              <span style={{ fontWeight: "bolder"}}>
                                Primary Action:
                              </span>
                              {` ${item.PRIMARY_ACTION}`}
                            </div>
                          )}
                          {item.SECONDARY_ACTION && (
                            <div>
                              <span style={{ fontWeight: "bolder" }}>
                                Secondary Action:
                              </span>
                              {`${item.SECONDARY_ACTION}`}
                            </div>
                          )}{" "}
                        </div>
                      )}
                  </div>

                  <div
                    className="recommendation-value amount"
                    style={{ marginLeft: 5 }}
                  >
                 {item.ACTIVE_ESTIMATED_VALUE === "N/A" || item.ACTIVE_ESTIMATED_VALUE === 0 && item.RECOMMENDATION_NAME ? "N/A" : generateFormattedEstimateValue(item.ACTIVE_ESTIMATED_VALUE)}  
                  </div>
                  <span
                    onClick={(e) => {
                      handleExpand(item.RECOMMENDATION_NAME);
                    }}
                  >
                    {item &&
                    (item.INFO ||
                      item.PRIMARY_ACTION ||
                      item.SECONDARY_ACTION) &&
                    expandSelectedRows &&
                    expandSelectedRows.indexOf(item.RECOMMENDATION_NAME) >
                      -1 ? (
                      <i
                        className="fas fa-chevron-up"
                        style={{ marginLeft: "4px", marginRight: "2px",fontSize:"14px"}}
                      ></i>
                    ) : (
                      <i
                        className="fas fa-chevron-down"
                        style={{ marginLeft: "4px",marginRight: "2px", fontSize:"14px" }}
                      ></i>
                    )}
                  </span>
                </div>
              );
            } else return null;
          })}
      </div>
    );
  };

  let renderActionedRows = () => {
    return (
      <div className="alerts_activerows">
        {actionedAndActiveData &&
          actionedAndActiveData.responseData &&
          actionedAndActiveData.responseData.map((item) => {
            if (item === null) return null;
            if (item.ACTIVE_COUNT === 0 && item.ACTUAL_ACTIVE_COUNT === 0 && item.IsSosExists === 0) return null;
            else
              return (
                <div
                  className={`d-flex flex-row p-2 recommendation-border-top ${
                    recommendationSelectedStrategy &&
                    item.RECOMMENDATION_NAME ===
                      recommendationSelectedStrategy.recommendationName &&
                    recommendationSelectedStrategy.selection === "actioned"
                      ? "row-highlighter-green"
                      : ""
                  }`}
                >
                  <div className="recommendation-value actioned">
                    {item.ACTION_ESTIMATED_VALUE === "N/A" || item.ACTION_ESTIMATED_VALUE === 0 ? "N/A" : generateFormattedEstimateValue(item.ACTION_ESTIMATED_VALUE)}
                  </div>
                  <div className="flex-fill" style={{ paddingLeft: 10 }}>
                    {item.ACTION_TEXT.map((eachSpan) => (
                      <span
                        className={`${
                          eachSpan.clickable && "clickable-span actioned"
                        }`}
                        onClick={(e) => {
                          eachSpan.clickable &&
                            handleStrategyClick(
                              item,
                              { selection: "actioned" },
                              eachSpan.string,
                              item.ACTION_ESTIMATED_VALUE,
                            );
                        }}
                      >
                        {eachSpan?.string === 'Competitor OOS' ? 'Competition Out Of Stock' : eachSpan.string}
                      </span>
                    ))}
                  </div>
                </div>
              );
          })}
      </div>
    );
  };
  return (
    <div className="row new-alert-mb">
      {/*1st column */}
      <div
        className={`col new-recomm alerts_section ${
          recommendationSelectedStrategy &&
          recommendationSelectedStrategy.selection === "active"
            ? ""
            : "inactive-section"
        } `}
        style={{
          background: "white",
          marginRight: 30,
          marginLeft: 20,
          paddingRight: "0px",
        }}
      >
        <div className="recommendation-header">
          Active
          {actionedAndActiveData &&
          actionedAndActiveData.responseData &&
          actionedAndActiveData.responseData.length > 0 ? (<span className="activeheader"
            onClick={(e) => {
              let text =
                expandAllText === "Expand All" ? "Collapse All" : "Expand All";
              setExpandAllText(text);
              handleExpand(text);
            }}
          >
          {expandAllText}
            <i
              className={`fas ${
                expandAllText === "Expand All"
                  ? "fa-chevron-down"
                  : "fa-chevron-up"
              }`}
              style={{ marginLeft: "8px",marginRight: "5px",  fontSize:"14px"}}
            ></i>
          </span>) : null}
          
        </div>

        {actionedAndActiveData &&
          actionedAndActiveData.responseData &&
          actionedAndActiveData.responseData.length > 0 ? renderActiveRows() : renderNoDataAvailableView()}
      </div>

      {/* 2nd column */}
      <div
        className={`col new-recomm alerts_section ${
          recommendationSelectedStrategy &&
          recommendationSelectedStrategy.selection === "actioned"
            ? ""
            : "inactive-section"
        } `}
        style={{
          background: "white",
          marginRight: 20,
          paddingRight: "0px",
        }}
      >
        <div className="recommendation-header">Actioned</div>
        {actionedAndActiveData &&
          actionedAndActiveData.responseData &&
          actionedAndActiveData.responseData.length > 0 ? renderActionedRows() : renderNoDataAvailableView()}
      </div>
    </div>
  );
};

export default RecommendationActionedAndActiveView;



