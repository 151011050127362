import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Modal from 'react-bootstrap/Modal';
import _get from 'lodash/get';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { displayAllkpiReport, insertNewKpiReport, updateKPIReport, deleteKPIReport } from '../../actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import {Link} from 'react-router-dom';

const AddKpiModal = (props) => {
    const { propMetricName, propReportId, proppbiTables, proppbiColumns, propStatus } = props.modalDetails;
    const [addKpiError, setKpiError] = useState("");
    const [metricName, setMetricName] = useState(propMetricName);     
    const [reportId, setReportId] = useState(propReportId);
    const [pbiTables, setPbiTables] = useState(proppbiTables);
    const [pbiColumns, setPbiColumns] = useState(proppbiColumns);
    const [status, setStatus] = useState(propStatus);
    let { selectedCustomerKey, } = useSelector((state) => state.globalReducer);
    const saveKpireport = (e, modalMode) => {
        e.preventDefault();
        if (metricName === "" || reportId === "" || pbiTables === "" || pbiColumns === "" || status === "") {
            setKpiError("Please fill all the values");
        } else {
            setKpiError("");
            props.displayModalOff();
            let reqObj = {customerKey: selectedCustomerKey, metricName: metricName, reportId: reportId, pbiTables : pbiTables, pbiColumns: pbiColumns, status:status}
            if (modalMode === "Add") {
                props.insertNewKpiReportAPI({kpiDetailReportObject : reqObj}, (res, rej) => {
                    if (res) {
                        toast.success(res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                        props.displayAllkpiReport();
                    }
                });
            } else {
                props.updateKPIReportAPI({kpiDetailReportObject : reqObj,existingMetricName:propMetricName}, (res, rej) => {
                    if (res) {
                        toast.success(res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                        props.displayAllkpiReport();
                    }
                });
            }

        }
    }

    let modalText = "Edit";
    if (props.modalMode === "Add") {
        modalText = "Add";
    }
    return (
        <Modal size={'md'} show={true} onHide={props.displayModalOff} id="pbireport-modal" >
            <Modal.Header closeButton>
                <Modal.Title className='title-cls'>{props.modalMode === "Add" ? 'New' : 'Update'} KPI Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">
                <div className="container ">
                    <div className="row recommendations-table">
                        <div className="col-12 my-2">
                            <form >
                                <div className="form-group">
                                    <label> Metric Name</label>
                                    <input type="text" maxlength="50" placeholder='Metric Name' value={metricName} className="form-control" onChange={(e) => { setMetricName(e.target.value); setKpiError("") }} />
                                </div>                                
                                <div className="form-group">
                                    <label> Report ID </label>
                                    <input type="text" maxlength="50" placeholder='Enter ReportId of KPI detail screen' value={reportId} className="form-control" onChange={(e) => { setReportId(e.target.value); setKpiError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> PBI Tables <small>(Enter PBI dataset names with comma separated.)</small></label>
                                    <input type="text" placeholder='Enter PBI dataset' value={pbiTables} className="form-control" onChange={(e) => { setPbiTables(e.target.value); setKpiError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> PBI Columns <small>(Enter Columns for PBI dataset names with comma separated.)</small></label>
                                    <input type="text" placeholder='Enter columns of PBI dataset' value={pbiColumns} className="form-control" onChange={(e) => { setPbiColumns(e.target.value); setKpiError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Status</label>
                                    <select className="form-control" value={status} onChange={(e) => { setStatus(e.target.value); setKpiError("") }}>
                                        <option value="" disabled>Please select a status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <p className="error">{addKpiError}</p>
                                <div className='d-flex justify-content-end'>
                                    <button class="btn btn-apply" onClick={(e) => { saveKpireport(e, props.modalMode) }}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const AdminConfigPBIReport = (props) => {
    const [displayModal, setDisplayModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const allKPIReportsData = props.allKPIReportsdata;
    const [modalMode, setModalMode] = useState("Add");
    const [modalDetails, setModalDetails] = useState({
        propMetricName: "",
        propReportId: "",
        proppbiTables: "",
        proppbiColumns: "",
        propStatus: "",
    });
    const isAppAdmin = useSelector((state) => state.globalReducer.isAppAdmin);
    const isProjectAdmin = useSelector((state) => state.globalReducer.isProjectAdmin);
    const isSubprojectAdmin = useSelector((state) => state.globalReducer.isSubprojectAdmin);
    let {selectedCustomerKey, project, subproject} = useSelector((state) => state.globalReducer);
    let hasAdminAccess = isAppAdmin || isProjectAdmin || isSubprojectAdmin ? true : false;
    const {totalcount} = useSelector((state) => state.adminReducer);
    const [pageNumber, setPageNumber] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);

    const options = {
        totalSize: totalcount,
        sizePerPage: sizePerPage,
        page: pageNumber,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
    };
    const handleTableChange = (
        type,
        { page, sizePerPage }
      ) => {     
        if (type === "pagination") {
          setSizePerPage(sizePerPage);
          setPageNumber(page);
        }
      };
    useEffect(() => { 
        Promise.all([
            props.displayAllkpiReportAPI({customerKey: selectedCustomerKey, pageNo: pageNumber, totalRows: sizePerPage})
        ]).then(function (values) {
            setLoading(false);
        });
    }, [pageNumber]);

    const displayModalOff = () => {
        setDisplayModal(false);
    }
    const displayAllkpiReport = () => {
        props.displayAllkpiReportAPI({customerKey: selectedCustomerKey, pageNo: pageNumber, totalRows: sizePerPage})
    }
    const handleAddKpiReport = () => {
        setDisplayModal(true);
        setModalMode("Add");
        let obj = {
            propMetricName: "",
            propReportId: "",            
            proppbiTables: "",
            proppbiColumns: "",            
            propStatus: "",
        }
        setModalDetails(obj);
    }
    const handleDelete = (e, row) => {
        Swal.fire({
            text: 'Are you sure you want to delete this KPI Report ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                props.deleteKPIReportAPI({ customerKey: selectedCustomerKey, metricName: row.METRIC_NAME, }, (res, rej) => {
                    if (res) {
                        props.displayAllkpiReportAPI({customerKey: selectedCustomerKey, pageNo: pageNumber, totalRows: sizePerPage});
                        toast.success(res.data.responseDesc === 'KPI report details got updated successfully' ? 'KPI report deleted successfully':res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                    } 
                });

            }
        })
    }
    const handleEditReport = (e, row) => {
        setDisplayModal(true);
        setModalMode("Edit");
        let obj = {
            propMetricName: row.METRIC_NAME,
            propReportId: row.REPORTID,
            proppbiTables: row.PBI_TABLES,              
            proppbiColumns: row.PBI_COLUMN_NAMES,          
            propStatus: row.STATUS,
        }
        setModalDetails(obj);
    }
    const AddKpiReports = () => {
        return (
            <div className="mb-3" >
                <button className="btn btn-light mr-5" onClick={(e) => { handleAddKpiReport(e) }}>
                    <i className="fas fa-plus mr-2"></i>New KPI Dashboard
                </button>
                <button className="btn btn-light">
                    <Link to={`/${project}/${subproject}/admin/salesprediction`} style={{color: '#0f059e'}}> <i class="fa fa-arrow-circle-right"></i> Config PBI Dashboard </Link>
                </button>
            </div>
        )
    }
    const nameFormatter = (cell, row) => {
        return (
            <div className="">
                <span className="name"> {row.METRIC_NAME} </span>
            </div>
        )
    }
    const statusFormatter = (cell, row) => {
        return (
            <div style={{ width: "8rem" }}>
                <i class={`fas ${row.STATUS === "Active" ? 'green' : 'red'} fa-dot-circle mr-2`}></i>
                {row.STATUS}
            </div>
        )
    }
    
    function renderAddModalButton() {
        if (hasAdminAccess) {
            return <AddKpiReports />;
        }
    }
    const editColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div className="flex editColumn">
               <span onClick={(e) => { handleEditReport(e, row) }}><i className="fas fa-edit mr-3" ></i></span> 
               <span  onClick={(e) => { handleDelete(e, row) }}><i className="fas fa-trash"></i></span> 
            </div>
        )
    }
    const pbiTableFormat = (cell,row) => {
        return(<div>{row.PBI_TABLES}</div>)
    }
    let columns = [{
        text: "Metric Name",
        dataField: "METRIC_NAME",
        formatter: nameFormatter
    }, {
        text: "Report ID",
        dataField: "REPORTID",
    },{
        text: "PBI Tables ",
        dataField: "PBI_TABLES",
        formatter : pbiTableFormat
    },{
        text: "Status",
        dataField: "STATUS",
        formatter: statusFormatter
    }
    ]
    if (hasAdminAccess) {
        columns.push({
            text: "Edit / Delete",
            formatter: editColumnFormatter
        });
    }
    const { SearchBar } = Search;
    const NoDataIndication = () => (
        <div className="spinner text-center p-2">
            No Data Available
        </div>
    );
    return (
        <>  
        {loading ? <div id="no-data-available" style={{ marginTop: "5%" }}> Loading....</div> :
        <div id="adminpbi-config">
            <ToastContainer />
            {displayModal ?
                <AddKpiModal
                    modalDetails={modalDetails}
                    modalMode={modalMode}
                    displayAllkpiReport={displayAllkpiReport}
                    insertNewKpiReportAPI={props.insertNewKpiReportAPI}
                    updateKPIReportAPI={props.updateKPIReportAPI}
                    display={displayModal} displayModalOff={displayModalOff} /> : <div></div>}
            <div className="container-fluid default-top bg-gray p-5">
                <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                        <div className="header">Dashboards</div>
                        {renderAddModalButton()} 
                    </div>
                </div>
                <ToolkitProvider keyField="id" data={allKPIReportsData} columns={columns} >
                    {
                        props => (
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    {/* <div><SearchBar {...props.searchProps} /></div> */}
                                </div>
                                <BootstrapTable remote pagination={paginationFactory(options)} onTableChange={ handleTableChange }
                                    {...props.baseProps} noDataIndication={ () => <NoDataIndication /> }/>
                            </div>
                        )
                    }
                </ToolkitProvider>

            </div>
        </div>
        }</>
    )
}

function mapStateToProps(state) {
    let allKPIReportsdata = _get(state, 'adminReducer.allKPIReportsdata', []);   
    return ({
        allKPIReportsdata: allKPIReportsdata,   
    })
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        displayAllkpiReportAPI: displayAllkpiReport,
        insertNewKpiReportAPI: insertNewKpiReport,
        updateKPIReportAPI: updateKPIReport,
        deleteKPIReportAPI: deleteKPIReport
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminConfigPBIReport);