const defaultHtmlTemp = {
  "0": {
    id: 0,
    type:'section',
    element:'div',
    classDefault:'container-fluid',
		classMobile:'',
		classTablet:'',
		classDesktop:'',
    text:'Section Area',
    innerhtml:'',
    configOptions:{
      label:'',
      desc:'',
    },
    children:[]
  }
}


export default function(state={},action){
    switch(action.type){
        case "UPDATE_VIEW_BUILDER_JSON" :  return action.payload
        case "GET_SELECTED_VIEW" :
              if(action.payload.responseType && action.payload.responseType==='success'){
                    let tempObj = {}
                    if(Object.keys(action.payload.responseData[0]['htmlTemp']).length>0){
                      tempObj = {
                             responseDesc:action.payload.responseDesc,
                             loadStatus:action.payload.loadStatus,
                             viewBuilderJSON:action.payload.responseData[0],
                             responseType:action.payload.responseType
                      }
                    }
                    else{
                      tempObj = {
                             responseDesc:action.payload.responseDesc,
                             loadStatus:action.payload.loadStatus,
                             viewBuilderJSON:action.payload.responseData[0],
                             responseType:action.payload.responseType
                      }
                      tempObj.viewBuilderJSON.htmlTemp = defaultHtmlTemp
                    }
                 return tempObj;
                }


        default:
            return state;
    }
}
