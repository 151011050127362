import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { updateViewBuilderJSON } from '../../../actions';
import cloneDeep from 'lodash/cloneDeep';
import DesignOptions from './DesignOptions';
import Layout from './Layout';
class ContentOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
          displayColorPicker:false,
          colorCode:'',
          displayColorPickerId:'',
        }
    }


  handleViewBuilderJSONChange(e, type, keyName){
      let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
      let id =  this.props.id
      if(type==='layout'){
        tempViewBuilderObj.viewBuilderJSON.htmlTemp[id][keyName]=e.target.value;
      }
      else if(type==='configOptions'){
        tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]['configOptions'][keyName] = e.target.value;
      }
      else if(type==='style' || type==='colorCode'){
            let targetVal = '';
            if(type==='colorCode'){
               targetVal = e.hex;
            }
            else{
              targetVal = e.target.value;
            }
          tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]['configOptions']['style'][keyName] = targetVal;
      }
      this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
  }

   renderContentOptions(){
       return(
            <div className="formContainer">
            <div className="form-group">
              <label for="exampleInputEmail1">Label</label>
              <input type="text" className="form-control"  value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['label']} onChange={(e)=>this.handleViewBuilderJSONChange(e,  'configOptions', 'label')} />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Description</label>
              <input type="text" className="form-control"   value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['desc']} onChange={(e)=>this.handleViewBuilderJSONChange(e,  'configOptions', 'desc')} />
            </div>
          </div>
       )
   }


    render() {
        return (
           this.renderContentOptions()
      );
    }
}
function mapStateToProps(state) {
	return ({
		viewBuilder:state.viewBuilderObj,

	})
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateViewBuilderJSONAPI:updateViewBuilderJSON
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ContentOptions);
