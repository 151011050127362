import React, { Component } from "react";
import moment from "moment";
import _get from 'lodash/get';
import { connect } from 'react-redux';
// Month Calender Selector
class MonthSelector extends React.Component {

  generateYearList=()=>{
    if(this.props.insightsYearsList?.length && this.props.spefificMonthList) {
      let yearList = this.props.insightsYearsList;
      return yearList.sort((a, b) => b - a); 
    } else {
      let yearList= [new Date().getFullYear() ];
      for(let i=1;i <2; i++){
        yearList.push(new Date().getFullYear()-i)
      }
      return yearList;
    }
    
   
  }
  isFutureMonth = (month) => {
    return moment().isBefore(month, 'month');
  };
  render() {
    let months = moment.monthsShort();
    return (
      <div>
       
        <select
          className="year-selector"
          value={this.props.selectedSpecificYear}
          onChange={(e) => {
            this.props.selectedSpecificYearHandler(e.target.value);
          }}
        >
          {this.generateYearList().map((item)=><option value={item}>{item}</option>)}
         
        </select>
        <div className="month-selector" style={{ width: 200, height: 200 }}>
          {months.map((month, i) => {
            // const monthValue = moment().month(i);
            // const isDisabled = this.isFutureMonth(monthValue);
            return (
              <span
                key={month}
                onClick={() => this.props.selectMonthHandler(i)}
                className={`selectable-month ${
                  i === this.props.selectedSpecificMonth ? "selected-month" : ""
                }`}               
              >
                {month}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let insightsYearsList = _get(state, 'insightReducer.insightsYearsList.responseData', []);
  return ({
    insightsYearsList: insightsYearsList
  })
}

export default connect(mapStateToProps)(MonthSelector);
