import * as React from 'react';
import {Link} from 'react-router-dom';
import {LeftArrowIcon} from './Icons';
import './PageNotFound.scss';

const PageNotFound:React.FC = () =>{
    return (
        <div className="container-fluid default-top bg-gray">
            <div className="p-5">

                    <div className="big_header">404</div>
                    <div className="header text-center">
                        Sorry, the page you are looking for cannot be found
                    </div>
                <div className="big_text text-center mt-3">
                    Please check the address you have typed or <Link to="/">Go to Home</Link>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;