import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch, useSelector } from "react-redux";
import { DropDownIcon } from "./Icons";
import {generateStrategyNameList} from "../helper"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {strategySelectedDateRange, strategyFilterAndOrCondition} from '../actions';
export default function RecommendationFilter(props) {
  const dispatch = useDispatch();
  const [strategyList, setStrategyList] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [isSecure, setIsSecure] = useState("");

  let allStrategyDetails = useSelector(
    (state) => state.strategyReducer.detailsForAllStrategies
  );
  let {keyMapTabList, defaultAlertFilterCondition} = useSelector(
    (state) => state.globalReducer
  );
  let { recommendationSelectedStrategy, selectedStrategies } = useSelector((state) => state.strategyReducer);
  let selectedFilterCondition = useSelector((state) => state.strategyReducer.selectedFilterCondition);
  let defaultFilter = selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition;
  const [selectedFiltersCondition, setSelectedFiltersCondition] = useState(defaultFilter); 
  useEffect(() => {
    if(window.location.hostname !== "localhost"){
      setIsSecure('secure')    
    }
    setStrategyList(props.selectedStrategies, "strategyList", strategyList);
  }, [props.selectedStrategies]);
  useEffect(() => {
    if(selectedFilterCondition == defaultAlertFilterCondition){
      if(selectedFiltersCondition) {
      setSelectedFiltersCondition(defaultAlertFilterCondition);
      }
    }
  },[selectedFilterCondition])
  
  function setCheckBox(option) {
    if(option ==='Select All')
      return generateStrategyNameList(allStrategyDetails).length === strategyList.length ? true : false
    else
      return strategyList.indexOf(option)> -1 ? true : false   
}

  function renderOptions(data) {
    let recomalertName = selectedStrategies?.length && selectedStrategies[0];
    if (data) {
      data.sort(function (a, b) {
        return a.strategyName.localeCompare(b.strategyName);
      });

      data = data.filter((strategy) => {
        return (
          strategy.strategyName
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) > -1
        );
      });
      if (props.isAdoption) data.unshift({ strategyName: "Select All" });
      return data.map((option, index) => {
        let selectedStrategyName = option.strategyName === 'Competitor Out Of Stock' ? 'Competition Out Of Stock' : option.strategyName; 
        return (
          <Form.Check
            checked={setCheckBox(option.strategyName === 'Competitor Out Of Stock' ? 'Competitor OOS' : option.strategyName)}
            type="checkbox"
            key={index}
            required
            name="multiSelectFilter"
            label={selectedStrategyName}
            onChange={(e) => {
              handleCheckboxClick(e,option.strategyName === 'Competitor Out Of Stock' ? 'Competitor OOS' : option.strategyName);
            }}
            id={index}
            className={`${strategyList?.length > 0 ? option.strategyName !== 'Share of Search' && strategyList[0] === 'Share of Search' ? 'disabledActiveActioned' : option.strategyName === 'Share of Search' && strategyList[0] !== 'Share of Search' ? 'disabledActiveActioned' : ''  : null}`}
          />
        );
      });
    } else {
      return null;
    }
  }

  function handleCheckboxClick(event, option ) {
    let finalStrategyList = [...strategyList]
    if (event.target.checked) 
       option ==='Select All' ? finalStrategyList= [...generateStrategyNameList(allStrategyDetails)] :  finalStrategyList.push(option)
    else 
      option ==='Select All' ? finalStrategyList= [] :  finalStrategyList.splice(finalStrategyList.indexOf(option), 1)
    setStrategyList(finalStrategyList)
  }

  function handleApplyFilter(e) {
      e.preventDefault();
      props.handleStrategyClick(strategyList);
      onToggle(false, true);
      props.getSelectedAlerts(strategyList);   
      dispatch(strategyFilterAndOrCondition(selectedFiltersCondition));
      document.cookie = "selected-sku-id=; path=/; max-age=0; isSecure;"
  }

  function onToggle(e, apply) {
    !apply &&  setStrategyList(props.selectedStrategies) 
    setDisplayDropdown(e);
  }

  return (
    <div id="recommendationFilter">
      <Dropdown
        className="mr-2"
        show={displayDropdown}
        onToggle={onToggle}
        id={
          props.isAdoption
            ? "adoption-strategy"
            : props.isNewRecommendationPage
            ? "strategy-filter"
            :"" 
        }
      >
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="btn-cta"
        >
          <i class="fas fa-filter mr-2"></i> { keyMapTabList ? keyMapTabList.recommendationsTab.toLowerCase() === "alerts" ? "Alert Filters" : "Strategy Filter": "Strategy Filter"}
          {props.isAdoption
            ? `(${strategyList.length})`
            : ""}
          <DropDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Form>
            <Form.Group className="filter_dropdown_wrapper">
              <div class="row align-items-center"> <input
                type="text"
                placeholder="Search"
                className="form-control mb-3"
               style={{width:190, marginLeft:10}}
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                  setStrategyList([]);
                }}
              />
              {props.isNewRecommendationPage ? (<OverlayTrigger
                  placement="left"
                
                 
                  overlay={<Tooltip id="tooltip-disabled">These filters impact the Alert 
                  table (do not apply to the Active 
                  and Actioned Alerts sections)</Tooltip>}
                >
                  <i
                    class="fas fa-info-circle"
                    style={{ fontSize:18, color: "#1a73e8",
                    marginLeft: 5,
                    marginBottom: 10, }}
                  ></i>
                </OverlayTrigger>) :  null} 
             </div>
              <div className="strategy_dropdown_wrapper">
                {allStrategyDetails ? renderOptions(allStrategyDetails) : null}
              </div>
              <div className="apply_button_wrapper">
                <div className="horizontal_divider"></div>
                {strategyList.length <=0 ? <div className="error">Please select a value</div> : null}
                <div className="filtercond"> <label> FILTER CONDITION  </label>
                <select className="form-control" value={selectedFiltersCondition}
                onChange={(e) => {setSelectedFiltersCondition(e.target.value)}} disabled={!strategyList?.length}>
                    <option value="AND">AND</option>
                    <option value="OR">OR</option>
                </select>
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    className={`btn btn-disabled ${
                      strategyList.length > 0 ? null : "btn-disabled-clear-all"
                    } my-2`}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(strategySelectedDateRange('Default'));
                      setStrategyList([]);
                      setSearchString("");
                      props.handleStrategyClick([]);
                      setSelectedFiltersCondition(defaultAlertFilterCondition);
                      dispatch(strategyFilterAndOrCondition(defaultAlertFilterCondition));
                      document.cookie = "selected-sku-id=; path=/; max-age=0; isSecure;"
                    }}
                  >
                    Clear All
                  </button>    
                  <button
                    className={`btn btn-apply  ${
                      strategyList.length > 0 ? null : "btn-disabled-apply"
                    } my-2`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleApplyFilter(e);
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
