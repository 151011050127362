import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Report from 'powerbi-report-component';
import { getEmbedToken } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';

const SentimentReportModal = (props) => {
    let dispatch = useDispatch();
    const [embedToken, setEmbedToken] = useState({})
    const { propsName, propsRatings, pbiTitle, pbiCompareValue } = props.modalDetails;
    const extraSettings = {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      hideErrors: false
    };
    let { selectedGlobalFilters } = useSelector((state) => state.globalReducer);
    let { dateFilterAndRange } = useSelector((state) => state.newRecommendationTableData);
    let { reportDetailsMetricName, selectedSelectionAndMetricName } = useSelector((state) => state.insightReducer);

    let reportId = '', pbiTables = '', customerkeyPbi = '', pbiColumns = '',
            mapobj = reportDetailsMetricName?.responseData?.reportData; 
            mapobj !== undefined && mapobj?.length && mapobj.map((report, index) => {
              reportId = report.REPORTID;
              pbiTables = report.PBI_TABLES;
              customerkeyPbi = report.CUSTOMER_KEY;
              pbiColumns = report.PBI_COLUMN_NAMES;
            });
    let dateFilterRange = dateFilterAndRange?.dateRange ? dateFilterAndRange.dateRange : 'MTD';
    let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${process.env.REACT_APP_GROUP_ID}`;
  
    const tableList = [...pbiTables.split(",")];
    const columnList = [];
    let filterArr = [];
  
    filterArr.push({
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "calendar_date",
        column: "date_range",
      },
      operator: "In",
      values: [dateFilterRange]
    });
  
    filterArr.push({
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "calendar_comparison",
        column: "compare_to"
      },
      operator: "In",
      values: [dateFilterRange === 'YTD' ? 'Prior year' : 'Prior period']
    });
    const updatedObject = { ...selectedGlobalFilters }; 
        const removeValueFromObjectArray = (obj) => {
            const key = 'rpln_flag';
            if (obj.hasOwnProperty(key) && obj[key].length > 0) {
             delete obj[key];
            }
            return obj;
        }
    const updatedDataObj = selectedSelectionAndMetricName?.sectionName === 'Pricing' || selectedSelectionAndMetricName?.sectionName ==='Sentiment' ? removeValueFromObjectArray(updatedObject) : selectedGlobalFilters;
    Object.keys(updatedDataObj)
      .forEach((key) => {
        if (updatedDataObj[key].length > 0) {
          if (key == 'special_filter') {
            updatedDataObj[key].forEach((spFilterObj) => {
              columnList.push({
                column: spFilterObj,
                values: ["Y"]
              })
            })
          } else if (key == 'rpln_flag') {
            let flags = [];
            updatedDataObj[key].forEach((spFilterObj) => {
              if (spFilterObj == 'Replenishable' || spFilterObj === 'Y') {
                flags.push('Y')
              }
              if (spFilterObj == 'Non Replenishable') {
                flags.push('N')
              }
            })
            columnList.push({
              column: 'RPLN_FLAG',
              values: flags
            })
          } else {
            columnList.push({
              column: key,
              values: updatedDataObj[key]
            })
          }
        }
      });
  
    if (pbiColumns.length > 0) {
      pbiColumns.split(",").forEach((columnName) => {
        columnList.push({
          column: columnName,
          values: [columnName === 'KC_PLATFORM' ? propsName : columnName === 'competitor_key' || columnName === 'Competitor Name' ? pbiCompareValue : customerkeyPbi]
        });
      })
    };
  
    if (tableList?.length > 0) {
      for (let i = 0; i < tableList.length; i++) {
        for (let j = 0; j < columnList.length; j++) {
          filterArr.push({
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: tableList[i].trim(),
              column: columnList[j].column
            },
            operator: "In",
            values: columnList[j].values
          });
        }
      }
    }
  
    console.log("filterArr...:", filterArr);
  
    const reportStyle = {
      // height: "48rem"
    };
  
    useEffect(() => {
      dispatch(getEmbedToken({
        groupId: process.env.REACT_APP_GROUP_ID,
        dashboardId: reportId
      }, (res, rej) => {
        if (res?.data?.embedToken) {
          setEmbedToken(res.data.embedToken);
        }
      }));
    }, []);
    return (
      <Modal size={'xl'} show={true} onHide={props.displayModalOff} id="newinsight-modal" >
        <Modal.Header closeButton>
          <Modal.Title className='ml-5 newupdatetxt'> <strong>{pbiTitle} </strong> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray py-4">
  
          <div className="row">
            <div className="col-12 my-2">
              {reportDetailsMetricName !== undefined && reportDetailsMetricName?.responseData?.reportData?.length ? 
              <Report
                embedType="report" // "dashboard"
                tokenType="Embed" // "Aad"
                accessToken={embedToken} // accessToken goes here
                embedUrl={embedUrl} // embedUrl goes here
                embedId={reportId}
                groupId={`${process.env.REACT_APP_GROUP_ID}`}
                permissions="All" // View
                style={reportStyle}
                extraSettings={extraSettings}
                onLoad={(report) => {
                  report = report;
                  report.setFilters(filterArr).then((req, res) => {
                  }).catch(function (errors) {
                  })
                }
                }
              />:(<div className='text-center' style={{height:'360px'}}>No data available to display</div>)
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  };

  export default SentimentReportModal;