import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import DashFilter from "../../components/DashFilter";
import RecommendationTable from "./RecommendationTable";
import BarLoader from "react-spinners/BarLoader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllRecommendations,
  updateActionTakenInfo,
  getSavedFilters,
  getDetailsForAllStrategies,
  storeSelectedStrategies,
  getAllPriorities,
} from "../../actions";
import _get from "lodash/get";
import RecommendationModal from "./RecommendationModal";
import "./Recommendations.scss";
import _isEqual from "lodash/isEqual";

import { addCommonActivityCaptureDetail } from "../../helper";
import { getFocusAltertEstimatedValue } from './FocusAlertFunctions';
import DOMPurify from 'dompurify';

class Recommendations extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      userInfo: "",
      displayModal: false,
      displayedTab: "All",
      recommendationName: "",
      chosenProductId: "",
      chosenLocation: "",
      chosenSource: "",
      asinChosen: "",
      loading: true,
      reportId: "",
      kcPlatform: "",
      recommendationFilters: this.props.selectedStrategies,
      filters: {},
    };
  }
  componentDidMount() {
   
    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.get('data') && JSON.parse(queryParams.get('data')).recommendationFilters) this.setState({recommendationFilters: JSON.parse(queryParams.get('data')).recommendationFilters})

    Promise.all([
      this.props.getSavedFiltersAPI(),
      this.props.getDetailsForAllStrategiesAPI({ strategyStatus: "active" }),
      this.props.getAllPrioritiesAPI(),
    ])
      .then((data) => {

      })
      .catch((err) => {
        this.setState({
          loading: true,
        });
      });

    this.props.authService.getUser().then((res, rej) => {
      if (res) {
        this.setState({ userInfo: res });
      } else {
        console.log(rej);
      }
    });
   
  }

  componentWillReceiveProps(nextprops) {
   
    document.title = nextprops.keyMapTabList ? nextprops.keyMapTabList.recommendationsTab : ""
    if (
      nextprops.selectedGlobalFilters &&
      !_isEqual(
        this.state.filters,
        nextprops.selectedGlobalFilters
      )
    ) {
  
      this.setState({ loading: true, filters: nextprops.selectedGlobalFilters }, () => {
        addCommonActivityCaptureDetail({ page: "Recommendations" ,globalFilters:this.state.filters,
        strategyFilters: this.state.recommendationFilters});
        this.props
          .getAllRecommendationsAPI({
            recommendationFilters: this.state.recommendationFilters,
            filterValues: nextprops.selectedGlobalFilters,
            currentIndex: 1,
            limit: 25,
          })
          .then((res, rej) => {
            this.setState({ loading: false });
          }) 
          .catch((err) => {
            this.setState({
              loading: true,
            })});
      });
    }
    
    
  }
  
 
  login = () => {
    this.props.authService.login("/");
  };

  logout = () => {
    this.props.authService.logout("/");
  };
  displayModalOn = (
    strategyClicked,
    asin,
    productId,
    location,
    source,
    actionTakenInfo,
    kcCategory
  ) => {
    let reportId = "";
    this.props.allStrategyDetails.map((strategy, index) => {
      if (
        strategy.strategyName.toLowerCase() === strategyClicked.toLowerCase()
      ) {
        reportId = strategy.strategyReportId;
      }
    });
    this.setState({
      displayModal: true,
      reportId: reportId,
      recommendationName: strategyClicked,
      asinChosen: asin,
      chosenProductId: productId,
      chosenSource: source,
      chosenLocation: location,
      actionTakenInfo: actionTakenInfo,
      kcCategory: kcCategory,
    });
  };
  displayModalOff = () => {
    if (this.state.displayModal) {
      this.setState({ displayModal: false });
    }
  };
  setDisplayedTab = (tab) => {
    this.setState({ displayedTab: tab });
  };

  getLatestRecommendation = () => {
    this.setState({ loading: true }, () => {
      this.props
        .getAllRecommendationsAPI({
          recommendationFilters: this.state.recommendationFilters,
          filterValues: this.state.filters,
          currentIndex: 1,
          limit: 25,
        })
        .then((res, rej) => {
          this.setState({ loading: false });
        });
    });
  };

  handleStrategyClick = (recommendationFilters) => {
    this.props.storeSelectedStrategies(recommendationFilters)
    this.setState(
      { recommendationFilters: recommendationFilters, loading: true },
      () => {
        this.props
          .getAllRecommendationsAPI({
            recommendationFilters: this.state.recommendationFilters,
            filterValues: this.state.filters,
            currentIndex: 1,
            limit: 25,
          })
          .then((res, rej) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  compare = (a,b) => {
    let focusAlertValueOfA = getFocusAltertEstimatedValue(this.state.recommendationFilters,a,this.props.priorities);
    let focusAlertValueOfB = getFocusAltertEstimatedValue(this.state.recommendationFilters,b,this.props.priorities);
    if ( focusAlertValueOfB < focusAlertValueOfA ){
      return -1;
    }
    if ( focusAlertValueOfB > focusAlertValueOfA ){
      return 1;
    }
    return 0;
  }
  
  render() {
    const queryParams = new URLSearchParams(DOMPurify.sanitize(window.location.search));
    if (this.props.authState.isPending) {
      return (
        <div className="container-fluid  default-top  bg-gray p-5 loading_wrapper">
          <div className="sweet-loading">
            <BarLoader
              size={150}
              color={"#123abc"}
              height={4}
              width={100}
              loading={this.state.loading}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.state.displayModal ? (
          <RecommendationModal
            asin={this.state.asinChosen}
            recommendationName={this.state.recommendationName}
            chosenProductId={this.state.chosenProductId}
            chosenLocation={this.state.chosenLocation}
            chosenSource={this.state.chosenSource}
            reportId={this.state.reportId}
            chosenKcCategory={this.state.kcCategory}
            actionTakenInfo={this.state.actionTakenInfo}
            user={this.state.userInfo.email}
            getLatestRecommendation={this.getLatestRecommendation}
            displayModalOff={this.displayModalOff}
          />
        ) : null}
        <div className="container-fluid  default-top  bg-gray">
          <div className="row">
            <div className="col-12 my-2">
              <DashFilter
                displayStrategyFilter={true}
                handleStrategyClick={this.handleStrategyClick}
                title={this.props.keyMapTabList ? this.props.keyMapTabList.recommendationsTab : ""}
                filters={queryParams.get('data') && JSON.parse(queryParams.get('data')).filters ? JSON.parse(queryParams.get('data')).filters : {}}
                recommendationFilters={queryParams.get('data') && JSON.parse(queryParams.get('data')).recommendationFilters ? JSON.parse(queryParams.get('data')).recommendationFilters : []}
              />
            </div>
          </div>

          {this.state.loading ? (
            <div className=" loading_wrapper">
              <div className="sweet-loading">
                <BarLoader
                  size={150}
                  color={"#123abc"}
                  height={4}
                  width={100}
                  loading={this.state.loading}
                />
              </div>
            </div>
          ) : (
            <RecommendationTable
              data={this.state.recommendationFilters.length>0 ? this.props.allRecommendations.sort(this.compare) : this.props.allRecommendations}
              displayedTab={this.state.displayedTab}
              displayModalOn={this.displayModalOn}
              recommendationFilters={this.state.recommendationFilters}
              priorities={this.props.priorities}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let allRecommendations = _get(
    state,
    "recommendationsReducer.allRecommendations.responseData.rows",
    []
  );
  let priorities = _get(
    state,
    "recommendationsReducer.priorities.responseData",
    {}
  );
  let allStrategyDetails = _get(
    state,
    "strategyReducer.detailsForAllStrategies",
    []
  );
  let selectedStrategies = _get(
    state,
    "strategyReducer.selectedStrategies",
    []
  );
  let {selectedGlobalFilters, keyMapTabList} = _get(
    state,
    "globalReducer",
   
  );
  let strategyObj = [];
  allStrategyDetails.map((strategy, index) => {
    if (strategy.strategyStatus === "Active") {
      strategyObj.push(strategy.strategyName.toLowerCase());
    }
  });
  allRecommendations.map((recommendation, index) => {
    if (recommendation) {
      let arr = [];
      recommendation.recommendationTypes.map((individualRecommendation) => {
        if (strategyObj.indexOf(individualRecommendation.toLowerCase()) > -1) {
          arr.push(individualRecommendation);
        }
      });
      recommendation.recommendationTypes = arr;
    }
  });
  return {
    allRecommendations: allRecommendations,
    allStrategyDetails: allStrategyDetails,
    selectedStrategies,
    selectedGlobalFilters,
    keyMapTabList:keyMapTabList,
    priorities,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllRecommendationsAPI: getAllRecommendations,
      getSavedFiltersAPI: getSavedFilters,
      getDetailsForAllStrategiesAPI: getDetailsForAllStrategies,
      updateActionTakenInfoAPI: updateActionTakenInfo,
      storeSelectedStrategies: storeSelectedStrategies,
      getAllPrioritiesAPI: getAllPriorities,
    },
    dispatch
  );
}

export default withOktaAuth(
  connect(mapStateToProps, mapDispatchToProps)(Recommendations)
);
