import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import './ProjectsPage.scss';
import AddNewProjectModal from './AddNewProjectModal'
import {addCommonActivityCaptureDetail} from '../helper'
import {
    setProject, setProjectInfo,
    resetViewBuilderData, setSubproject, setSubprojectInfo,getTabList, saveDateRangeFilter
} from '../actions'

const ProjectsPage: React.FC = () => {
    const [displayAddNewProject, setDisplayAddNewProject] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isSecure, setIsSecure] = useState('');

 
    interface Project {
        name: string,
        oktaGroupAdmin: string,
        subProjectInfo: [],
        logo:string
    }
    interface Subproject {
        name: string,
        logo: string,
        oktaGroupAdmin: string,
        oktaGroupRead: string
    }
    useEffect(() => {
        if(window.location.hostname !== "localhost"){
            setIsSecure('secure')    
          }
        addCommonActivityCaptureDetail({page : "Projects",globalFilters:selectedGlobalFilters ,strategyFilters: selectedStrategies})
    }, []);
    let projectAndSubprojects: Array<Project> = useSelector((state: any) => state.globalReducer.projectAndSubprojects);
    let isAppAdmin: boolean = useSelector((state: any) => state.globalReducer.isAppAdmin);
    let selectedGlobalFilters: Object = useSelector((state: any) => state.globalReducer.selectedGlobalFilters);
    let selectedStrategies: Array<String> = useSelector((state: any) => state.strategyReducer.selectedStrategies);

    let isProjectAdmin: boolean = useSelector((state: any) => state.globalReducer.isProjectAdmin);
    function selectProject(project: Project) {
        localStorage.removeItem("global");
        localStorage.setItem("project", project.name);
        dispatch(setProject({ project: project.name }));
        dispatch(setProjectInfo(project));
        dispatch(resetViewBuilderData());
        history.push(`/${project.name.toLocaleLowerCase()}`);
    }
    function hideAddNewProjectModal() {
        setDisplayAddNewProject(false);
    }
    function displayAddNewProjectModal() {
        setDisplayAddNewProject(true);
    }
    return (
        <div className="wrappers-with-min-height">
            {displayAddNewProject ? <AddNewProjectModal
                displayAddNewProject={displayAddNewProject}
                hideAddNewProjectModal={hideAddNewProjectModal}
            /> : null}
            <div className="container-fluid  default-top  bg-gray full-screen-height" >
                <div className="p-5">
                    <div className="row d-flex align-items-center justify-content-between  mb-4">
                        <h3 className="header">Projects</h3>
                        {isAppAdmin  ?
                            <button className="btn btn-cta"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => { setDisplayAddNewProject(true) }}>

                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="mr-2 bi bi-plus-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                                </svg>
                                <span>New Project</span>
                            </button>
                            : null}

                    </div>
                    <div className="row bg-white tile-list">

                        {projectAndSubprojects && projectAndSubprojects.map((project, index) => {
                            return (
                                <div key={index}
                                    onClick={() => {
                                        selectProject(project)
                                        document.cookie = "selected-global-filters= ; path=/; max-age=0; isSecure;"
                                        document.cookie = "selected-date-range-filters= ; path=/; max-age=0; isSecure;"
                                        document.cookie = "selected-parent-filter=; path=/; maxage=0; isSecure;"
										document.cookie = "selected-filter-name=; path=/; max-age=0; isSecure;"
                                        document.cookie = "selected-filter-object=; path=/; max-age=0; isSecure;"
                                        dispatch(saveDateRangeFilter({
                                            'dateRange': '',
                                            'compareTo': ''
                                        }));
                                    }}
                                    className="col-3 tile-wrapper">
                                         <div className="tile">
                                        <div>
                                            <img src={project.logo} height="30" />
                                            <span className="project-name">{project.name}</span>
                                        </div>
                                        <div className="title-subtext">

                                        <div  className="btn title-subtext">View Sub-projects<svg width="1em" height="1em" viewBox="0 0 16 16" className="ml-2 bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z" />
                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z" />
                                    </svg></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProjectsPage;
