import React, { useState, useEffect } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { getInsightsTable, getReportDetailsByMetricName, getCrispLink } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import {PriceFormatter, getFormattedPriceValue, convertToKMB, dynamicRetailerimpactPriceFormat } from '../../helper';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import NewInsightsDetailsModal from './NewInsightsDetailsModal.js';
import { ExcelfileIcon, RightArrowUpIcon, CarretIconUp, CarretIconDown } from '../../components/Icons';
import BarLoader from "react-spinners/BarLoader";
import moment from "moment";

const NewInsightsTable = () => {
  let dispatch = useDispatch();
  const [insightTableData, setInsightTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState();
  const [loading, setLoading] = useState(true);
  const [displaydetailsModal, setDisplayDetailsModal] = useState([]);
  const [showInsightCellModal, setShowInsightCellModal] = useState(false)
  const [mqyToValue, setMqyToValue] = useState('');
  const [selectedItemRep, setSelectedItemRep] = useState(false);
  let {  insightsTabsDetailsData, insightsDatesParamaterdata, insightsTabsResponseData, selectedSelectionAndMetricName, insightsStartEndDatesdata } = useSelector((state) => state.insightReducer);
  let { selectedCustomerKey, selectedGlobalFilters, selectedRetailerCurrency, dynamicPriceFormatting, subproject, globalFilter} = useSelector((state) => state.globalReducer);
  let finalSectionName = insightsTabsDetailsData?.finalData;
  let startendDate = insightsStartEndDatesdata?.start;
  const options = {
    totalSize: totalSize,sizePerPage: sizePerPage,
    page: pageNumber,showTotal: true, disablePageTitle: true, hideSizePerPage: true,paginationShowsTotal: totalSize,
  };
  const responseHandlerupdate = (response) => {
    if(response?.data?.responseData?.insightsTableData?.length >= 0){
      setInsightTableData(response.data.responseData.insightsTableData);
      setTotalSize(response.data.responseData.insightsTableData.length)
      setLoading(false)
    }else {setLoading(false)}    
  }
  const updatedDataObj = { ...selectedGlobalFilters };
  const removeValueFromObjectArray = (obj) => {
    const key = 'rpln_flag';
    if (obj.hasOwnProperty(key) && obj[key].length > 0 && selectedSelectionAndMetricName?.sectionName === 'Digital Shelf') {
      delete obj[key];
    }
    return obj;
  }
  const updatedObject = selectedSelectionAndMetricName?.sectionName === 'Digital Shelf' ? removeValueFromObjectArray(updatedDataObj) : selectedGlobalFilters;
  useEffect(() => {
    setLoading(true);
    if(finalSectionName?.METRIC_NAME !== undefined){ 
      if(finalSectionName?.SECTION_NAME !== 'Digital Shelf') {
        dispatch(getInsightsTable({customerKey: selectedCustomerKey,filterValues: updatedObject,sectionName:finalSectionName?.SECTION_NAME,
          metricNames:finalSectionName?.METRIC_NAME,selectedDateRange: insightsStartEndDatesdata?.start !== undefined ? insightsStartEndDatesdata : ''},responseHandlerupdate));
      } else {
        dispatch(getInsightsTable({customerKey: selectedCustomerKey,filterValues: updatedObject,sectionName:finalSectionName?.SECTION_NAME,
          metricNames:finalSectionName?.METRIC_NAME},responseHandlerupdate));
      }
    }
  }, [selectedGlobalFilters,insightsTabsResponseData, insightsStartEndDatesdata?.start]);
  function getSheetData(data, header) {
    let fields = Object.keys(data[0]);
    let sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  const downloadData = () => {
    let headers = []
    let bodyData = []
    if(insightTableData.length){
      if(startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf'){
        headers = [" ", "Last Day Value","Last Day PoP","Last Day YoY", "MTM Value", "MTM POP", "MTM YOY", "QTM Value", "QTM POP", "QTM YOY", "YTM Value", "YTM YOY"]      
      } else {
        headers = [" ", "Last Day Value","Last Day PoP","Last Day YoY", "MTD Value", "MTD POP", "MTD YOY", "QTD Value", "QTD POP", "QTD YOY", "YTD Value", "YTD YOY"]
      }
      let multilehund = 100;
      bodyData = insightTableData.map(item => { 
        let ldtypecheck = item?.LD_TYPE?.toLowerCase(),
        mtdtypecheck = item?.MTM_TYPE ? item?.MTM_TYPE.toLowerCase(): item?.MTD_TYPE?.toLowerCase(),
        qtdtypecheck = item?.QTM_TYPE ? item?.QTM_TYPE.toLowerCase(): item?.QTD_TYPE?.toLowerCase(),
        ytdtypecheck = item?.YTM_TYPE ? item?.YTM_TYPE.toLowerCase():item?.YTD_TYPE?.toLowerCase(),
        ldVal = '',mtdVal = '', qtdVal = '', ytdVal = '', percent = '';
        if(item.DISPLAY_NAME === 'Coupon ROAS' && (subproject === 'Shopee-eCom-Malaysia' || subproject === 'Shopee-eCom-Vietnam')) {
          ldVal = 'N/A';
          mtdVal = 'N/A';
          qtdVal = 'N/A';
          ytdVal = 'N/A';
          percent = 'N/A'
        } else {
          ldVal = ldtypecheck === 'percentage' ? '0%' : '0';
          mtdVal = mtdtypecheck === 'percentage' ? '0%' : '0';
          qtdVal = qtdtypecheck === 'percentage' ? '0%' : '0';
          ytdVal = ytdtypecheck === 'percentage' ? '0%' : '0';
          percent = '0%'
        }
        let mtmdVal = '',qtmdVal = '',ytmdVal = '',mtmdPoP = '',mtmdYoY = '',qtmdPoP = '',qtmdYoY = '',ytmdYoY = '';
        if(startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf'){
          mtmdVal = item?.MTM_VALUE;
          qtmdVal = item?.QTM_VALUE;
          ytmdVal = item?.YTM_VALUE;
          mtmdPoP = item?.MTM_POP;
          mtmdYoY = item?.MTM_YOY;
          qtmdPoP = item?.QTM_POP;
          qtmdYoY = item?.QTM_YOY;
          ytmdYoY = item?.YTM_YOY;
        } else {
          mtmdVal = item?.MTD_VALUE;
          qtmdVal = item?.QTD_VALUE;
          ytmdVal = item?.YTD_VALUE;
          mtmdPoP = item?.MTD_POP;
          mtmdYoY = item?.MTD_YOY;
          qtmdPoP = item?.QTD_POP;
          qtmdYoY = item?.QTD_YOY;
          ytmdYoY = item?.YTD_YOY;
        }
        return [item.DISPLAY_NAME, item?.LD_VALUE ? mtdtypecheck === 'percentage' ? item.LD_VALUE !== 0 ? ((item.LD_VALUE) * multilehund).toFixed(0) + '%' : `${item.LD_VALUE}%` : mtdtypecheck === 'text' ? convertToKMB((item.LD_VALUE),selectedRetailerCurrency) : item.LD_VALUE !== 0 ? (item.LD_VALUE).toFixed(2) : item.LD_VALUE : mtdVal,
        item?.LD_POP ? ((item.LD_POP) * multilehund).toFixed(0) + '%' : percent, item?.LD_YOY ? ((item.LD_YOY) * multilehund).toFixed(0) + '%' : percent,mtmdVal ? mtdtypecheck === 'percentage' ? mtmdVal !== 0 ? ((mtmdVal) * multilehund).toFixed(0) + '%' : `${mtmdVal}%` : mtdtypecheck === 'text' ? convertToKMB((mtmdVal),selectedRetailerCurrency) : mtmdVal !== 0 ? mtmdVal.toFixed(2) : mtmdVal : mtdVal,
        mtmdPoP ? ((mtmdPoP) * multilehund).toFixed(0) + '%' : percent, mtmdYoY ? ((mtmdYoY) * multilehund).toFixed(0) + '%' : percent, qtmdVal ? qtdtypecheck === 'percentage' ? qtmdVal !== 0 ? ((qtmdVal)* multilehund).toFixed(0) + '%' : `${qtmdVal}%` : qtdtypecheck === 'text' ? convertToKMB((qtmdVal),selectedRetailerCurrency) : qtmdVal !== 0 ? (qtmdVal).toFixed(2) : qtmdVal : qtdVal,
        qtmdPoP ? ((qtmdPoP) * multilehund).toFixed(0) + '%' : percent, 
        qtmdYoY ? ((qtmdYoY) * multilehund).toFixed(0) + '%' : percent, ytmdVal ? ytdtypecheck === 'percentage' ? ytmdVal !== 0 ? ((ytmdVal) * multilehund).toFixed(0) + '%': `${ytmdVal}%` : ytdtypecheck === 'text' ? convertToKMB((ytmdVal),selectedRetailerCurrency) : ytmdVal !== 0 ? (ytmdVal).toFixed(2) : ytmdVal : ytdVal, ytmdYoY ? 
        ((ytmdYoY) * multilehund).toFixed(0) + '%' : percent    ]
      })
      
      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(bodyData, headers);
        const totalColumns = sheetData[0].length;  
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        let fileName = "DownloadKPIS.xlsx"
        return workbook.outputAsync().then((res) => {
          saveAs(res, fileName);
        });
      });
    }
  };
  let toggleDynamicColumnLogModal = () =>{
    let flag =showInsightCellModal
    setShowInsightCellModal(!flag)
  }
  let responseHandlermodal = (response) => {   
    setDisplayDetailsModal(response?.data?.responseData?.reportData);
    setShowInsightCellModal(true);
  }
  function showDynamicColDialog (cell,row, todate) {
    setMqyToValue(todate);
    dispatch(getReportDetailsByMetricName({metricName: row?.METRIC_NAME, customerKey: selectedCustomerKey,
    }, responseHandlermodal))

  }

  const newValueformatld = (cell,row) => {
    let detailslink = row?.DETAIL_SCREEN;
    let ldtypecheck = row?.LD_TYPE?.toLowerCase(); 
    let timeFrame = 'Last Day';
    let rowdisplayName = row?.DISPLAY_NAME;  
    if(cell !== null && cell !== undefined){    
    let newcelldfault = cell !== 0 ? cell.toFixed(2) : cell;
    let newcellprecent = cell !== 0 ? (cell*100).toFixed(0) : cell;       
    return (<>{detailslink === "Y" && rowdisplayName !== 'In-Stock' ? <span onClick={() => showDynamicColDialog(cell, row, timeFrame)} className="newValueformat">
      {ldtypecheck === 'percentage' ? `${newcellprecent}%` : ldtypecheck === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : newcelldfault}
      </span>: <span>
      {ldtypecheck === 'percentage' ? `${newcellprecent}%` : ldtypecheck === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : newcelldfault}
      </span>}</>)
    }else {
      let cell = row.DISPLAY_NAME === 'Coupon ROAS' && (subproject === 'Shopee-eCom-Malaysia' || subproject === 'Shopee-eCom-Vietnam') ? 'N/A' : ldtypecheck === 'percentage' ? '0%' : 0;
      if(detailslink === "Y" && (rowdisplayName !== 'Content Health Score' && rowdisplayName !== 'In-Stock')){
        return <span onClick={() => showDynamicColDialog(cell, row, timeFrame)} className="newValueformat">{cell}</span>
      }else {
        return cell;
      }
           
    }
  }
  
  const newValueformatmtd = (cell,row) => {
    let detailslink = row?.DETAIL_SCREEN;
    let mtdtypecheck = row?.MTM_TYPE ? row?.MTM_TYPE.toLowerCase() :row?.MTD_TYPE?.toLowerCase(); 
    let timeFrame = row?.MTM_TIMEFRAME ? row?.MTM_TIMEFRAME : row?.MTD_TIMEFRAME ? row?.MTD_TIMEFRAME : startendDate !== undefined ? 'MTM' : "MTD";
    let rowdisplayName = row?.DISPLAY_NAME;  
    if(cell !== null && cell !== undefined){    
    let newcelldfault = cell !== 0 ? cell.toFixed(2) : cell;
    let newcellprecent = cell !== 0 ? (cell*100).toFixed(0) : cell;       
    return (<>{detailslink === "Y" && (rowdisplayName !== 'Content Health Score' && rowdisplayName !== 'In-Stock') ? <span onClick={() => showDynamicColDialog(cell, row, timeFrame)} className="newValueformat">
      {mtdtypecheck === 'percentage' ? `${newcellprecent}%` : mtdtypecheck === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : newcelldfault}
      </span>: <span>
      {mtdtypecheck === 'percentage' ? `${newcellprecent}%` : mtdtypecheck === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : newcelldfault}
      </span>}</>)
    }else {
      let cell = row.DISPLAY_NAME === 'Coupon ROAS' && (subproject === 'Shopee-eCom-Malaysia' || subproject === 'Shopee-eCom-Vietnam') ? 'N/A' : mtdtypecheck === 'percentage' ? '0%' : 0;
      if(detailslink === "Y" && (rowdisplayName !== 'Content Health Score' && rowdisplayName !== 'In-Stock')){
        return <span onClick={() => showDynamicColDialog(cell, row, timeFrame)} className="newValueformat">{cell}</span>
      }else {
        return cell;
      }
           
    }
  }

  const newValueformatqtd = (cell,row) => {
    let detailslink = row?.DETAIL_SCREEN;
    let qtdtypecheck = row?.QTM_TYPE ? row?.QTM_TYPE.toLowerCase() :row?.QTD_TYPE?.toLowerCase();
    let timeFrame = row?.QTM_TIMEFRAME ? row?.QTM_TIMEFRAME : row?.QTD_TIMEFRAME ? row?.QTD_TIMEFRAME : startendDate !== undefined ? 'QTM' : "QTD";
    let rowdisplayName = row?.DISPLAY_NAME;  
    if(cell !== null && cell !== undefined){  
    let newcelldfault = cell !== 0 ? cell.toFixed(2) : cell;
    let newcellprecent = cell !== 0 ? (cell*100).toFixed(0) : cell;      
    return (<>{detailslink === "Y" && (rowdisplayName !== 'Content Health Score' && rowdisplayName !== 'In-Stock') ? <span onClick={() => showDynamicColDialog(cell, row, timeFrame)} className="newValueformat">
      {qtdtypecheck === 'percentage' ? `${newcellprecent}%` : qtdtypecheck === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : newcelldfault}
      </span>: <span>
      {qtdtypecheck === 'percentage' ? `${newcellprecent}%` : qtdtypecheck === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : newcelldfault}
      </span>}</>)
    }else {
      let cell = row.DISPLAY_NAME === 'Coupon ROAS' && (subproject === 'Shopee-eCom-Malaysia' || subproject === 'Shopee-eCom-Vietnam') ? 'N/A' : qtdtypecheck === 'percentage' ? '0%' : 0;
      if(detailslink === "Y" && (rowdisplayName !== 'Content Health Score' && rowdisplayName !== 'In-Stock')){
        return <span onClick={() => showDynamicColDialog(cell, row, timeFrame)} className="newValueformat">{cell}</span>
      }else {
        return cell;
      }
    }
  }

  const newValueformatytd = (cell,row) => {
    let detailslink = row?.DETAIL_SCREEN;
    let ytdtypecheck = row?.YTM_TYPE ? row?.YTM_TYPE.toLowerCase() :row?.YTD_TYPE?.toLowerCase();
    let timeFrame = row?.YTM_TIMEFRAME ? row?.YTM_TIMEFRAME : row?.YTD_TIMEFRAME ? row?.YTD_TIMEFRAME : startendDate !== undefined ? 'YTM' :"YTD";
    let rowdisplayName = row?.DISPLAY_NAME;  
    if(cell !== null && cell !== undefined){ 
    let newcelldfault = cell !== 0 ? cell.toFixed(2) : cell;
    let newcellprecent = cell !== 0 ? (cell*100).toFixed(0) : cell;     
    return (<>{detailslink === "Y" && (rowdisplayName !== 'Content Health Score' && rowdisplayName !== 'In-Stock') ? <span onClick={() => showDynamicColDialog(cell,row,timeFrame)} className="newValueformat">
      {ytdtypecheck === 'percentage' ? `${newcellprecent}%` : ytdtypecheck === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : newcelldfault}
      </span>: <span>
      {ytdtypecheck === 'percentage' ? `${newcellprecent}%` : ytdtypecheck === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : newcelldfault}
      </span>}</>)
    }else {
      let cell = row.DISPLAY_NAME === 'Coupon ROAS' && (subproject === 'Shopee-eCom-Malaysia' || subproject === 'Shopee-eCom-Vietnam') ? 'N/A' : ytdtypecheck === 'percentage' ? '0%' : 0;
      if(detailslink === "Y" && (rowdisplayName !== 'Content Health Score' && rowdisplayName !== 'In-Stock')){
        return <span onClick={() => showDynamicColDialog(cell, row, timeFrame)} className="newValueformat">{cell}</span>
      }else {
        return cell;
      }
    }
  }
  
  const newpercentValue = (cell, row) => {
    if(cell !== null && cell !== undefined){ 
      let newCentVal = cell*100;
      if(newCentVal >= 0){
        return(<span className='clr-grn'><CarretIconUp />{newCentVal.toFixed(0)}%</span>)
      }else {
        return(<span className='clr-rd'><CarretIconDown />{(newCentVal.toFixed(0)) * -1}%</span>)
      }    
    }else {
      let cell = row.DISPLAY_NAME === 'Coupon ROAS' && (subproject === 'Shopee-eCom-Malaysia' || subproject === 'Shopee-eCom-Vietnam') ? 'N/A' : '0%';
      return <span className='clr-grn'><CarretIconUp />{cell}</span>
    }
  }
  const renderDetailsModelWindow = () => {
    if(showInsightCellModal){
      let reportId = "";
      let pbiTables = "";
      let customerkeyPbi = "";
      let pbiColumns = "";
      displaydetailsModal.map((report, index)=>{
        reportId = report.REPORTID;
        pbiTables = report.PBI_TABLES;
        customerkeyPbi = report.CUSTOMER_KEY;
        pbiColumns = report.PBI_COLUMN_NAMES;
      });
      return (
        <NewInsightsDetailsModal showInsightCellModal={showInsightCellModal} displaydetailsModal={displaydetailsModal}
         displayColumnDialogOff={toggleDynamicColumnLogModal} customerkeyPbi={customerkeyPbi}
         reportId={reportId} pbiTables={pbiTables} mqyToValue={mqyToValue}
         pbiColumns = {pbiColumns} 
         />
      )
    }
  }
  const metricDisplayName = (cell, row) => {
    if(row?.DETAIL_SCREEN === 'Y' && row?.DISPLAY_NAME === 'In-Stock') {
      let timeFrame = '';
      return(<span onClick={() => showDynamicColDialog(cell, row, timeFrame)} className="newValueformat">{row?.DISPLAY_NAME ? row?.DISPLAY_NAME : row?.METRIC_NAME} </span>)
    } else {
      return(<>{row?.DISPLAY_NAME !== undefined && row?.DISPLAY_NAME !== null ? row?.DISPLAY_NAME : row?.METRIC_NAME} </>)
    }    
  }
  const showCrispLink = (selecteddisplayName) => {
    dispatch(getCrispLink({customerKey: selectedCustomerKey,displayName:selecteddisplayName},(reponse)=>{
      window.open(reponse?.data?.responseData?.url, "_blank");
    }));
  }
  const dataCrispLink = (cell, row) => {
    return (<>{cell === 'TRUE' ? <div className='crisplink' onClick={(e) => showCrispLink(row?.DISPLAY_NAME)}><RightArrowUpIcon />CRISP</div> : ""} </>)
  }
  const InsightTableInfo = () => {  
  if(loading) {
    return (<div id="strategy_wrapper">
       <div className="container-fluid  default-top loading_insights loading_wrapper bg-w p-5">
         <div className="sweet-loading">
           <span><b>Processing...</b></span>
           <BarLoader style={{ display: 'block', margin: '0 auto', borderColor: 'red' }} size={150} color={"#123abc"}
             height={4} width={100} loading={loading} />
         </div>
       </div>
     </div>
   )} else { 
  let monthVal = startendDate !== undefined ?  `${moment(insightsDatesParamaterdata, "MM").format("MMMM")} - ${moment(insightsStartEndDatesdata?.start,"YYYY-MM-DD").year()}` : 'Month To Date',
  querterVal = startendDate !== undefined ?  'QTM' :'Quarter To Date',
  yearVal = startendDate !== undefined ? 'YTM' : 'Year To Date';
  return(
    <div className='insighttable mb-5'>       
    {renderDetailsModelWindow()}
    <div className='text-right downloadfile' onClick={downloadData}> Download <ExcelfileIcon /></div>
    {finalSectionName?.CRISP_LINK > 0 ? 
    <BootstrapTable data={ insightTableData } pagination={ true } options={ options } > 
    <TableHeaderColumn row='0' width='100' dataField='DISPLAY_NAME' colSpan='1' isKey={ true } />
    <TableHeaderColumn row='1' width='100' dataField='DISPLAY_NAME' dataFormat={metricDisplayName} dataAlign='center' />
    <TableHeaderColumn row='0' width='90' colSpan='3' headerAlign='center'>Last Day</TableHeaderColumn>
    <TableHeaderColumn row='1' width='90' dataField='LD_VALUE' dataAlign='center' dataFormat={newValueformatld}>Value</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField='LD_POP' dataAlign='center' dataFormat={newpercentValue}>PoP</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField='LD_YOY' dataAlign='center' dataFormat={newpercentValue}>YoY</TableHeaderColumn>    
    <TableHeaderColumn row='0' width='100' colSpan='3' headerAlign='center'> {monthVal}</TableHeaderColumn>
    <TableHeaderColumn row='1' width='90' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'MTM_VALUE' : 'MTD_VALUE'} dataAlign='center' dataFormat={newValueformatmtd}>Value</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'MTM_POP' : 'MTD_POP'} dataAlign='center' dataFormat={newpercentValue}>PoP</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'MTM_YOY' : 'MTD_YOY'} dataAlign='center' dataFormat={newpercentValue}>YoY</TableHeaderColumn>
    <TableHeaderColumn row='0' width='100' colSpan='3' headerAlign='center'>{querterVal}</TableHeaderColumn>
    <TableHeaderColumn row='1' width='90' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'QTM_VALUE' : 'QTD_VALUE'} dataAlign='center' dataFormat={newValueformatqtd}>Value</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'QTM_POP' : 'QTD_POP'} dataAlign='center' dataFormat={newpercentValue}>PoP</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'QTM_YOY' : 'QTD_YOY'} dataAlign='center' dataFormat={newpercentValue}>YoY</TableHeaderColumn>
    <TableHeaderColumn row='0' width='100' colSpan='2' dataAlign='center'>{yearVal}</TableHeaderColumn>
    <TableHeaderColumn row='1' width='90' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'YTM_VALUE' : 'YTD_VALUE'} dataAlign='center' dataFormat={newValueformatytd}>Value</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'YTM_YOY' : 'YTD_YOY'} dataAlign='center' dataFormat={newpercentValue}>YoY</TableHeaderColumn>
    <TableHeaderColumn row='0' width='70' colSpan='1'></TableHeaderColumn>
     <TableHeaderColumn row='1' width='70' dataField='CRISP' dataAlign='center' dataFormat={dataCrispLink}></TableHeaderColumn>
    </BootstrapTable>: <BootstrapTable data={ insightTableData } pagination={ true } options={ options } > 
    <TableHeaderColumn row='0' width='100' dataField='DISPLAY_NAME' colSpan='1' isKey={ true }></TableHeaderColumn>
    <TableHeaderColumn row='1' width='100' dataField='DISPLAY_NAME' dataFormat={metricDisplayName} dataAlign='center'></TableHeaderColumn>
    <TableHeaderColumn row='0' width='100' colSpan='3' headerAlign='center'>Last Day</TableHeaderColumn>
    <TableHeaderColumn row='1' width='90' dataField='LD_VALUE' dataAlign='center' dataFormat={newValueformatld}>Value</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField='LD_POP' dataAlign='center' dataFormat={newpercentValue}>PoP</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField='LD_YOY' dataAlign='center' dataFormat={newpercentValue}>YoY</TableHeaderColumn>
    <TableHeaderColumn row='0' width='100' colSpan='3' headerAlign='center'> {monthVal}</TableHeaderColumn>
    <TableHeaderColumn row='1' width='90' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'MTM_VALUE' : 'MTD_VALUE'} dataAlign='center' dataFormat={newValueformatmtd}>Value</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'MTM_POP' : 'MTD_POP'} dataAlign='center' dataFormat={newpercentValue}>PoP</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'MTM_YOY' : 'MTD_YOY'} dataAlign='center' dataFormat={newpercentValue}>YoY</TableHeaderColumn>
    <TableHeaderColumn row='0' width='100' colSpan='3' headerAlign='center'>{querterVal}</TableHeaderColumn>
    <TableHeaderColumn row='1' width='90' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'QTM_VALUE' : 'QTD_VALUE'} dataAlign='center' dataFormat={newValueformatqtd}>Value</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'QTM_POP' : 'QTD_POP'} dataAlign='center' dataFormat={newpercentValue}>PoP</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'QTM_YOY' : 'QTD_YOY'} dataAlign='center' dataFormat={newpercentValue}>YoY</TableHeaderColumn>
    <TableHeaderColumn row='0' width='100' colSpan='2' dataAlign='center'>{yearVal}</TableHeaderColumn>
    <TableHeaderColumn row='1' width='90' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'YTM_VALUE' : 'YTD_VALUE'} dataAlign='center' dataFormat={newValueformatytd}>Value</TableHeaderColumn>
    <TableHeaderColumn row='1' width='70' dataField={startendDate !== undefined && finalSectionName?.SECTION_NAME !== 'Digital Shelf' ? 'YTM_YOY' : 'YTD_YOY'} dataAlign='center' dataFormat={newpercentValue}>YoY</TableHeaderColumn>
    </BootstrapTable>} 
    <div className='f-15'> <strong> Note: </strong> <i> <strong> PoP:</strong> Period over Period percentage comparison. <strong>YoY:</strong> Year over Year percentage comparison </i></div>
    {finalSectionName?.SECTION_NAME === 'Digital Shelf' && <div className='mb-3 f-15 ml-5 pl-2'><i> <strong> Share of Search: </strong>How many K-C SKUs are in the Top 10 results measured by keyword. Keywords are configured in the provider.</i></div>}
    </div>
  )}}
  return <InsightTableInfo />
}

export default NewInsightsTable;