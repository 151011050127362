import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetDashboardDetails, 
  addNewProject, addNewSubproject,
  getAllProjectAndSubprojects } from '../../actions';
import Modal from 'react-bootstrap/Modal';
import DashboardBasicDetails from './DashboardBasicDetails';
import debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { ToastContainer, toast } from 'react-toastify';

class AdminFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettingsWindow: false,
      viewBuilderJSON: {
        "viewId": null,
        "viewName": null,
        "viewDescription": null,
        "viewStatus": "Draft",
        "htmlTemp": {},
        "projectId": null,
        "categoryId": null,
        "projectName": null,
        "categoryName": null,
        linkForDocumentation: null,
        displayAddNewProjectModal: false,
        displayAddNewSubprojectModal: false,
        subprojectName: null,
        projectName: null,
        oktaGroupAdmin: null,
        oktaGroupRead: null,
        logo: null,
      }

    }
  }



  setSearchQuery = (e) => {
    e.persist();
    debounce(() => {
      let searchString = e.target.value;
      this.props.setSearchString(searchString);
    }, 1000)();
  }
  getFiles(e) {
    this.setState({error:""});
    let self = this;
    var reader = new FileReader();
    let file = e.target.files[0];
    reader.onload = function(upload) {
      self.setState({
          logo: upload.target.result
      });
  };
    reader.readAsDataURL(file);
}
  renderAddNewSubproject() {
    return (
      <Modal size={'md'} show={this.state.displayAddNewSubprojectModal} onHide={() => {
        this.setState({ displayAddNewSubprojectModal: false });
      }} id="setting_modal" >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="modalForWidgetLabel"><i className="fas fa-plus-circle mr-2" aria-hidden="true"></i>Add new subproject</h5>
        </Modal.Header>
        <Modal.Body>
          <form >
            <div className="form-group">
              <label>Subproject name</label>
              <input type="text" value={this.state.subprojectName} className="form-control" onChange={(e) => { this.setState({ subprojectName: e.target.value, error: "" }); }} />
            </div>
            <div className="form-group">
              <label> Okta admin group name</label>
              <input type="text" value={this.state.oktaGroupAdmin} className="form-control" onChange={(e) => { this.setState({ oktaGroupAdmin: e.target.value, error: "" }); }} />
            </div>
            <div className="form-group">
              <label> Okta read group name</label>
              <input type="text" value={this.state.oktaGroupRead} className="form-control" onChange={(e) => { this.setState({ oktaGroupRead: e.target.value, error: "" }); }} />
            </div>
            <div className="form-group">
              <label> Upload subproject logo</label>
              <input
                            type="file"
                            accept=".png,.jpeg,.jpg"
                            className="form-control my-2"
                            onChange={(e) => {
                                this.getFiles(e);
                            }} />
            </div>
          </form>
          <p className="error">{this.state.error}</p>
          <button class="btn btn-light" onClick={(e) => { this.handleAddNewSubproject(e) }}>Save</button>
        </Modal.Body>
      </Modal>
    )
  }
  renderAddNewProject() {
    return (
      <Modal size={'md'} show={this.state.displayAddNewProjectModal} onHide={() => {
        this.setState({ displayAddNewProjectModal: false });
      }} id="setting_modal" >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="modalForWidgetLabel"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-folder" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z" />
            <path fill-rule="evenodd" d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z" />
          </svg>  Add new project</h5>
        </Modal.Header>
        <Modal.Body>
          <form >
            <div className="form-group">
              <label> Name</label>
              <input type="text" value={this.state.projectName} className="form-control" onChange={(e) => { this.setState({ projectName: e.target.value, error: "" }); }} />
            </div>
            <div className="form-group">
              <label> Okta admin group name</label>
              <input type="text" value={this.state.oktaGroupAdmin} className="form-control" onChange={(e) => { this.setState({ oktaGroupAdmin: e.target.value, error: "" }); }} />
            </div>
            <div className="form-group">
              <label> Okta read group name</label>
              <input type="text" value={this.state.oktaGroupRead} className="form-control" onChange={(e) => { this.setState({ oktaGroupRead: e.target.value, error: "" }); }} />
            </div>
            <div className="form-group">
              <label> Upload project logo</label>
              <input
                            type="file"
                            accept=".png,.jpeg,.jpg"
                            className="form-control my-2"
                            onChange={(e) => {
                                this.getFiles(e);
                            }} />
            </div>
            <p className="error">{this.state.error}</p>
            <button class="btn btn-light" onClick={(e) => { this.handleAddNewProject(e) }}>Save</button>
          </form>

        </Modal.Body>
      </Modal>
    )
  }
  renderSettingsWindow() {
    return (
      <Modal size={'md'} show={this.state.showSettingsWindow} onHide={() => {
        this.setState({ showSettingsWindow: false });
      }} id="setting_modal" >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="modalForWidgetLabel"><i className="fas fa-cog"></i>  Dashboard Settings</h5>
        </Modal.Header>
        <Modal.Body>
          <DashboardBasicDetails
            hideSettingsWindow={(e) => { this.setState({ showSettingsWindow: false }) }}
            viewBuilderJSON={this.state.viewBuilderJSON} />
        </Modal.Body>
      </Modal>
    )
  }
  handleAddNewProject(e) {
    e.preventDefault();
    const { projectName, oktaGroupRead, oktaGroupAdmin, logo } = this.state;
    if (projectName || oktaGroupAdmin || oktaGroupRead || logo) {
      this.props.addNewProjectAPI({
        projectName,
        oktaGroupAdmin,
        oktaGroupRead,
        projectLogo: logo,
      }, (res, rej) => {
        if (res) {
          if (res.data.responseType === "success") {
            toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
          } else {
            toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
          }

          this.props.getAllProjectAndSubprojectsAPI();
        } else {
          
        }
      })
    } else {
      this.setState({ error: "Please enter all values" });
    }
  }
  handleAddNewSubproject(e) {
    e.preventDefault();
    const { projectName, subprojectName, oktaGroupRead, oktaGroupAdmin, logo } = this.state;
    if (projectName || subprojectName || oktaGroupAdmin || oktaGroupRead || logo) {
      this.props.addNewSubprojectAPI({
        projectName: this.props.project,
        subprojectName,
        oktaGroupAdmin,
        oktaGroupRead,
        subprojectLogo: logo,
      }, (res, rej) => {
        if (res) {
          if (res.data.responseType === "success") {
            toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
            this.props.getAllProjectAndSubprojectsAPI();
          } else {
            toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
          }
        } else {
          
        }
      })
    }else {
      this.setState({ error: "Please enter all values" });
    }
  }
  render() {
    return (
      <div className="row bg-grey-1 width-90">
        <ToastContainer />
        {this.renderSettingsWindow()}
        
        {  this.renderAddNewProject()}
       
        {this.renderAddNewSubproject()}
        <div className="col-3">
          <ul>
            <li onClick={(e) => { this.props.changeUserSelectedTab("Active") }} data-toggle="tooltip" data-placement="top" id="active" className={`${this.props.activeMember === "Active" ? "active" : null}`} title="Pages which are published &amp; active">Active</li>
            <li onClick={(e) => { this.props.changeUserSelectedTab("Inactive") }} data-toggle="tooltip" data-placement="top" id="inactive" className={`${this.props.activeMember === "Inactive" ? "active" : null}`} title="Pages which are published &amp; but active">Inactive</li>
            <li onClick={(e) => { this.props.changeUserSelectedTab("Draft") }} data-toggle="tooltip" data-placement="top" className={`${this.props.activeMember === "Draft" ? "active" : null}`} title="Pages which are in Draft status">Draft</li>
          </ul>
        </div>
        <div className="col-9 d-flex justify-content-end align-items-center">          
          <div className="search-box">
            <div className="input-group">
              <input type="text"
                className="form-control"
                placeholder="search view"
                onChange={this.setSearchQuery}
              />
              <div className="input-group-append">
                <span className="input-group-text"><i className="fas fa-search" aria-hidden="true"></i></span>
              </div>
            </div>

          </div>
        </div>
      </div>);
  }
}


function mapStateToProps(state) {
  let project = _get(state, 'globalReducer.project', '');
  let isAppAdmin = _get(state, 'globalReducer.isAppAdmin', false);
	let isProjectAdmin = _get(state, 'globalReducer.isProjectAdmin', false);
	let isSubprojectAdmin = _get(state, 'globalReducer.isSubprojectAdmin', false);
  return ({
    project,
    isAppAdmin,
		isProjectAdmin,
		isSubprojectAdmin
  })
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    resetDashboardDetailsAPI: resetDashboardDetails,
    addNewProjectAPI: addNewProject,
    addNewSubprojectAPI: addNewSubproject,
    getAllProjectAndSubprojectsAPI:getAllProjectAndSubprojects
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminFilters);
