import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import { getSummaryByUser } from '../../actions/index';
import { PriceFormatter,generateStrategyNameList, convertToKMB, dynamicRetailerimpactPriceFormat } from "../../helper";
import { CarretIconUp, CarretIconDown } from '../../components/Icons';

const UserSummaryTable = (props) => {
  const [userSummaryData, setUserSummaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState();

  const dispatch = useDispatch();
  let {selectedCustomerKey, selectedRetailerCurrency, dynamicPriceFormatting, defaultDailyUsageConfig, selectedSubprojectInfo } = useSelector((state) => state.globalReducer);
  let allStrategyDetails = useSelector(
    (state) => state.strategyReducer.detailsForAllStrategies
  );
  let [selectedStrategies, setSelectedStrategies] = useState(generateStrategyNameList(allStrategyDetails));

  let {
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedStrategies,
    userSummary,
    adoptionSelectedUseCases,
    retailerCurrency
  } = useSelector((state) => state.adoptionReducer);

  let retailerCurrencySign = retailerCurrency === undefined || retailerCurrency === '' || retailerCurrency === 'USD' ? selectedRetailerCurrency : '$'; 

  const options = {
    totalSize: totalSize,
    sizePerPage: sizePerPage,
    page: pageNumber,
    showTotal: true,
    disablePageTitle: true,
  };

  let responseHandler = (response) => {
  if(response?.data){
     if(response.data.responseData.length <= 0) {
      setLoading(false)
     } else {
        setUserSummaryData(response.data.responseData) 
        setTotalSize(response.data.responseData.length)
        setLoading(false)
    }
  } else {
    setLoading(false)
  }
  };

  let requestObject = () => {
    return {
      userNames: adoptionSelectedUsers ? adoptionSelectedUsers : [],
      strategies: adoptionSelectedStrategies ? adoptionSelectedStrategies : [],
      dropDownSelectionPeriod: selectedDateRange
        ? selectedDateRange
        : "Prior Week",
      additionalParametersInterval: adoptionCustomDates
        ? adoptionCustomDates
        : {},
      additionalParametersPoint: adoptionAdditionalParamaters,
      pageNo: pageNumber,
      totalRows: sizePerPage,
      useCase: adoptionSelectedUseCases,
      isCustomDateRange: selectedDateRange == "Custom Date Range" ? true : false,
      defaultDailyUsageConfig: defaultDailyUsageConfig ? defaultDailyUsageConfig : '',
      currency: retailerCurrency === undefined || retailerCurrency === '' ? 'USD' : retailerCurrency,
      customerKey: selectedCustomerKey
    };
  };
  useEffect(() => {
    if(userSummary && userSummary.responseData){
      setUserSummaryData(userSummary.responseData) 
      setTotalSize(userSummary.responseData.length)
      setLoading(false)
    }
  }, [userSummary]);
  useEffect(() => {
    setLoading(true)
    if (selectedDateRange) {
    dispatch(getSummaryByUser(requestObject(), responseHandler));
    }
  },[
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedStrategies,
    sizePerPage, 
    pageNumber,
    retailerCurrency,
    adoptionSelectedUseCases,
    adoptionSelectedStrategies
  ]);
  
  const estimatedRevenueFormatter = (cell) => {
    if(cell !== undefined && cell !== null && cell !== 'NaN') {
    return (
      <>
        {Number(cell) && dynamicPriceFormatting && dynamicPriceFormatting === 'dot' ? dynamicRetailerimpactPriceFormat(convertToKMB(Number(cell), retailerCurrencySign)) : Number(cell) ? convertToKMB(Number(cell), retailerCurrencySign) : retailerCurrencySign + cell}
      </>
    )
    } else {
      return `${retailerCurrencySign}0`
    }
  }
  const percentValue = (cell) => {
    if(cell !== undefined && cell !== null && cell !== 'NaN' && cell !== 'NA') {
      let finalVal = parseFloat(cell).toFixed(2);
      let finalValfixed = parseFloat(cell).toFixed(0);
      if(finalVal == 0) {
        return <span className="clr-grn"><CarretIconUp />{finalValfixed}%</span>
      } else if(finalVal > 0) {
        return <span className="clr-grn"><CarretIconUp />{finalVal}%</span>
      }  else {
        return  <span className="clr-rd"><CarretIconDown />{finalVal}%</span>
      }     
    } else {
      return  <span className="clr-grn"><CarretIconUp />0%</span>
    }  
  }
  const percentValuenew = (cell) => {
    return(<>{cell != undefined ? cell + '%' : '0%'}</>)
  }
  const actionTime = (cell) => {
    return(<>{cell == 1 ? cell + 'day' : cell != undefined ? cell + ' days' : '0 days'}</>)
  }
  
  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (type === "pagination") {
      setSizePerPage(sizePerPage);
      setPageNumber(page);
    }
  };
  const textoptions = {
    noDataText: 'No User Data Available'
  };
  if (loading) {
    return (
      <div id="no-data-available" style={{ marginTop: "5%" }}>
        Loading....
      </div>
    );
  } else {
    return (
      <div className='alert_summarytable mt-5'>
        <div className="row ml-0">
          <div style={{fontSize: 18, marginBottom: 10,fontWeight: "bold" }}>
            Summary By User
          </div>
        </div>
        <BootstrapTable data={ userSummaryData } options={textoptions} pagination={ paginationFactory(options)} onTableChange={ handleTableChange }> 
        <TableHeaderColumn row='0' width='75' dataField='userName' dataSort={ true } rowSpan='2' isKey={ true }>User</TableHeaderColumn>
        <TableHeaderColumn row='0' width='55' dataField='daysOfUsage' dataSort={ true } dataAlign='center' rowSpan='2'>Days of usage </TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='3' headerAlign='center'>Usage % </TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='dailyUsageCurrPercent' dataFormat={percentValuenew} dataAlign='center' dataSort={true}>Current</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='dailyUsagePOPPercent' dataFormat={percentValue} dataSort={true} dataAlign='center'>PoP</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='dailyUsageYOYPercent' dataFormat={percentValue} dataAlign='center' dataSort={true}>YoY</TableHeaderColumn>        
        <TableHeaderColumn row='0' width='75' colSpan='3' headerAlign='center'>AVG Actions Taken per day</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='avgActionTakenperDayCur' dataAlign='center' dataSort={true}>Current</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='avgActionTakenperDayPOP' dataFormat={percentValue} dataSort={true} dataAlign='center'>PoP</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='avgActionTakenperDayYOY' dataFormat={percentValue} dataAlign='center' dataSort={true}>YoY</TableHeaderColumn> 
        <TableHeaderColumn row='0' width='75' colSpan='2' headerAlign='center'>Actions Taken</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='actionedEstimatedValue' dataFormat={estimatedRevenueFormatter} dataAlign='center' dataSort={ true }>Estimated Value</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='avgActionTime' dataFormat={actionTime} dataAlign='center' dataSort={true}>Avg Action Time</TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='1' headerAlign='center'>Unresolved Actions</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='unResolvedEstimatedValue' dataFormat={estimatedRevenueFormatter} dataAlign='center' dataSort={true}>Estimated Value</TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='2' headerAlign='center'>Resolved Actions</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='resolvedEstimatedValue' dataFormat={estimatedRevenueFormatter} dataAlign='center' dataSort={true}>Estimated Value</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='avgResolutionTime' dataFormat={actionTime} dataSort={true} dataAlign='center'>Avg Resol. time</TableHeaderColumn>
        <TableHeaderColumn row='0' width='75' colSpan='2' headerAlign='center'>Sales Impact</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='salesImpact' dataFormat={estimatedRevenueFormatter} dataAlign='center' dataSort={true}> Value</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='salesImpactPercent' dataFormat={percentValue} dataSort={true} dataAlign='center'>PoP</TableHeaderColumn>        
        <TableHeaderColumn row='0' width='75' colSpan='6' headerAlign='center'>Effectiveness </TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='effectiveNessVSTriggered' dataFormat={percentValuenew} dataAlign='center' dataSort={true}>vs.Triggered</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='effectiveNessVSTriggeredPOP' dataFormat={percentValue} dataAlign='center' dataSort={true}>PoP</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='effectiveNessVSTriggeredYOY' dataFormat={percentValue} dataAlign='center' dataSort={true}>YoY</TableHeaderColumn>
        <TableHeaderColumn row='1' width='75' dataField='effectiveNessVSActioned' dataFormat={percentValuenew} dataAlign='center' dataSort={true}>vs.Actioned</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='effectiveNessVSActionedPOP' dataFormat={percentValue} dataAlign='center' dataSort={true}>PoP</TableHeaderColumn>
        <TableHeaderColumn row='1' width='65' dataField='effectiveNessVSActionedYOY' dataFormat={percentValue} dataAlign='center' dataSort={true}>YoY</TableHeaderColumn>
        </BootstrapTable> 
      </div>
    )
  }
}

export default UserSummaryTable;