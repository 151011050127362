import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import BarLoader from "react-spinners/BarLoader";
import Filters from "../../components/Filters";
import NoStatregyRecommendationPage from "./NoStatregyRecommendationPage";
import {
  getAllRecommendations,
  updateActionTakenInfo,
  getSavedFilters,
  storeActionTakenInfo,
  getRecommendationActionedAndActiveData,
  getDetailsForAllStrategies,
  storeGlobalFilterSelections,
  storeRecommendationSelectedStrategies,
  updateSnoozeSubProject,
} from "../../actions";
import { css } from "@emotion/core";

import RecommendationActionedAndActiveView from "./RecommendationActionedAndActiveView";
import { addCommonActivityCaptureDetail, getCookie } from "../../helper";
import IdleTimeOutModal from "./IdleTimeOutModal";
import {Alert, Button } from 'react-bootstrap';

const NewRecommendationPage = () => {
  let dispatch = useDispatch();
  let [currentUserName, setCurrentUserName] = useState();
  let [loading, setLoading] = useState(true);
  const [showAlertTxt, setShowAlertTxt] = useState(false);
  let { globalFilter,subproject, selectedGlobalFilters, keyMapTabList, alertPrecision } = useSelector(
    (state) => state.globalReducer
  );
  let {selectedStrategies, recommendationSelectedStrategy } = useSelector((state) => state.strategyReducer);

  let { isActionTaken, responseData, newRecoSearchText } = useSelector(
    (state) => state.newRecommendationTableData
  );

  let login = () => {
    this.props.authService.login("/");
  };

  let logout = () => {
    this.props.authService.logout("/");
  };

  useEffect(() => {    
    if(newRecoSearchText === undefined && recommendationSelectedStrategy?.selection === "active" && recommendationSelectedStrategy?.activeRowDetails?.ACTUAL_ACTIVE_COUNT !== responseData?.totalCount && recommendationSelectedStrategy?.activeRowDetails !== undefined && selectedStrategies?.length === 1 && selectedStrategies?.length && selectedStrategies[0] !== 'Share of Search'){
      setShowAlertTxt(true);
      setLoading(false);
  }
  }, [responseData?.totalCount]);

  useEffect(() => {
		return () => {
      let isFilterUpdated = false
      let globalFilters = getCookie("selected-global-filters")
      if(globalFilters) {
        Object.keys(globalFilters).forEach(filter => {
          if(globalFilters[filter] && globalFilters[filter].length) {
            isFilterUpdated = true
          }
        })
        if(!isFilterUpdated) {
          document.cookie = "selected-parent-filter= ; path=/; max-age=0;"
					document.cookie = "selected-filter-name= ; path=/; max-age=0;"
          document.cookie = "selected-filter-object= ; path=/; max-age=0;"
        }
      }
    };
	})

  useEffect(() => {
    document.title = keyMapTabList ? keyMapTabList.recommendationsTab : ""
  // hard code for snooze
    let isSnoozeSubProject= true;
	  dispatch(updateSnoozeSubProject(isSnoozeSubProject)) 

  }, [keyMapTabList]);
  useEffect(() => {
    let userName = localStorage.getItem("user-info")
      ? JSON.parse(localStorage.getItem("user-info")).given_name
      : "No Data Available";
    setCurrentUserName(userName);

    Promise.all([
      dispatch(getSavedFilters()),
      dispatch(getDetailsForAllStrategies({ strategyStatus: "active" })),
    ])
      .then((data) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  }, []);
  let updateFinalActiveObject=(resultObj)=>{
    var result = recommendationSelectedStrategy ? resultObj.filter(obj => {
      return obj.RECOMMENDATION_NAME === recommendationSelectedStrategy["strategyName"]
    }) : null;
    
    recommendationSelectedStrategy &&  dispatch(
      storeRecommendationSelectedStrategies({
        ...recommendationSelectedStrategy,
        activeRowDetails:result[0],    
        estimatedValue:recommendationSelectedStrategy.selection === "actioned" ? result[0]["ACTION_ESTIMATED_VALUE"] : result[0]["ACTIVE_ESTIMATED_VALUE"],       
   
      })
    );
  }
  useEffect(() => {  
    if (isActionTaken) {
      setLoading(true);
    }
     if(isActionTaken !== undefined && isActionTaken === false){
      setShowAlertTxt(false);
      dispatch(getRecommendationActionedAndActiveData( {
        filterValues: selectedGlobalFilters,
        alertPrecision: alertPrecision,
        dbType: "read-write",
      }))
        .then((data) => {
            updateFinalActiveObject(data.data.responseData)    
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
     }
      
    
  }, [isActionTaken]);

  useEffect(() => {
      setLoading(true);
      setShowAlertTxt(false);
      dispatch(getRecommendationActionedAndActiveData({
        filterValues: selectedGlobalFilters,
        alertPrecision: alertPrecision,
        dbType: "read-only",
      }))
        .then((data) => {
          setLoading(false);
          if (data.data.responseData.length > 0) {
            updateFinalActiveObject(data.data.responseData) 
          }               
        })
        .catch((err) => {
          setLoading(false);
        });   
  }, [selectedGlobalFilters]);

  if (loading) {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    return (
      <div id="strategy_wrapper">
        <div className="container-fluid  default-top loading_wrapper  bg-gray p-5">
          <div className="sweet-loading">
            <span><b>Processing...</b></span>
            <BarLoader
              css={override}
              size={150}
              color={"#123abc"}
              height={4}
              width={100}
              loading={loading}
          />
          </div>
        </div>
      </div>
    );
  }
  const refreshPage = ()=>{window.location.reload();}
  return (
    <div
      className="container-fluid  default-top  bg-gray "
      style={{ paddingLeft: 40, paddingRight: 40 }}
    > 
    {showAlertTxt ?
      <Alert variant="primary" className="text-center" style={{backgroundColor:"#0056bb", top:"15px",fontWeight:"bold",fontSize:"14px",color:"#fff"}}>
        <span>Alerts have been updated. Please refresh the page to see the latest changes.</span>
        <span className="ml-5"> <Button variant="light" onClick={refreshPage} style={{color:"#1563ff",fontWeight:"bold",padding:"6px 40px"}}>Refresh</Button></span>
      </Alert> : null}    
      <div className="row">
        <div className="col-12 my-2">
          <div
            style={{marginLeft: 10}}
            className="row new-recommendation align-items-center w-100 new-recommt"
          >
            <span style={{marginRight: 5,fontWeight: 700}}>
            {`Hi ${currentUserName},`} <span></span>
            </span>
            <span className="daily_mix">
      
              Your Daily Mix
            </span>
            <div class="ml-auto">  <Filters hideFilteredRow={true}/></div>
          
          </div>
         
          <RecommendationActionedAndActiveView />
          <NoStatregyRecommendationPage />
          <IdleTimeOutModal />
        </div>
      </div>
    </div>
  );
};

export default NewRecommendationPage;
