import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { DropDownIcon } from "../../components/Icons";
import "../Recommendations/Recommendations.scss";
import {
  adoptionSelectedUSers, getAdoptionEstimatedValueNew,getSummaryByAlert,getSummaryByUser, storeAdoptionSelectedStrategies,
  getEngagementSummary  } from "../../actions/index";
import { generateStrategyList, generateStrategyNameList } from "../../helper";
export default function AlertImpactFilter(props) {
  const [userNameList, setUserList] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [searchString, setSearchString] = useState("");
  let {detailsForAllUsers, 
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedStrategies,
    adoptionSelectedUseCases
  } =  useSelector(
    (state) => state.adoptionReducer
  );

  let allStrategyDetails = useSelector(
    (state) => state.strategyReducer.detailsForAllStrategies
  );
 
  let dispatch = useDispatch()


  useEffect(() => {
    setUserList(generateStrategyNameList(allStrategyDetails));
    dispatch(storeAdoptionSelectedStrategies(generateStrategyNameList(allStrategyDetails)));
  }, [allStrategyDetails]);

  function setCheckBox(option) {      
      if(option ==='Select All')
        return allStrategyDetails.length === userNameList.length ? true : false
      else
        return userNameList.indexOf(option)> -1 ? true : false   
  }

  function renderOptions(data) {
    if (data) {
      data.sort((a, b) => {
        return a?.strategyName.localeCompare(b.strategyName);
      });
      data = data.filter((username) => {
        return username?.strategyName.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
      });
      data.unshift({strategyName :"Select All"});
      return data.map((option, index) => {
        return (
          <Form.Check
            checked={setCheckBox(option.strategyName)}
            type="checkbox"
            key={index}
            required
            name="multiSelectFilter"
            label={option.strategyName}
            onChange={(e) => {
              handleCheckboxClick(e,option);
            }}
            id={index}
          />
        );
      });
    } else {
      return null;
    }
  }

  function handleCheckboxClick(event, option ) {
    let finalUserList = [...userNameList]
    if (event.target.checked) 
       option.strategyName ==='Select All' ? finalUserList= [...generateStrategyNameList(allStrategyDetails)] :  finalUserList.push(option.strategyName)
    else 
      option.strategyName ==='Select All' ? finalUserList=  [] :  finalUserList.splice(userNameList.indexOf(option.strategyName), 1)
    setUserList(finalUserList)
  }

  let requestObject = () => {
    return {
      userNames: adoptionSelectedUsers ? adoptionSelectedUsers : [],
      strategies: userNameList,
      dropDownSelectionPeriod: selectedDateRange
        ? selectedDateRange
        : "Prior Week",
      additionalParametersInterval: adoptionCustomDates
        ? adoptionCustomDates
        : {},
      additionalParametersPoint: adoptionAdditionalParamaters,
      useCase: adoptionSelectedUseCases
    };
  };
 

  function handleApplyFilter(e) {
      e.preventDefault();
     if (selectedDateRange)
      dispatch(storeAdoptionSelectedStrategies(userNameList));
      onToggle(false, true);   
  }

  function onToggle(e, apply) {
    !apply &&  setUserList(adoptionSelectedStrategies) 
    setDisplayDropdown(e);
  }

  return (
    <div id="UserNameFilter">
      <Dropdown
        className=""
        show={displayDropdown}
        onToggle={onToggle}
        id="user-dropdown"
      >
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="btn-cta"
        >
          Alert Filters 
          {`(${userNameList.length})`}
          <DropDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Form>
            <Form.Group className="filter_dropdown_wrapper">
              <input
                type="text"
                placeholder="Search"
                className="form-control mb-3"
                value={searchString}
                onChange={(e) => {
                  e.preventDefault();
                  setSearchString(e.target.value);
                  setUserList([]);
                }}
              />
              <div className="strategy_dropdown_wrapper">
                {allStrategyDetails ? renderOptions(allStrategyDetails) : "No Data Available"}
              </div>
              <div className="apply_button_wrapper">
                <div className="horizontal_divider"></div>
                {userNameList.length <=0 ? <div className="error">Please select a value</div> : null}
                <div className="d-flex justify-content-between">
                  <button
                     className={`btn btn-disabled ${
                      userNameList.length > 0 ? null : "btn-disabled-clear-all"
                    } my-2`}                
                        onClick={(e) => {
                      e.preventDefault();
                      setUserList([]);
                      setSearchString("");
                    }}
                  >
                    Clear All
                  </button>
                  <button
                    className={`btn btn-apply  ${
                      userNameList.length > 0 ? null : "btn-disabled-apply"
                    } my-2`}
                    onClick={(e) => {
                      handleApplyFilter(e);
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
