import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEmbedToken, updateActionTakenInfo, updateUserActivity } from '../../actions';
import Report from 'powerbi-report-component';
import _get from 'lodash/get';
import { ToastContainer, toast } from 'react-toastify';
import {addCommonActivityCaptureDetail} from '../../helper';

class NewRecommendationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            embedToken: "",
            table: this.props.recommendationName,
            column: "",
            activeRowForModel:this.props.activeRowForModel,
            reportId: this.props.reportId,
            tabClicked: false,
            sosAlertName: this.props.sosAlertname
            
        }

    }
    getActivityInfo =()=>{
        let estimatedValue;
        const {  productId, fcNameId, dateOfRecommendation} = this.props.selectedRecommendationDetails;
        this.props.listOfStrategiesInfo && this.props.listOfStrategiesInfo.map((item)=>{
          if(item.recommendationName === this.state.table)
          estimatedValue = item.estimatedValue;
          
        })
      let  activityInfo ={
            page : "Recommendations Usage Summary",
            strategies : this.state.table,
            listOfProducts:[{product_id : productId,
            location : fcNameId,
            estimatedValue :estimatedValue,
            dateOfRecommendation : dateOfRecommendation}],
            globalFilters:this.props.selectedGlobalFilters,
            strategyFilters: this.props.selectedStrategies
        }
         return activityInfo
    }
    componentDidMount() {
        addCommonActivityCaptureDetail(this.getActivityInfo());
        this.props.getEmbedTokenAPI({
            groupId: process.env.REACT_APP_GROUP_ID,
            dashboardId: this.props.reportId
        }, (res, rej) => {
                if (res.data.hasOwnProperty("embedToken")) {
                this.setState({  embedToken: res.data.embedToken });
            } else {

            }
        });

    }
    renderPowerBi() {
        let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${this.state.reportId ? this.state.reportId :this.props.reportId }&groupId=${process.env.REACT_APP_GROUP_ID}`;
        const embedToken = this.state.embedToken;
        embedUrl = embedUrl.replace("watch?v=", "v/");

        const reportStyle = {
            height: "48rem"
        };
        const extraSettings = {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            hideErrors: false
        };
        let filterArr = [];
        const columnList = [];
        const tableList = [];        
        if(this.state.table === 'Change In Sentiment') {
            tableList.push('Change in Sentiment','Sentiment Analysis') 
            columnList.push({
                column: "Product ID",
                propsName: "chosenProductId"
            }, {
                column: "Customer Key",
                propsName: "chosenCustomerKey"
            });            
        } else { 
            if(this.state.table === 'Competitor Price Difference') {
                tableList.push(`${this.state.table} Detailed`); 
            } else if(this.state.table === 'Coupon ROAS') {
                tableList.push('Coupon Codes'); 
            } else if(this.state.table === 'Keyword Efficiency') {
                tableList.push(`${this.state.table} Detailed`,`${this.state.table} Detailed Store`); 
            } else if(this.state.table === 'Content Health Change') {
                tableList.push('Content Elements');  
            }     
            tableList.push(this.state.table);
        
        if(this.state.sosAlertName) {
            columnList.push({
                column: "Keyword",
                propsName: "chosenKeyword"
            }, {
                column: "Rank Type",
                propsName: "chosenRankType"
            }, {
                column: "Customer Key",
                propsName: "chosenCustomerKey"
            }
            );
        }else {
            columnList.push({
                column: "Location",
                propsName: "chosenLocation"
            }, {
                column: "Product ID",
                propsName: "chosenProductId"
            }, {
                column: "Customer Key",
                propsName: "chosenCustomerKey"
            }
            );
        }
        }
        
        
        for (let i = 0; i < tableList.length; i++) {
            for (let j = 0; j < columnList.length; j++) {
                filterArr.push({
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: tableList[i],
                        column: columnList[j].column
                    },
                    operator: "In",
                    values: this.props[columnList[j].propsName] ? [`${this.props[columnList[j].propsName]}`] : []
                });
            }
        }
        console.log("powerbi filterArr ", filterArr);
        let counter = 1;
        return (<Report
                embedType="report" // "dashboard"
                tokenType="Embed" // "Aad"
                accessToken={embedToken} // accessToken goes here
                embedUrl={embedUrl} // embedUrl goes here
                embedId={this.state.reportId}
                reportMode="View"
                groupId={`${process.env.REACT_APP_GROUP_ID}`}
                permissions="All" // View
                style={reportStyle}
                extraSettings={extraSettings} 
                onLoad={(report) => { this.report = report;}}                                        
                onRender={(report) => {                    
                    if(counter === 1){
                        this.report = report;
                        this.report.setFilters(filterArr).then((res, rej) => {
                            counter = counter + 1;
                        }).catch(function (errors) {})
                    }
                 }                    
                }
           />
        )
    }

    renderSelectedTabPowerBi=(strategyName)=>{
        let reportId= "";
        this.props.detailsForAllStrategies.map((strategy, index) => {
            if (
              strategy.strategyName ===
              strategyName
            ) {
              reportId = strategy.strategyReportId;
            }
          });
        this.props.getEmbedTokenAPI({
            groupId: process.env.REACT_APP_GROUP_ID,
            dashboardId: reportId
        }, (res, rej) => {
                if (res.data.hasOwnProperty("embedToken")) {
                this.setState({ embedToken: res.data.embedToken , reportId: reportId,table: strategyName},
                     ()=>{addCommonActivityCaptureDetail(this.getActivityInfo())}    
                );
            } else {

            }
        });
    }
   renderAllStrategies(){
     return this.props.activeRowForModel.recommendationInfo.map((strategy, index) => {
       if(this.state.table===strategy.recommendationName){
         return(<div className='recommendation-model-strategy-name active-strategy'>{strategy.recommendationName === 'Competitor Out Of Stock' ? 'Competition Out Of Stock' : strategy.recommendationName}</div>)
       }
       else{
         return(<div className='recommendation-model-strategy-name' onClick={(e)=>{this.renderSelectedTabPowerBi(strategy.recommendationName)}}>{strategy.recommendationName === 'Competitor Out Of Stock' ? 'Competition Out Of Stock' : strategy.recommendationName}</div>)
       }

     })
   }

    render() {
        return (<Modal size={'xl'} show={true} onHide={this.props.displayModalOff} id="recommendations-modal-new" >
            <ToastContainer />
            <Modal.Header closeButton id='new-recommendation-header-mar'>
            <div className='row new-recommendation-header-mar'>
                <div className='col-12'>
                    {this.state.sosAlertName ? <>
                        <div className='recommendation-model-strategy-name active-strategy'>{this.state.table}</div>
                    </> : this.renderAllStrategies()}
                </div>
            </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row recommendations-table">

                        <div className="col-12" style={{ textAlign: "center" }}>
                            {this.renderPowerBi()}
                        </div>


                    </div>
                </div>
            </Modal.Body>

        </Modal>);
    }
}

function mapStateToProps(state) {

    let selectedRecommendationDetails = _get(state, 'recommendationsReducer.selectedRecommendation', []);
    let selectedGlobalFilters = _get(state, 'globalReducer.selectedGlobalFilters', {});
    let detailsForAllStrategies=_get(state, 'strategyReducer.detailsForAllStrategies', []); 
    let { subproject } = _get(state, "globalReducer", "");
    let selectedStrategies = _get(
      state,
      "strategyReducer.selectedStrategies",
      []
    );
    return ({
        selectedRecommendationDetails: selectedRecommendationDetails,
        selectedGlobalFilters,
        selectedStrategies,
        detailsForAllStrategies,
        subproject
    })
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getEmbedTokenAPI: getEmbedToken,
        updateActionTakenInfoAPI: updateActionTakenInfo
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(NewRecommendationModal);
