import React, { useEffect, useState ,useRef} from "react";
import {
  getRecommendationTableContent,
  getDetailsForAllStrategies,
  selectRecommendation,
  storeIsFetching,
  recommendationTableSelectedRows,
  recommendationTableSelectAllProductList,
  storeTableAllDataLoaded,
  updateRecommendationTableContent,
  updateSkuCountData,
  getAlertsActionReasonAndSnooze
} from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";
import "./new-recommendation.css";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import NewRecommendationModal from "./NewRecommendationModal";
import { getCookie, PriceFormatter,dynamicRetailerPriceFormat, getRpcEnabledRetailers, decimalConfigRetailerPriceFormat } from "../../helper";
import cloneDeep from "lodash/cloneDeep";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import $ from "jquery";
import AlertLogModalSos from "../../components/AlertLogModalSos";

const ProductTableSosAlert = () => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tableWidth, setTableWidth] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [activeRowForModel, setActiveRowForModel] = useState({});
  const [activeStrategy, setActiveStrategy] = useState(null);
  const [showAllStrategy, setShowAllStrategy] = useState(true);
  const [isProductLevel, setIsProductLevel] = useState(false);
  let [displayAlertLogModal, setDisplayAlertLogModal] = useState(false);
  const [alertLogModalDetails, setAlertLogModalDetails] = useState({});
  const [allSkuId, setAllSkuId] = useState([])
  const [allSkuSelected, setAllSkuSelected] = useState(false)

  

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  let { selectedGlobalFilters, project, subproject, isSnoozeSubProject, selectedRetailerCurrency, selectedCustomerKey, dynamicColumnPrecision,
    dynamicPriceFormatting, defaultAlertFilterCondition,getDecimalRetailersData } = useSelector((state) => state.globalReducer);
  let {
    recommendationSelectedStrategy,
    selectedStrategies,
    detailsForAllStrategies,
    selectedStrategyDateRange,
    strategyCustomDates,
    rankTypefilter
  } = useSelector((state) => state.strategyReducer);

  let {
    loadStatus,
    responseData,
    responseDesc,
    responseType,
    selectedRows,
    sortDirecetion,
    sortedColumn,
    curentIndex,
    dynamicColumns,
    selectAllProductList,
    setOfDynamicColumns,
    allSkuIds
  } = useSelector((state) => state.newRecommendationTableData);

  //loader
  let { isActionTaken, newRecoSearchText, isExpandTableButtonClicked, isLoadedAll, isFetchingData } = useSelector(
    (state) => state.newRecommendationTableData
  );
  let requestObject = {
    filterValues: selectedGlobalFilters,
    recommendationFilters: selectedStrategies,
    curentIndex: 0,
    selectedTab: recommendationSelectedStrategy ? recommendationSelectedStrategy.selection : 'active',
    searchText: newRecoSearchText,
    sortDirecetion: "desc",
    sortedColumn: isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" ? "focusAlertEstimation" : "focusAlertEstimation",
    filterName: "",
    selectedDateRange: selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
    rankTypeFilter: rankTypefilter?.length ? rankTypefilter : []
  };

  let snoozeActiveColumnValueKey =
    [
      { displayName: 'Actioned By', columnKey: 'actionedBy', sort: false },
      { displayName: 'Last Actioned Date', columnKey: 'lastActionedDate', sort: true },
      { displayName: 'Days since Last Actioned', columnKey: 'daysSinceLastActioned', sort: true },
      { displayName: 'Snooze Days Left', columnKey: 'snoozeDaysLeft', sort: false },
    ]

  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 189);

    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    isSnoozeSubProject && selectedStrategies && selectedStrategies.length && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "active" && dispatch(getAlertsActionReasonAndSnooze({ alertName: selectedStrategies.toString() }))
  }, [selectedStrategies]);

  function handleScrollForTable(e) {
    const bottom = e.target.scrollHeight - e.target.scrollTop < (e.target.clientHeight + 20);
    if (bottom && responseData.keywordList && responseData.keywordList.length !== responseData.totalCount) {
      if (!isFetchingData && !isLoadedAll) {
        dispatch(storeIsFetching(true))
        let requestObject = {
          filterValues: selectedGlobalFilters,
          recommendationFilters: selectedStrategies,
          currentIndex: curentIndex,
          selectedTab: recommendationSelectedStrategy && recommendationSelectedStrategy.selection ? recommendationSelectedStrategy.selection : "active",
          searchText: newRecoSearchText,
          sortDirecetion: sortDirecetion,
          sortedColumn: sortedColumn,
          filterName: "",
          selectedDateRange: selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
          rankTypeFilter: rankTypefilter?.length ? rankTypefilter : []
        };
        dispatch(
          getRecommendationTableContent(requestObject)
        )
          .then((data) => {
            if (data.data) {
              if (Object.keys(data.data.responseData).length > 0) {

                let tempResponseData = cloneDeep(responseData)
                data.data.responseData.keywordList.map(item => {
                  tempResponseData.keywordList.push(item)
                })
                tempResponseData.curentIndex = data.data.responseData.curentIndex;
                dispatch(updateSkuCountData({ currentCount: tempResponseData.keywordList.length, totalCount: tempResponseData.totalCount }))
                dispatch(updateRecommendationTableContent({ curentIndex: data.data.responseData.curentIndex, responseData: tempResponseData }))
              }
              else {
                dispatch(storeTableAllDataLoaded(true))
              }
            }
            dispatch(storeIsFetching(false))
          })
          .catch((err) => {
            setLoading(true);
          });

      }
    }
  }

  useEffect(() => {
    let width = $('.new-recomm-container').width()
    if (width !== tableWidth) {
      if (setOfDynamicColumns && setOfDynamicColumns.length < 4)
        width = width + 30;
      setTableWidth(width)
    }
  })

  useEffect(() => {
    setLoading(true);
    dispatch(updateSkuCountData({ currentCount: 0, totalCount: 0 }))
    if (selectedStrategyDateRange) {
      dispatch(
        getRecommendationTableContent(requestObject)
      )
        .then((data) => {
          setAllSkuSelected(false)
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
      dispatch(recommendationTableSelectedRows({}));
      dispatch(recommendationTableSelectAllProductList([]))
    }
  }, [
    selectedStrategies,
    selectedGlobalFilters,
    newRecoSearchText,
    selectedStrategyDateRange,
    strategyCustomDates,
    rankTypefilter
  ]);


  let displayLoader = () => {
    return (
      <div id="strategy_wrapper">
        <div className="container-fluid  default-top loading_wrapper  bg-gray p-5">
          <div className="sweet-loading">
            <BarLoader
              css={override}
              size={150}
              color={"#123abc"}
              height={4}
              width={100}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  };

  function handleStrategyClick(recommendationInfo, productDetails) {
    setActiveRowForModel(productDetails);
    setActiveStrategy(recommendationInfo);
    dispatch(selectRecommendation(productDetails));
    setDisplayModal(true);
  }
  function displayModalOff() {
    if (displayModal) {
      setDisplayModal(false);
    }
  }
  function setCheckBox(store, type, key) {
    if (selectedRows.constructor === Object) {
      if (type === 'selectAll') {
        if (selectedRows[key] && Object.keys(selectedRows[key]).length === store.length) {
          return true;
        }
        return false;

      }
      if (selectedRows[store.productId] && selectedRows[store.productId][store.fcNameId]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  function getEstimatedValue(data) {
    let final = {};
    selectedStrategies.map(strategy => {
      data
        .filter((item) => {
          return (
            item.recommendationName.toLowerCase() ===
            strategy.toLowerCase()
          );
        })
        .map((obj) => {
          final[strategy] = obj.estimatedValue;
        });
    })
    return final;
  }

  function handleCheckBoxChange(event, productDetails, store, type, selectionType, skus) {
    let tempSelectAllProductList = [...selectAllProductList]
    dispatch(recommendationTableSelectAllProductList(skus.length <= 0 ? [] : tempSelectAllProductList))
  }

  function storeSelectedSkuId(skus) {
    document.cookie = `selected-sku-id=${JSON.stringify(skus)};path=/;max-age=3600`
  }

  function reorderColumnData(columnName) {
    let direction = 'asc';
    if (sortedColumn && (sortedColumn.indexOf(columnName) > -1)) {
      if (sortDirecetion === 'asc') {
        direction = 'desc'
      }
      else if (sortDirecetion === 'desc') {
        direction = 'asc'
      }

    }
    let requestObject = {
      filterValues: selectedGlobalFilters,
      recommendationFilters: selectedStrategies,
      curentIndex: 0,
      selectedTab: recommendationSelectedStrategy ? recommendationSelectedStrategy.selection : 'active',
      searchText: newRecoSearchText,
      sortDirecetion: direction,
      sortedColumn: columnName,
      filterName: "",
      selectedDateRange: selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
      rankTypeFilter: rankTypefilter?.length ? rankTypefilter : []
    };
    dispatch(
      getRecommendationTableContent(requestObject)
    )
      .then((data) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });


  }
  function renderSortDirection(columnName) {
    if (sortedColumn && (sortedColumn.indexOf(columnName) > -1)) {

      if (sortDirecetion === 'asc') {
        return (<i className="fas fa-sort-up  default-sort-icon active"></i>)
      }
      else if (sortDirecetion === 'desc') {
        return (<i className="fas fa-sort-down  default-sort-icon active"></i>)
      }

    }
    else {
      return (<i className="fas fa-sort default-sort-icon"></i>)
    }

  }
  function renderStrategiesModelWindow() {
    if (displayModal) {
      let user_info = localStorage.getItem("user-info");
      let reportId = "";
      detailsForAllStrategies.map((strategy, index) => {
        if (
          strategy.strategyName.toLowerCase() ===
          activeStrategy.toLowerCase()
        ) {
          reportId = strategy.strategyReportId;
        }
      });
      
      return (
        <NewRecommendationModal
          sosAlertname={true}          
          recommendationName={activeStrategy}
          chosenKeyword={activeRowForModel["KEYWORD"]}
          chosenRankType={activeRowForModel["RANK_TYPE"]}
          reportId={reportId}
          chosenCustomerKey={selectedCustomerKey}
          user={JSON.parse(user_info).email}
          displayModalOff={displayModalOff}
          activeRowForModel={activeRowForModel}
        />
      );
    }
  }

  function renderTooltip(dataToDisplay) {
    return (
      <Tooltip id="info-tooltip">{PriceFormatter(dataToDisplay, { precision: 0, symbol: selectedRetailerCurrency })}</Tooltip>
    );
  }
  function renderStrategies(recommendationInfo, productDetails) {
    let actionTakenClass = "recomm-name";
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          renderTooltip(productDetails.focusAlertEstimation)
        }
      >


        <div
          className={actionTakenClass}
          onClick={(e) => handleStrategyClick(recommendationInfo, productDetails)}
        >
          {recommendationInfo}
        </div>
      </OverlayTrigger>
    );



  }
  function renderStrategiesContainer(recommendationInfo, productDetails) {
    if (showAllStrategy) {
      return (
        <div className='recomm-col strategies '>
          {renderStrategies(recommendationInfo, productDetails)}
        </div>
      )
    }

  }
  function handleStrategyColumnClick() {
    if (showAllStrategy) {
      setShowAllStrategy(false)
    }
    else {
      setShowAllStrategy(true)
    }
  }
  function renderDynamicColumnValues(store) {
    if (setOfDynamicColumns && setOfDynamicColumns.length > 0) {
      return setOfDynamicColumns.map((col, index) => {
        let tempVal = store[col.Metric_Display_Value];
        let tempType = (col.Type).toLowerCase();
        let alertName = col.Strategy.toLowerCase().replace(/ /g, "_");
        let metricName = col.Metric_name.toLowerCase().trim();
        if ((dynamicPriceFormatting && dynamicPriceFormatting === 'dot') && tempVal) {
          if (tempType === 'dollar') {
            tempVal = decimalConfigRetailerPriceFormat(tempVal,selectedRetailerCurrency);
          }
          else if (tempType === 'double') {
            tempVal = PriceFormatter((tempVal), { precision: 2, symbol: '' }).replace(/(\.|,)/g, (x) => {
              return x == ',' ? '.' : x == '.' ? ',' : '';
            });
          }
          else if (tempType === 'percentage') {
            tempVal = PriceFormatter((tempVal), { precision: 2, symbol: '%', format: '%v %s' }).replace(/(\.|,)/g, (x) => {
              return x == ',' ? '.' : x == '.' ? ',' : '';
            })
          }
        } else if (dynamicColumnPrecision && dynamicColumnPrecision[alertName] && (dynamicColumnPrecision[alertName][metricName] || dynamicColumnPrecision[alertName][metricName] === 0)) {
          if (tempType === 'dollar') {
            tempVal = PriceFormatter(tempVal, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol: selectedRetailerCurrency })
          }
          else if (tempType === 'percentage') {
            tempVal = PriceFormatter((tempVal), { precision: dynamicColumnPrecision[alertName][metricName], symbol: '%', format: '%v %s' })
          }
          else {
            tempVal = PriceFormatter((tempVal), { precision: dynamicColumnPrecision[alertName][metricName], symbol: '' })
          }
        } else {
          if (tempType === 'dollar') {
            tempVal = PriceFormatter(tempVal, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol: selectedRetailerCurrency })
          }
          else if (tempType === 'double') {
            tempVal = PriceFormatter(tempVal, { precision: 2, symbol: '' })
          }
          else if (tempType === 'percentage') {
            tempVal = PriceFormatter((tempVal), { precision: 1, symbol: '%', format: '%v %s' })
          }
        }
        return (
          <div className="recomm-col align-items-center">
            {tempVal}
          </div>
        )

      })
    }
  }

  function renderActiveSnoozeColumnValues(store) {
    return snoozeActiveColumnValueKey.map((col, index) => {
      return (
        <div className="recomm-col align-items-center">
          {store[col.columnKey]}
        </div>
      )
    })

  }
  function renderSnoozeCommonColumnValue(productDetails) {
    return (
      <>
        <div className={`recomm-col align-items-center ${productDetails.previouslyActioned === 'Yes' ? "previously-actioned" : "disable-click"}`}
          onClick={(e) => {
            let finalProductDetails = { ...productDetails }
            setAlertLogModalDetails(finalProductDetails)
            setIsProductLevel(false)
            setDisplayAlertLogModal(true)
          }}>
          {productDetails.previouslyActioned}
        </div>

        <div className="recomm-col align-items-center" >
          {productDetails.alertTriggerDate}
        </div>
        {recommendationSelectedStrategy && recommendationSelectedStrategy.activeRowDetails ? (<>
          <div className="recomm-col align-items-center" >
            {productDetails.daysSinceAlertTrigger}
          </div> </>) : null}
      </>
    )

  }

  function renderStoreList(productDetails) {
    let tempClassForLocationRow = "locations";

    return (
      <div className={tempClassForLocationRow}>
        <div className="recomm-col location_block" style={{alignItems:"center"}}>         
            {productDetails.RANK_TYPE}         
        </div>
       
        <div className="recomm-col align-items-center">
          {(dynamicPriceFormatting && dynamicPriceFormatting === 'dot') && productDetails.focusAlertEstimation ?
            decimalConfigRetailerPriceFormat(productDetails.focusAlertEstimation, selectedRetailerCurrency) :
            PriceFormatter(productDetails.focusAlertEstimation, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol: selectedRetailerCurrency })}
        </div> 
        {isSnoozeSubProject && renderSnoozeCommonColumnValue(productDetails)}
        {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && renderActiveSnoozeColumnValues(productDetails)}
        {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && <div className="recomm-col align-items-center 3 col-reason text-break" >
          {productDetails["reason"]}
        </div>}

        {renderStrategiesContainer(productDetails.recommendations, productDetails)}
        {renderDynamicColumnValues(productDetails)}


      </div>
    );


  }
  function renderStrategyArrow() {
    if (showAllStrategy) {
      return (<i className="fas fa-angle-left  default-sort-icon active"></i>)
    }
    else {
      return (<i className="fas fa-angle-right  default-sort-icon "></i>)
    }
  }
  function renderProductLinks(productDetails) {
    if (productDetails.links.length > 0) {
      return productDetails.links.map((item) => {
        return (
          <li>
            <a href={item.hrefLink} target="_blank">
              {item.text}
            </a>
          </li>
        );
      });
    }
  }
  function renderEmptyColumns() {
    if (setOfDynamicColumns && setOfDynamicColumns.length > 0) {
      return setOfDynamicColumns.map((col, index) => {
        return (<div className="recomm-col"></div>)
      })
    }
  }
  function renderFetchingMoreItems() {
    if (isFetchingData && !isLoadedAll) {
      return (<div className='fetchingMoreItem'>
        <img src={require('./loadmore.gif')} className='fetechMoreImg' />
        Fetching more products...
      </div>)
    }
  }
  let renderEmptySnoozeCols = () => {
    return snoozeActiveColumnValueKey.map((col, index) => {
      return (<div className="recomm-col"></div>)
    })
  }

  function updateProduct(event, productDetails, updateProductName) {
    productDetails['selectedKeyword'] = event.target.checked;
    let skus = [...allSkuId];
    if (event.target.checked) {
      skus.push({keyword:productDetails.KEYWORD,rankType:productDetails.RANK_TYPE});     
    } else {
      skus = skus.filter(({keyword,rankType}) => keyword !== productDetails.KEYWORD || rankType !== productDetails.RANK_TYPE)
    }
    setAllSkuId(skus)
    storeSelectedSkuId(skus)
    dispatch({
      type: "SELECTED_SKU_IDS",
      payload: skus
    })
    const allselectedkeyword = responseData.keywordList.length === skus.length ? true : false;
    setAllSkuSelected(allselectedkeyword)
    dispatch(recommendationTableSelectAllProductList(skus.length ? skus : []));
    dispatch(recommendationTableSelectedRows(skus.length > 0 ? skus : {}));

  }

  function updateAllSKU(event) {
    let skus = [];
    let reskeywordList = [];
    let reskeywordListobj = {}
    if(event.target.checked){
      let filteredProduct = {}
      reskeywordList = responseData.keywordList;
      filteredProduct = reskeywordList.forEach(prod => {
        skus.push({keyword:prod.KEYWORD,rankType:prod.RANK_TYPE})
      })
      reskeywordListobj = reskeywordList
    }else {
      reskeywordList = [];
      reskeywordListobj = {}
    }
    setAllSkuId(skus)
    storeSelectedSkuId(skus)
    dispatch({
      type: "SELECTED_SKU_IDS",
      payload: skus
    })
    
    setAllSkuSelected(event.target.checked)
    dispatch(recommendationTableSelectedRows(reskeywordListobj));
    dispatch(recommendationTableSelectAllProductList(reskeywordList));
    
    
  }

  
  function renderRecommmendationTableRows() {
    if (loadStatus && loadStatus === "loaded") {
      return responseData && responseData.keywordList && responseData.keywordList.map((product) => {
        let productDetails = product;
        const found = allSkuId.find(({ keyword, rankType }) => {
          if (product.KEYWORD === keyword && product.RANK_TYPE === rankType) {
            return true
          } else {
            return false
          }
        })
        return (
          <div className={setOfDynamicColumns && setOfDynamicColumns.length === 0 ? "recomm-row recomm-dynamiccol" : "recomm-row"}>
            {/* First Column - product Column */}
            <div className="recomm-product">
              <div className="" style={{ marginLeft: "16px" }}>
                <input className="form-check-input custom"
                  type="checkbox" disabled={!selectedStrategies?.length}
                  checked={found}
                  onChange={(e) => {
                    updateProduct(e, productDetails, 'selectkeyword')
                  }}
                />
                <label className="form-check-label pl-2" for="all">
                  {product.KEYWORD}
                </label>
              </div>
            </div>
            {/* Recomm stores*/}

            <div className={setOfDynamicColumns && setOfDynamicColumns.length === 0 ? "recomm-locations recomm-dynamiccol recomm-locations-overflow" : "recomm-locations recomm-locations-overflow"}>

              {renderStoreList(productDetails)}
            </div>

            {/* Recomm stores end*/}
          </div>
        );

      });
    }
  }
  function renderStrategiesHeader() {
    if (showAllStrategy) {
      return (
        <div className="strategies" onClick={(e) => handleStrategyColumnClick()}>
          <div className="col-title">
            Alerts
            {renderStrategyArrow()}
          </div>
        </div>
      )
    }
    else {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false,
              },
            },
          }}
          overlay={
            <Tooltip id="info-tooltip">Click to see alerts</Tooltip>
          }
        >
          <div className="strategies all-collapsed " onClick={(e) => handleStrategyColumnClick()}>
            <div className='collapsed-icon '>
              <i className="fas fa-arrows-alt-h  default-sort-icon active"></i>
            </div>
          </div>
        </OverlayTrigger>
      )
    }
  }

  function renderRecommmendationEmptyCol() {
    if (showAllStrategy) {
      return (
        <div className="recomm-col strategies"></div>
      )
    }
  }

  !isExpandTableButtonClicked && $(function () {
    $(".wrapper1").scroll(function () {
      $(".wrapper2")
        .scrollLeft($(".wrapper1").scrollLeft());
    });
    $(".wrapper2").scroll(function () {
      $(".wrapper1")
        .scrollLeft($(".wrapper2").scrollLeft());
    });
  });
  function renderTableHeaderColumns() {
    if (setOfDynamicColumns && setOfDynamicColumns.length > 0) {
      return setOfDynamicColumns.map((col, index) => {
        return (
          <div onClick={(e) => reorderColumnData(col.Metric_Display_Value)}>
            <div className="col-title" >
              {col.Display_name}{" "}
              {renderSortDirection(col.Metric_Display_Value)}
            </div>
          </div>
        )
      })
    }
  }
  let renderSnoozeRelatedColumnsHeader = () => {
    return (
      <>
        <div onClick={(e) => reorderColumnData('previouslyActioned')}>
          <div className="col-title" >
            Previously <br />Actioned (P3M){renderSortDirection('previouslyActioned')}
          </div>
        </div>

        <div onClick={(e) => reorderColumnData('alertTriggerDate')}>
          <div className="col-title" >
            Alert Trigger Date{renderSortDirection('alertTriggerDate')}
          </div>
        </div>

        {recommendationSelectedStrategy && recommendationSelectedStrategy?.activeRowDetails ?
          <div onClick={(e) => reorderColumnData('daysSinceAlertTrigger')}>
            <div className="col-title" >
              Days since Alert Trigger{renderSortDirection('daysSinceAlertTrigger')}
            </div>
          </div>
          : null}
        {recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" &&
          snoozeActiveColumnValueKey.map((item) => {
            return <div onClick={(e) => item.sort ? reorderColumnData(item.columnKey) : undefined}>
              <div className="col-title" >
                {item.displayName}{item.sort && renderSortDirection(item.columnKey)}
              </div>
            </div>
          })}
        {recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && <div className="col-reason" >
          <div className="col-title " >
            Reason
          </div>
        </div>}
      </>
    )
  }

  let toggleAlertLogModal = () => {
    let toggle = displayAlertLogModal
    setDisplayAlertLogModal(!toggle)
  }
  if (loading) return displayLoader();
  else if (responseData.keywordList && responseData.keywordList.length <= 0)
    return (<div id="no-data-available">No products available to display</div>)
  else if ((newRecoSearchText || (selectedStrategies && selectedStrategies?.length) || (recommendationSelectedStrategy && Object.keys(recommendationSelectedStrategy) && Object.keys(recommendationSelectedStrategy).length)) && responseData.keywordList && responseData.keywordList.length > 0)
    return (
      <div className={`${isExpandTableButtonClicked ? "full-screen-mode-table-row" : "row"} sosAlertcls pb-3`}>
        {displayAlertLogModal ? <AlertLogModalSos displayAlertLogModalOff={toggleAlertLogModal} displayAlertLogModal={displayAlertLogModal} isProductLevel={isProductLevel} alertLogModalDetails={alertLogModalDetails} /> : null}

        <div className="col-12" >
          {/* Custom code for  product table*/}
          <div style={{ display: "flex", width: "100%", paddingTop: "4px" }}>
            <div className={setOfDynamicColumns && setOfDynamicColumns.length === 0 ? "form-check form-check-custom recom_horizontal recomm-dynamiccol" : "form-check form-check-custom recom_horizontal"} >
              <input className="form-check-input custom" type="checkbox" checked={allSkuSelected} disabled={!selectedStrategies?.length} onChange={(e) => {
                updateAllSKU(e);
              }} />
              <label className="form-check-label" for="all" style={{ paddingLeft: 6, position: "relative", bottom: "1px" }} >
                Select All {responseData.totalCount} Terms
              </label>
            </div>
            {!isExpandTableButtonClicked && <div
              className={`wrapper1 new-recomm sticky-wrapper ${setOfDynamicColumns && setOfDynamicColumns.length < 2 && !isSnoozeSubProject ? "width-auto" : ""}`}
              ref={ref}
              id="sticky"
              style={{ backgroundColor: "white", height: 10, overflowY: "auto", marginTop: "8px" }}
            >
              <div style={{ width: tableWidth, height: 16 }}>  </div>
            </div>}
          </div>
          <div className={`table-responsive wrapper2 new-recomm sticky-wrapper ${isExpandTableButtonClicked ? "full-screen-mode-table" : ""} ${setOfDynamicColumns && setOfDynamicColumns.length < 2 && !isSnoozeSubProject ? 'width-auto' : ''}`} ref={ref} id='sticky' onScroll={(e) => { handleScrollForTable(e) }} style={{ backgroundColor: "white" }}>
            <div className="new-recomm-container bgsosalert-clr">
              <div className={setOfDynamicColumns && setOfDynamicColumns.length === 0 ? "recomm-header recomm-dynamiccol" : "recomm-header"} >
                <div className="headcol first-col">Search Term</div>
                <div className="headcol location_block" onClick={(e) => reorderColumnData('RANK_TYPE')}>
                  <div>
                    Rank Type <i class='fas fa-chart-line'></i>
                    {renderSortDirection('RANK_TYPE')}
                  </div>
                </div>
                
                <div onClick={(e) => reorderColumnData('focusAlertEstimation')}>
                  <div className="col-title" >
                    Estimated Value{renderSortDirection('focusAlertEstimation')}
                  </div>
                </div> 
                {/* hard code for snooze */}
                {isSnoozeSubProject && renderSnoozeRelatedColumnsHeader()}
                {renderStrategiesHeader()}
                {renderTableHeaderColumns()}

              </div>
              <div className="recomm-body">
                {renderRecommmendationTableRows()}
              </div>
            </div>

            {renderFetchingMoreItems()}
          </div>
          {/* Custom code for  product table* ends */}
        </div>
        {renderStrategiesModelWindow()}
      </div>
    );
  else
    return (<div id="no-data-available">No products available to display</div>)
};

export default ProductTableSosAlert;
