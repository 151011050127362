export default function(state={},action){
    switch(action.type){
        case "GET_VERSIONS_LIST":return {...state,versionsList:action.payload}
        case "GET_VERSION_DATA" : return {...state,versionData:action.payload}
        case "UPDATE_CURRENT_VERSION_DATA" : return {...state,currentVersionData:action.payload}
        case "CREATE_NEW_RELEASE" : return {...state,createNewReleaseData:action.payload}
        case "DISPLAY_ALL_RELEASES":return {...state,allRleasesdata:action.payload}
        case "DISPLAY_ALL_SELFSERVICE":return {...state,allSelfservicedata:action.payload}
        default:
            return state;
    }
}
