import React from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import ProductTableDCModal from '../containers/NewRecommendation/ProductTableDCModal';
import { getRpcEnabledRetailers } from "../../src/helper";
import NoImageeCompass from '../containers/Insights/NoImageeCompass.jpg';
function DynamicColumnDialog(props) {
  let productlocationDetails = props.locationModalDetails;
  let { subproject } = useSelector((state) => state.globalReducer);
  let recomproductImage = productlocationDetails?.imageUrl ? productlocationDetails.imageUrl : NoImageeCompass;
  return (
    <Modal
      show={props.dynamicColumnLog}
      onHide={props.displayColumnDialogOff}
      id="alert-log-modal" className="location-modal"
    >
      <Modal.Header closeButton>
      <div class="d-flex" style={{marginLeft:"20px"}}>
          <img src={recomproductImage} style={{ width: "132px", padding: "10px" }} />
          <div
            style={{
              alignSelf: "center",
              paddingLeft: "10px",
              fontSize: "14px",
            }}
          >
            <div style={{fontSize:"20px",fontWeight:"bold",paddingBottom:"5px"}}>
              <span>{productlocationDetails.title}</span>
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>Product Id : </span>
              <span>{productlocationDetails.productId}</span>
            </div>
            {getRpcEnabledRetailers().includes(subproject) && 
            <div>
              <span style={{ fontWeight: 600 }}>RPC : </span>
              <span>{productlocationDetails?.RPC ? productlocationDetails.RPC : 'N/A'}</span>
            </div> }
            <div>
              <span style={{ fontWeight: 600 }}>UPC : </span>
              <span>{productlocationDetails.asin}</span>
            </div>
            
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="py-4">
        <ProductTableDCModal productlocationDetails={productlocationDetails}/>           
      </Modal.Body>
    </Modal>
  );

          

}

export default DynamicColumnDialog;
