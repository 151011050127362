import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import './Updates.scss';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import CKEditor  from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { createNewRelease, displayAllReleases, updateReleaseById, deleteReleaseId, updateLatestUpdateForAllUsers, getOrUpdateLatestUpdateAvailability } from '../actions/index';
import moment from "moment";
import {useHistory, useLocation} from 'react-router-dom';

const AddUpdateModal = (props) => {
    let dispatch = useDispatch();
    const {propsDate, propsReleasenumber, propsTitle, propsContent, propsRetailers, propsSubprojectId, propsProjectId, propsId, propsCreatedBy, propsCreatedByEmail } = props.modalDetails;
    const [addUpdateError, setAddUpdateError] = useState("");
    const [releaseNumberError, setReleaseNumberError] = useState("");
    const [releaseNumber, setReleaseNumber] = useState(propsReleasenumber);
    const [title, setTitle] = useState(propsTitle);
    const [content, setContent] = useState(propsContent);
    let { projectAndSubprojects, project, subproject } = useSelector((state) => state.globalReducer);
    const projectDetails = projectAndSubprojects.filter((individualProject) => {
        return individualProject.name === project;
    });
    const listOfSubprojects = projectDetails[0].subProjectInfo;
    const [startDateToday, setStartDateToday] = useState(propsDate);
    function releaseDateFormat(date) {
        return moment(date).utc().format("YYYY-MM-DD");
    }
    const currentDate = releaseDateFormat(startDateToday);
    const propscurrentDate = releaseDateFormat(propsDate);
    let retailersList = props.modalMode === "Edit" ? propsRetailers.split(',') : [];
    const [checkedAllData, setCheckedAllData] = useState(retailersList);
    
    const ref = useRef([]);
    const modalRef = useRef(null);
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    
    useEffect(() => {
        if (props.modalMode === "Edit") {
            listOfSubprojects.filter((retailersName, index) => {
               return retailersList.some((strName) => {
                if(strName === retailersName.name) {
                    ref.current[index].checked = true; 
                }                
               });                
            })}
    }, []);

    const saveUpdate = (e, modalMode) => {
        e.preventDefault();
        const areListsEqual = (list1, list2) => {
            if (list1.length !== list2.length) {
                return false;
            }
            list1.sort();
            list2.sort();
            return list1.every((value, index) => value === list2[index]);
        };
        const isEqual = areListsEqual(checkedAllData, retailersList);
        const uncheckedRetailers = listOfSubprojects
        .map(subproject => subproject.name)
        .filter(name => !checkedAllData.includes(name));
        const edituncheckedRetailers = retailersList.filter(retailer => !checkedAllData.includes(retailer));
        const re = /^[0-9.]+$/
        if (releaseNumber === "" || title === "" || checkedAllData?.length === 0 || content === undefined || content?.length === 0) {
            setAddUpdateError("Please fill all values");            
        }else if(re.test(releaseNumber) === false && releaseNumber !== "") {
            modalRef.current.scrollIntoView();
            setReleaseNumberError("Please enter Release number in the format YY.MM.DD");
        } else {
            setAddUpdateError("");
            props.displayModalOff();
            if (modalMode === "Add") {
                dispatch(createNewRelease({ title: title, releaseDate: currentDate, releaseNumber: releaseNumber, content: content, createdBy: userInfo?.email, retailers:checkedAllData,uncheckedretailers:uncheckedRetailers, createByName: userInfo?.name, IS_LATEST_UPDATE:'TRUE' }, (res, rej) => {
                    if (res) {
                        toast.success(res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });  
                        dispatch(displayAllReleases({pageNo:1,sortColumn: "ReleaseDate",
                        sortOrder: "desc"},(res) => { })); 
                        dispatch(updateLatestUpdateForAllUsers({retailers:checkedAllData,uncheckedretailers:uncheckedRetailers})); 
                        dispatch(getOrUpdateLatestUpdateAvailability({userId:userInfo?.sAMAccountName,userName:userInfo?.name,userEmail:userInfo.email,isUpdate:'FALSE', Retailer:subproject}));                    
                    }
                }));
            } else {
                if(propscurrentDate === currentDate && releaseNumber === propsReleasenumber && title === propsTitle && content === propsContent && isEqual === true ) {
                    toast.success('Edit Version successful', { position: toast.POSITION.BOTTOM_LEFT });
                } else {
                    dispatch(updateReleaseById({releaseData:{ Title: title, ReleaseDate: currentDate, ReleaseNumber: releaseNumber, Content: content, CreatedBy: propsCreatedByEmail, Retailers:checkedAllData.join(), uncheckedretailers:uncheckedRetailers.join(),
                        CreateByName: propsCreatedBy, UpdatedBy: userInfo?.email, UpdatedByName: userInfo?.name,UpdatedOn: releaseDateFormat(new Date()),SubprojectId: propsSubprojectId, ProjectId: propsProjectId, IS_LATEST_UPDATE:'TRUE'},releaseId: propsId}, (res, rej) => {
                        if (res) {
                            toast.success(res?.data?.responseDesc === 'Release data got updated successfully.' ? 'Edit Version successful' : res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });  
                            dispatch(displayAllReleases({pageNo:1,sortColumn: "ReleaseDate",
                            sortOrder: "desc"},(res) => { })); 
                            dispatch(updateLatestUpdateForAllUsers({retailers:checkedAllData, uncheckedretailers:uncheckedRetailers,edituncheckedRetailers:edituncheckedRetailers})); 
                            dispatch(getOrUpdateLatestUpdateAvailability({userId:userInfo?.sAMAccountName,userName:userInfo?.name,userEmail:userInfo.email,isUpdate:'FALSE', Retailer:subproject}));                     
                        }
                    }));
                }  

            }

        }
    }

    const handleChange = (event, individualProject, type) => {
        let updatedList = [...checkedAllData];
        if (event.target.checked) {
        updatedList = [...checkedAllData, event.target.value];
        } else {
        updatedList.splice(checkedAllData.indexOf(event.target.value), 1);
        }
        setCheckedAllData(updatedList);        
    };
    const handleSelect = () => {
        let updatedList = [];
        for (let i = 0; i < listOfSubprojects.length; i++) {
            ref.current[i].checked = true;
            updatedList.push(listOfSubprojects[i].name);
        }
        setCheckedAllData(updatedList);
    };    
    const handleClear = () => {        
        for (let i = 0; i < ref.current.length; i++) {
            ref.current[i].checked = false;
        }
        setCheckedAllData([]);
    };      
    const handleContent = (event, editor) => {
        const data = editor.getData();
        setContent(data);
    }
    const cancelUpdate = (e) => {
        e.preventDefault();
        props.displayModalOff();
    }
    let modalText = "Manage";
    if (props.modalMode === "Add") {
        modalText = "New";
    }
    return (
        <Modal size={'md'} show={true} onHide={props.displayModalOff} id="Updation-modal" >
            <Modal.Header closeButton>
                <Modal.Title className='ml-5 newupdatetxt'>{modalText} Update</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">
                <div className=" ">
                    <div className="row">
                        <div className="col-12 my-2">
                            <form >
                                <div className="form-group d-flex">
                                    <label className="col-3 col-form-label"> Release Date </label>
                                    <DatePicker value={startDateToday} selected={startDateToday} onChange={(date) => setStartDateToday(date)} maxDate={new Date()}
                                    className="form-control col-5" />
                                </div>
                                <div className="form-group d-flex mt-4" ref={modalRef}>
                                    <label className="col-3 col-form-label"> Release number </label>
                                    <input type="text" placeholder='Release Number' value={releaseNumber} className="form-control col-4" onChange={(e) => { setReleaseNumber(e.target.value); setAddUpdateError("") }} />
                                </div>
                                {releaseNumberError !== '' && <div className="form-group d-flex">
                                    <lable className="col-3 col-form-label"></lable>
                                    <p className="error col-5">{releaseNumberError}</p>
                                </div> }
                                <div className="form-group d-flex mt-4">
                                    <label className="col-3 col-form-label">Update Title</label>
                                    <input type="text" placeholder='Title Name' value={title} className="form-control col-4" onChange={(e) => { setTitle(e.target.value); setAddUpdateError("") }} />
                                </div>

                                <div className="form-group d-flex mt-4">
                                    <label className="col-3 col-form-label">Update Contents</label>
                                    <CKEditor config={{placeholder: "Add text..."}} editor={ ClassicEditor } data={content} onChange={handleContent} />
                                </div>
                                
                                <div className="form-group d-flex mt-2">
                                    <div className='col-3'>
                                    <div className="col-form-label mt-3">Retailers</div>
                                    <div className="col-form-label mt-3 selet-clr" onClick={handleSelect}>Select All</div>
                                    <div className="col-form-label mt-3 selet-clr" onClick={handleClear}>Clear </div>
                                    </div>
                                    <div className="row">                                    
                                    {listOfSubprojects.map((individualSubproject, index) => {
                                        return(
                                            <div key={index} className="col-4 tile-wrapper">
                                                <div className="form-check form-check-custom">
                                                    <input className="form-check-input custom" ref={(element) => { ref.current[index] = element }}
                                                    type="checkbox" value={individualSubproject.name} 
                                                    onChange={(event) => handleChange(event, individualSubproject, 'single')}                                                    
                                                    />
                                                    <label className="form-check-label ml-2" for="all">
                                                    {individualSubproject.name}
                                                    </label>
                                                </div>                                                
                                            </div>
                                        )
                                    })} 
                                    </div>         
                                </div>
                                <p className="error">{addUpdateError}</p>
                                <div className='d-flex mt-1 btn_udpates'>
                                    <button class="btn btn-default mr-5" onClick={(e) => { cancelUpdate(e) }}>Cancel</button>
                                    <button class="btn btn-apply" onClick={(e) => { saveUpdate(e, props.modalMode) }}>Save Update</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const Updates = () => {
    let dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [allreasesData, setAllreasesData] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modalMode, setModalMode] = useState("Add");
    const [modalDetails, setModalDetails] = useState({
        propsDate: new Date(),
        propsReleasenumber: "",
        propsTitle: "", 
        propsRetailers:[],
    });
    const [pageNumber, setPageNumber] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState();
    const options = {
        totalSize: totalSize,
        sizePerPage: sizePerPage,
        page: pageNumber,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
    };
    const handleTableChange = (
        type,
        { page, sizePerPage }
      ) => {     
        if (type === "pagination") {
          setSizePerPage(sizePerPage);
          setPageNumber(page);
        }
      };
    let { allRleasesdata } = useSelector((state) => state.versionReducer);
    let { isAppAdmin, isProjectAdmin, isSubprojectAdmin, subproject, project, latestUpdateAvailble} = useSelector((state) => state.globalReducer);
    // console.log(latestUpdateAvailble?.isLatestUpdate, "latestUpdateAvailble");
    let hasAdminAccess = isAppAdmin || isProjectAdmin || isSubprojectAdmin ? true : false;
    const [titleSortDirection, setTitleSortDirection] = useState();
    const [releaseNumberSort, setReleaseNumberSort] = useState();
    const [releaseDateSort, setReleaseDateSort] = useState();
    const [updatedByNamesort, setUpdatedByNamesort] = useState();
    const [updatedOnsort, setUpdatedOnsort] = useState();
    const sortCareticon = (order, column) => {
        if(column.dataField == 'Title') {
            order === 'desc' ? setTitleSortDirection('asc') : setTitleSortDirection('desc') ;
        } else if(column.dataField == 'ReleaseNumber'){
            order === 'desc' ? setReleaseNumberSort('asc') : setReleaseNumberSort('desc');
        }else if(column.dataField == 'ReleaseDate'){
            order === 'desc' ? setReleaseDateSort('desc') : order === undefined ? setReleaseDateSort('asc') : setReleaseDateSort('asc');
        }else if(column.dataField == 'UpdatedByName'){
            order === 'desc' ? setUpdatedByNamesort('asc') : setUpdatedByNamesort('desc');
        }else if(column.dataField == 'UpdatedOn'){
            order === 'desc' ? setUpdatedOnsort('asc') : setUpdatedOnsort('desc');
        }
        if (order === 'asc') {          
          return (<i className="fas fa-sort-up  default-sort-icon active"></i>)
       } else if (order === 'desc') {          
         return (<i className="fas fa-sort-down  default-sort-icon active"></i>)
       } else {
          return(<i className="fas fa-sort default-sort-icon"></i>) 
       }
      }
      const sortingEvents = {    
        onClick: (e, column, columnIndex) => {
          let colSort = column.dataField == 'Title' ? titleSortDirection : column.dataField == 'ReleaseNumber' ? releaseNumberSort : column.dataField == 'ReleaseDate'? releaseDateSort
           : column.dataField == 'UpdatedByName' ? updatedByNamesort : column.dataField == 'UpdatedOn' ? updatedOnsort : "";
          dispatch(displayAllReleases({pageNo: pageNumber,
            totalRows: sizePerPage,sortOrder: colSort,
            sortColumn: column.dataField,},responseHandlerupdate));  
        } 
      }
    const displayModalOff = () => {
        setDisplayModal(false);
    }
    const handleAddUpdates = (e) => {
        setDisplayModal(true);
        setModalMode("Add");
        let obj = {
            propsDate: new Date(),
            propsReleasenumber: "",
            propsTitle: "",
            propsContent: "",
            propsRetailers: [],
        }
        setModalDetails(obj);
    }

    const responseHandlerupdate = (response) => {
        if(response?.data?.responseData){
            if(response.data.responseData.length <= 0) {
                setLoading(false)
            }else {
                if(response?.data?.responseData?.releaseList){
                    setAllreasesData(response.data.responseData.releaseList) 
                    setTotalSize(response.data.responseData.totalRows)            
                    setLoading(false);
                }
            }
        } else {
            setLoading(false)
        } 
    }

    useEffect(() => {
        if(allRleasesdata?.responseData?.releaseList){
          setAllreasesData(allRleasesdata.responseData.releaseList) 
          setTotalSize(allRleasesdata.responseData.totalRows)
          setLoading(false);
        }
      }, [allRleasesdata]);

    useEffect(() => {
        setLoading(true)
        dispatch(displayAllReleases({pageNo: pageNumber,
        totalRows: sizePerPage,sortColumn: "ReleaseDate",
        sortOrder: "desc"},responseHandlerupdate));       
    }, [sizePerPage, pageNumber]);

    const NoDataIndication = () => (
        <div className="spinner text-center p-2">
            No Data Available
        </div>
    );

    const dateFormatter = (cell) => {
        return (<>{moment.utc(cell).format("MMMM DD, YYYY")}</>)
    }

    const handleEditRelease = (e, row) => {
        setDisplayModal(true);
        setModalMode("Edit");
        let obj = {
            propsDate: new Date(row.ReleaseDate),
            propsReleasenumber: row.ReleaseNumber,
            propsTitle: row.Title,
            propsContent: row.Content,
            propsRetailers : row.Retailers,
            propsSubprojectId : row.SubprojectId,
            propsProjectId : row.ProjectId,
            propsId : row.id,
            propsCreatedByEmail: row.CreatedBy,
            propsCreatedBy: row.CreatedByName
        }
        setModalDetails(obj);
    }

    const handleDelete = (e, row) => {
        Swal.fire({
            title: 'Are you sure you want to delete this Update ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.value) {
                dispatch(deleteReleaseId({ releaseId: row.id}, (res, rej) => {
                    if (res) {
                        dispatch(displayAllReleases({pageNo:1,sortColumn: "ReleaseDate",
                       sortOrder: "desc"},(res) => { })); 
                        toast.success(res.data.responseDesc === 'Deleted selected release data successfully.' ? 'Delete Version successful':res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                    } 
                }));
            }
        })
    }

    const editColumnFormatter = (cell, row) => {
        return (
            <tr className="flex">
               <td className='mr-5 ed-clr' onClick={(e) => { handleEditRelease(e, row) }}>Edit Version</td> 
               <td className='ml-2 dl-clr' onClick={(e) => { handleDelete(e, row) }}>Delete Version</td> 
            </tr>
        )
    }

    const linkFormatter = (cell, row) => {
        return (
            <div className='ed-clr' onClick={(e) => history.push(`/ecompass/${subproject}?id=${row.id}`)}>                
                Link               
            </div>
        )
    }

    let columns = [{
        text: "Update Title",
        dataField: "Title",
        sort: true,
        sortCaret: sortCareticon,
        headerEvents: sortingEvents,
    }, {
        text: "Release Number",
        dataField: "ReleaseNumber",
        sort: true,
        sortCaret: sortCareticon,
        headerEvents: sortingEvents,
    },{
        text: "Release Date",
        dataField: "ReleaseDate",
        formatter: dateFormatter,
        sort: true,
        sortCaret: sortCareticon,
        headerEvents: sortingEvents,
    },{
        text: "Link",
        formatter: linkFormatter
    },{
        text: "Last Updated by User",
        dataField: "UpdatedByName",
        sort: true,
        sortCaret: sortCareticon,
        headerEvents: sortingEvents,
    },{
        text: "Last Updated Date",
        dataField: "UpdatedOn",
        formatter: dateFormatter,
        sort: true,
        sortCaret: sortCareticon,
        headerEvents: sortingEvents,
    }
    ]
    if (hasAdminAccess) {
        columns.push({
            text: "Actions",
            formatter: editColumnFormatter,
            classes : 'editdelete-cls',
        });
    }

    return(
        <div>
        {loading ?  (
        <div id="no-data-available" style={{ marginTop: "5%" }}> Loading....</div>) :
           (<div>
            <ToastContainer />
             {displayModal ?
                <AddUpdateModal
                    modalDetails={modalDetails}
                    modalMode={modalMode}
                    display={displayModal} displayModalOff={displayModalOff} /> : <div></div>}
       
            <div className="container-fluid default-top bg-gray p-5">
                <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                        <div className="header"> Manage Updates</div>
                            <div className="mb-3">
                            <button className="btn btn-apply" onClick={(e) => { handleAddUpdates(e) }}>
                                Create Update
                            </button>
                        </div>
                    </div>
                </div>

                <BootstrapTable remote bodyClasses="Updatepad" headerClasses="Updatetable" pagination={ paginationFactory(options)} data={ allreasesData }
              columns={ columns } keyField="updates" onTableChange={ handleTableChange } noDataIndication={ () => <NoDataIndication /> } />

            </div>
        </div>
        )}    
        </div>
    )

}

export default Updates;