import React, { Component, useEffect, useState } from 'react';
import './Navbar.scss';
import { Link, withRouter } from 'react-router-dom';
import {
	submitFeedback,
	setSubproject, setProject,
	setSubprojectInfo, setProjectInfo,
	resetViewBuilderData,
	setIsAppAdmin, 
	setIsProjectAdmin,
	 setIsSubprojectAdmin,
	 getTabList,
	 storeSelectedStrategies,
	 storeNewRecomSearchText,
	 adoptionSelectedUSers,
	 storeGlobalFilterSelections,
	 storeRecommendationSelectedStrategies,
	 storeSaveFilterClicked,
	 getDateFilterOptions,
	 getDateFilterKeyValueOptions,
	 getComparisonIdValues,
	 setRetailerCurrency,
	 setCustomerKey,
	 saveDateRangeFilter,
	 storeAdoptionSelectedStrategies,
	 storeAdoptionSelectedUseCases,
	 strategySelectedDateRange,
	 strategyFilterAndOrCondition,
	 getOrUpdateLatestUpdateAvailability,
	 newInsightLeftNameClicked,
	 RankTypeAlertFilter,
	 newInsightSectionName,
	 retailerCurrencyCode,
	 getDecimalForRetailers
} from '../actions'
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import { ToastContainer, toast } from 'react-toastify';
import Feedback from './Feedback';
import HelpModal from './HelpModal';
import LatestModal from './LatestModal';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import {DropDownIcon,RightArrowIcon,TickIcon,UserIcon,BoxArrowUpRight} from './Icons';
import HandleUserListModal from './HandleUserListModal';
import EtlModal from './EtlModal';
import { generateStrategyNameList,generateUseCaseNameList } from '../helper';
import DOMPurify from 'dompurify';
import greatLogo from '../img/great_logo_new.png';
import kccLogo from '../img/kcc_logo.png'

//navigation other retailers or pages
class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayProjectDropdown: false,
			activeProject: this.props.subproject,
			userInfo: "",
			environment: process.env.REACT_APP_OKTA_ENV,
			displayHelpModal: false,
			displayLatestModal:false,
			displayUserListModal: false,
			selectedTab: "",
			isAdmin: false,
			hoveredProjectName: this.props.project,
			hoveredProjectInfo: this.props.selectedProjectInfo,
			hoveredSubproject: this.props.subproject,
			displayEtlModal: false,
			isECAdmin: false,
			isSecure:""
		}
	}

	componentDidMount() {
		if (window.location.hostname !== "localhost") {
			this.setState({ isSecure:'secure'});
		}
		if (window.location.pathname === "/coming_soon") {
			this.setState({ activeProject: DOMPurify.sanitize(sessionStorage.getItem("activeProject")) });
		}
		this.props.authService.getUser().then((res, rej) => {
			if (res) {

				this.setState({ userInfo: res }, () => {
					const { userInfo } = this.state;
					const { selectedSubprojectInfo } = this.props;
					const { selectedProjectInfo } = this.props;
					const projectAdminGroupName = selectedProjectInfo.oktaGroupAdmin;
					const subprojectAdminGroupName = selectedSubprojectInfo.oktaGroupAdmin;
					this.props.setRetailerCurrencyAPI(selectedSubprojectInfo.currencyCode)
					this.props.setCustomerKeyAPI(selectedSubprojectInfo.customerKey)
					this.props.getDateFilterOptions();// for future implementation to configure date filter option
					this.props.getDateFilterKeyValueOptions();
					this.props.getComparisonIdValues();
					this.props.getTabList();
					localStorage.setItem("user-info", JSON.stringify(userInfo));
					this.props.getDecimalForRetailers({customerKey:selectedSubprojectInfo?.customerKey},()=>{});
					this.props.getOrUpdateLatestUpdateAvailability({userId:userInfo?.sAMAccountName,userName:userInfo?.name,userEmail:userInfo.email,isUpdate:'FALSE', Retailer:this.props.subproject});
					
					if (userInfo) {
						if ((userInfo.Roles && userInfo.Roles.indexOf(`Admin`) > -1) || (userInfo.groups && userInfo.groups.indexOf(`Admin`)) > -1) {
							this.props.setIsAppAdmin(true);
						}
						if ((userInfo.Roles && userInfo.Roles.indexOf(projectAdminGroupName) > -1) || (userInfo.groups && userInfo.groups.indexOf(projectAdminGroupName) > -1) ) {
							
							this.props.setIsProjectAdmin(true);
						}
						if ((userInfo.Roles && userInfo.Roles.indexOf(subprojectAdminGroupName) > -1) || (userInfo.groups && userInfo.groups.indexOf(subprojectAdminGroupName) > -1)) {
							this.props.setIsSubprojectAdmin(true);
						}
					}
				});
			} else {
				
			}
		})

	}

listOfPages(currentPath, project, subProject ) {
  	return this.props.tabListItems.map((item, index)=>{
  		let linkText = item.toLocaleLowerCase();
		  if(project && project.toLowerCase() === "dash"){
			if(linkText==='recommendations'){
				return(
					<li className="nav-item">
	  				<Link className={`nav-link ${ currentPath === "" || currentPath === "/" ? "active" : null}`} to={`/${project}/${subProject}/`} onClick={(e) => { this.props.resetViewBuilderDataAPI() }}>{item}</Link>
	  			</li>
				)
			}
		  }else{
			if(linkText==='insights'){
				return(
					<li className="nav-item">
	  				<Link className={`nav-link  ${this.props.tabListItems?.length > 5 ? 'nav-fsize' : ''}  ${ currentPath === "" || currentPath === "/" ? "active" : null}`} to={`/${project}/${subProject}/`} onClick={(e) => { this.props.resetViewBuilderDataAPI() }}>{item}</Link>
	  			</li>
				)
			}
		  }			
  		return(
  			<li className="nav-item">
  				<Link className={`nav-link  ${this.props.tabListItems?.length > 5 && project === 'ecompass' ? 'nav-fsize' : ''} ${currentPath === linkText ? "active" : null}`} to={`/${project}/${subProject}/${linkText}`} onClick={(e) => {this.props.newInsightLeftNameClicked();this.props.RankTypeAlertFilter([]); this.props.newInsightSectionName();this.props.retailerCurrencyCode(); this.props.resetViewBuilderDataAPI();this.props.storeAdoptionSelectedUseCases(generateUseCaseNameList(this.props.detailsForAllUsecase)); this.props.storeAdoptionSelectedStrategies(generateStrategyNameList(this.props.detailsForAllStrategies)); }}>{item}</Link>
  			</li>
  		)
  	})
  }



	setActiveProject(project) {
		sessionStorage.setItem("activeProject", project);
		this.setState({ activeProject: project }, () => {

		});
	}
	login = () => {
		this.props.authService.login('/');
	}

	logout = () => {
		this.props.authService.logout('/');
	}
	renderAdminButton() {
		const { isAdmin, isProjectAdmin, isSubprojectAdmin } = this.props;
		if (isAdmin || isProjectAdmin || isSubprojectAdmin) {
			return (
				<Link className="dropdown-item" to="/admin" >Admin</Link>
			)
		}
		return null;
	}

	displayModalOff = () => {
		this.setState({ displayModal: false });
	}
	displayLatestModalOff = () => {
		this.setState({ displayLatestModal: false });
	}
	displayHelpModalOff = () => {
		this.setState({ displayHelpModal: false });
	}
	displayUserListModalOff = () => {
		this.setState({ displayUserListModal: false });
	}
	displayEtlModalOff = () => {
        this.setState({ displayEtlModal: false });    
    }
	submitFeedback = (selections) => {
		selections.projectId = 2;
		selections.feedbackProvider = this.state.userInfo.email;

		this.props.submitFeedbackAPI(selections, (res, rej) => {
			if (res) {
				if (res.data.responseType === "success") {
					toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
				} else {
					toast.error(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
				}
			} else {

			}
			this.setState({ displayModal: false });
		});

	}
	renderTooltip(props, name) {

		return (
			<Tooltip id="button-tooltip" {...props}>
				Coming Soon ...
			</Tooltip>
		);
	}
	renderExplorerAndDetails(currentPath, formattedProject, formattedSubProject) {
		if (this.state.environment === "PROD") {
			return (
				<React.Fragment>
					<li className="nav-item">
						<OverlayTrigger
							placement="right"
							delay={{ show: 250, hide: 400 }}
							overlay={this.renderTooltip()}
						>
							<div className={`nav-link nav-item-disabled`} >Details</div>

						</OverlayTrigger>
					</li>
					<li className="nav-item">
						<OverlayTrigger
							placement="right"
							delay={{ show: 250, hide: 400 }}
							overlay={this.renderTooltip()}
						>
							<div className={`nav-link nav-item-disabled`} >Scorecard</div>

						</OverlayTrigger>
					</li>
				</React.Fragment>
			)
		}
		return (
			<React.Fragment>
				<li className="nav-item">
					<Link className={`nav-link ${currentPath === "details" ? "active" : null}`} to={`/${formattedProject}/${formattedSubProject}/details`} onClick={(e) => { this.setState({ selectedTab: "Details" }) }}>Details</Link>
				</li>
				<li className="nav-item">
					<Link className={`nav-link ${currentPath === "scorecard" ? "active" : null}`} to={`/${formattedProject}/${formattedSubProject}/scorecard`} onClick={(e) => { this.setState({ selectedTab: "Scorecard" }); this.props.resetViewBuilderDataAPI() }}>Scorecard</Link>
				</li>
			</React.Fragment>
		)
	}

	renderSubprojects() {
		const { projectAndSubprojects } = this.props;
		const { project } = this.props;
		let projectArr = projectAndSubprojects.filter((currentProject) => {
			return currentProject.name === project;
		});
		let arr = projectArr[0].subProjectInfo;
		if (!arr) {
			return null;
		}
		return arr.map((subproject, index) => {
			return (
				<div
					key={index}
					className="dropdown-item d-flex" onClick={(e) => {
						this.props.setSubprojectInfoAPI(subproject);
						this.props.setRetailerCurrencyAPI(subproject.currencyCode)
						this.props.setCustomerKeyAPI(subproject.customerKey)
						this.props.setSubprojectAPI({ subproject: subproject.name });
						localStorage.setItem("subproject", subproject.name);
						this.props.getTabList();
						this.props.resetViewBuilderDataAPI();
						this.props.getDecimalForRetailers({customerKey:subproject?.customerKey},()=>{});
					
					}}>
					<img className="mr-2" src={subproject.logo} height="24" /> <span>{subproject.name}</span>
				</div>

			)
		})
	}
	renderProjectsAndSubprojects() {
		const { projectAndSubprojects } = this.props;
		const { hoveredProjectName, hoveredSubproject, hoveredProjectInfo } = this.state;
		const { subproject } = this.props;
		const { project } = this.props;
		const projectDetails = projectAndSubprojects && projectAndSubprojects.filter((project) => {
			return project.name === hoveredProjectName;
		});
		const listOfSubprojects = projectDetails[0].subProjectInfo;
		return (
			<div className="d-flex projects_subprojects_dropdown">
				<div id="projects_list">
					<ListGroup>
						{projectAndSubprojects.map((project, index) => {
							const projectName = project.name;
							const firstSubProject = project.subProjectInfo[0];
							return (
								<ListGroup.Item
									onMouseEnter={(e) => {
										this.setState({ hoveredProjectName: project.name, hoveredProjectInfo: project });
									}}
									onClick={(e) => {
                                        this.props.setProjectAPI({ project: projectName });
										this.props.storeGlobalFilterSelections({})
										document.cookie = "selected-global-filters= ; path=/; max-age=0; this.state.isSecure; "
										document.cookie = "selected-parent-filter= ; path=/; max-age=0; this.state.isSecure; "
										document.cookie = "selected-filter-name= ; path=/; max-age=0; this.state.isSecure; "
										document.cookie = "selected-filter-object= ; path=/; max-age=0; this.state.isSecure; "
										document.cookie = "selected-sku-id=; path=/; max-age=0; this.state.isSecure; "
										this.props.storeSaveFilterClicked(false)	
										this.props.storeNewRecomSearchText("");	
										this.props.adoptionSelectedUSers([]);
										this.props.storeAdoptionSelectedStrategies(generateStrategyNameList(this.props.detailsForAllStrategies));			
                                        localStorage.setItem("project", projectName);
										this.props.setProjectInfo(project);
										this.props.setSubprojectAPI({ subproject: firstSubProject.name });
										this.props.setSubprojectInfoAPI(firstSubProject);
										this.props.setRetailerCurrencyAPI(firstSubProject.currencyCode)
										this.props.setCustomerKeyAPI(firstSubProject.customerKey)
										localStorage.setItem("subproject", firstSubProject.name);
										this.props.resetViewBuilderDataAPI();
										this.props.storeRecommendationSelectedStrategies(undefined);
										this.props.getTabList();
										this.props.history.push(`/${ projectName.toLowerCase()}/${ firstSubProject.name.toLowerCase()}/`);
										document.cookie = "selected-date-range-filters= ; path=/; max-age=0; this.state.isSecure; "
										this.props.newInsightLeftNameClicked();
										this.props.RankTypeAlertFilter([]);
										this.props.newInsightSectionName();
										this.props.retailerCurrencyCode();
										this.props.saveDateRangeFilterClicked({
											'dateRange': '',
											'compareTo': ''
										});									}}
									className="d-flex justify-content-between"
									active={project.name === hoveredProjectName ? true : false} key={index}>
									{project.name}
									<RightArrowIcon/>
								</ListGroup.Item>
							)
						})}
								<div className="horizontal_divider"></div>
								<ListGroup.Item className="manage_projects" onClick={(e) => {
									this.props.history.push("/");
								}}>View Projects</ListGroup.Item>
					</ListGroup>
				</div>
				<div id="subprojects_list" className={`${listOfSubprojects?.length > 12 ? 'listOfSubprojects_cls' : ''}`}>
					<ListGroup>
						{listOfSubprojects && listOfSubprojects.map((subProject, index) => {
							let active = subproject === subProject.name && project === hoveredProjectName;
							return (
								<ListGroup.Item
									onMouseEnter={(e) => {
										this.setState({ hoveredSubproject: subProject.name });
									}}
									onClick={(e) => {
										this.props.setProjectAPI({ project: hoveredProjectName });
										localStorage.setItem("project", hoveredProjectName);
										this.props.setProjectInfo(hoveredProjectInfo);
										this.props.setSubprojectInfoAPI(subProject);
										this.props.setSubprojectAPI({ subproject: subProject.name });
										this.props.setRetailerCurrencyAPI(subProject.currencyCode)
										this.props.setCustomerKeyAPI(subProject.customerKey)
										localStorage.setItem("subproject", subProject.name);
                                        this.props.resetViewBuilderDataAPI();
										this.props.storeGlobalFilterSelections({})	
										document.cookie = "selected-global-filters= ; path=/; this.state.isSecure; expires = Thu, 01 Jan 1970 00:00:00 GMT"
										document.cookie = "selected-parent-filter=; path=/; max-age=0; this.state.isSecure;"
										document.cookie = "selected-filter-name=; path=/; max-age=0; this.state.isSecure;"
										document.cookie = "selected-filter-object=; path=/; max-age=0; this.state.isSecure;"
										document.cookie = "selected-sku-id=; path=/; max-age=0; this.state.isSecure;"
										this.props.storeRecommendationSelectedStrategies(undefined)
										this.props.storeSaveFilterClicked(false)
										this.props.storeNewRecomSearchText("");	
										this.props.storeSelectedStrategies([])
										this.props.adoptionSelectedUSers([]);	
									    this.props.storeAdoptionSelectedStrategies(generateStrategyNameList(this.props.detailsForAllStrategies));		
										this.props.getTabList();
										this.props.getDecimalForRetailers({customerKey:subProject?.customerKey},()=>{});
										this.props.getOrUpdateLatestUpdateAvailability({userId:this.state.userInfo?.sAMAccountName,userName:this.state.userInfo?.name,userEmail:this.state.userInfo.email,isUpdate:'FALSE', Retailer:subProject?.name});
										this.props.history.push(`/${ hoveredProjectName.toLowerCase()}/${ subProject.name.toLowerCase()}/`);
										document.cookie = "selected-date-range-filters= ; path=/; max-age=0; this.state.isSecure;"
										this.props.saveDateRangeFilterClicked({
											'dateRange': "",
          									'compareTo': ""
										});
										this.props.newInsightLeftNameClicked();
										this.props.getDateFilterOptions();
										this.props.strategySelectedDateRange('Default');
										this.props.strategyFilterAndOrCondition('');
										this.props.RankTypeAlertFilter([]);
										this.props.newInsightSectionName();
										this.props.retailerCurrencyCode();
									}}
									active={active ? true : false} key={index}>
									<div className="d-flex justify-content-between">
										<div>{subProject.name}</div>
										<div>
											{active ?
												<TickIcon/> : null}
										</div>
									</div>
								</ListGroup.Item>
							)
						})}

					</ListGroup>
				</div>
			</div>
		)
	}
	renderProjects() {
		const { projectAndSubprojects } = this.props;
		return projectAndSubprojects.map((entry, index) => {
			return (
				<Link
					to="/"
					key={index}
					className="dropdown-item"
					onClick={(e) => {
						this.props.setProjectAPI({ project: entry.name });
						localStorage.setItem("project", entry.name);
						localStorage.removeItem("subproject");
						this.props.setProjectInfo(entry);
						this.props.setSubprojectAPI({ subproject: entry.subProjectInfo[0].name });
						this.props.setSubprojectInfoAPI(entry.subProjectInfo[0]);
						this.props.setRetailerCurrencyAPI(entry.subProjectInfo[0].currencyCode)
						this.props.setCustomerKeyAPI(entry.subProjectInfo[0].customerKey)
						localStorage.setItem("subproject", entry.subProjectInfo[0].name);
						this.props.resetViewBuilderDataAPI();

					}}>
					{entry.name}
				</Link>

			)
		})
	}
	onToggleProjectSubproject = () => {
		const { project } = this.props;
		this.setState({ hoveredProjectName: project });
	}

	getPageDetails(currentPath, currentPathArr, project, subProject) {
		let pos = window.dataLayer.map(function (e) {
			return e && e.event ? e.event : '';
		}).indexOf('core-page-view');
		let pathValue = currentPath
		if(!pathValue) {
			pathValue = currentPathArr.length===2 && this.props.location.pathname==="/" ? 'Projects' : 
			((currentPathArr.length === 2 || currentPathArr.length === 3) && (this.props.location.pathname.includes('dash') || this.props.location.pathname.includes('ecompass'))) ? 'Sub Projects' : 
			currentPathArr.length === 4 ? this.props.location.pathname.includes('ecompass') ? 'Insights' : this.props.location.pathname.includes('dash') ? 'Recommendations' : pathValue : pathValue
		}
		pathValue = (pathValue === 'ecompass' || pathValue === 'dash') ? 'Sub Projects' : pathValue
		let pageDataLayer = {
			'event': 'core-page-view',
			'pageInfo': {
				'pageName': pathValue,
				'pagePath': this.props.location.pathname,
				'retailerName': `${project}_${subProject}`,
			}
		}
		if(pos === -1) {
			window.dataLayer.push(pageDataLayer)
		}else{
			window.dataLayer[pos] = pageDataLayer
		}
	}

	render() {
		if (this.props.authState.isPending) {
			return (
				<div>Loading ...</div>
			)
		};
		let currentPath = this.props.location.pathname;
		let currentPathArr = currentPath.split("/");
		let currentAlertspage = currentPath.substring(currentPath.lastIndexOf('/')+1);
		if(currentAlertspage === 'alerts'){
			document.body.classList.add('alertspagestle');
		}else {
			document.body.classList.remove('alertspagestle');
		}
		currentPath = currentPathArr[currentPathArr.length - 1];
		const {isAppAdmin,isProjectAdmin, isSubprojectAdmin, project,subproject,selectedProjectInfo,selectedSubprojectInfo } = this.props;
		const formattedProject = project.toLowerCase();
		const formattedSubProject = subproject.toLowerCase();
		this.getPageDetails(currentPath, currentPathArr, formattedProject, formattedSubProject);
		return this.props.authState.isAuthenticated ?
			<nav id="navbar" className={`navbar  fixed-top navbar-expand-lg  top-nav kimberlylogo ${project === 'eCompass' ? 'menubarnewHeader' : ''}`} >
				<ToastContainer />
				{this.state.displayModal ? <Feedback submitFeedback={this.submitFeedback} displayModalOff={this.displayModalOff} /> : null}
				{this.state.displayHelpModal ? <HelpModal displayHelpModalOff={this.displayHelpModalOff} /> : null}
				{this.state.displayLatestModal ? <LatestModal displayLatestModalOff={this.displayLatestModalOff} /> : null}
				{this.state.displayUserListModal ?  <HandleUserListModal displayUserListModalOff={this.displayUserListModalOff} displayUserListModal={this.state.displayUserListModal} />  : null}
				<Dropdown className={`project_wrapper ${project === 'eCompass' ? 'newprojectHeader' : ''}` } onToggle={this.onToggleProjectSubproject}>
					<Dropdown.Toggle variant="light" className="d-flex align-items-center project_subproject_name">
						{project === 'eCompass' ?
						<div>
							<img src={greatLogo} className='greatheader_logo'/>
							<span className="ml-1 mr-2 retailer_header">Retailer Analytics</span>
						</div> :
						<div>
							<img src={selectedProjectInfo?.logo} height="30" />
							<span className="ml-2 mr-2">{project}</span>
						</div>
						}
						<div className="vertical_divider"></div>
						<div className="project_logo_pl6">
							<img src={selectedSubprojectInfo.logo} height="30" />
							<span className="ml-1">{subproject}</span>
						</div>
						<DropDownIcon/>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{this.renderProjectsAndSubprojects()}
					</Dropdown.Menu>
				</Dropdown>

				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#listOfPages" aria-controls="listOfPages" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				
				<div className={`ml-auto ${project === 'eCompass' ? 'nwheader_stlelinks' : 'dash-header'}`}>
				{ project === 'eCompass' ? <div className='rightmenu_pbilinks'>
					<a href='https://app.powerbi.com/groups/938a56ce-8cfa-4c98-ba87-b179aa61b462/reports/02d8d0d6-569c-4cf4-a9d1-f5d8112ad9c8/ReportSection?experience=power-bi' target="_blank"><span>CRISP <BoxArrowUpRight /></span></a>
					<a href={`${process.env.REACT_APP_CRISP_LINK}`} target="_blank"><span className='ml-1'>Sales Dashboard <BoxArrowUpRight /></span></a>
					</div> : null }
				{this.props.hideNavbarPages ? null :
					<React.Fragment>
						<div className="collapse navbar-collapse" id="listOfPages">
							<ul className="navbar-nav ml-auto">
								{this.listOfPages(
									currentPath,
									formattedProject,
								  formattedSubProject
								)
								}
								<li className="nav-item dropdown">
									<div className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<div>
											{this.props.latestUpdateAvailble.isLatestUpdate === "TRUE" && this.props?.project.toLowerCase() === 'ecompass' ?
												<span className='position-relative top-badge'>
													<UserIcon />
													<span class="badgeicon rounded-circle"></span>
												</span> : <UserIcon /> 
											}									
											<span>{this.state.userInfo.given_name}</span>
											<DropDownIcon/>
										</div>
									</div>
									<div className="dropdown-menu align-right-auto" aria-labelledby="navbarDropdown">
										<div className="dropdown-item" onClick={(e) => {
											this.setState({ displayModal: true });
										}}><span>Feedback</span></div>
										{this.props?.project?.toLowerCase() !== 'ecompass' ?
										<div className="dropdown-item" onClick={(e) => {
											this.setState({ displayHelpModal: true });
										}}> <span>Version History</span></div>
										: (<div className="dropdown-item" onClick={(e) => {
											this.setState({ displayLatestModal: true });
											if(this.props.latestUpdateAvailble?.isLatestUpdate === "TRUE"){												
											this.props.getOrUpdateLatestUpdateAvailability({userId:DOMPurify.sanitize(this.state.userInfo?.sAMAccountName),userName:DOMPurify.sanitize(this.state.userInfo?.name),userEmail:DOMPurify.sanitize(this.state.userInfo.email),isUpdate:"TRUE", Retailer:subproject});
											}			
										}}> <span>{this.props.latestUpdateAvailble.isLatestUpdate === "TRUE" && <span className='latest_badge'></span>}Latest Updates</span></div>)
										}										
                                        <div className="dropdown-divider"></div>
                                        <span className="ml-4"><b>Manage</b></span>
										{this.props?.project.toLowerCase() !== 'ecompass' ? <Link className="dropdown-item" to={`/${formattedProject}/${formattedSubProject}/subscriptions`}  ><span> Subscriptions</span></Link>:''}                                        
										{isAppAdmin || isProjectAdmin || isSubprojectAdmin ? <Link className="dropdown-item" to={`/${formattedProject}/${formattedSubProject}/admin`}  ><span> Dashboard</span></Link> : null}
                                        {isAppAdmin || isProjectAdmin ? <Link className="dropdown-item" to={`/${formattedProject}/`}  ><span> Sub Projects</span></Link> : null}
                                        {isAppAdmin ? <Link className="dropdown-item" to="/"  ><span> Projects</span></Link> : null}
										{isAppAdmin || isProjectAdmin  ? (<div className="dropdown-item" onClick={(e) => {
											this.setState({ displayUserListModal: true });
										}}> <span> User List</span></div>) : null}
										{this.props?.project.toLowerCase() === 'ecompass' ? <>{isAppAdmin || isProjectAdmin || isSubprojectAdmin ? <Link className="dropdown-item" to={`/${formattedProject}/updates`}  ><span> Updates</span></Link> : null} </>
										:<>{isAppAdmin || isProjectAdmin || isSubprojectAdmin ?<Link className="dropdown-item" to={`/${formattedProject}/${formattedSubProject}/admin/versions`}  ><span> Version History</span></Link>: null}
										</>}
										<div className="dropdown-divider"></div>
										<button className="dropdown-item" onClick={(e) => { this.logout() }}> <span className="logout-text">Logout</span></button>
									</div>
								</li>
							</ul>
						</div>
						{/* <div className=" navbar-collapse-custom">
							<ul className="navbar-nav mr-auto">
								
							</ul>
						</div> */}
					</React.Fragment>}</div>
			</nav> :
			null;

	}
}
function mapStateToProps(state) {
	let hideNavbarPages = _get(state, 'globalReducer.hideNavbarPages', false);
	let projectAndSubprojects = _get(state, 'globalReducer.projectAndSubprojects', {});
	let project = _get(state, 'globalReducer.project', "");
	let subproject = _get(state, 'globalReducer.subproject', "");
	let selectedSubprojectInfo = _get(state, 'globalReducer.selectedSubprojectInfo', {});
	let selectedProjectInfo = _get(state, 'globalReducer.selectedProjectInfo', {});
	let isAppAdmin = _get(state, 'globalReducer.isAppAdmin', false);
	let isProjectAdmin = _get(state, 'globalReducer.isProjectAdmin', false);
	let isSubprojectAdmin = _get(state, 'globalReducer.isSubprojectAdmin', false);
	let customerKey = _get(state, 'globalReducer.selectedCustomerKey', false);
	let detailsForAllStrategies = _get(state, 'strategyReducer.detailsForAllStrategies', []);
	let detailsForAllUsecase = _get(state, 'adoptionReducer.allUseCases', []);
	let defaultAlertFilterCondition = _get(state, 'globalReducer.defaultAlertFilterCondition', "");
	let latestUpdateAvailble = _get(state, 'globalReducer.latestUpdateAvailble', {isLatestUpdate:""});

	return ({
		hideNavbarPages,
		projectAndSubprojects,
		project: project,
		subproject: subproject,
		selectedSubprojectInfo,
		selectedProjectInfo,
		isAppAdmin,
		isProjectAdmin,
		isSubprojectAdmin,
		tabListItems:state.globalReducer.tabList,
		customerKey,
		detailsForAllStrategies,
		detailsForAllUsecase,
		defaultAlertFilterCondition,
		latestUpdateAvailble
	})
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		submitFeedbackAPI: submitFeedback,
		resetViewBuilderDataAPI: resetViewBuilderData,
		setSubprojectAPI: setSubproject,
		setSubprojectInfoAPI: setSubprojectInfo,
		setRetailerCurrencyAPI: setRetailerCurrency,
		setCustomerKeyAPI: setCustomerKey,
		setProjectAPI: setProject,
		setIsAppAdmin,
		setIsProjectAdmin,
		setIsSubprojectAdmin,
		setProjectInfo,
		getTabList,
		storeSelectedStrategies,
		storeGlobalFilterSelections,
		storeSaveFilterClicked,
		storeNewRecomSearchText,
		adoptionSelectedUSers,
		storeRecommendationSelectedStrategies,
		getDateFilterOptions,
		saveDateRangeFilterClicked : saveDateRangeFilter,
		getDateFilterKeyValueOptions,
		getComparisonIdValues,
		storeAdoptionSelectedStrategies,
		storeAdoptionSelectedUseCases,
		strategySelectedDateRange,
		strategyFilterAndOrCondition,
		getOrUpdateLatestUpdateAvailability,
		newInsightLeftNameClicked,
		RankTypeAlertFilter,
		newInsightSectionName,
		retailerCurrencyCode,
		getDecimalForRetailers
	}, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(Navbar)));
