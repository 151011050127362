import React, { useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
    addNewSubproject, getAllProjectAndSubprojects
} from '../actions'
import { ToastContainer, toast } from 'react-toastify';
import {addCommonActivityCaptureDetail} from '../helper'
import {
	getTabListForSubProjectCreation
} from '../actions'
import Form from "react-bootstrap/Form";
import cloneDeep from "lodash/cloneDeep";
import SelectCurrency from 'react-select-currency';

const AddNewSubProjectModal = (props) => {
    const [name, setName] = useState(null);
    const [oktaAdminGroupName, setOktaAdminGroupName] = useState(null);
    const [oktaReadGroupName, setOktaReadGroupName] = useState(null);
    const [customerKeyValue, setCustomerKeyValue] = useState(null);
    const [currencyCode, setCurrencyCode] = useState('USD');
    const [tabList, setTabList] = useState([]);  
    const [logo, setLogo] = useState(null);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    let project = useSelector((state) => state.globalReducer.project);
    let {  selectedStrategies } = useSelector(
        (state) => state.strategyReducer
      );
      let {  selectedGlobalFilters, tabListForSubProjectCreation } = useSelector(
        (state) => state.globalReducer
      );
    function getFiles(e) {
        setError('');
        var reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = function () {
            setLogo(reader.result);
        }
        reader.readAsDataURL(file);
    }
    useEffect(() => {
        addCommonActivityCaptureDetail({page : "Add new sub-project",globalFilters:selectedGlobalFilters ,strategyFilters: selectedStrategies})
        dispatch(getTabListForSubProjectCreation())

    }, []);
    function handlePageSelection(e, pageName){
      let tempTabList = cloneDeep(tabList);
      let index = tabList.indexOf(pageName)
      if(tabList.includes(pageName) && !e.target.checked){
        tempTabList.splice(index, 1);
      }
      else if(e.target.checked){
        tempTabList.push(pageName)
      }
      setTabList(tempTabList)

    }
    function renderTabList(){
      return(
        <div className='tabList'>
        <label>Select Page(s)</label>
        <Form.Group controlId="formBasicCheckbox" >
          {tabListForSubProjectCreation ? tabListForSubProjectCreation.map(item =>{
           return <Form.Check type="checkbox" label= {item}    onChange={(e) => {handlePageSelection(e, {item})}}/>
          }         
          )      : "No Data Available"
        }
        </Form.Group>
        </div>
      )
    }
    function handleAddNewSubproject(e) {
        e.preventDefault();

        if (name && oktaAdminGroupName && oktaReadGroupName && logo) {

            dispatch(addNewSubproject({
                projectName: project,
                oktaGroupAdmin: oktaAdminGroupName,
                oktaGroupRead: oktaReadGroupName,
                subprojectName:name,
                subprojectLogo: logo,
                tabList:tabList,
                customerKey: customerKeyValue ? customerKeyValue : null,
                currencyCode: currencyCode ? currencyCode : 'USD'
            }, (res, rej) => {
                if (res) {
                  if (res.data.responseType === "success") {
                    toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                  } else {
                    toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                  }

                  dispatch(getAllProjectAndSubprojects());
                } else {
                  
                }
              }));
        } else {
            setError("Please fill all values");
        }
    }
    return (
        <Modal size={'md'} show={props.displayAddNewSubProjectModal} onHide={() => {
            props.hideAddNewSubProjectModal();
        }} id="setting_modal" >
            <Modal.Header closeButton>
                <h5 className="modal-title" id="modalForWidgetLabel"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-folder" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z" />
                    <path fill-rule="evenodd" d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z" />
                </svg>  Add new sub-project</h5>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">
                <form >
                    <div className="form-group">
                        <label> Name</label>
                        <input type="text" value={name} className="form-control" onChange={(e) => {
                            setName(e.target.value);
                            setError("");
                        }} />
                    </div>
                    <div className="form-group">
                        <label> Okta admin claim name</label>
                        <input type="text" value={oktaAdminGroupName} className="form-control" onChange={(e) => {
                            setOktaAdminGroupName(e.target.value);
                            setError("");
                        }} />
                    </div>
                    <div className="form-group">
                        <label> Okta read claim name</label>
                        <input type="text" value={oktaReadGroupName} className="form-control" onChange={(e) => {
                            setOktaReadGroupName(e.target.value);
                            setError("");
                        }} />
                    </div>
                    <div className="form-group">
                        <label> Upload sub-project logo</label>
                        <input
                            type="file"
                            accept=".png,.jpeg,.jpg"
                            className="form-control my-2"
                            onChange={(e) => {
                                setError("");
                                getFiles(e);
                            }} />
                    </div>
                    <div className="form-group">
                      <label>Customer key</label>
                      <input type="text" value={customerKeyValue} className="form-control" onChange={(e) => {
                            setCustomerKeyValue(e.target.value);
                            setError("");
                          }} />
                    </div>
                    <div className="form-group">
                      <label>Select country/currency </label>
                      <SelectCurrency className="form-control" value={currencyCode} onChange={(e) => {
                            setCurrencyCode(e.target.value);
                            setError("");
                        }}/>
                    </div>
                    {renderTabList()}
                    <p className="error">{error}</p>
                    <button class="btn btn-apply" onClick={(e) => { handleAddNewSubproject(e) }}>Save</button>
                </form>

            </Modal.Body>
        </Modal>
    )
}

export default AddNewSubProjectModal;
