const getHighPriorityStrategy = (Recommendations,priorities) => {
    let highReco = Recommendations[0];
    Recommendations.forEach(recommendation => {
        if(priorities[recommendation] < priorities[highReco])
            highReco = recommendation;
    });
    return highReco;
}

const getModifiedStrategy = (strategy) => {
    let modifiedStrategy=strategy.toUpperCase().replace(/ /g,"_")+"_RECOMMENDATION";
    if(modifiedStrategy === "FORECAST_CHANGE_SKUS_RETAILER_DC_RECOMMENDATION")
        return "FORECAST_CHANGE_SKUS_RETAILER_DC_RECOMMENDATION"
    else{
        modifiedStrategy = modifiedStrategy.replace("SKUS","SKU");
        modifiedStrategy = modifiedStrategy.replace("KC_DC","KCDC");
        modifiedStrategy = modifiedStrategy.replace("OOS_WITH_INVENTORY_IN_NETWORK_RECOMMENDATION","OOS_W_INV_IN_NETWORK_ITEM_RECOMMENDATION");
        modifiedStrategy = modifiedStrategy.replace("OOS_WITH_INVENTORY_IN_NETWORK_-_HISTORICAL_RECOMMENDATION","OOS_W_INV_IN_NETWORK_HISTORICAL_ITEM_RECOMMENDATION");
        modifiedStrategy = modifiedStrategy.replace("SALES_ABOVE/BELOW_RETAILER_FORECAST_DC_RECOMMENDATION","SALES_ABOVE_BELOW_RETAILER_FORECAST_SKU_DC_RECOMMENDATION");
        modifiedStrategy = modifiedStrategy.replace("SALES_ABOVE/BELOW_RETAILER_FORECAST_NATIONAL_RECOMMENDATION","SALES_ABOVE_BELOW_RETAILER_FORECAST_SKU_NATIONAL_RECOMMENDATION");
        modifiedStrategy = modifiedStrategy.replace("SALES_ABOVE/BELOW_RETAILER_FORECAST_STORE_RECOMMENDATION","SALES_ABOVE_BELOW_RETAILER_FORECAST_SKU_STORE_RECOMMENDATION");
        return modifiedStrategy
    }
}

export function getFocusAltertEstimatedValue(selectedStrategies,row,priorityObj) {

    let priorities = priorityObj.priorities;
    let weightages = priorityObj.weightage;
    var selectedRecommendations = [];
    var values = [];

    let filteredStrategies = [];
    selectedStrategies.forEach(strategy => {
        if(row.recommendationTypes.includes(strategy))
            filteredStrategies.push(strategy);
    });

    filteredStrategies.forEach(strategy => {
        selectedRecommendations.push(getModifiedStrategy(strategy));
        values.push(row[strategy.replace(/ /g,"_").toLowerCase()+"_estimated_value"]);
    });

    let highReco = getHighPriorityStrategy(selectedRecommendations,priorities);
    let weightage = weightages[highReco];
    
    let focusAlertEstimationValue = 0;
    for(let i=0;i<selectedRecommendations.length;i++){
        focusAlertEstimationValue += (Math.abs(values[i]) * weightage[selectedRecommendations[i]])
    }
    return focusAlertEstimationValue;
}

export function getTotalFocusAlertValue(selectedStrategies,priorityObj,data){
    var totalFocusAlertValue = 0;
    for(let i=0;i<data.length;i++){
        totalFocusAlertValue += getFocusAltertEstimatedValue(selectedStrategies,data[i],priorityObj)
    }
    return totalFocusAlertValue;
}