import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class TabsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <aside className="side-nav-container bg-white">
            <Tabs>
              <TabList>
               <Tab>Blocks</Tab>
              </TabList>

              <TabPanel>
                <div className='tab_container_block'>
                 {/* this will be used in future, currently we dont support section seperations but in future it will be supported  
                  <div className="widget_item" draggable="true" onDragStart={(e) => this.props.onDragStart(e)} data-target="section" id="section">
                     <div className="icon"><i className="far fa-square" aria-hidden="true"></i></div>
                     <div className="text">Section</div>
                   </div>
                */}
                   <div className="widget_item" draggable="true" onDragStart={(e) => { this.props.onDragStart(e)}} data-target="row" id="row">
                    <div className="icon"><i className="fas fa-grip-lines" aria-hidden="true"></i></div>
                    <div className="text">Row</div>
                  </div>
                  <div className="widget_item" draggable="true" onDragStart={(e) => { this.props.onDragStart(e)}} data-target="column" id="column">
                     <div className="icon"><i className="fas fa-th" aria-hidden="true"></i></div>
                     <div className="text">Column</div>
                   </div>
                   <div className="widget_item" draggable="true" onDragStart={(e) => { this.props.onDragStart(e)}} data-target="dashboard" id="dashboard">
                      <div className="icon"><i className="fas fa-chart-area" aria-hidden="true"></i></div>
                      <div className="text">BI Dashboard</div>
                    </div>
                  <div className="widget_item" draggable="true" onDragStart={(e) => { this.props.onDragStart(e)}} data-target="text" id="text">
                     <div className="icon"><i className="fas fa-font" aria-hidden="true"></i></div>
                     <div className="text">Text</div>
                   </div>
                  </div>
              </TabPanel>

              <TabPanel>
              <div className='tab_container_block'>

                </div>
              </TabPanel>

              </Tabs>
            </aside>
        );
    }
}

export default TabsContainer;
