import React from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import ProductTableTriggeredDCModal from "../containers/NewRecommendation/ProductTableTriggeredDCModal";
import { getRpcEnabledRetailers } from "../../src/helper";
import NoImageeCompass from '../containers/Insights/NoImageeCompass.jpg';
function DynamicColumnDialogDCNew(props) {

  let dcLocationModalDetails = props.dcLocationModalDetails;
  let { subproject } = useSelector((state) => state.globalReducer);
  let recomproductImage = dcLocationModalDetails?.imagelink ? dcLocationModalDetails.imagelink : NoImageeCompass;
  return (
    <Modal
      show={props.dynamicColumnLog}
      onHide={props.displayDynamicColumnDialogOff}
      id="alert-log-modal" className="location-modal"
    >
      <Modal.Header closeButton>
      <div class="d-flex" style={{marginLeft:"20px"}}>
          <img src={recomproductImage} style={{ width: "132px", padding: "10px" }} />
          <div
            style={{
              alignSelf: "center",
              paddingLeft: "10px",
              fontSize: "14px",
            }}
          >
            <div style={{fontSize:"20px",fontWeight:"bold",paddingBottom:"5px"}}>
              <span>{dcLocationModalDetails.name}</span>
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>Product Id : </span>
              <span>{dcLocationModalDetails.productId}</span>
            </div>
            {getRpcEnabledRetailers().includes(subproject) && 
            <div>
              <span style={{ fontWeight: 600 }}>RPC : </span>
              <span>{dcLocationModalDetails?.rpc ? dcLocationModalDetails.rpc : 'N/A'}</span>
            </div> }
            <div>
              <span style={{ fontWeight: 600 }}>UPC : </span>
              <span>{dcLocationModalDetails.upc}</span>
            </div>
            
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="py-4">
        <ProductTableTriggeredDCModal  productlocationDetails={dcLocationModalDetails}/>         
      </Modal.Body>
    </Modal>
  );

          

}

export default DynamicColumnDialogDCNew;
