import React, { Component, useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import DashboardBuilder from './DashboardBuilder';
import AdminDefaultView from './AdminDefaultView';
import Versions from './Versions';
import CreateAndEditVersion from './CreateAndEditVersion';
import './Admin.scss';
import AdminConfigPBIReport from './AdminConfigPBIReport';

function Admin() {
    const [userInfo, setUserInfo] = useState(null);
    const { authState, authService } = useOktaAuth();
    useEffect(() => {
        document.title = `Admin`;
    }, []);
    useEffect(() => {
        if (!authState.isAuthenticated) {
            setUserInfo(null);

        } else {
            authService.getUser().then((info) => {
                setUserInfo(info);
            });
        }
    }, [authState, authService]); // Update if authState changes
    const project = useSelector((state) => state.globalReducer.project);

    const subproject = useSelector((state) => state.globalReducer.subproject);
   const isAppAdmin = useSelector((state) => state.globalReducer.isAppAdmin);
   const isProjectAdmin = useSelector((state) => state.globalReducer.isProjectAdmin);
   const isSubprojectAdmin = useSelector((state) => state.globalReducer.isSubprojectAdmin);

    const formattedProject = project ? project.toLowerCase():"";
    const formattedSubProject = subproject ? subproject.toLowerCase():"";
   let hasAdminAccess = isAppAdmin || isProjectAdmin || isSubprojectAdmin ? true : false;
    function renderAdmin() {
        return (
            <div>


                <Switch>

                    
                    <Route exact path={`/${formattedProject}/${formattedSubProject}/admin/dashboard_builder`} component={DashboardBuilder} />
                   <Route exact path={`/${formattedProject}/${formattedSubProject}/admin/create-version`} render={()=>{
                                           return <CreateAndEditVersion type="create"/>
                                       }} />
                    <Route exact path={`/${formattedProject}/${formattedSubProject}/admin/versions`} component={Versions} />
                    <Route exact path={`/${formattedProject}/${formattedSubProject}/admin/edit-version`} render={()=>{
                        return <CreateAndEditVersion type="edit"/>
                    }} />
                    <Route exact path={`/${formattedProject}/${formattedSubProject}/admin/salesprediction`} component={AdminDefaultView} />                    
                    <Route exact path={`/${formattedProject}/${formattedSubProject}/admin`} component={formattedProject === "ecompass" ? AdminConfigPBIReport : AdminDefaultView} />
                </Switch>

            </div>
        )
    }
    return (
        <div className="">
            {hasAdminAccess ?
                renderAdmin() :
                <div className="default-top p-5">
                    <h1 className="sku">You do not have access to this page</h1>
                </div>}
        </div>
    )
}


export default Admin;