import React, { useState, useEffect } from 'react';
import { Treemap, Tooltip, ResponsiveContainer } from 'recharts';
import { getRatingReviewsSummary, getReportDetailsByMetricName, getSentimentAnalysisReviewCount } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import colorratingImg from "./colorrating.png";
import sentimentcolorratingImg from "./sentiment_colorrtng.png";
import NewInsightsPbiComponent from './NewInsightsPbiComponent';
import SentimentReportModal from './SentimentReportModal';
import moment from "moment";
import SentimentSectionCharts from './SentimentSectionCharts';
import BarLoader from "react-spinners/BarLoader";
import { ExcelfileIcon  } from '../../components/Icons';

const SentmentSection = () => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [activeTab, setActiveTab] = useState('tab1');
  const [displayModal, setDisplayModal] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    propsName: '',
    propsRatings: ''
  });
  const [reviewCount, setReviewCount] = useState();
  let { selectedCustomerKey, selectedGlobalFilters, getLastDataRefreshDate, insightsDateValues } = useSelector((state) => state.globalReducer);
  let { selectedSelectionAndMetricName, ratingReviewSummary, insightsTabsDetailsData } = useSelector((state) => state.insightReducer);
  let rtrwsummaryData = ratingReviewSummary?.responseData;
  let { dateFilterAndRange } = useSelector((state) => state.newRecommendationTableData);
  let dateFilterRange = dateFilterAndRange?.dateRange ? dateFilterAndRange.dateRange : 'MTD';
  let  transMaxDate = getLastDataRefreshDate?.responseData?.transMaxDate;
  let  lastDataRefreshDate = getLastDataRefreshDate?.responseData?.lastDataRefreshDate;
  const dateFilterList = ['MTD', 'QTD', 'YTD'];
  let timemaxlastDate = dateFilterList.includes(dateFilterRange) && transMaxDate ? transMaxDate : lastDataRefreshDate;
  const dateFormat = (dateVal) => moment(dateVal).format('D-M-YYYY'); 

  const updatedDataObj = { ...selectedGlobalFilters };
  const removeValueFromObjectArray = (obj) => {
      const key = 'rpln_flag';
      if (obj.hasOwnProperty(key) && obj[key].length > 0) {
      delete obj[key];
      }
      return obj;
  }
  const updatedObject = removeValueFromObjectArray(updatedDataObj);

  useEffect(() => {    
    if (activeTab === 'tab1' || activeTab === 'tab3') {
      setLoading(true)
      dispatch(getRatingReviewsSummary({ customerKey: selectedCustomerKey, filterValues: updatedObject, timeframe: dateFilterRange, sectionName: selectedSelectionAndMetricName.sectionName }, (res) => {
        if (activeTab === 'tab1') {
          setLoading(false);
        }
      }));
    }
  }, [selectedGlobalFilters, dateFilterAndRange?.dateRange, activeTab])

  useEffect(() => {    
    if (activeTab === 'tab3') {      
      setLoading(true)
      dispatch(getSentimentAnalysisReviewCount({customerKey: selectedCustomerKey, filterValues: updatedObject, timeframe: dateFilterRange, sectionName: selectedSelectionAndMetricName.sectionName, 
        subSectionName: 'sentimentAnalysis', date:{startDate:dateFormat(insightsDateValues?.startDateCurrent),endDate:dateFormat(insightsDateValues?.endDateCurrent)}}, (res) => {
          setReviewCount(res?.data?.responseData);          
          setLoading(false);
      }))     
    }
  }, [selectedGlobalFilters, dateFilterAndRange?.dateRange, activeTab])

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };
  const displayModalOff = () => {
    setDisplayModal(false);
  }
  const getColorByRating = (rating) => {
    return rating >= 5 ? '#07b24d' : rating >= 4.5 ? '#60cd31' : rating >= 4 ? '#77d529' : rating >= 3.5 ? '#a2e11d' :
     rating >= 3 ? '#ffff00' : rating >= 2.5 ? '#ffe700' : rating >= 2 ? '#ff9b00' : rating >= 1.5 ? '#ff4500' : '#ff0900';
  };
  const metricNamelist = insightsTabsDetailsData?.finalData?.METRIC_NAME.split(',');
  const metricListName = metricNamelist?.length > 0 && activeTab === 'tab1' ? metricNamelist[0] : metricNamelist?.length > 0 && activeTab === 'tab3' ? metricNamelist[1] : '';

  const handleCategoryClick = (name, rating) => {
    dispatch(getReportDetailsByMetricName({
      metricName: metricListName, customerKey: selectedCustomerKey,
    }, (res) => {
      let obj = {
        propsName: name,
        propsRatings: rating,        
        pbiTitle: activeTab === 'tab1' ? 'Rating & Review Summary' : 'Sentiment Analysis Summary'
      }
      setModalDetails(obj);
      setDisplayModal(true);
    }))
  };

  const CustomizedContent = (props) => {
    const { x, y, width, height, RATINGS, REVIEWS, KC_PLATFORM } = props;
    const color = getColorByRating(RATINGS);
    const starwd =  width < 110 ? width : 108;
    const starX =  x + width - starwd;
    const starY = width <= 330 && height <= 80 ? y + 1 : height <= 120 ? y + 6 : y + 10;
    const stars = [];
    const fullStars = RATINGS ? RATINGS.toFixed(1) : 0;
    const hasHalfStar = fullStars % 1 !== 0;
    const ratingwidth = width <= 25 ? 'rtw-25' : width < 85 ? 'ratingwidh': '';
    const starrtwidht = width < 100 ? width : 'auto';
    // Add full stars
    for (let i = 1; i <= fullStars; i++) {
        stars.push( <i class="fas fa-star star-full"></i>);
    }  
    // Add half star if necessary
    if (hasHalfStar) {
      stars.push(<i class="fas fa-star-half-alt star-half"></i>);
    }
    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<i class="fas fa-star star-empty"></i>);
    }
    let catName = KC_PLATFORM?.length > 14 ? KC_PLATFORM.substring(0,10) : "";
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={color}
          stroke="#fff"
          onClick={() => handleCategoryClick(KC_PLATFORM, RATINGS)}
          style={{ cursor: 'pointer' }}
        />
        {width <= 80 && height <= 70 || (width <= 50 || height <=30) ? '' :
        <text x={x + width / 2} y={y + height / 2} fontWeight="bold" fontSize={height <=50 ? '9px' : '14px'} textAnchor="middle" dy={height <= 120 ? '0.2em' : '-0.5em'}>
          {KC_PLATFORM?.length > 14 && width <= 130 ? catName +'...' : KC_PLATFORM}
        </text>
        }
        <text x={x + width / 2} y={y + height / 2} fontWeight="bold" fontSize={height <=50 ? '9px' : '13px'} textAnchor="middle" dy={height <= 120 ? width <= 80 && height <= 70 || height <= 30 ? '0.2em' : '1.5em' : '1.0em'}>
          {REVIEWS}
        </text>
        <foreignObject x={starX} y={starY} width="100%" height={60}>
        {width <= 230 && height <= 70 || (width <= 50 || height <=40) ? '': 
        <span className={`star-rating ${ratingwidth}`} style={{width: starrtwidht}}>
          {stars}
        </span>
        }
        </foreignObject>

      </g>
    );
  };
  const numberWithCommas = (val) => {
    let result = (val - Math.floor(val)) !== 0;
    let resultNum = result ? val.toFixed(2) : val;
    return resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const numberWithCommasFix = (val) => val !== null ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {payload.map((pld, index) => (
            <>
              {index === 0 &&
                <div style={{ display: "inline-block", backgroundColor: "#000", color: "#fff", padding: "10px 15px" }}>
                  {pld.payload.KC_PLATFORM ? <div>Category Name: {pld.payload.KC_PLATFORM} </div> : ''}
                  {pld.payload.REVIEWS ? <div>Reviews: {numberWithCommas(pld.payload.REVIEWS)}</div> : ''}
                  {pld.payload.RATINGS ? <div>Ratings: {pld.payload.RATINGS.toFixed(1)} </div> : ''}
                  {pld.payload?.SENTIMENT_SCORE ? <div>Sentiment Score: {pld.payload?.SENTIMENT_SCORE.toFixed(1)} </div> : ''}
                </div>}
            </>
          ))}
        </div>
      );
    }
    return null;
  };

  const sentimentTreeGraph = () => {
      return (
        <>
        {displayModal && <SentimentReportModal modalDetails={modalDetails} display={displayModal} displayModalOff={displayModalOff} />}
        <ResponsiveContainer width="100%" height={360}>
          <Treemap
            width={360}
            height={360}
            data={rtrwsummaryData?.boxData}
            dataKey="REVIEWS"
            ratio={3}           
            content={<CustomizedContent />}
            isAnimationActive={false}
          >
            <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
          </Treemap>
        </ResponsiveContainer>
        </>
      )
  }
  const sentimentSectionTab = () => {
    if(loading) {
      return (<div id="strategy_wrapper">
      <div className="container-fluid  default-top loading_insights loading_wrapper bg-w p-5">
        <div className="sweet-loading">
        <span><b>Processing...</b></span>
          <BarLoader style={{display: 'block', margin:'0 auto', borderColor: 'red'}} size={150} color={"#123abc"}
            height={4} width={100} loading={loading} />
        </div>
      </div>
    </div>)
    } else {
    if (activeTab === 'tab1') {
      if (rtrwsummaryData?.boxData?.length > 0) {
        let ratingRev = rtrwsummaryData?.ratings?.length > 0 ? rtrwsummaryData?.ratings[0] : [];
        return (
          <div className='ratingreview d-flex'>
            <div className='w-g'>
              <div className='ratingclr'>Platform Level Rating & Review Summary:</div> 
              {sentimentTreeGraph()}
              <div className='d-flex'>
                <div className='ratingoverall'>
                  <div> Star Rating Overall : {ratingRev?.OVERALL_RATINGS ? ratingRev.OVERALL_RATINGS.toFixed(1) : 'N/A'} </div>
                  <div> Star Rating with Review : {ratingRev?.RATING_WITH_REVIEWS ? ratingRev.RATING_WITH_REVIEWS.toFixed(1) : 'N/A'}</div>
                </div>
                <div className='ratingdesc mt-2'>
                  <i> Desc: The size of the boxes are defined by the number of reviews and the colors represent the rating scores.</i>
                  <strong style={{ color: '#6dca88' }}> Green</strong> <i> means it is closer to 5 Rating and </i> <strong style={{ color: '#f46a6e' }}>Red </strong><i> means it is closer 1 Rating</i>
                </div>
              </div>
            </div>
            <div className='clrimgcls'>
              <img src={colorratingImg} className='img-fluid' />
            </div>
          </div>)
      } else {
        return (
          <div className='text-center mt-5 pt-5 loading_insights loading_wrapper bg-w p-5'>No Rating & Review Summary available to display</div>
        )
      }
    } else if(activeTab === 'tab2') {
      return <div className='mt-5 sentiment-abilitytab'><NewInsightsPbiComponent pbiviewName="Sentiment" /></div>
    } else if(activeTab === 'tab3') {
      let ratingRev = rtrwsummaryData?.ratings?.length > 0 ? rtrwsummaryData?.ratings[0] : [];
      const downloadData = () => {
          let combinedObj = {
            customerKey: selectedCustomerKey,
            filterValues: updatedObject, 
            timeframe: dateFilterRange, 
            sectionName: selectedSelectionAndMetricName?.sectionName,
            date:{startDate:dateFormat(insightsDateValues?.startDateCurrent),endDate:dateFormat(insightsDateValues?.endDateCurrent)}  
        },
        bufferValue = new Buffer(JSON.stringify(combinedObj)),
        base64data = bufferValue.toString("base64");
        window.open(`${process.env.REACT_APP_ROOT_URL}/download/sentimentExcelDownload/${base64data}`, "_blank");
      }
      return (
        <>       
        <div className='ml-5 mr-5 mb-4'>
          <div className='row'>
            <div className='ratingsummary col-3 pr-0'>Sentiment Analysis Summary:</div> 
            <div className='col-4'>
              <span className='downloadfile' onClick={(e) => downloadData()}> Download full report <ExcelfileIcon /></span>
            </div> 
          </div>
        <div class="row ratingsummarycount">
            <div class="col-md-3">
                <div class="card text-center">
                    <div class="card-header p-1" style={{backgroundColor: '#0096FF'}}><strong> Total # of Reviews / Topics  </strong></div>
                    <div class="card-body pt-2 pb-0" style={{backgroundColor: '#d4d9ec'}}>
                        <h5 class="card-title mb-1"><b>{reviewCount?.totalReview ? numberWithCommasFix(reviewCount?.totalReview) : 0}</b></h5>                       
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card text-center">
                    <div class="card-header p-1" style={{backgroundColor: '#70ad47'}}><strong> # Positive Reviews / Topics  </strong></div>
                    <div class="card-body pt-2 pb-0" style={{backgroundColor: '#d4d9ec'}}>
                        <h5 class="card-title mb-1"><b>{reviewCount?.positivereview ? numberWithCommasFix(reviewCount?.positivereview) : 0}</b></h5>                        
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card text-center">
                    <div class="card-header p-1" style={{backgroundColor: '#f1f904'}}><strong># Neutral Reviews / Topics</strong></div>
                    <div class="card-body pt-2 pb-0" style={{backgroundColor: '#d4d9ec'}}>
                        <h5 class="card-title mb-1"><b>{reviewCount?.neutralReview ? numberWithCommasFix(reviewCount?.neutralReview) : 0}</b></h5>                        
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card text-center">
                    <div class="card-header p-1" style={{backgroundColor: '#f74c13'}}><strong># Negative Reviews</strong></div>
                    <div class="card-body pt-2 pb-0" style={{backgroundColor: '#d4d9ec'}}>
                        <h5 class="card-title mb-1"><b>{reviewCount?.negativeReview ? numberWithCommasFix(reviewCount?.negativeReview) : 0}</b></h5>                        
                    </div>
                </div>
            </div>
        </div>
      </div>
          {rtrwsummaryData?.boxData?.length > 0 ? 
          <div className='ratingreview d-flex position-relative'>
            <div className='w-g'>                         
              {sentimentTreeGraph()}
              <div className='d-flex'>
                <div className='ratingoverall'>
                  <div> Star Rating Overall : {ratingRev?.OVERALL_RATINGS ? ratingRev.OVERALL_RATINGS.toFixed(1) : 'N/A'} </div>
                  <div> Star Rating with Review : {ratingRev?.RATING_WITH_REVIEWS ? ratingRev.RATING_WITH_REVIEWS.toFixed(1) : 'N/A'}</div>
                </div>
                <div className='ratingdesc mt-2'>
                  <i> Desc: The size of the boxes are defined by the number of reviews and the colors represents the tonality of the reviews with</i>
                  <strong style={{ color: '#6dca88' }}> Green</strong> <i> showing positive and </i> <strong style={{ color: '#f46a6e' }}>Red </strong><i> showing negative tones</i>
                </div>
              </div>
            </div>
            <div className='clrimgcls-cls'>
              <img src={sentimentcolorratingImg} className='img-fluid' />
            </div>
          </div> :  <div className='text-center mt-5 pt-5 loading_insights loading_wrapper bg-w p-5'>No Rating & Review Summary available to display</div>}
          
        </>
      )}
    }
  }
  return (
    <div className='mb-5'>
      <div className='d-flex justify-content-end mt-4 mb-2'>
        <button className={`tabbtn ${activeTab === 'tab1' ? 'selectedbtn' : ''}`} onClick={() => handleTabChange('tab1')}>
          Rating & Review Summary
        </button>
        <button className={`tabbtn ${activeTab === 'tab2' ? 'selectedbtn' : ''}`} onClick={() => handleTabChange('tab2')}>
          Buyability
        </button>
        <button className={`tabbtn ${activeTab === 'tab3' ? 'selectedbtn' : ''}`} onClick={() => handleTabChange('tab3')}>
          Sentiment Analysis
        </button>
      </div>        
      {sentimentSectionTab()}
      {activeTab === 'tab3' && <SentimentSectionCharts />}       
    </div>
  );
};

export default SentmentSection;
