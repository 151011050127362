import React, { useState, useEffect } from 'react';
import { getSkuHealthChart } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from "moment";
import BarLoader from "react-spinners/BarLoader";

const NewInsightsDigitalShelfChart = (props) => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [insightSkuHealthChart, setInsightSkuHealthChart] = useState([]);
  let { selectedCustomerKey, selectedGlobalFilters, selectedRetailerCurrency, globalFilter } = useSelector((state) => state.globalReducer);
  let {  selectedSelectionAndMetricName } = useSelector((state) => state.insightReducer);
  
  const updatedDataObj = { ...selectedGlobalFilters };
  const removeValueFromObjectArray = (obj) => {
    const key = 'rpln_flag';
    if (obj.hasOwnProperty(key) && obj[key].length > 0 && selectedSelectionAndMetricName?.sectionName === 'Digital Shelf') {
      delete obj[key];
    }
    return obj;
  }
  const updatedObject = removeValueFromObjectArray(updatedDataObj);
  useEffect(() => {
    setLoading(true);   
    dispatch(getSkuHealthChart({ customerKey: selectedCustomerKey, filterValues: updatedObject }, responseHandlerupdate));
  }, [selectedGlobalFilters]);

  const responseHandlerupdate = (response) => {
    if (response?.data?.responseData?.length >= 0) {      
      setInsightSkuHealthChart(response.data.responseData);
      setLoading(false)
    } else { setLoading(false) }
  }
  const formatXAxis = (tickItem) => moment(tickItem).format('D-M-YYYY'); 
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {payload.map((pld, index) => (
            <>
              {index === 0 &&
                <div key={index} style={{ display: "inline-block", backgroundColor: "#000", color: "#fff", padding: "10px 15px" }}>
                  {pld.payload.DATE ? <div><span className='' >DATE:</span> {pld.payload.DATE}</div> : ''}
                  {pld.payload.GREY_GROUP_PERCENTAGE && pld.payload.GREY_GROUP_PERCENTAGE >= 0.1 ? <div>Grey Category: {fixeddecimalFormat(pld.payload.GREY_GROUP_PERCENTAGE)}% </div> : ''}
                  {pld.payload.RED_GROUP_PERCENTAGE && pld.payload.RED_GROUP_PERCENTAGE >= 0.1 ? <div>Red Category: {fixeddecimalFormat(pld.payload.RED_GROUP_PERCENTAGE)}% </div> : ''}
                  {pld.payload.YELLOW_GROUP_PERCENTAGE  && pld.payload.YELLOW_GROUP_PERCENTAGE >= 0.1 ? <div>Yellow Category: {fixeddecimalFormat(pld.payload.YELLOW_GROUP_PERCENTAGE)}%</div> : ''}
                  {pld.payload.GREEN_GROUP_PERCENTAGE && pld.payload.GREEN_GROUP_PERCENTAGE >= 0.1 ? <div>Green Category: {fixeddecimalFormat(pld.payload.GREEN_GROUP_PERCENTAGE)}% </div> : ''}

                </div>}
            </>
          ))}
        </div>
      );
    }
    return null;
  };
  const labelFormatterPercent = (num) => num !== null ? `${num}%` : '';
  const fixeddecimalFormat = (cell) => cell === 100 ? cell : parseFloat(cell).toFixed(2);
  const DigitalShelfChart = () => {  
  if(loading) {
   return (<div id="strategy_wrapper">
      <div className="container-fluid  default-top loading_insights loading_wrapper bg-w p-5">
        <div className="sweet-loading">
          <span><b>Processing...</b></span>
          <BarLoader style={{ display: 'block', margin: '0 auto', borderColor: 'red' }} size={150} color={"#123abc"}
            height={4} width={100} loading={loading} />
        </div>
      </div>
    </div>
  )} else {
  return (
    <div className='mt-1' style={{ margin: "auto", paddingRight: "20px" }}>
      <div className='text-center' style={{ fontSize: "18px", fontWeight: "bold", marginTop: "40px" }}> SKU Health </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={100} height={300} data={insightSkuHealthChart}
          margin={{ top: 20, right: 10, bottom: 25 }} >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="DATE" tick={{ angle: -45 }} style={{ fontSize: "10px" }} dy={15} tickFormatter={formatXAxis} minTickGap={-200} axisLine={false} />
          <YAxis allowDataOverflow={true} domain={[0, 100]} style={{ fontSize: "10px" }} dx={-5} tickFormatter={labelFormatterPercent} />
          <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
          <Bar dataKey="GREY_GROUP_PERCENTAGE" stackId="a" isAnimationActive={false} barSize={26} fill="#808080" />
          <Bar dataKey="RED_GROUP_PERCENTAGE" stackId="a" isAnimationActive={false} barSize={26} fill="#c10000" />
          <Bar dataKey="YELLOW_GROUP_PERCENTAGE" stackId="a" isAnimationActive={false} barSize={26} fill="#ffc100" />
          <Bar dataKey="GREEN_GROUP_PERCENTAGE" stackId="a" isAnimationActive={false} barSize={26} fill="#70ad46" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )}}
  return <DigitalShelfChart />
}
export default NewInsightsDigitalShelfChart;