import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { updateViewBuilderJSON } from '../../../actions';
import ContentOptions from './ContentOptions';
import DesignOptions from './DesignOptions';
import Editor from './Editor';

class TextContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          displayColorPicker:false,
          colorCode:'',
          displayColorPickerId:'',
           
        }
    }
 renderContentOptions(){
   return(<ContentOptions currentObj = {this.props.currentObj} id={this.props.id} />)
  }
  onEditorStateChange = (editorState) => {
     this.setState({
       editorState,
     });
   };
renderTextEditor(){

      return(
      <Editor currentObj = {this.props.currentObj} id={this.props.id} />
      )
}

  renderDesignOptions(){
    return(<DesignOptions currentObj = {this.props.currentObj} id={this.props.id} />)
  }
    render() {
        return (
            <Tabs>
              <TabList>
               <Tab>Content</Tab>
              <Tab>Design</Tab>
              </TabList>
              <TabPanel>
                {this.renderContentOptions()}
                {this.renderTextEditor()}
              </TabPanel>

              <TabPanel>
                {this.renderDesignOptions()}
              </TabPanel>
              </Tabs>
      );
    }
}
function mapStateToProps(state) {
	return ({
		viewBuilder:state.viewBuilderObj,

	})
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateViewBuilderJSONAPI:updateViewBuilderJSON
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TextContent);
