export default function(state={},action){
    switch(action.type){
        case "DETAILS_FOR_ALL_USERS":return {...state,detailsForAllUsers:action.payload}
        case "USER_NAME_FILTER":return {...state,userNamesFilter:action.payload}
        case "DAILY_USAGE_VALUE" : 
         return {...state,dailyUsageValue:action.payload}
        case "AVERAGE_VOLUME_DAILY_ACTIONS" : return {...state,averageVolumeDailyActions:action.payload}
        case "ALERT_SUMMARY": return {...state, alertSummary: action.payload}
        case "USER_SUMMARY": return {...state, userSummary: action.payload}
        case "ESTIMATED_VALUE" : return {...state,estimatedValue:action.payload}       
        case "AVERAGE_AGE_OF_RECOMMENDATIONS_BEFORE_ACTIONED" : return {...state,averageAgeofRecommendationsBeforeActioned:action.payload}
        case "AVERAGE_AGE_OF_RECOMMENDATIONS_BEFORE_RESOLUTION" : return {...state,averageAgeofRecommendationsBeforeResolution:action.payload}
        case "AGE_OF_ACTIVE_RECOMMENDATIONS_BY_STRATEGY" : return {...state,ageofActiveRecommendationsByStrategy:action.payload}
        case "RECOMMENDATION_RESOLVED_WITHOUT_BEING_ACTIONED" : return {...state,recommendationResolvedWithoutActioned:action.payload}
        case "SELECTED_DATE_RANGE" : return {...state,selectedDateRange:action.payload}
        case "CUSTOM_START_END_DATE" : return {...state,adoptionCustomDates:action.payload}
        case "ADOPTION_SELECTED_USERS" : return {...state,adoptionSelectedUsers:action.payload}
        case "ADOPTION_ADDITIONAL_PARAMETERS" : return {...state,adoptionAdditionalParamaters:action.payload} 
        case "ADOPTION_SELECTED_STRATEGIES" : return {...state,adoptionSelectedStrategies:action.payload} 
        case "ADOPTION_SELECTED_USECASES" : return {...state,adoptionSelectedUseCases:action.payload} 
        case "AGE_OF_RECOMMENDATION_BY_STRATEGY" : return {...state,adoptionAgeByStrategy:action.payload} 
        case "ALL_STRATEGY_DETAILS" : return {...state,allStrategyDetails:action.payload} 
        case "CURRENT_WEEK_DATES": return {...state,currentWeekDates:action.payload} 
        case "SELECTED_CUSTOM_DATES": return {...state,selectedCustomDates:action.payload} 
        case "ESTIMATED_VALUE_NEW" : return {...state,estimatedValueNew:action.payload}
        case "USE_CASE_FILTER": return {...state, allUseCases: action.payload}
        case "ENGAGEMENT_SUMMARY": return {...state, engagementSummary: action.payload}
        case "RETAILER_CURRENCY_CODE": return {...state, retailerCurrency: action.payload}
        default:
            return state;
    }
}