import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlertLogData } from "../actions/index";
import { PriceFormatter } from "../helper";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import Modal from "react-bootstrap/Modal";
import { selectedStrategies } from "../actions"

function AlertLogModal(props) {
  const [alertLogData, setAlertLogData] = useState([]);
  const [preModalBodyText, setPreModalBodyText] = useState("Loading...");
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState();
  let { subproject, selectedRetailerCurrency, defaultAlertFilterCondition } = useSelector((state) => state.globalReducer);
  let { selectedStrategies, selectedFilterCondition } = useSelector((state) => state.strategyReducer)
  let { strategyName,recommendationSelectedStrategy } = useSelector(
    (state) => state.strategyReducer
  );
  let { actionedAndActiveData } = useSelector(
    (state) => state.recommendationsReducer
  );
  let productDetails = props.alertLogModalDetails;
  let productlocationDetails = props.productlocationDetails
  const options = {
    totalSize: totalSize,
    sizePerPage: sizePerPage,
    page: pageNumber,
    showTotal: true,
    disablePageTitle: true,
  };
 
  useEffect(() => {
    setLoading(true)
    setPreModalBodyText("Loading...")
    let alertType = "";
    if(selectedStrategies?.length){
      if(selectedStrategies && selectedStrategies?.length == 1) {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DISPLAY_TYPE"];
      } else {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DEFAULT_TYPE"];
      }
    }
    getAlertLogData(
      {
        alertNames: selectedStrategies,
        productId: productDetails?.productId ? productDetails.productId : "",
        location: productDetails?.location ? productDetails.location : "",
        dcId : productlocationDetails?.fcNameId ? productlocationDetails?.fcNameId : "",
        type: alertType === 'DC/Cluster' || alertType === 'Total' ? alertType :'' ,
        pageNo: pageNumber,
        totalRows: sizePerPage,
        alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition,
      },
      (response) => {
       
        if (
          response.data &&
          response.data.responseType &&
          response.data.responseType.toLowerCase() === "success"
        ) {
         if(response.data.responseData.data.length <= 0) {
          setLoading(true)
          setPreModalBodyText("No Data Available")
         } else
             {
               setAlertLogData(response.data.responseData.data) 
               setTotalSize(response.data.responseData.totalRows)
               setLoading(false)
            }
        } else {
          setLoading(true)
          setPreModalBodyText("No Data Available");
        }
      }
    );
  }, [sizePerPage, pageNumber]);

  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    

    if (type === "pagination") {
      setSizePerPage(sizePerPage);
      setPageNumber(page);
    }
  };
  function renderBody() {
    const columns = [
      {
        dataField: "alertName",
        text: "Alert Name"
      },
      {
        dataField: "alertStatus",
        text: "Alert Status",
      },
      {
        dataField: "statusDate",
        text: "Status Date",
       
      },
      {
        dataField: "alertTriggerDate",
        
        text: "Alert Trigger Date",
       
      },
      {
        dataField: "alertEstimatedValue",
        text: "Focused Estimated Value",
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            {PriceFormatter(cell, {
              precision: 0,
              symbol: selectedRetailerCurrency,
            })}
          </div>
        ),
      },
      {
        dataField: "actionedBy",
        text: "Actioned By",
       
      },
      {
        dataField: "snoozeDays",
        text: "Snooze Days",
      },
      {
        dataField: "reason",
        text: "Reason",
      },
    ];
    if (props.isProductLevel)
      columns.unshift({
        dataField: "location",
        text: "Location"
      });

    return (
      
      <ToolkitProvider

      data={ alertLogData }
      columns={ columns }
      search
     >
      {
        props => (
          <div className='tableM'>
            <BootstrapTable  wrapperClasses={alertLogData && alertLogData.length > 8 ? "table-responsive fix-ht":"table-responsive"} bootstrap4 pagination={ paginationFactory(options)}
             remote
             { ...props.baseProps }  
             keyField="alertLog"          
             size="sm"            
             onTableChange={ handleTableChange }
             striped
             hover
             bordered={false}
             headerClasses='staticTableheaderProduct'
            />
          </div>
        )
      }

     </ToolkitProvider>
      
    );
  }

  return (
    <Modal
      show={props.displayAlertLogModal}
      onHide={props.displayAlertLogModalOff}
      id="alert-log-modal"
    >
      <Modal.Header closeButton>
        <div class="d-flex">
          <img
            src={productDetails.imagelink}
            style={{ width: "132px", padding: "10px" }}
          />

          <div className="alertProductname">
            <div>
              <span style={{ fontWeight: 600 }}>Product Name : </span>
              <span>{productDetails.name}</span>
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>Product Id : </span>
              <span>{productDetails.productId}</span>
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>Alert : </span>
              <span>{selectedStrategies.toString()}</span>
            </div>
            {!props.isProductLevel && (
              <div>
                <span style={{ fontWeight: 600 }}>Location : </span>
                <span>{productDetails.location}</span>
              </div>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-gray py-4">
        {loading ? (
          <div style={{ height: "400px", textAlign: "center" }}>
            {preModalBodyText}
          </div>
        ) : (
          renderBody()
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AlertLogModal;
