import React, { Component } from 'react';
//Sidebar Menu on Dash
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    renderOptions() {
        let {sidebarOptions} = this.props;
       if( (sidebarOptions.constructor === Array && sidebarOptions.length === 0 ) || (sidebarOptions.constructor === Object && Object.keys(sidebarOptions).length === 0 )){
            sidebarOptions = [
                {
                    icon: "", title: "No data available", data: {}
                }
            ]
        }
        return sidebarOptions.map((data, index) => {
            return (
                <div className="panel panel-default" key={index}>
                    <div className="panel-heading " role="tab" >
                        <div onClick={(e)=>{this.props.setSelectedTab(data.title,data)}} className={`panel-title ${this.props.selectedTab === data.title ? "active" : null}`}>
                            <a  role="button" >
                                <span><i className={`${data.icon} mr-1`}></i>{data.title}</span>
                            </a>
                        </div>
                    </div>
                </div>
            )
        });
    }
    render() {
        return (
            <aside className="side-nav">
                <div className="widget-title">
                    <div className="title-img"><span>{this.props.title}</span></div>
                </div>
                <div className="panel-group accordionMenu" id="side-nav-accordionMenu" role="tablist" aria-multiselectable="true">
                    {this.renderOptions()}
                </div>

            </aside>
        );
    }
}

export default Sidebar;