import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { PriceFormatter } from '../../helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectRecommendation } from '../../actions';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { getTotalFocusAlertValue, getFocusAltertEstimatedValue } from './FocusAlertFunctions';

class RecommendationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    callRecommendationModal(row, strategyClicked, asin, productId, location, channel, actionTakenInfo, kcPlatform) {
        if (strategyClicked.substring(0, 3) !== "<ul") {
            this.props.displayModalOn(strategyClicked, asin, productId, location, channel, actionTakenInfo, kcPlatform);
            this.props.selectRecommendationAPI({ row });
        }


    }

    minusSignFormatter() {
        return (
            <i className="fas fa-minus-circle"></i>
        )
    }
    skuDetailFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <div className="flex">
                <div className="mr-3">
                    {row.imageUrl !== null && row.imageUrl !== "0" ?
                        <a className="sku" target="_blank" rel="noopener noreferrer" href={row.productUrl}>
                            <img src={row.imageUrl} className="image-fluid" height="70" width="75" />
                        </a>
                        :

                        <img src={process.env.PUBLIC_URL + '/img/kc_logo.jpg'} className="image-fluid" height="70" width="75" />
                    }
                </div>
                <div>
                    <p> {row.title} </p>
                    <p>UPC : {row.asin}</p>
                    <p>ID : {row.productId}</p>
                  {row.tradeItem ? <p>Trade Item : {row.tradeItem}</p> : null}  


                </div>
            </div>
        )
    }
    upcFormatter(row, cell) {
        return (
            <div className="sku">{Number(cell.asin)}</div>
        )
    }
    channelFormatter(row, cell) {
        return (
            <div className="channel_td">{cell.channel}</div>
        )
    }
    estimatedRevenueFormatter(cell, row, rowIndex, formatExtraData) {

        return (
            <div style={{ textAlign: "right", paddingRight: "15px" }}>
                <strong> {PriceFormatter(row.estimatedRevenueImpact,{ precision : 0 })}</strong>
            </div>
        )
    }
    focusedEstimatedRevenueFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <div style={{ textAlign: "right", paddingRight: "5px" }}>
                <strong> {PriceFormatter(getFocusAltertEstimatedValue(formatExtraData.recommendationFilters,row,formatExtraData.priorityObj),{ precision : 0 })}</strong>
            </div>
        )
    }
    recommendationFormatter(cell, row, rowIndex, formatExtraData) {
        function renderTooltip(props) {
            if (props==="No Data Present") {
                return (
                    <Tooltip id="button-tooltip" {...props}>
                        No Data Present
                    </Tooltip>
                );
            }
            return (
                <Tooltip id="button-tooltip" {...props}>
                    {PriceFormatter(props,{ precision : 0 })}
                </Tooltip>
            );
        }
        let { actionTakenInfo } = row;
        let map = {
            "Open": {
                color: "#fff",
                background: "#9C9A9A",
                border: "#9C9A9A"
            },
            "Action Taken": {
                color: "#fff",
                background: "#0056BB",
                border: "#0056BB"
            },
            "Approve": {
                color: "#fff",
                background: "#F3AC00",
                border: "#F3AC00"
            },
            "Remove": {
                color: "#303030",
                background: "#F5F5F5",
                border: "#F5F5F5"
            },
            "None": {
                color: "#fff",
                background: "#777",
                border: "#777"
            }
        }
        let recommendations = [];
        if(formatExtraData.recommendationFilters.length>0){
            row.recommendationInfo.forEach(itemObj =>{
                if(formatExtraData.recommendationFilters.includes(itemObj.recommendationName))
                    recommendations.push(itemObj);
            })
            row.recommendationInfo.forEach(itemObj =>{
                if(! formatExtraData.recommendationFilters.includes(itemObj.recommendationName))
                    recommendations.push(itemObj);
            })
        }
        else{
            recommendations = row.recommendationInfo;
        }
        return (
            <ul className="recommendations-type" onClick={(e) => {

            }} style={{ width: "fit-content" }}>
                {recommendations.map((itemObj, index) => {
                    let styleObj = { background: '#fff', color: map["None"], border: `1px solid ${map["None"]}` };
                    if (actionTakenInfo && actionTakenInfo.hasOwnProperty(itemObj.recommendationName)) {
                        let action = map[actionTakenInfo[itemObj.recommendationName].action];
                        let color = action.color;
                        let background = action.background;
                        let border = action.border;
                        styleObj =  { background: background, color: color, border: `1px solid ${border}` }
                    }
                    if(formatExtraData && formatExtraData.recommendationFilters && 
                        formatExtraData.recommendationFilters.length>0 && 
                        ! formatExtraData.recommendationFilters.includes(itemObj.recommendationName)){
                            styleObj["opacity"]="0.2";
                    }
                    return (
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip(itemObj.estimatedValue)}
                        >
                            < li style={styleObj} key={index} onClick={()=> {localStorage.setItem("selected-estimated-value",itemObj.estimatedValue)}}>{itemObj.recommendationName}</li>
                        </OverlayTrigger>
                    ) 
                })}
            </ul>
        )
    }
    estimatedRevenueHeaderFormatter(column,colIndex,component){
        return(
            <div style={{display:"Flex",textAlign:"center"}}>
                <div>{column.text}</div>
                <div style={{cursor:"pointer"}}>
                    <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="comments-tooltip">
                                <div style={{maxWidth:"370px"}}>The weighted estimated value of all recommendations for the concerned product SKU</div>
                            </Tooltip>
                        }
                    >
                        <i
                        class="fas fa-info-circle"
                        style={{
                            color: "#58595B",
                            cursor: "pointer",
                        }}
                        ></i>
                    </OverlayTrigger>
                </div>
            </div>
        )
    }
    focusAlertEstimationColumnHeaderFormatter(column,colIndex,component){
        return(
            <div style={{display:"Flex",textAlign:"center"}}>
                <div>{column.text}</div>
                <div style={{cursor:"pointer"}}>
                    <div onClick={(event) => {
                        event.stopPropagation();
                    }}>
                    <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="comments-tooltip">
                                <div style={{maxWidth:"370px"}}>The weighted estimated value of only filtered recommendations for the concerned product SKU</div>
                            </Tooltip>
                        }
                    >
                        <i
                        class="fas fa-info-circle"
                        style={{
                            color: "#58595B",
                            cursor: "pointer",
                            paddingLeft: "5px" 
                        }}
                        ></i>
                    </OverlayTrigger></div>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="ml-2 bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
            </div>
        )
    }
    render() {
        const { SearchBar } = Search;
        const columns = [
            {
                text: 'Product',
                dataField: 'title',
                formatter: this.skuDetailFormatter,
            },
            {
                text: "Channel",
                dataField: 'channel',
                formatter: this.channelFormatter,
            },
            {
                text: "Location",
                dataField: 'fcNameId'
            },
            {
                text: "Action",
                dataField: 'actionTakenInfo',
                hidden: true
            },
            {
                text: "UPC",
                dataField: 'asin',
                hidden: true
            },
            {
                text: "ID",
                dataField: 'productId',
                hidden: true
            },
            {
                text: "Trade Item",
                dataField: 'tradeItem',
                hidden: true
            },
            {
                formatter: this.focusedEstimatedRevenueFormatter,
                headerFormatter: this.focusAlertEstimationColumnHeaderFormatter,
                text: "Focus Alert Estimated Value",
                hidden: this.props.recommendationFilters.length>0 ? false : true,
                formatExtraData: {
                    "recommendationFilters": this.props.recommendationFilters,
                    "priorityObj": this.props.priorities
                },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let focusAlertValueOfA = getFocusAltertEstimatedValue(this.props.recommendationFilters,rowA,this.props.priorities);
                    let focusAlertValueOfB = getFocusAltertEstimatedValue(this.props.recommendationFilters,rowB,this.props.priorities);
                    if (order === 'asc') {
                        return focusAlertValueOfB - focusAlertValueOfA;
                    }
                    return focusAlertValueOfA - focusAlertValueOfB; // desc
                }
            },
            {
                formatter: this.estimatedRevenueFormatter,
                headerFormatter: this.estimatedRevenueHeaderFormatter,
                dataField: 'estimatedRevenueImpact',
                hidden: this.props.recommendationFilters.length>0 ? true : false,
                text: 'Total Estimated Value',
                headerStyle:this.props.recommendationFilters.length>0 ? {textAlign:"center",width:"13%"} : {},
                formatExtraData: {
                    "recommendationFilters": this.props.recommendationFilters
                },
            },
             {
                formatter: this.recommendationFormatter,
                dataField: 'recommendationTypes',
                text: 'Recommendations',
                formatExtraData: {
                    "recommendationFilters": this.props.recommendationFilters
                },
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.callRecommendationModal(row, e.target.innerHTML, row.asin, row.productId, row.location, row.channel, row.actionTakenInfo, row.kcPlatform)
                    },
                }
            }];
        const defaultSorted = [{
            dataField: 'estimatedRevenueImpact',
            order: 'desc'
        }];

        return (
            <div className="table-responsive recommendations-table ">
                <ToolkitProvider
                    keyField="id"
                    data={this.props.data}
                    columns={columns}
                    search

                >

                    {
                        props => (
                            <div>

                                <div className="flex justify-content-between">
                                    <h4>Showing {this.props.data.length} SKUs</h4>
                                    {this.props.recommendationFilters.length>0 ?
                                    <div style={{display : "Flex"}}>
                                        <div>
                                            <h4><strong>Focus Alert:</strong> <font color="#0056BB">The Selected Focus Alerts</font> valued at <strong>{PriceFormatter(getTotalFocusAlertValue(this.props.recommendationFilters,this.props.priorities,this.props.data),{ precision : 0 })}</strong> </h4>
                                        </div>
                                        <div style={{marginLeft: "5px", marginTop: "-2px"}}>
                                    <OverlayTrigger
                                            placement="bottom"
                                            pointerEvents= "none"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id="comments-tooltip">
                                                    <div style={{maxWidth:"370px"}}>Total value which can be generated if actioned on all SKUs for the filtered recommendations</div>
                                                </Tooltip>
                                            }
                                        >
                                            <i
                                            class="fas fa-info-circle"
                                            style={{
                                                color: "#58595B",
                                                cursor: "pointer",
                                            }}
                                            ></i>
                                        </OverlayTrigger></div></div>
                                    : <></>}
                                    <SearchBar {...props.searchProps} />
                                </div>

                                <BootstrapTable
                                    pagination={paginationFactory()}
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectRecommendationAPI: selectRecommendation
    }, dispatch)
}

export default connect(null, mapDispatchToProps)(RecommendationTable);