import React, { useEffect, useState,useRef} from "react";
import TakeActionModal from "../../components/TakeActionModal";
import {
  selectRecommendation,
  storeIsFetching,
  recommendationTableSelectedRows,
  recommendationTableSelectAllProductList,
  storeTableAllDataLoaded,
  updateSkuCountData,
  getAlertsActionReasonAndSnooze,
  getDynamicsColumnData,
  storeSelectedStrategies,
  storeActionTakenInfo,
  storeActionTakenInfoForProductTable,
  updateActionTakenInfoNew,
  updateDynamicColumnTableContent
} from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";
import _isEqual from "lodash/isEqual";
import "./new-recommendation.css";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import NewRecommendationModal from "./NewRecommendationModal";
import { PriceFormatter,dynamicRetailerPriceFormat, addCommonActivityCaptureDetail } from "../../helper";
import cloneDeep from "lodash/cloneDeep";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import $ from "jquery";
import AlertLogModal from "../../components/AlertLogModal";
import { DropDownIcon, CloseIcon } from "../../components/Icons";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";

const ProductTableDCModal = (props) => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tableWidth, setTableWidth] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [activeRowForModel, setActiveRowForModel] = useState({});
  const [activeStrategy, setActiveStrategy] = useState(null);
  const [showAllStrategy, setShowAllStrategy] = useState(true);
  const [isProductLevel, setIsProductLevel] = useState(false);
  let [displayAlertLogModal, setDisplayAlertLogModal] = useState(false);
  const [alertLogModalDetails, setAlertLogModalDetails] = useState({});
  let [displayActionModal, setDisplayActionModal] = useState(false);
  let productlocationDetails = props.productlocationDetails;
  const [tempSelectedRows, setTempSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [tempSelectAll, setTempSelectAll] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  let [ deSelectedIndexes, setDeSelectedIndexes] = useState([]); 

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  let { selectedGlobalFilters,
    project,
    subproject, 
    isSnoozeSubProject,
    selectedRetailerCurrency, selectedCustomerKey, dynamicColumnPrecision, dynamicPriceFormatting, defaultAlertFilterCondition} = useSelector((state) => state.globalReducer);
  
  let {
    recommendationSelectedStrategy,
    selectedStrategies,
    detailsForAllStrategies,
    productLocationData,
    setOfDynamicDCColumns,
    curentIndexDC,
    sortDirecetionDC,
    sortedColumnDC,
    allLocationIds,
    allSkuIdsDC,
    totalCountDC,
    selectedStrategyDateRange,
    strategyCustomDates,
    selectedFilterCondition,
  } = useSelector((state) => {
    return state.strategyReducer
  });
  

  let allStrategyDetails = useSelector(
    (state) => state.adoptionReducer
  );
  let { actionedAndActiveData } = useSelector(
    (state) => state.recommendationsReducer
  );
  let {
    loadStatus,
    selectedRows,
    sortDirecetion,
    sortedColumn,
    selectAllProductList,
    allSkuIds,
    totalCount,
    newRecoSearchText,
    isExpandTableButtonClicked,
    isLoadedAll,
    isFetchingData,
    selectedSkuIds
  } = useSelector((state) => state.newRecommendationTableData);

  
  let requestObject = {
    filterValues: selectedGlobalFilters,
    recommendationFilters: selectedStrategies,
    currentIndex: 0,
    selectedTab: recommendationSelectedStrategy ? recommendationSelectedStrategy.selection : 'active',
    searchText: newRecoSearchText,
    sortDirecetion: "desc",
    sortedColumn: isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" ? ["focusAlertEstimation", "estimatedRevenueImpact", "daysSinceLastActioned"] : ["focusAlertEstimation", "estimatedRevenueImpact"],
    filterName: "",
    defaultType: "DC/Cluster"
  };

  let snoozeActiveColumnValueKey =
    [
      { displayName: 'Actioned By', columnKey: 'actionedBy', sort: false },
      { displayName: 'Last Actioned Date', columnKey: 'lastActionedDate', sort: true },
      { displayName: 'Days since Last Actioned', columnKey: 'daysSinceLastActioned', sort: true },
      { displayName: 'Snooze Days Left', columnKey: 'snoozeDaysLeft', sort: false },
    ]

  const [isSticky, setSticky] = useState(false);
  const tableref = useRef(null);
    const handleScrollDC = () => {
      if (tableref.current) {
        setSticky(tableref.current.getBoundingClientRect().top <= 240);

      }
    };

    useEffect(() => {
    window.addEventListener('scroll', handleScrollDC);

    return () => {
      window.removeEventListener('scroll', () => handleScrollDC);
    };
  }, []);

  useEffect(() => {
    isSnoozeSubProject && selectedStrategies && selectedStrategies.length && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "active" && dispatch(getAlertsActionReasonAndSnooze({ alertName: selectedStrategies.toString() }))
  }, [selectedStrategies]);



  useEffect(() => {
    let width = $('.new-recomm-container').width()
    if (width !== tableWidth) {
      if (setOfDynamicDCColumns && setOfDynamicDCColumns?.length < 4)
        width = width + 30;
      setTableWidth(width)
    }
  })

  useEffect(() => {
    // setLoading(true);
    // dispatch(updateSkuCountData({ currentCount: 0, totalCount: 0 }))
    dispatch(recommendationTableSelectedRows({}));
    dispatch(recommendationTableSelectAllProductList([]))
  }, [
    selectedStrategies,
    selectedGlobalFilters,
    newRecoSearchText,
  ]);


  let displayLoader = () => {
    return (
      <div id="strategy_wrapper">
        <div className="container-fluid  default-top loading_wrapper  bg-gray p-5">
          <div className="sweet-loading">
            <BarLoader
              css={override}
              size={150}
              color={"#123abc"}
              height={4}
              width={100}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  };

  let handleFilterStrategyClick = (recommendationFilters) => {
    dispatch(storeSelectedStrategies(recommendationFilters));
  };

  function handleStrategyClick(store, strategyClicked) {
    setActiveRowForModel(store);
    setActiveStrategy(strategyClicked);
    dispatch(selectRecommendation(store));
    setDisplayModal(true);
  }

  let dynamicDataResHandler =  (data) => {
    if(data.data) {
      if(Object.keys(data.data.responseData).length > 0 ) {
        
         let tempResponseData = cloneDeep(productLocationData);
         data.data.responseData.productList.map(item=>{
            let productKey = Object.keys(item);
            tempResponseData.productList[0][productKey].storeList = [
              ...tempResponseData.productList[0][productKey].storeList,  
              ...data.data.responseData.productList[0][productKey].storeList
            ];
            tempResponseData.productList[0][productKey].storeList = tempResponseData.productList[0][productKey].storeList.map((store,index)=> {
              if (tempSelectAll) {
                store['locationSelected'] = selectedIndexes.indexOf(index) > -1 ? false : true;
              }
              return store;
            })
            return item;
          });
          tempResponseData.currentIndex = data.data.responseData.currentIndex;
          dispatch(updateDynamicColumnTableContent({...tempResponseData, currentIndex:data.data.responseData.currentIndex}));
      }
      else{
        dispatch(storeTableAllDataLoaded(true)) 
      }
    }

    dispatch(storeIsFetching(false))  
  }

  let handleScrollForTable = (e) => {
    const bottom  = e.target.scrollHeight - e.target.scrollTop < (e.target.clientHeight+20);  
    if(bottom && curentIndexDC <= totalCountDC){
      if(!isFetchingData && !isLoadedAll){
        dispatch(storeIsFetching(true))
        let alertType = "";
        if(selectedStrategies && selectedStrategies?.length == 1) {
          let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
          alertType = filterArr[0]["DISPLAY_TYPE"];
        } else {
          let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
          alertType = filterArr[0]["DEFAULT_TYPE"];
        }
        let requestObject= {
          filterValues: selectedGlobalFilters,
          recommendationFilters: selectedStrategies,
          currentIndex: curentIndexDC,
          selectedTab: recommendationSelectedStrategy.selection,
          searchText: newRecoSearchText,
          sortDirecetion: sortDirecetionDC,
          sortedColumn: sortedColumnDC,
          filterName: "",
          alertInFocus:recommendationSelectedStrategy.strategyName,
          dcId:productlocationDetails.fcNameId,
          dcIdCount: productlocationDetails.dcIdCount,
          productId: productlocationDetails.productId,
          type:alertType,
          selectedDateRange:selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
          alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition, 
        };
        dispatch(
          getDynamicsColumnData(requestObject, dynamicDataResHandler)
        );
      }
    }
  }

  function displayModalOff() {
    if (displayModal) {
      setDisplayModal(false);
    }
  }
  function setProductCheckBox(store, type, key) {
    if (selectedRows.constructor === Object) {
      if (type === 'selectAll') {
        if (selectedRows[key] && Object.keys(selectedRows[key]).length === store.length) {
          return true;
        }
        return false;

      }
      if (Object.keys(selectedRows)?.length && selectedRows[store.productId] && selectedRows[store.productId][store.fcNameId]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function getEstimatedValue(data) {
    let final = {};
    selectedStrategies.map(strategy => {
      data
        .filter((item) => {
          return (
            item.recommendationName.toLowerCase() ===
            strategy.toLowerCase()
          );
        })
        .map((obj) => {
          final[strategy] = obj.estimatedValue;
        });
    })
    return final;
  }

  
  function handleCheckBoxModalChange(event, store, type, key, storeListLength, itemIndex) {
    let tempSelectedRows = cloneDeep(productLocationData.productList[0][productlocationDetails.productId]["storeList"]);
    let tempSelectAllProductList = [...selectAllProductList]
    if (event.target.checked) {
      let productId = ''
      if (type === 'selectAll') {
          let rows = {};
          setSelectAll(true);
          setTempSelectAll(true);
          if (store.length > 0) {
             store.map((storeItem, index) => {
              let data = {
                  location: storeItem.fcNameId,
                  product_id: storeItem.productId,
                  upc: storeItem.asin,
                  oldInfo: storeItem.actionTakenInfo,
                  dateOfRecommendation: storeItem.dateOfRecommendation,
                  estimatedVal: getEstimatedValue(storeItem.recommendationInfo),
              };
              if (index === 0) {
                  productId = storeItem.productId
              }
              storeItem["locationSelected"] = true;
              rows[storeItem.fcNameId] = data;
            })
          }
          setDeSelectedIndexes([]);
          tempSelectedRows[productId] = rows;
          tempSelectAllProductList.push(productId);
      } else {
        let data = {
          location: store.fcNameId,
          product_id: store.productId,
          upc: store.asin,
          oldInfo: store.actionTakenInfo,
          dateOfRecommendation: store.dateOfRecommendation,
          estimatedVal: getEstimatedValue(store.recommendationInfo),
        };
        store["locationSelected"] = true;
        const rmIndex = selectedIndexes.indexOf(itemIndex);
        if (rmIndex > -1) {
          selectedIndexes.splice(rmIndex, 1); 
        }
        setSelectedIndexes(selectedIndexes);
        
        var index = deSelectedIndexes.indexOf(store.fcNameId);
        if (index !== -1) {
          deSelectedIndexes.splice(index, 1);
        }
        setDeSelectedIndexes(deSelectedIndexes);

        if (!tempSelectedRows[store.productId]) {
          tempSelectedRows[store.productId] = {}
          tempSelectedRows[store.productId][store.fcNameId] = data;
        }
        else {
          tempSelectedRows[store.productId][store.fcNameId] = data;
        }

        if (tempSelectedRows[store.productId] && Object.keys(tempSelectedRows[store.productId]).length === storeListLength) {
          tempSelectAllProductList.push(store.productId)
        }

        let selectedLen = productLocationData.productList[0][productlocationDetails.productId]["storeList"]
                .filter((storeSelItem)=>{
                      if(storeSelItem['locationSelected']) {
                          return storeSelItem;
                      }
                });

        if( selectedLen.length == productLocationData.productList[0][productlocationDetails.productId]["storeList"].length) {
            setSelectAll(true);
        }

      }
      setTempSelectedRows(tempSelectedRows);
    } 
      else {
        if (type === 'selectAll') {
            setSelectAll(false);
            let productList = productLocationData.productList.map((product,index)=> {
              let productKey = Object.keys(product);
              product[productKey]['storeList'].map(store => {
                store['locationSelected'] = false
                return store;
              })
              return productLocationData.productList[0];
            });
            productLocationData.productList = productList; 
                        
            dispatch({
              type: "DYNAMIC_COLUMNS_DCDATA",
              payload: productLocationData
            });
        }
         else {           
            setSelectAll(false);
            setSelectedIndexes(selectedIndexes => [...selectedIndexes, itemIndex])
            let productList = productLocationData.productList.map((product,index)=> {
              let productKey = Object.keys(product);
              product[productKey]['storeList'].map(storeObj => {
                if(storeObj.fcNameId == store.fcNameId) {
                  store['locationSelected'] = false;
                  deSelectedIndexes.push(store.fcNameId);
                  setDeSelectedIndexes(deSelectedIndexes);
                }
                return store;
              })
              return productLocationData.productList[0];
            });
            productLocationData.productList = productList; 
                       
            dispatch({
              type: "DYNAMIC_COLUMNS_DCDATA",
              payload: productLocationData
            });
        }
     }
  }

  function storeSelectedSkuId(skus) {
    document.cookie = `selected-sku-id=${JSON.stringify(skus)};path=/;max-age=3600`
  }

  function reorderColumnData(columnName) {
    let alertType = "";
    if(selectedStrategies?.length){
      if(selectedStrategies && selectedStrategies?.length == 1) {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DISPLAY_TYPE"];
      } else {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DEFAULT_TYPE"];
      }
    }
    let direction = 'asc';
    if (sortedColumnDC && (sortedColumnDC.indexOf(columnName) > -1)) {
      if (sortDirecetionDC === 'asc') {
        direction = 'desc'
      }
      else if (sortDirecetionDC === 'desc') {
        direction = 'asc'
      }

    }
    let requestObject = {
      filterValues: selectedGlobalFilters,
      recommendationFilters: selectedStrategies,
      currentIndex: 0,
      selectedTab: recommendationSelectedStrategy ? recommendationSelectedStrategy.selection : 'active',
      searchText: newRecoSearchText,
      sortDirecetion: direction,
      sortedColumn: [columnName],
      filterName: "",
      dcId:productlocationDetails.fcNameId,
      dcIdCount: productlocationDetails.dcIdCount,
      productId: productlocationDetails.productId,
      alertInFocus: recommendationSelectedStrategy.strategyName,
      type: alertType,
      selectedDateRange:selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
      alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition,
    };
    dispatch(
      getDynamicsColumnData(requestObject,(res) => { })
    )
      .then((data) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });


  }
  function renderSortDirection(columnName) {
    if (sortedColumnDC && (sortedColumnDC.indexOf(columnName) > -1)) {

      if (sortDirecetionDC === 'asc') {
        return (<i className="fas fa-sort-up  default-sort-icon active"></i>)
      }
      else if (sortDirecetionDC === 'desc') {
        return (<i className="fas fa-sort-down  default-sort-icon active"></i>)
      }

    }
    else {
      return (<i className="fas fa-sort default-sort-icon"></i>)
    }

  }
  function renderStrategiesModelWindow() {
    if (displayModal) {
      let user_info = localStorage.getItem("user-info");
      let reportId = "";
      detailsForAllStrategies.map((strategy, index) => {
        if (
          strategy.strategyName.toLowerCase() ===
          activeStrategy.recommendationName.toLowerCase()
        ) {
          reportId = strategy.strategyReportId;
        }
      });
      return (
        <NewRecommendationModal
          asin={activeRowForModel["asin"]}
          recommendationName={activeStrategy["recommendationName"]}
          chosenProductId={activeRowForModel["productId"]}
          chosenLocation={activeRowForModel["location"]}
          chosenSource={activeRowForModel.channel}
          reportId={reportId}
          chosenKcCategory={activeRowForModel["kcPlatform"]}
          chosenCustomerKey={selectedCustomerKey}
          user={JSON.parse(user_info).email}
          displayModalOff={displayModalOff}
          listOfStrategiesInfo={activeRowForModel["recommendationInfo"]}
          activeRowForModel={activeRowForModel}
        />
      );
    }
  }

  function renderTooltip(dataToDisplay) {
    return (
      <Tooltip id="info-tooltip">{PriceFormatter(dataToDisplay, { precision: 0, symbol: selectedRetailerCurrency })}</Tooltip>
    );
  }
  function renderStrategies(recommendationInfo, store) {
    if (recommendationInfo.length > 0) {
      return recommendationInfo.map((strategy, index) => {
        let actionTakenClass = "recomm-name";
        if (
          store.actionFlagInfo &&
          store.actionFlagInfo.indexOf(strategy.recommendationName) > -1
        ) {
          actionTakenClass = "recomm-name2";
        }
        if (!showAllStrategy && index === 0) {
          return (
            <OverlayTrigger
              placement="right"
              overlay={
                renderTooltip(strategy.estimatedValue)
              }
            >
              <div
                className={actionTakenClass}
                onClick={(e) => handleStrategyClick(store, strategy)}
              >
                {strategy.recommendationName}
              </div>
            </OverlayTrigger>
          );
        } else if (showAllStrategy) {
          return (
            <OverlayTrigger
              placement="right"
              overlay={
                renderTooltip(strategy.estimatedValue)
              }
            ><div
              className={actionTakenClass}
              onClick={(e) => handleStrategyClick(store, strategy)}
            >
                {strategy.recommendationName}
              </div></OverlayTrigger>

          );
        }
      });
    }
  }
  function renderStrategiesContainer(recommendationInfo, store) {
    if (showAllStrategy) {
      return (
        <div className='recomm-col strategies expanded'>
          {renderStrategies(store.recommendationInfo, store)}
        </div>
      )
    }

  }
  function handleStrategyColumnClick() {
    if (showAllStrategy) {
      setShowAllStrategy(false)
    }
    else {
      setShowAllStrategy(true)
    }
  }
  function renderDynamicColumnValues(store) {
    if (setOfDynamicDCColumns && setOfDynamicDCColumns.length > 0) {
      return setOfDynamicDCColumns.map((col, index) => {
        let tempVal = store[col.Metric_Display_Value];
        let tempType = (col.Type).toLowerCase();
        let alertName = col.Strategy.toLowerCase().replace(/ /g, "_");
        let metricName = col.Metric_name.toLowerCase().trim();
        if ((dynamicPriceFormatting && dynamicPriceFormatting === 'dot') && tempVal) {
          if (tempType === 'dollar') {
            tempVal = dynamicRetailerPriceFormat(tempVal, selectedRetailerCurrency);
          }
          else if (tempType === 'double') {
            tempVal = PriceFormatter((tempVal), { precision: 2, symbol: '' }).replace(/(\.|,)/g, (x) => {
              return x == ',' ? '.' : x == '.' ? ',' : '';
            });
          }
          else if (tempType === 'percentage') {
            tempVal = PriceFormatter((tempVal), { precision: 2, symbol: '%', format: '%v %s' }).replace(/(\.|,)/g, (x) => {
              return x == ',' ? '.' : x == '.' ? ',' : '';
            })
          }
        } else if (dynamicColumnPrecision && dynamicColumnPrecision[alertName] && (dynamicColumnPrecision[alertName][metricName] || dynamicColumnPrecision[alertName][metricName] === 0)) {
          if (tempType === 'dollar') {
            tempVal = PriceFormatter(tempVal, { precision: dynamicColumnPrecision[alertName][metricName], symbol: selectedRetailerCurrency })
          }
          else if (tempType === 'percentage') {
            tempVal = PriceFormatter((tempVal), { precision: dynamicColumnPrecision[alertName][metricName], symbol: '%', format: '%v %s' })
          }
          else {
            tempVal = PriceFormatter((tempVal), { precision: dynamicColumnPrecision[alertName][metricName], symbol: '' })
          }
        } else {
          if (tempType === 'dollar') {
            tempVal = PriceFormatter(tempVal, { precision: 0, symbol: selectedRetailerCurrency })
          }
          else if (tempType === 'double') {
            tempVal = PriceFormatter(tempVal, { precision: 2, symbol: '' })
          }
          else if (tempType === 'percentage') {
            tempVal = PriceFormatter((tempVal), { precision: 1, symbol: '%', format: '%v %s' })
          }
        }
        return (
          <div className="recomm-col txt-right-aligned">
            {tempVal}
          </div>
        )

      })
    }
  }

  function renderActiveSnoozeColumnValues(store) {
    return snoozeActiveColumnValueKey.map((col, index) => {
      return (
        <div className="recomm-col txt-right-aligned">
          {store[col.columnKey]}
        </div>
      )
    })

  }
  function renderSnoozeCommonColumnValue(store, productDetails, prodinformation) {
    return (
      <>
        <div className={`recomm-col align-items-center ${store["previouslyActioned"].toLowerCase() === "yes" ? "previously-actioned" : "disable-click"}`}
          onClick={(e) => {
            let finalProductDetails = { ...productDetails }
            finalProductDetails["location"] = store.fcNameId
            setAlertLogModalDetails({...prodinformation,...finalProductDetails})
            setIsProductLevel(false)
            setDisplayAlertLogModal(true)
          }}>
          {store["previouslyActioned"]}
        </div>

       <div className="recomm-col txt-right-aligned" >
          {store["alertTriggerDate"]}
        </div>
        {recommendationSelectedStrategy && recommendationSelectedStrategy.activeRowDetails ? (<>
          <div className="recomm-col txt-right-aligned" >
            {store["daysSinceAlertTrigger"]}
          </div> </>) : null}
      </>
    )

  }
  function downloadPriceDifferenceDetails(productId, selectedCustomerKey, selectedGlobalFilters) {
    let reqObj = {
      projectName: project,
      subprojectName: subproject,
      productId: productId,
      customerKey: selectedCustomerKey,
      filterValues: selectedGlobalFilters
    }
    let bufferValue = new Buffer(JSON.stringify(reqObj));
    let base64data = bufferValue.toString("base64");
    window.open(
      `${process.env.REACT_APP_ROOT_URL}/download/exportPriceDifferenceDetails/${base64data}`,
      "_blank"
    );
  }
  function renderStoreList(storeList, productDetails,prodinformation) {
    if (storeList.length > 0) {
      // setStoreListData(storeList);
      return storeList.map((store, index) => {
        let tempClassForLocationModalRow = "locations";
        if (setProductCheckBox(store)) {
          tempClassForLocationModalRow = "locations active";
        }
        return (
          <div className={tempClassForLocationModalRow}>
            <div className="recomm-col location_block">
              <div className="form-check form-check-custom">
                <input
                  className="form-check-input custom"
                  type="checkbox" disabled={!selectedStrategies?.length}
                  checked={store.locationSelected}
                  onChange={(event) => handleCheckBoxModalChange(event, store, 'single', "", storeList.length, index)}
                />
                <label className="form-check-label" for="all">
                  {store.fcNameId}
                </label>
              </div>
            </div>
            { recommendationSelectedStrategy?.activeRowDetails?.DISPLAY_TYPE === "DC/Cluster" &&
            <div className="recomm-col txt-right-aligned"> {store.dcId} </div>}
            {recommendationSelectedStrategy && recommendationSelectedStrategy.activeRowDetails ?
              <div className="recomm-col txt-right-aligned">
                {(dynamicPriceFormatting && dynamicPriceFormatting === 'dot') && store.focusAlertEstimation ?
                  dynamicRetailerPriceFormat(store.focusAlertEstimation, selectedRetailerCurrency) :
                  PriceFormatter(store.focusAlertEstimation, { precision: subproject == 'Tesco-UK' ? 2 : 0, symbol: selectedRetailerCurrency })}
              </div> : null}
            {isSnoozeSubProject && renderSnoozeCommonColumnValue(store, productDetails,prodinformation)}
            {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && renderActiveSnoozeColumnValues(store)}
            {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && <div className="recomm-col align-items-center 3 col-reason text-break" >
              {store["reason"]}
            </div>}
            <div className="recomm-col txt-right-aligned">{(dynamicPriceFormatting && dynamicPriceFormatting === 'dot') && store.estimatedRevenueImpact ?
              dynamicRetailerPriceFormat(store.estimatedRevenueImpact, selectedRetailerCurrency) :
              PriceFormatter(store.estimatedRevenueImpact, { precision: subproject == 'Tesco-UK' ? 2 : 0, symbol: selectedRetailerCurrency })}</div>
            {renderStrategiesContainer(store.recommendationInfo, store)}
            {renderDynamicColumnValues(store)}
            {recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === "Retailer Price Difference" &&
              <div className="recomm-col txt-right-aligned" onClick={() => { downloadPriceDifferenceDetails(store?.productId, selectedCustomerKey, selectedGlobalFilters) }} style={{color:"#1563ff",cursor: "pointer"}}>Download report </div>
            }
          </div>
        );
      });
    }
  }
  function renderStrategyArrow() {
    if (showAllStrategy) {
      return (<i className="fas fa-angle-left  default-sort-icon active"></i>)
    }
    else {
      return (<i className="fas fa-angle-right  default-sort-icon "></i>)
    }
  }
  function renderProductLinks(productDetails) {
    if (productDetails.links.length > 0) {
      return productDetails.links.map((item) => {
        return (
          <li>
            <a href={item.hrefLink} target="_blank">
              {item.text}
            </a>
          </li>
        );
      });
    }
  }
  function renderEmptyColumns() {
    if (setOfDynamicDCColumns && setOfDynamicDCColumns.length > 0) {
      return setOfDynamicDCColumns.map((col, index) => {
        return (<div className="recomm-col"></div>)
      })
    }
  }
  function renderFetchingMoreItems() {
    if (isFetchingData && !isLoadedAll) {
      return (<div className='fetchingMoreItem' style={{margin:"10px 0px"}}>
        <img src={require('./loadmore.gif')} className='fetechMoreImg' />
        Fetching more product locations...
      </div>)
    }
  }
  let renderEmptySnoozeCols = () => {
    return snoozeActiveColumnValueKey.map((col, index) => {
      return (<div className="recomm-col"></div>)
    })
  }

  function renderRecommmendationTableRows() {
    if (loadStatus && loadStatus === "loaded") {
     
      return productLocationData && productLocationData['productList'].map((product) => {

        return Object.keys(product).map((key, index) => {
          
          return (
            <div style={{ height: "420px",overflow:'auto',zIndex:10 }} className={`recomm-row  ${setOfDynamicDCColumns?.length === 0 && "recomm-dynamiccol"}`}>
              <div style={{ marginLeft: 0, }} className={setOfDynamicDCColumns && setOfDynamicDCColumns.length === 0 ? "recomm-locations recomm-dynamiccol overflowm" : "recomm-locations"}>
                <div className="locations">
                  <div className="recomm-col location_block">
                    <div className="form-check form-check-custom">
                      <input
                        className="form-check-input custom"
                        type="checkbox" disabled={!selectedStrategies?.length}
                        checked={selectAll}
                        onChange={(event) => handleCheckBoxModalChange(event, product[key]['storeList'], 'selectAll', key, index)}
                      />
                      <label className="form-check-label" for="all">
                        Select All {totalCountDC} Locations
                      </label>
                    </div>
                  </div>
                  {recommendationSelectedStrategy?.activeRowDetails?.DISPLAY_TYPE === "DC/Cluster" && <div className="recomm-col"></div> }
                  {recommendationSelectedStrategy && recommendationSelectedStrategy.activeRowDetails &&
                    <div className="recomm-col"></div>}

                  {isSnoozeSubProject &&
                    <div className={`recomm-col align-items-center ${product[key]["productdetails"].previouslyActioned?.toLowerCase() === "yes" ? "previously-actioned" : "disable-click"}`}
                      onClick={(e) => {
                        setIsProductLevel(true)
                        setAlertLogModalDetails(product[key]["productdetails"])
                        setDisplayAlertLogModal(true)

                      }}>
                      {product[key]["productdetails"].previouslyActioned} </div>
                  }
                  <div className="recomm-col"></div>
                  <div className="recomm-col"></div>
                  {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && renderEmptySnoozeCols()}
                  {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && <div className="recomm-col col-reason"></div>}
                  {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.activeRowDetails && <div className="recomm-col"></div>}
                  {renderRecommmendationEmptyCol()}
                  {renderEmptyColumns()}
                  {recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === "Retailer Price Difference" &&<div className="recomm-col"></div>}
                </div>
                {renderStoreList(product[key]["storeList"], product,product[key]["productdetails"])}
              </div>
              
              {/* Recomm stores end*/}
            </div>
          );
        });
      });
    }
  }
  function renderStrategiesHeader() {
    if (showAllStrategy) {
      return (
        <div className="strategies" onClick={(e) => handleStrategyColumnClick()}>
          <div className="col-title">
            Alerts
            {renderStrategyArrow()}
          </div>
        </div>
      )
    }
    else {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false,
              },
            },
          }}
          overlay={
            <Tooltip id="info-tooltip">Click to see alerts</Tooltip>
          }
        >
          <div className="strategies all-collapsed " onClick={(e) => handleStrategyColumnClick()}>
            <div className='collapsed-icon '>
              <i className="fas fa-arrows-alt-h  default-sort-icon active"></i>
            </div>
          </div>
        </OverlayTrigger>
      )
    }
  }

  function renderRecommmendationEmptyCol() {
    if (showAllStrategy) {
      return (
        <div className="recomm-col strategies"></div>
      )
    }
  }

  !isExpandTableButtonClicked && $(function () {
    $(".wrapper1").scroll(function () {
      $(".wrapper2")
        .scrollLeft($(".wrapper1").scrollLeft());
    });
    $(".wrapper2").scroll(function () {
      $(".wrapper1")
        .scrollLeft($(".wrapper2").scrollLeft());
    });
  });
  function renderTableHeaderColumns() {
    if (setOfDynamicDCColumns && setOfDynamicDCColumns.length > 0) {
      return setOfDynamicDCColumns.map((col, index) => {
        return (
          <div onClick={(e) => reorderColumnData(col.Metric_name)}>
            <div className="col-title" >
              {col.Display_name}{" "}
              {renderSortDirection(col.Metric_name)}
            </div>
          </div>
        )
      })
    }
  }

  function downloadRecommendationCSVModalFile() {
    let finalSortColumnValue = [];
    let alertType = "";
    if(selectedStrategies?.length){
      if(selectedStrategies && selectedStrategies?.length == 1) {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DISPLAY_TYPE"];
      } else {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DEFAULT_TYPE"];
      }
    }
    finalSortColumnValue = sortedColumnDC && sortedColumnDC.length === 2 ? [sortedColumnDC[1]]: sortedColumnDC;
      let combinedObj = {
      filterValues: selectedGlobalFilters,
      recommendationFilters: selectedStrategies,
      currentIndex: totalCount,
      selectedTab: recommendationSelectedStrategy ? recommendationSelectedStrategy.selection : '',
      searchText: newRecoSearchText,
      sortDirecetion: sortDirecetionDC,
      sortedColumn: finalSortColumnValue,
      filterName: "",
      projectName: project,
      subprojectName: subproject,
      strategies: allStrategyDetails?.allStrategyDetails,
      productId: productlocationDetails.productId,
      dcId:productlocationDetails.fcNameId,
      type: alertType,
      selectedDateRange:selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
      alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition,

    };
    let bufferValue = new Buffer(JSON.stringify(combinedObj));
    let base64data = bufferValue.toString("base64");
    let finalApiToBeCalled= "exportAlertRecommendations"
    window.open(
      `${process.env.REACT_APP_ROOT_URL}/download/${finalApiToBeCalled}/${base64data}`,
      "_blank"
    );
  };

  function downloadAlertLogHistoryCSVModalFile(){
    let alertType = "";
    if(selectedStrategies?.length){
      if(selectedStrategies && selectedStrategies?.length == 1) {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DISPLAY_TYPE"];
      } else {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DEFAULT_TYPE"];
      }
    }
    let combinedObj = {
     projectName: project,
     subprojectName: subproject,
     alertName: selectedStrategies,
     alertStatus: recommendationSelectedStrategy.selection,
     allSkuIds: productLocationData.allSkuIds,
     dcId:productlocationDetails.fcNameId,
     type: alertType,
     selectedDateRange:selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
     alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition,
   };
   let bufferValue = new Buffer(JSON.stringify(combinedObj));
   let base64data = bufferValue.toString("base64");
   window.open(
     `${process.env.REACT_APP_ROOT_URL}/download/exportAlertLogDataNew/${base64data}`,
     "_blank"
   );
 };

  let renderSnoozeRelatedColumnsHeader = () => {
    return (
      <>
        <div onClick={(e) => reorderColumnData('previouslyActioned')}>
          <div className="col-title" >
            Previously <br />Actioned (P3M){renderSortDirection('previouslyActioned')}
          </div>
        </div>
        
        <div onClick={(e) => reorderColumnData('alertTriggerDate')}>
          <div className="col-title" >
            Alert Trigger Date{renderSortDirection('alertTriggerDate')}
          </div>
        </div>
         
        {recommendationSelectedStrategy && recommendationSelectedStrategy?.activeRowDetails ?
          <div onClick={(e) => reorderColumnData('daysSinceAlertTrigger')}>
            <div className="col-title" >
              Days since Alert Trigger{renderSortDirection('daysSinceAlertTrigger')}
            </div>
          </div>
          : null}
        {recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" &&
          snoozeActiveColumnValueKey.map((item) => {
            return <div onClick={(e) => item.sort ? reorderColumnData(item.columnKey) : undefined}>
              <div className="col-title" >
                {item.displayName}{item.sort && renderSortDirection(item.columnKey)}
              </div>
            </div>
          })}
        {recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && <div className="col-reason" >
          <div className="col-title " >
            Reason
          </div>
        </div>}
      </>
    )
  }

  let toggleAlertLogModal = () => {
    let toggle = displayAlertLogModal
    setDisplayAlertLogModal(!toggle)
  }

  const isLocationsSelected = () => {
    return selectAll;
  }

  const handleDCLocationActionButtonClick = (data) => {
    dispatch(storeActionTakenInfo(true));
    dispatch(storeActionTakenInfoForProductTable(true));
   
    let obj = {};
    obj.productDetails = productLocationData;
    let newObj = {};
    
    // hard code for snooze
    if (isSnoozeSubProject) {
      newObj.snoozeTime =
        data.snoozeTime || data.snoozeTime == 0 ? data.snoozeTime : "N/A";
      newObj.reason = data.reason
        ? data.reason
        : "Moved back to Active by User";
      newObj.modeOfAction = data.scheduleAuto
    } else {
      newObj.comment = data.comments;
      newObj.asPerRecommendation = data.checkBoxSelection;
    }

    newObj.action =
      recommendationSelectedStrategy.selection === "active"
        ? "actioned"
        : "active";
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    newObj.user = userInfo.name;
    newObj.userMailId = userInfo !== null ? userInfo.email : "";
    newObj.sAMAccountName = userInfo.sAMAccountName
      ? userInfo.sAMAccountName
      : "";
    newObj.globalFilters = selectedGlobalFilters;

    let date = new Date();
    newObj.updatedDate = date.toDateString();
    let updatedInfo = {};
    let key = selectedStrategies[0];
    let tempSelectedRowIds = [];
   
    let selectedLocations = [];
    
    productLocationData.productList[0][productlocationDetails.productId]["storeList"]
    .map((store)=>{
        if( store.locationSelected ) {
          selectedLocations.push(store.fcNameId);
        }
    });

   let selectionLocNameIds = selectedLocations.map((selLoc)=>{
     return selLoc;
   })
    
   let alertType = "";
   if(selectedStrategies?.length){
     if(selectedStrategies && selectedStrategies?.length == 1) {
       let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
       alertType = filterArr[0]["DISPLAY_TYPE"];
     } else {
       let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
       alertType = filterArr[0]["DEFAULT_TYPE"];
     }
   }

    updatedInfo[key] = newObj;
    obj.updatedInfo = updatedInfo;
    obj.selectAllProductList = selectAll ? allSkuIdsDC : []
    obj.recommendationFilters = selectedStrategies
    obj.focusAlert = recommendationSelectedStrategy
    obj.productId = productlocationDetails.productId
    obj.dcId = productlocationDetails.fcNameId
    obj.dcIdCount = productlocationDetails.dcIdCount
    obj.type = alertType
    obj.selectedDateRange = selectedStrategyDateRange !== 'Default' ? strategyCustomDates : ''
    obj.alertFilterCondition = selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition 
    if(!selectAll) {
      if(deSelectedIndexes.length == 0){
        let selectedLocationNameIds = [];
        productLocationData.productList[0][productlocationDetails.productId]["storeList"].forEach((store)=>{
          if(store.locationSelected){
            selectedLocationNameIds.push(store.fcNameId)
          }
        });
        deSelectedIndexes = allLocationIds.filter((locId)=> !selectedLocationNameIds.includes(locId));
      }    
      let locNameIds = allLocationIds.filter(id => !deSelectedIndexes.includes(id))
      obj.selectedLocNameIds = locNameIds 
    } else  {
      obj.selectedLocNameIds = [];
    }

    let commonInfo = {
      page: "Recommendations",
      strategies: selectedStrategies[0],
      globalFilters: selectedGlobalFilters,
      strategyFilters: selectedStrategies,
      comment: isSnoozeSubProject
        ? data
          ? data.reason
          : "N/A"
        : data.comments,
      activity:
        recommendationSelectedStrategy.selection === "active"
          ? "Action Taken"
          : "Open",
      asPerRecommendation: data.checkBoxSelection ? 1 : 0,
      selectAllProductList: selectAll ? allSkuIdsDC : [],
      recommendationFilters: selectedStrategies,
      focusAlert: recommendationSelectedStrategy,
      productId: productlocationDetails.productId,
      dcId:productlocationDetails.fcNameId,
      dcIdCount:productlocationDetails.dcIdCount,
		  type:alertType,
      selectedDateRange:selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
      alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition,
    };

    if (!isSnoozeSubProject)
      addCommonActivityCaptureDetail(
        { ...commonInfo, ...{ listOfProducts: productLocationData.productList } },
        (response) => {
          obj.recomendationTable = false;
          dispatch(
            updateActionTakenInfoNew(obj, (res, rej) => {
              if (res) {
                //hide modal
                toast.success(res.data.responseDesc, {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
                dispatch(storeActionTakenInfo(false));
              } else {
                toast.error(res.data.responseDesc, {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
              }
            })
          );
        }
      );
    else {
      obj.recomendationTable = false;
      dispatch(
        updateActionTakenInfoNew(obj, (res, rej) => {
          if (res) {
            //hide modal
            if (res?.data?.responseDesc === "Some of the SKUs have been actioned already. Refresh the page to see the updated data" ||
              res?.data?.responseDesc === "Some of the SKUs have been moved back to active already. Refresh the page to see the updated data") {
              toast.warning("Some of the alerts have been updated by another user already; hence the page has been refreshed with the latest status.", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 15000,
                className: 'toastwarning-cls',
              });
            } else {
              toast.success(res.data.responseDesc, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
            dispatch(storeActionTakenInfo(false));
          } else {
            toast.error(res.data.responseDesc, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        })
      );
    }
  }

  const handleActionButtonClick = (data) => {
    dispatch(storeActionTakenInfo(true));
    dispatch(storeActionTakenInfoForProductTable(true));

    let filteredObject1 = [];
    Object.keys(selectedRows).map((key) => {
      filteredObject1.push(selectedRows[key]);
    });
    let filteredObject2 = [];
    filteredObject1.map((item) => {
      Object.keys(item).map((key) => {
        filteredObject2.push(item[key]);
      });
    });
    filteredObject2.map((item) => {
      Object.keys(item.estimatedVal).map((key) => {
        if (key.toLowerCase() === selectedStrategies[0].toLowerCase())
          item.estimatedValue = item.estimatedVal[key];
      });
    });
    let obj = {};
    obj.productDetails = filteredObject2;
    let newObj = {};
    
    // hard code for snooze
    if (isSnoozeSubProject) {
      newObj.snoozeTime =
        data.snoozeTime || data.snoozeTime == 0 ? data.snoozeTime : "N/A";
      newObj.reason = data.reason
        ? data.reason
        : "Moved back to Active by User";
      newObj.modeOfAction = data.scheduleAuto
    } else {
      newObj.comment = data.comments;
      newObj.asPerRecommendation = data.checkBoxSelection;
    }

    newObj.action =
      recommendationSelectedStrategy.selection === "active"
        ? "actioned"
        : "active";
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    newObj.user = userInfo.name;
    newObj.userMailId = userInfo !== null ? userInfo.email : "";
    newObj.sAMAccountName = userInfo.sAMAccountName
      ? userInfo.sAMAccountName
      : "";
    newObj.globalFilters = selectedGlobalFilters;

    let date = new Date();
    newObj.updatedDate = date.toDateString();
    let updatedInfo = {};
    let key = selectedStrategies[0];
   
    let selectedLocations = [];
    productLocationData.productList[0][productlocationDetails.productId]["storeList"].map((store)=>{
        if( store.locationSelected ) {
          selectedLocations.push(store.fcNameId);
        }
    });

    let alertType = "";
    if(selectedStrategies?.length){
      if(selectedStrategies && selectedStrategies?.length == 1) {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DISPLAY_TYPE"];
      } else {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) => selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
        alertType = filterArr[0]["DEFAULT_TYPE"];
      }
    }

    updatedInfo[key] = newObj;
    obj.updatedInfo = updatedInfo;
    obj.selectAllProductList = allSkuIdsDC
    obj.recommendationFilters = selectedStrategies
    obj.focusAlert = recommendationSelectedStrategy
    obj.productId = productlocationDetails.productId
    obj.dcId = productlocationDetails.fcNameId
    obj.dcIdCount = productlocationDetails.dcIdCount
		obj.type = alertType
    obj.selectedDateRange = selectedStrategyDateRange !== 'Default' ? strategyCustomDates : ''
    obj.alertFilterCondition = selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition
    if(!selectAll) {
      if(deSelectedIndexes.length == 0){
        let selectedLocationNameIds = [];
        productLocationData.productList[0][productlocationDetails.productId]["storeList"].forEach((store)=>{
          if(store.locationSelected){
            selectedLocationNameIds.push(store.fcNameId)
          }
        });
        deSelectedIndexes = allLocationIds.filter((locId)=> !selectedLocationNameIds.includes(locId));
      } 
      let locNameIds = allLocationIds.filter(id => !deSelectedIndexes.includes(id))
      obj.selectedLocNameIds = locNameIds
    } else  {
      obj.selectedLocNameIds = [];
    }
    
    let commonInfo = {
      page: "Recommendations",
      strategies: selectedStrategies[0],
      globalFilters: selectedGlobalFilters,
      strategyFilters: selectedStrategies,
      comment: isSnoozeSubProject
        ? data
          ? data.reason
          : "N/A"
        : data.comments,
      activity:
        recommendationSelectedStrategy.selection === "active"
          ? "Action Taken"
          : "Open",
      asPerRecommendation: data.checkBoxSelection ? 1 : 0,
      selectAllProductList: allSkuIdsDC,
      recommendationFilters: selectedStrategies,
      focusAlert: recommendationSelectedStrategy,
      productId: productlocationDetails.productId,
      dcId:productlocationDetails.fcNameId,
      dcIdCount: productlocationDetails.dcIdCount,
		  type:alertType,
      selectedDateRange:selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
      alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition,
    };

    if (!isSnoozeSubProject)
      addCommonActivityCaptureDetail(
        { ...commonInfo, ...{ listOfProducts: filteredObject2 } },
        (response) => {
          obj.recomendationTable = true;
          dispatch(
            updateActionTakenInfoNew(obj, (res, rej) => {
              if (res) {
                //hide modal
                toast.success(res.data.responseDesc, {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
                dispatch(storeActionTakenInfo(false));
              } else {
                toast.error(res.data.responseDesc, {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
              }
            })
          );
        }
      );
    else {
      dispatch(
        updateActionTakenInfoNew(obj, (res, rej) => {
          if (res) {
            //hide modal
            toast.success(res.data.responseDesc, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            dispatch(storeActionTakenInfo(false));
          } else {
            toast.error(res.data.responseDesc, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        })
      );
    }
  };

  function getActionText() {
    return(
      recommendationSelectedStrategy && recommendationSelectedStrategy.selection == "actioned" ? isSnoozeSubProject ? "Move To Active" : "Action" : "Action"
    )
  }

  let handleActionSaveButtonClicked = () => {
    // handleActionButtonClick({
    //   comments: this.state.actioncomments,
    //   checkBoxSelection: this.state.actionCheckBoxClicked,
    // });
    handleActionButtonClick({});
  };

  let toggleActionModal = () => {
    let toggle = displayActionModal;
    setDisplayActionModal(!toggle);
  };

  let renderActionButton = () => {
    let selectedProducts = 0;
    productLocationData.productList[0][productlocationDetails.productId]["storeList"]
        .forEach((store)=>{
             if(store?.locationSelected){
                selectedProducts++;
             }
        });
    
    // return _isEqual(selectedRows, {}) && _isEqual(selectAllProductList, []) ? (
    return selectedProducts == 0 ? (
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip-disabled">
            {selectedStrategies.length <= 0
              ? "Select an alert to take action"
              : "Select a product to take Action"}
          </Tooltip>
        }
        popperConfig={{
          modifiers: {
            preventOverflow: {
              enabled: false,
            },
          },
        }}
        delay={{ show: 250, hide: 400 }}
        placement={isExpandTableButtonClicked ? "bottom" : "top"}
      >
        <span className="d-inline-block">
          <button className="btn ml-2 btn-none"               
         >
       {getActionText()}  {!isSnoozeSubProject && <DropDownIcon />}
          </button>
        </span>
      </OverlayTrigger>
    ) : (
      <button
        className={`btn ${
          !isSnoozeSubProject
            ? "action-button-clicked"
            : "action-button"
        }`}
        onClick={(e) => {
          isSnoozeSubProject && recommendationSelectedStrategy.selection !== "actioned" && setDisplayActionModal(true);
          isSnoozeSubProject && recommendationSelectedStrategy.selection === "actioned" && handleActionSaveButtonClicked()
        }}
      >
     {getActionText()}
      </button>
    );
  };
  let selectedStrategyName = selectedStrategies?.length && selectedStrategies[0];  
  if (loading) return displayLoader();
  else if (productLocationData.productList && productLocationData.productList.length <= 0)
    return (<div id="no-data-available">No product locations available to display</div>)
  else if ((newRecoSearchText || (selectedStrategies && selectedStrategies?.length) || (recommendationSelectedStrategy && Object.keys(recommendationSelectedStrategy) && Object.keys(recommendationSelectedStrategy).length)) && productLocationData.productList && productLocationData.productList.length > 0)
    return (
      <div className="row" >
        <div className="mt-1 ml-4 mr-2">
          <span style={{fontWeight: "400",marginLeft: "8px"}}>Showing <span style={{fontWeight: "bold"}}>{productLocationData?.productList[0][productlocationDetails.productId]?.storeList.length}</span> of <span style={{fontWeight: "bold"}}>{totalCountDC}</span> Locations</span>
        </div>
       <div class="ml-auto mr-4">
          <div class="new-recommendation flex ">
            <div class="d-flex">
            
            <div class="dropdown">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic"  style={{background: "#0F059E",fontWeight: "bold",color: "white",marginLeft: 5}}>
                Export <DropDownIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => { downloadRecommendationCSVModalFile() }}>By Status</Dropdown.Item>
                  <Dropdown.Item onClick={() => { downloadAlertLogHistoryCSVModalFile() }}>By History</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
             {renderActionButton()}            
            </div>
          </div>
        </div>
        {displayActionModal ? (
          <TakeActionModal
            displayActionModalOff={toggleActionModal}
            displayActionModal={displayActionModal}
            handleActionButtonClick={handleActionButtonClick}
            dcLocation={true}
            handleDCLocationActionButtonClick={handleDCLocationActionButtonClick}
            isLocationsSelected = {isLocationsSelected}
            selectedStrategyName={selectedStrategyName}
            dclocationCount={productlocationDetails?.dcIdCount}
            dclocationName={productlocationDetails?.fcNameId}
            dcproductId={productlocationDetails?.productId}
          />
        ) : null}
        {displayAlertLogModal ? <AlertLogModal displayAlertLogModalOff={toggleAlertLogModal} displayAlertLogModal={displayAlertLogModal} isProductLevel={isProductLevel} productlocationDetails={productlocationDetails} alertLogModalDetails={alertLogModalDetails} /> : null}
        <div className="col-12">
          <div className={`table-responsive new-recomm sticky-wrapper ${setOfDynamicDCColumns && setOfDynamicDCColumns.length < 2 && !isSnoozeSubProject ? 'width-auto' : ''}`} id='sticky' ref={tableref} style={{ backgroundColor: "white", width:"100%",overflow:"scroll" }}>
            <div className="new-recomm-container" style={{marginBottom:"5px"}}>
              <div className={`recomm-header sticky-top ${setOfDynamicDCColumns?.length === 0 && "recomm-dynamiccol"}`} >
                {/* <div className="headcol first-col">Product</div> */}
                <div className="headcol location_block" onClick={(e) => reorderColumnData('location')}>
                  <div>

                    Locations
                    {renderSortDirection('location')}
                  </div>
                </div>
                {recommendationSelectedStrategy?.activeRowDetails?.DISPLAY_TYPE === "DC/Cluster" &&
                <div>
                  <div className="col-title">
                    DC/Cluster
                  </div>
                </div> }
                {recommendationSelectedStrategy && recommendationSelectedStrategy.activeRowDetails ?
                  <div onClick={(e) => reorderColumnData('focusAlertEstimation')}>
                    <div className="col-title" >
                      Focus Alert<br /> Estimated Value{renderSortDirection('focusAlertEstimation')}
                    </div>
                  </div> : null}
                {/* hard code for snooze */}
                {isSnoozeSubProject && renderSnoozeRelatedColumnsHeader()}
                <div onClick={(e) => reorderColumnData('estimatedRevenueImpact')}>

                  <div className="col-title"  >
                    Total Estimated Value
                    {renderSortDirection('estimatedRevenueImpact')}
                  </div>
                </div>

                {renderStrategiesHeader()}

                {renderTableHeaderColumns()}
                {recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === "Retailer Price Difference" &&
                <div> 
                  <div className="col-title"  >
                    Price Difference Details 
                  </div>
                </div>}
              </div>
              <div className="recomm-body" onScroll={ (e) => handleScrollForTable(e)} >
                {renderRecommmendationTableRows()}
              </div>
              
            </div>
            
            {renderFetchingMoreItems()}
          </div>
          {/* Custom code for  product table* ends */}
        </div>

        {renderStrategiesModelWindow()}
      </div>
    );
  else
    return (<div id="no-data-available">No product locations available to display</div>)
};

export default ProductTableDCModal;
