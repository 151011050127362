export default function(state={setOfDynamicDCColumns:[],sortDirecetionDC:'',curentIndexDC:'',defaultTypeDC:'',sortedColumnDC:[],allLocationsIds:[],productTriggeredLocationData:"",
                                setOfTriggeredDynamicDCColumns: "",
                                curentTriggeredIndexDC: "",
                                sortTriggeredDirecetionDC: "",
                                sortedTriggeredColumnDC:"" ,
                                allTriggeredLocationIds: "",
                                allTriggeredSkuIdsDC: "",
                                totalTriggeredCountDC: "",
                                productLocationData:""},action){
    switch(action.type){
        case "INSERT_NEW_STRATEGY":return {...state,insertNewStrategyData:action.payload}
        case "DISPLAY_ALL_STRATEGIES":return {...state,allStrategies:action.payload}
        case "UPDATE_SELECTED_STRATEGY":return {...state,updateSelectedStrategy:action.payload}
        case "DELETE_SELECTED_STRATEGIES":return {...state,deleteSelectedStrategies:action.payload}
        case "DETAILS_FOR_ALL_STRATEGIES":return {...state,detailsForAllStrategies:action.payload.responseData}
        case "RECOMMENDATION_SELECTED_STRATEGY":return {...state,recommendationSelectedStrategy:action.payload}
        case "SELECTED_STRATEGIES":return {...state,selectedStrategies:action.payload}
        case "DYNAMIC_COLUMNS_DCDATA": return {...state, productLocationData: action.payload,setOfDynamicDCColumns:action.payload.setOfDynamicColumns,
            curentIndexDC:action.payload.currentIndex,sortDirecetionDC:action.payload.sortDirecetion,sortedColumnDC: action.payload.sortedColumn,
            allLocationIds: action.payload.allLocationIds,allSkuIdsDC: action.payload.allSkuIds,totalCountDC:action.payload.totalCount
        } 
        case "UPDATE_DYNAMIC_COLUMNS_DCDATA": return{...state, 
            curentIndexDC: action.payload.currentIndex, 
            productLocationData: action.payload}
        case "DYNAMIC_TRIGGERED_DC_COLUMNS_DCDATA": return {...state, productTriggeredLocationData: action.payload,setOfTriggeredDynamicDCColumns:action.payload.setOfDynamicColumns,
            curentTriggeredIndexDC:action.payload.currentIndex,sortTriggeredDirecetionDC:action.payload.sortDirecetion,sortedTriggeredColumnDC: action.payload.sortedColumn,
            allTriggeredLocationIds: action.payload.allLocationIds,allTriggeredSkuIdsDC: action.payload.allSkuIds,totalTriggeredCountDC:action.payload.totalCount
        }
        case "UPDATE_TRIGGERED_DYNAMIC_COLUMNS_DCDATA": return{...state, 
            curentIndexDC: action.payload.currentIndex, 
            productLocationData: action.payload}
        case "SELECTED_STRATEGYDATE_RANGE" : return {...state,selectedStrategyDateRange:action.payload} 
        case "STRATEGY_START_END_DATE" : return {...state,strategyCustomDates:action.payload}
        case "SELECTED_STRATEGYCUSTOM_DATES": return {...state,selectedCustomDates:action.payload}
        case "STRATEGY_ADDITIONAL_PARAMETERS" : return {...state,strategyAdditionalParamaters:action.payload}
        case "SELECTED_FILTER_CONDITION" : return {...state,selectedFilterCondition:action.payload} 
        case "RANKTYPE_FILTER" : return {...state,rankTypefilter:action.payload}
        default:
            return state;
    }
}