import React, { useState, useEffect } from 'react';
import {
    getAllPagesForAdmin, getSelectedView, getEmbedToken, updateViewBuilderJSON,
    getLastDataRefreshDate, getSavedFilters
} from '../../actions';
import Report from 'powerbi-report-component';
import _get from 'lodash/get';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import BarLoader from "react-spinners/BarLoader";

const SalesPredictionPbiComponent = (props) => {
    const [loading, setLoading] = useState(true);
    let { selectedGlobalFilters, selectedCustomerKey } = useSelector((state) => state.globalReducer);
    let { dateFilterAndRange } = useSelector((state) => state.newRecommendationTableData);
    let dateFilterRange = dateFilterAndRange?.dateRange ? dateFilterAndRange.dateRange : 'MTD';
    const [random, setRandomKey] = useState();
    useEffect(() => {
        setRandomKey(Math.random());
        Promise.all([
            props.updateViewBuilderJSONAPI({}),
            props.getAllPagesForAdminAPI({ viewStatus: 'Active', categoryName: 'Insights' }),
        ]).then(function (values) {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (props.allAdminViews?.length > 0) {
            props.allAdminViews.map((data, index) => {
                if(props?.pbiviewName === data?.viewName) { 
                    props.getSelectedViewAPI({ viewId: data.viewId });
                }
            });
        }
    }, [props.allAdminViews?.length, selectedGlobalFilters]);

    const extraSettings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        hideErrors: false
    };

    const reportStyle = {
        height: "48rem"
    };

    const renderPowerBIDashboard = (obj) => {
        let filterArr = [],
        columnList = [],
        tableList = [];        

        if (obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken !== '') {
            filterArr.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: "calendar_date",
                  column: "date_range",
                },
                operator: "In",
                values: [dateFilterRange]
              });
            
              filterArr.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: "calendar_comparison",
                  column: "compare_to"
                },
                operator: "In",
                values: [dateFilterRange === 'YTD' ? 'Prior year' : 'Prior period']
              });
            Object.keys(selectedGlobalFilters)
                .forEach((key) => {
                    if (selectedGlobalFilters[key].length > 0) {
                        if (key == 'special_filter') {
                            selectedGlobalFilters[key].forEach((spFilterObj) => {
                                columnList.push({
                                    column: spFilterObj,
                                    values: ["Y"]
                                })
                            })
                        } else if (key == 'rpln_flag') {
                            let flags = [];
                            selectedGlobalFilters[key].forEach((spFilterObj) => {
                                if (spFilterObj == 'Replenishable' || spFilterObj === 'Y') {
                                    flags.push('Y')
                                }
                                if (spFilterObj == 'Non Replenishable') {
                                    flags.push('N')
                                }
                            })
                            columnList.push({
                                column: 'RPLN_FLAG',
                                values: flags
                            })
                        } else {
                            columnList.push({
                                column: key,
                                values: selectedGlobalFilters[key]
                            })
                        }
                    }
                });
                tableList = props.viewBuilder.viewBuilderJSON.tablesForPbi;
                if(columnList.length > 0) {
                    for (let i = 0; i < tableList.length; i++) {
                        for (let j = 0; j < columnList.length; j++) {
                            filterArr.push({
                                $schema: "http://powerbi.com/product/schema#basic",
                                target: {
                                    table: tableList[i],
                                    column: columnList[j].column
                                },
                                operator: "In",
                                values: columnList[j].values
                            });
                        }
                    }
                }
           
            let customizedColumns = ['customer_key']
            if (tableList.length > 0) {
                for (let columnIndex = 0; columnIndex < customizedColumns.length; columnIndex++) {
                for (let i = 0; i < tableList.length; i++) {
                    // for (let j = 0; j < columnList.length; j++) {
                        filterArr.push({
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: tableList[i],
                                column: customizedColumns[columnIndex]
                            },
                            operator: "In",
                            values: [selectedCustomerKey]
                        });
                    }
                }
            }

            console.log("filterArr...:", filterArr);
            let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${obj.reportId}&groupId=${process.env.REACT_APP_GROUP_ID}`;
            embedUrl = embedUrl.replace("watch?v=", "v/");
            return (
                <div className="col-12 p-0 m-0" key={random} id={obj.id}>
                    <Report
                        embedType="report" // "dashboard"
                        tokenType="Embed" // "Aad"
                        accessToken={obj.embedToken} // accessToken goes here
                        embedUrl={embedUrl} // embedUrl goes here
                        embedId={obj.reportId}
                        groupId={`${process.env.REACT_APP_GROUP_ID}`}
                        permissions="All" // View
                        style={reportStyle}
                        extraSettings={extraSettings}
                        onRender={(data)=>{
                        }} 
                        onLoad={(report) => {
                            report = report;
                            report.setFilters(filterArr).then((req, res) => {
                            }).catch(function (errors) {
                            })
                        }
                        }
                    />
                </div>
            )
        }

    }

    const renderChildrenOfId = (id) => {
        if (Object.keys(props.viewBuilder).length > 0) {
            if (props.viewBuilder.hasOwnProperty("viewBuilderJSON") && props.viewBuilder.viewBuilderJSON.hasOwnProperty("htmlTemp")) {
                let newJson = props.viewBuilder.viewBuilderJSON.htmlTemp;
                if (Object.keys(newJson).length > 0) {
                    let obj = newJson[id];
                    const HtmlTag = `${obj.element}`;                   
                    return (
                        <HtmlTag className={obj.classDefault + ' ' + obj.classMobile + ' ' + obj.classTablet + ' ' + obj.classDesktop}
                            id={obj.id} type={obj.type}
                            style={obj.configOptions.style}>
                              
                            {renderPowerBIDashboard(obj)}
                            {obj.children.length > 0 ? obj.children.map((id, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {renderChildrenOfId(id)}
                                    </React.Fragment>
                                )
                            }) : null}
                        </HtmlTag>
                    )
                }
            }

        } else if (props.allAdminViews?.length <= 0) { 
            return <div className='text-center mt-5'>No data available to display </div>
        } 

    }
    if (loading) {
        return (
          <div id="strategy_wrapper">
            <div className="container-fluid  default-top loading_wrapper  bg-w p-5">
              <div className="sweet-loading">
              <span><b>Processing...</b></span>
                <BarLoader style={{display: 'block', margin:'0 auto', borderColor: 'red'}} size={150} color={"#123abc"}
                  height={4} width={100} loading={loading} />
              </div>
            </div>
          </div>
        );
    } else {
        return (
            <>
                {renderChildrenOfId(0)}
            </>
        );
    }
}


function mapStateToProps(state) {
    let allPagesForAdmin = _get(state, 'adminReducer.allAdminViews.responseData', []);
    let viewBuilder = _get(state, 'viewBuilderObj', { enableDatepicker: false });

    return ({
        allAdminViews: allPagesForAdmin,
        viewBuilder: viewBuilder,

    })
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllPagesForAdminAPI: getAllPagesForAdmin,
        getSelectedViewAPI: getSelectedView,
        getEmbedTokenAPI: getEmbedToken,
        updateViewBuilderJSONAPI: updateViewBuilderJSON,
        getLastDataRefreshDateAPI: getLastDataRefreshDate,
        getSavedFiltersAPI: getSavedFilters,

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesPredictionPbiComponent);