import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSelectedView } from '../../actions';
import { Link } from 'react-router-dom';
import { ChevronRight } from '../../components/Icons';
import _get from 'lodash/get';

class AdminPageList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    renderTiles() {
        if (this.props.pages && this.props.pages.length > 0) {
            return this.props.pages.map((data, index) => {
                return (
                    <Link to={`/${this.props.project}/${this.props.subproject}/admin/dashboard_builder`} className="col-sm-12 col-md-4 tile-wrapper" key={index + 1} onClick={(e) => {
                        localStorage.setItem("viewId", data.viewId)
                    }} >
                        <div className="tile">
                            <div className="tile-name">{data.viewName}</div>
                            <div className="desc">{data.viewDescription}</div>
                            <div className="title-subtext align-items-center">
                                <span>Manage</span> <ChevronRight />
                            </div>
                        </div>

                    </Link>
                )

            });

        } else {
            return (
                <div className="col-sm-12">
                    <span>No data available. Please create new views or change the status of already created views.</span>
                </div>
            )

        }
    }
    
    render() {

        return (
            <React.Fragment>
               
                <div className="row bg-white tile-list">

                    {this.renderTiles()}
                </div>


            </React.Fragment>
        );
    }
}
function mapStateToProps(state){
    let project = _get(state, 'globalReducer.project', "");
	let subproject = _get(state, 'globalReducer.subproject', "");
    return ({
        project,
        subproject
    })
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getSelectedViewAPI: getSelectedView
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPageList);
