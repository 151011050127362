import React, { useState, useEffect, useReducer } from 'react';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import { getFormattedDate } from '../helper';
import Dropdown from 'react-bootstrap/Dropdown';
import './Filters.scss';
import { useDispatch, useSelector } from "react-redux";
import { setInsightsDateValues } from "../actions"

function returnMomentObject(date) {
    return moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
}
function Datepicker(props) {
    let date = new Date(returnMomentObject(new Date(props.lastRefreshedDate)));
    let year = date.getFullYear();
    let month = date.getMonth() - 1;
    return (
        <DayPicker
            className="Selectable"
            numberOfMonths={2}
            modifiers={props.modifiers}
            month={new Date(year, month)}
        />
    )
}

export default function DateFilter(props) {
    const dispatch = useDispatch();
    // Date filter won't know what is default selectedDateRange or compareToOption
    // this info should come from the component who is trying to embed date filter
    const [selectedDateRange, setSelectedDateRange] = useState(props.selectedDateRange);
    const [defaultDateRange, setDefaultDateRange] = useState(props.defaultDateRange);
    const [compareToOption, setCompareToOption] = useState(props.compareToOption);
    // moment object is required to ignore local timezone info, in the returnMomentObj method
    // date object is returned with UTC
    const [startDateCompare, setStartDateCompare] = useState(new Date(returnMomentObject(new Date())));
    const [endDateCompare, setEndDateCompare] = useState(new Date(returnMomentObject(new Date())));
    const [startDateCurrent, setStartDateCurrent] = useState(new Date(returnMomentObject(new Date())));
    const [endDateCurrent, setEndDateCurrent] = useState(new Date(returnMomentObject(new Date())));
    const [modifiers, setModifiers] = useState({});
    const [displayDatepicker, setDisplayDatepicker] = useState(false);
    const [showTransmaxRefreshDate, setShowTransmaxRefreshDate] = useState(props.transMaxDate);
    //set the end date current based on last refresh date
    let { dateFilterOptions, subproject } = useSelector((state) => state.globalReducer);
    let { dateFilterAndRange } = useSelector((state) => state.newRecommendationTableData);

    let _selectedDateRange = useSelector((state) => state.adminReducer);

    const selectDefaultDateOption = ['MTD', 'QTD', 'YTD'];
    
    const Q1="Q1", Q2="Q2", Q3="Q3" , Q4="Q4";
    useEffect(() => {
        if(props.selectedDateRange) {
            setSelectedDateRange(props.selectedDateRange);
            setCompareToOption(props.compareToOption);
        }      
    }, [props.selectedDateRange])

    useEffect(() => {
        if(dateFilterAndRange?.dateRange){
            setSelectedDateRange(dateFilterAndRange.dateRange)
            setCompareToOption(dateFilterAndRange.compareTo)
        } 
    }, [dateFilterAndRange])
    
    useEffect(() => {
        if(props.transMaxDate && selectDefaultDateOption.includes(selectedDateRange)) {            
            setEndDateCurrent(new Date(returnMomentObject(new Date(props.transMaxDate))));
            setShowTransmaxRefreshDate(props?.transMaxDate);
        }else if (props.lastRefreshedDate !== endDateCurrent) {
            setEndDateCurrent(new Date(returnMomentObject(new Date(props.lastRefreshedDate))));
            setShowTransmaxRefreshDate(props?.lastRefreshedDate);
        }        
    }, [props.lastRefreshedDate,props.transMaxDate, selectedDateRange]);

    function getNoOfDaysInPreviousQuarter(quarter, month) {
        if(quarter === Q1) {
           return month == 1 ? 3 : month == 2 ? 4 : 5;   
        } else if(quarter === Q2) {
            return month == 4 ? 3 : month == 5 ? 4 : 5; 
        } else if(quarter === Q3) {
            return month == 7 ? 3 : month == 8 ? 4 : 5; 
        } else if(quarter === Q4) {
            return month == 10 ? 3 : month == 11 ? 4 : 5; 
        }
    }

    function checkLeapYear(year) {
        if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
            return true;
        } else {
            return false;
        }
    }
   
    function getNoOfDaysInQuarter() {
        let month = '';
        let transMaxlastDate;
        transMaxlastDate = props.transMaxDate ? props.transMaxDate : props.lastRefreshedDate
        month = new Date(transMaxlastDate).getMonth()+1;
        let quarter = "";
        let noOfDays = 0;
        let lastrefreshdate = '';
        lastrefreshdate = new Date(transMaxlastDate).getDate();
        let isLeapYear = "";
        isLeapYear = checkLeapYear(new Date(transMaxlastDate).getFullYear());
        
        switch(month) {
               case 1:
                noOfDays = lastrefreshdate;
                quarter = Q1;
               break;
               case 2:
                noOfDays = 31 + lastrefreshdate;
                quarter = Q1;
               break;
               case 3:
                noOfDays = isLeapYear ? 59 + 1 : 59 + lastrefreshdate;
                quarter = Q1;
               break;
               case 4:
                noOfDays = lastrefreshdate;
                quarter = Q2;
               break;
               case 5:
                noOfDays = 30 + lastrefreshdate;
                quarter = Q2;
               break;
               case 6:
                noOfDays = 61 + lastrefreshdate;
                quarter = Q2;
               break;
               case 7:
                noOfDays = lastrefreshdate;
                quarter = Q3;
               break;
               case 8:
                noOfDays = 31 + lastrefreshdate;
                quarter = Q3;
               break;
               case 9:
                noOfDays = 61 + lastrefreshdate;
                quarter = Q3;
               break;
               case 10:
                noOfDays = lastrefreshdate;
                quarter = Q4;
               break;
               case 11:
                noOfDays = 30 + lastrefreshdate;
                quarter = Q4;
               break;
               case 12:
                noOfDays = 61 + lastrefreshdate;
                quarter = Q4;
               break;
               default:
               break;
            }
            return {"noOfDays": noOfDays, "quarter": quarter }
        }

    function getQuarterFromMonth(month) {
        let quarter = "";
        switch(month) {
            case 1:
                quarter = 1;
                break;
            case 2:
                quarter = 2;
                break;
            case 3:
                break;
                quarter = 3;
               // break;
            case 4:
                quarter = 1;
                break;
            case 5:
                quarter = 2;
                break;
            case 6:
                break;
                quarter = 3;
                //break;
            case 7:
                quarter = 1;
                break;
            case 8:
                quarter = 2;
                break;
            case 9:
                break;
                quarter = 3;
               // break;
            case 10:
                quarter = 1;
                break;
            case 11:
                quarter = 2;
                break;
            case 12:
                break;
                quarter = 3;
                //break;
            default:
                break;
            
        }
        return quarter;
    } 
        

    useEffect(() => {
        let startDateCompareNum;
        let startDateCurrentNum;
        let endDateCompareNum;
        let firstDayOfWalmartYear;
        let transMaxlastDate;
        // shift start date of compare depending on "selcted date range chosen"
        if (selectedDateRange === "Last 1 week") {
            startDateCurrentNum = 7;
        } else if (selectedDateRange === "Last 4 weeks") {
            startDateCurrentNum = 28;
        } else if (selectedDateRange === "Last 13 weeks") {
            startDateCurrentNum = 91;
        } else if (selectedDateRange === "Last 26 weeks") {
            startDateCurrentNum = 182;
        } else if (selectedDateRange === "Last 52 weeks") {
            startDateCurrentNum = 364;
        }else if (selectedDateRange === "MTD") {
            transMaxlastDate = props.transMaxDate ? props.transMaxDate : props.lastRefreshedDate
            startDateCurrentNum = new Date(transMaxlastDate).getDate();
        }else if (selectedDateRange === "QTD") {
            let { noOfDays } = getNoOfDaysInQuarter();
            startDateCurrentNum = noOfDays;
        } else if (selectedDateRange === "YTD") {
            if (compareToOption !== "Prior year") {
                // if YTD is selected, Prior period should be disabled
                setCompareToOption("Prior year");
            } else {
                transMaxlastDate = props.transMaxDate ? props.transMaxDate : props.lastRefreshedDate
                let today = new Date(returnMomentObject(new Date(transMaxlastDate)));
                let ytdStartDate = props.ytdStartDate ? props.ytdStartDate : props.lastRefreshedDate ? new Date(props.lastRefreshedDate).getFullYear() : new Date().getFullYear();
                firstDayOfWalmartYear = new Date(returnMomentObject(new Date(`${ytdStartDate}`)));
                // calculate difference in number of days between ytdStartDate and last refreshed date
                // if ytdStartDate is null/undefined then start date would be Jan 1
                const utc1 = Date.UTC(firstDayOfWalmartYear.getFullYear(), firstDayOfWalmartYear.getMonth(), firstDayOfWalmartYear.getDate());
                const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
                startDateCurrentNum = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
            }
        }

        if (compareToOption === "Prior period") {
            endDateCompareNum = startDateCurrentNum + 1;
            startDateCompareNum = startDateCurrentNum * 2 - 1;
        } else {
            endDateCompareNum = 367 ;
            startDateCompareNum = 365 + startDateCurrentNum;
        }
        
        if(selectedDateRange === "MTD") {
            if (compareToOption === "Prior period") {
                setStartDateCompare(moment(endDateCurrent).subtract((1), 'month').startOf('month').toDate());
                setEndDateCompare(moment(endDateCurrent).subtract((1), 'month').toDate());
                setStartDateCurrent(moment(endDateCurrent).subtract((startDateCurrentNum - 1), 'day').toDate()); 
            } else {
                setStartDateCompare(moment(endDateCurrent).subtract((1), 'year').startOf('month').toDate());
                setEndDateCompare(moment(endDateCurrent).subtract((1), 'year').toDate());
                setStartDateCurrent(moment(endDateCurrent).subtract((startDateCurrentNum - 1), 'day').toDate());
            }
        }else if(selectedDateRange === "QTD") {
            if (compareToOption === "Prior period") {
                let {quarter } = getNoOfDaysInQuarter();
                let noOfMonthsToReduce = "";
                transMaxlastDate = props.transMaxDate ? props.transMaxDate : props.lastRefreshedDate
                noOfMonthsToReduce = getNoOfDaysInPreviousQuarter(quarter, new Date(returnMomentObject(new Date(transMaxlastDate))).getMonth()+1);
                setStartDateCompare(moment(endDateCurrent).subtract((noOfMonthsToReduce), 'month').startOf('quarter').toDate());
                setEndDateCompare(moment(endDateCurrent)
                            .subtract((noOfMonthsToReduce), 'month')
                            .add(getQuarterFromMonth(new Date(transMaxlastDate).getMonth()), 'month')
                            .set("date",(new Date(transMaxlastDate)).getDate()).toDate());                
                setStartDateCurrent(moment(endDateCurrent).subtract((startDateCurrentNum-1), 'day').toDate()); 
            } else {
                setStartDateCompare(moment(endDateCurrent).subtract((1), 'year').startOf('quarter').toDate());
                setEndDateCompare(moment(endDateCurrent).subtract((1), 'year').toDate());
                setStartDateCurrent(moment(endDateCurrent).subtract((startDateCurrentNum - 1), 'day').toDate());
            }
        }else if(selectedDateRange === "YTD") {
            setStartDateCompare(moment(endDateCurrent).subtract((1), 'year').startOf('year').toDate());
            setEndDateCompare(moment(endDateCurrent).subtract((1), 'year').toDate());
            setStartDateCurrent(moment(endDateCurrent).startOf('year').toDate());   
        } else  {
            setStartDateCompare(moment(endDateCurrent).subtract((startDateCompareNum), 'day').toDate());
            setEndDateCompare(moment(endDateCurrent).subtract((endDateCompareNum - 1), 'day').toDate());
            setStartDateCurrent(moment(endDateCurrent).subtract((startDateCurrentNum - 1), 'day').toDate()); 
        }
        


    }, [endDateCurrent, compareToOption, selectedDateRange]);

    useEffect(() => {
        // object which needs to be provided to the date picker library
        setModifiers({
            compareToDateRange: {
                from: startDateCompare,
                to: endDateCompare
            },
            currentDateRange: {
                from: startDateCurrent,
                to: endDateCurrent,
            },
            outside: false
        });
        dispatch(setInsightsDateValues({startDateCurrent: getFormattedDate(startDateCurrent).toString(), endDateCurrent: getFormattedDate(endDateCurrent).toString(), 
            startDateCompare: getFormattedDate(startDateCompare).toString(), endDateCompare: getFormattedDate(endDateCompare).toString()}))
    }, [startDateCompare, endDateCompare, startDateCurrent, endDateCurrent]);

    // useEffect(() => {
    //     if(dateFilterAndRange.dateRange) {
    //         setSelectedDateRange(dateFilterAndRange.dateRange);
    //         setCompareToOption(dateFilterAndRange.compareTo);
    //     }
    // }, [dateFilterAndRange])
      

    const onToggleDatepicker = (e, apply) => {
        if(!apply) {               
            if(props?.newDateDefault){
                updateDateRange(props.selectedDateRange);
                setCompareToOption(props.compareToOption);
            }else {
                updateDateRange(props.selectedDateRange);
                setCompareToOption(props.compareToOption);
            }
        }        
        setDisplayDatepicker(e);
    }

    const updateDateRange = (value) => {
        setSelectedDateRange(value);
        if(props.transMaxDate && selectDefaultDateOption.includes(value)) {
            setEndDateCurrent(new Date(returnMomentObject(new Date(props.transMaxDate))));
            setShowTransmaxRefreshDate(props?.transMaxDate);
        }else if (props.lastRefreshedDate !== endDateCurrent) {
            setEndDateCurrent(new Date(returnMomentObject(new Date(props.lastRefreshedDate))));
            setShowTransmaxRefreshDate(props?.lastRefreshedDate);
        }
    }

    function refreshCalendar () {
        dispatch(setInsightsDateValues({
            startDateCurrent: getFormattedDate(startDateCurrent).toString(), 
            endDateCurrent: getFormattedDate(endDateCurrent).toString(), 
            startDateCompare: getFormattedDate(startDateCompare).toString(), 
            endDateCompare: getFormattedDate(endDateCompare).toString()
        }))
    }
   
    return (
        <Dropdown onToggle={onToggleDatepicker} show={displayDatepicker} className="mr-2" >
            <Dropdown.Toggle variant="light" id="dropdown-datepicker">
                <div id="date-range-picker" className="date-range-picker">
                    <span className="range">{getFormattedDate(startDateCurrent).toString()} - {getFormattedDate(endDateCurrent).toString()}</span>
                    <i className="range-text">Compare to 
                    {getFormattedDate(startDateCompare).toString()} - {getFormattedDate(endDateCompare).toString()} </i>
                    <i className="far fa-calendar-alt clander-icon"></i>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu id="datepicker-menu" toggle={false}>
                <div className="flex">
                    <Datepicker
                        lastRefreshedDate={showTransmaxRefreshDate}
                        modifiers={modifiers}
                        endDateCurrent={endDateCurrent}
                    />
                    <div>
                        <div>
                            <p className="blue-heading">Date Range</p>
                            <select className="form-control" value={selectedDateRange} onChange={(e) => {  updateDateRange(e.target.value); }}>
                            {dateFilterOptions.map((item) => (
                  <option value={item}>{item}</option>
                ))}
                            </select>
                            {!props?.newDateDefault ? <><p className="mt-5 orange-heading">Compare To</p>
                            <select className="form-control" value={compareToOption} onChange={(e) => { setCompareToOption(e.target.value) }}>
                                {/* disabled prior period if it is YTD */}
                                <option value="Prior period" disabled={selectedDateRange === "YTD" ? true : false}>Prior period</option>
                                <option value="Prior year" >Prior year</option>
                            </select></> : null
                            }
                            <div id="footer-buttons" className="mt-4">

                                <button className="btn btn-disabled  mr-2" onClick={(e) => {
                                    if(props?.newDateDefault){
                                        setSelectedDateRange(props.defaultDateRange); 
                                    }else {
                                     setSelectedDateRange(_selectedDateRange.selectedDateRange); 
                                    }
                                     setCompareToOption("Prior period")
                                  }}>Reset</button>
                                <button className="btn btn-apply" onClick={(e) => {
                                    // call parent's applyDateFilter method they will call whoever needs it
                                    refreshCalendar();
                                    props.handleApplyDateFilter(selectedDateRange, compareToOption);
                                    onToggleDatepicker(false, true);
                                }}>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}