import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { DropDownIcon } from "../../components/Icons";
import "../Recommendations/Recommendations.scss";
import {
  adoptionSelectedUSers,
  } from "../../actions/index";
export default function UserNameFilter(props) {
  const [userNameList, setUserList] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [searchString, setSearchString] = useState("");
  let {detailsForAllUsers, adoptionSelectedUsers} =  useSelector(
    (state) => state.adoptionReducer
  );

  let dispatch = useDispatch()

  useEffect(() => {
    setUserList(adoptionSelectedUsers);
  }, [adoptionSelectedUsers]);

  function setCheckBox(option) {
      if(option ==='Select All')
        return detailsForAllUsers.length === userNameList.length ? true : false
      else
        return userNameList.indexOf(option)> -1 ? true : false   
  }

  function renderOptions(data) {
    if (data) {
      data.sort(function (a, b) {
        return a?.localeCompare(b);
      });
      data = data.filter((username) => {
        return username?.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
      });
      data.unshift("Select All");
      return data.map((option, index) => {
        return (
          <Form.Check
            checked={setCheckBox(option)}
            type="checkbox"
            key={index}
            required
            name="multiSelectFilter"
            label={option}
            onChange={(e) => {
              handleCheckboxClick(e,option);
            }}
            id={index}
          />
        );
      });
    } else {
      return null;
    }
  }

  function handleCheckboxClick(event, option ) {
    let finalUserList = [...userNameList]
    if (event.target.checked) 
       option ==='Select All' ? finalUserList= [...detailsForAllUsers] :  finalUserList.push(option)
    else 
      option ==='Select All' ? finalUserList=  [] :  finalUserList.splice(userNameList.indexOf(option), 1)
    setUserList(finalUserList)
  }

  function handleApplyFilter(e) {
      e.preventDefault();
      dispatch(adoptionSelectedUSers(userNameList));
      onToggle(false, true);   
  }

  function onToggle(e, apply) {
    !apply &&  setUserList(adoptionSelectedUsers) 
    setDisplayDropdown(e);
  }

  return (
    <div id="UserNameFilter">
      <Dropdown
        show={displayDropdown}
        onToggle={onToggle}
        id="user-dropdown"
      >
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="btn-cta"
        >
          User Filter 
          {`(${userNameList.length})`}
          <DropDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Form>
            <Form.Group className="filter_dropdown_wrapper">
              <input
                type="text"
                placeholder="Search"
                className="form-control mb-3"
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                  setUserList([]);
                }}
              />
              <div className="strategy_dropdown_wrapper">
                {detailsForAllUsers ? renderOptions(detailsForAllUsers) : "No Data Available"}
              </div>
              <div className="apply_button_wrapper">
                <div className="horizontal_divider"></div>
                {userNameList.length <=0 ? <div className="error">Please select an user</div> : null}
                <div className="d-flex justify-content-between">
                  <button
                     className={`btn btn-disabled ${
                      userNameList.length > 0 ? null : "btn-disabled-clear-all"
                    } my-2`}                
                        onClick={(e) => {
                      e.preventDefault();
                      setUserList([]);
                      setSearchString("");
                    }}
                  >
                    Clear All
                  </button>
                  <button
                    className={`btn btn-apply  ${
                      userNameList.length > 0 ? null : "btn-disabled-apply"
                    } my-2`}
                    onClick={(e) => {
                      handleApplyFilter(e);
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
