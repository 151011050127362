import React, { useState, useEffect } from 'react';
import { getPriceIndexGraph } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import {
  ComposedChart, XAxis,Tooltip, YAxis, Label,
  Legend,
  CartesianGrid,
  Bar,Line,
  ResponsiveContainer,
  LabelList
} from "recharts";
import moment from "moment";
import BarLoader from "react-spinners/BarLoader";

const NewInsightsChart = (props) => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [insightGraphData, setInsightGraphData] = useState([]);
  let {  insightsTabsDetailsData } = useSelector((state) => state.insightReducer);
  let metricNames = insightsTabsDetailsData?.finalData?.SECTION_NAME; 
  let metricNametab = props?.displayName === undefined ? insightsTabsDetailsData !== undefined && insightsTabsDetailsData?.tabDetails !== undefined ? insightsTabsDetailsData?.tabDetails[metricNames][0]?.DISPLAY_NAME : "" : props?.displayName;

  const [isGraphShow, setIsGraphShow] = useState({
    graphAsp:true,
    graphCurrentyear:true,
    graphPrevYear:true,
    graphGrossMargin:true
  });
  let { selectedCustomerKey, selectedGlobalFilters,selectedRetailerCurrency, subproject} = useSelector((state) => state.globalReducer);
  const arrayCogsTabNames = ['C.O.G.S', 'Promo Price Sales'];
  const cogsTabName = arrayCogsTabNames.includes(metricNametab) ? 'Avg. Sell-in Price' : 'A.S.P';
  const arrayPosTabNames = ['P.O.S', 'M.R.P', 'Total', 'Ecom'];
  const posTabName = arrayPosTabNames.includes(metricNametab) ? `${subproject} Gross Margin` : 'Gross Margin';
  const responseHandlerupdate = (response) => {
    if(response?.data?.responseData?.length >= 0){
      setInsightGraphData(response.data.responseData);
      let aspindex = 0;     
      for (let i = 0; i < response.data.responseData.length; i++) {
        if(response.data.responseData[i].ASP.value === null){
          aspindex++;          
        }        
      }

      if(response.data.responseData.length === aspindex){
        setIsGraphShow(existingValues => ({
          ...existingValues,
          graphAsp: false,
        }))
      }; 
    
      setLoading(false)
    }else {setLoading(false)}    
  }
  
  useEffect(() => {
    setLoading(true)
    if(insightsTabsDetailsData && metricNames === 'Sales Overview'){
    dispatch(getPriceIndexGraph({customerKey: selectedCustomerKey,filterValues: selectedGlobalFilters,displayName:metricNametab},responseHandlerupdate));
    }
  }, [selectedGlobalFilters, insightsTabsDetailsData, metricNametab]);
  
  const labelFormatterPercent = (num) => {
    if(num !== null){
    return num.toFixed(0) + '%';
    }
  };

  const labelFormatterNum = (num) => {
    if(num !== null){
    if (num >= 1000000000) {
        return selectedRetailerCurrency + (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return selectedRetailerCurrency + (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return selectedRetailerCurrency + (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    if (num < 1000) {
      return selectedRetailerCurrency + (num).toFixed(1).replace(/\.0$/, '');
    }
    return num;  
    }
  };
  const labelFormatterNumcupr = (num) => {
    if(num !== null){
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(0).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(0).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(0).replace(/\.0$/, '') + 'K';
    }
    if (num < 1000) {
      return num.toFixed(0).replace(/\.0$/, '');
    }
    return num;
    }  
  };


  const formatXAxis = (tickItem) => moment(tickItem).format('D-MMM'); 

  function numberWithCommas(val) {
    let result = (val - Math.floor(val)) !== 0; 
    let resultNum = result ? val.toFixed(2) : val;
    return resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
            {payload.map((pld, index) => (
              <>
             {index === 0 &&
              <div key={index} style={{display: "inline-block", backgroundColor: "#000",color:"#fff", padding:"10px 15px"}}>
                  {pld.payload.DATE ? <div><span className='' >DATE:</span> {pld.payload.DATE}</div>:''}                                  
                  {pld.payload.PREV_YEAR_lABEL ? <div><span className=''>PY Value({selectedRetailerCurrency}):</span> {numberWithCommas(pld.payload.PREV_YEAR_lABEL)}</div> : ''}
                  {pld.payload.CURRENT_YEAR_lABEL ? <div><span className=''>CUR Value({selectedRetailerCurrency}):</span> {numberWithCommas(pld.payload.CURRENT_YEAR_lABEL)} </div>:''}  
                  {pld.payload.ASP_lABEL ? <div><span className=''>{cogsTabName}({selectedRetailerCurrency}):</span> {numberWithCommas(pld.payload.ASP_lABEL)}</div>:''} 
                  {pld.payload.GROSS_MARGIN ? <div><span className=''>{posTabName}:</span> {pld.payload.GROSS_MARGIN}</div>:''}                 
              </div>}
              </>
            ))}
        </div>
      );
    }  
    return null;
  };

  return(
    <div className='mt-1'>
      {loading ? (<div id="strategy_wrapper">
                <div className="container-fluid  default-top loading_insights loading_wrapper bg-w p-5">
                    <div className="sweet-loading">
                    <span><b>Processing...</b></span>
                    <BarLoader style={{display: 'block', margin:'0 auto', borderColor: 'red'}} size={150} color={"#123abc"}
                        height={4} width={100} loading={loading} />
                    </div>
                </div>
                </div>) :
      <ResponsiveContainer width="100%" height={400}>
      <ComposedChart  data={insightGraphData} margin={{ top: 10, bottom: 10, left: -25, right: 10 }}>
        <XAxis  dataKey="DATE" textAnchor="end" tick={{ angle: -45 }} style={{fontSize:"10px"}} dy={5} minTickGap={-200} axisLine={false} tickFormatter={formatXAxis}  />
        <YAxis hide yAxisId="left" />
        {isGraphShow.graphAsp &&
          <YAxis yAxisId="right" style={{fontSize:"10px"}} tickFormatter={labelFormatterNumcupr} />
        }
        <YAxis hide yAxisId={3}  />
        <Tooltip content={<CustomTooltip />} />
        <Legend wrapperStyle={{paddingTop: "25px",}} />
        <CartesianGrid stroke="#f5f5f5" />
        <Bar yAxisId="left" isAnimationActive={false} dataKey="PREV_YEAR_lABEL" name={`Prev. Year Value(${selectedRetailerCurrency})`} barSize={38} fill="#1be8bd" label={{ position: 'insideTop', fontSize:"10px",  formatter: labelFormatterNumcupr, fill: "#0e6757"}} />  
        <Bar yAxisId="left" isAnimationActive={false} dataKey="CURRENT_YEAR_lABEL" name={`Curr. Value(${selectedRetailerCurrency})`} barSize={38} fill="#0dc5a2" label={{ position: 'top', fontSize:"10px",  formatter: labelFormatterNumcupr, fill: "#0e6757"}} />
        <Line yAxisId="right" isAnimationActive={false} dataKey="ASP_lABEL"  name={`${cogsTabName}(${selectedRetailerCurrency})`} label={{ position: 'top',  formatter: labelFormatterNum, fill: "#365e9f", fontSize:"10px" }} 
          stroke="#365e9f" strokeWidth="2" dot={{ stroke: "blue", strokeWidth: 2, fill:"blue" }} />        
        <Line yAxisId={3} stackOffset="sign" isAnimationActive={false} dataKey="GROSS_MARGIN"  name={posTabName} label={{ position: 'top',  formatter: labelFormatterPercent, fill: "#db7560", fontSize:"10px" }} 
          stroke="#db7560" strokeWidth="2" dot={{ stroke: "red", strokeWidth: 2, fill:"red" }} />       
      </ComposedChart>
      </ResponsiveContainer>
      }
    </div>
  )

}
export default NewInsightsChart;