import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEmbedToken, updateActionTakenInfo, updateUserActivity } from '../../actions';
import Report from 'powerbi-report-component';
import RecommendationAction from './RecommendationAction'
import BarLoader from "react-spinners/BarLoader";
import _get from 'lodash/get';
import { ToastContainer, toast } from 'react-toastify';
import {addCommonActivityCaptureDetail} from '../../helper';

class RecommendationModal extends Component {
    constructor(props) {
        super(props);
        const {  productId, fcNameId, dateOfRecommendation} = this.props.selectedRecommendationDetails.row;
        this.state = {
            loading: true,
            embedToken: "",
            table: this.props.recommendationName.toLowerCase(),
            column: "",
            activityInfo : { 
                page : "Recommendations Usage Summary",
                strategies : this.props.recommendationName,
                listOfProducts:[{product_id : productId,
                location : fcNameId,
                estimatedValue : localStorage.getItem("selected-estimated-value"),
                dateOfRecommendation : dateOfRecommendation}],
                globalFilters:this.props.selectedGlobalFilters,
                strategyFilters: this.props.selectedStrategies
            }          
        }

    }
    componentDidMount() { 
        addCommonActivityCaptureDetail(this.state.activityInfo); 
        this.props.getEmbedTokenAPI({
            groupId: process.env.REACT_APP_GROUP_ID,
            dashboardId: this.props.reportId
        }, (res, rej) => {
                if (res.data.hasOwnProperty("embedToken")) {
                this.setState({ loading: false, embedToken: res.data.embedToken });
            } else {

            }
        });

    }
    renderPowerBi() {
        const { reportId } = this.props;  
        let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${process.env.REACT_APP_GROUP_ID}`;
        const embedToken = this.state.embedToken;
        embedUrl = embedUrl.replace("watch?v=", "v/");

        const reportStyle = {
            height: "48rem"
        };
        const extraSettings = {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            hideErrors: false
        };
        const tableList = [this.state.table, `${this.state.table} detailed store`, `${this.state.table} detailed dc`,`md_targets`];
        const columnList = [{
            column: "location",
            propsName: "chosenLocation"
        }, {
            column: "product_id",
            propsName: "chosenProductId"
        }, {
            column: "source",
            propsName: "chosenSource"
        }, {
            column: "kc_platform",
            propsName: "chosenKcCategory"
        }];
        let filterArr = [];
        for (let i = 0; i < tableList.length; i++) {
            for (let j = 0; j < columnList.length; j++) {
                filterArr.push({
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: tableList[i],
                        column: columnList[j].column
                    },
                    operator: "In",
                    values: [`${this.props[columnList[j].propsName]}`]
                });
            }
        }
        console.log("filterArr ", filterArr);
        return (

            <Report
                embedType="report" // "dashboard"
                tokenType="Embed" // "Aad"
                accessToken={embedToken} // accessToken goes here
                embedUrl={embedUrl} // embedUrl goes here
                embedId={reportId}
                permissions="All" // View
                style={reportStyle}
                extraSettings={extraSettings}
                onLoad={(report) => {
                    this.report = report;
                    this.report.getFilters().then(function (filters) {

                    }).catch(function (errors) {

                    })

                    
                    this.report.setFilters(filterArr).then((res, rej) => {


                    }).catch(function (errors) {

                    });
                }
                }


            />
        )
    }
    renderRecommendationAction() {
        const { actionTakenInfo, recommendationName } = this.props;
        let emptyObj = true;
        let actionForReco = {
            action: "",
            comment: "",
            user: "",
            updatedDate: ""
        };
        if (actionTakenInfo && actionTakenInfo.hasOwnProperty(recommendationName)) {
            emptyObj = false;
            actionForReco = actionTakenInfo[recommendationName];
        }
        return (
            <RecommendationAction
                callUpdateActionTakenInfo={this.callUpdateActionTakenInfo}
                emptyObj={emptyObj}
                recommendationName={this.props.recommendationName}
                actionTakenInfo={actionForReco} />
        )
    }
    callUpdateActionTakenInfo = (selections) => {

        const { asin, productId, location, actionTakenInfo } = this.props.selectedRecommendationDetails.row;
        let obj = {}
        obj.product_id = productId;
        obj.upc = asin;
        obj.location = location;
        obj.oldInfo = actionTakenInfo;

        let newObj = {};
        newObj.action = selections.action;
        newObj.comment = selections.comments;
        newObj.user = this.props.user;
        let date = new Date()
        newObj.updatedDate = date.toDateString();
        let updatedInfo = {};
        let key = this.props.recommendationName;

        updatedInfo[key] = newObj;
        obj.updatedInfo = updatedInfo;

        addCommonActivityCaptureDetail({...this.state.activityInfo, ...{comment : newObj.comment, activity : newObj.action}});

        this.props.updateActionTakenInfoAPI(obj, (res, rej) => {
            this.props.getLatestRecommendation();
            if (res) {
                //hide modal
                this.props.displayModalOff();
                toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
            } else {

                toast.error(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
            }
        });

    }
    render() {

        if (this.state.loading) {
            return (
                <div className="container-fluid  default-top  bg-gray p-5 loading_wrapper">
                    <div className="sweet-loading">
                        <BarLoader
                            size={150}
                            color={"#123abc"}
                            height={4}
                            width={100}
                            loading={this.state.loading}
                        />
                    </div>

                </div>
            )
        }
        return (<Modal size={'xl'} show={true} onHide={this.props.displayModalOff} id="recommendations-modal" >
            <ToastContainer />
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row recommendations-table">

                        <div className="col-12" style={{ textAlign: "center" }}>
                            {this.renderPowerBi()}
                        </div>
                        {this.renderRecommendationAction()}

                    </div>
                </div>
            </Modal.Body>

        </Modal>);
    }
}

function mapStateToProps(state) {

    let selectedRecommendationDetails = _get(state, 'recommendationsReducer.selectedRecommendation', []);
    let selectedGlobalFilters = _get(state, 'globalReducer.selectedGlobalFilters', {});
  
    let selectedStrategies = _get(
      state,
      "strategyReducer.selectedStrategies",
      []
    );
    return ({
        selectedRecommendationDetails: selectedRecommendationDetails,
        selectedGlobalFilters,
        selectedStrategies
    })
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getEmbedTokenAPI: getEmbedToken,
        updateActionTakenInfoAPI: updateActionTakenInfo
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(RecommendationModal);