import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  
  getAdoptionEstimatedValue,
  getAdoptionEstimatedValueNew,
  getEngagementSummary
} from "../../actions/index";
import { generateStrategyNameList,PriceFormatter,dynamicRetailerimpactPriceFormat, getexistingRetailers, addCommonActivityCaptureDetail } from "../../helper";
import { CarretIconUp, CarretIconDown } from '../../components/Icons';

const ThreeColumn = (props) => {
  let dispatch = useDispatch();
  let allStrategyDetails = useSelector((state) => state.strategyReducer.detailsForAllStrategies);
  let [loading, setLoading] = useState();
  let [estimatedRevenueData, setEstimatedRevenueData] = useState({});
  let [selectedStrategies, setSelectedStrategies] = useState(generateStrategyNameList(allStrategyDetails));
  let {
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedStrategies,
    estimatedValueNew,
    adoptionSelectedUseCases,
    engagementSummary,
    retailerCurrency
  } = useSelector((state) => state.adoptionReducer);
  let {selectedCustomerKey, subproject, selectedRetailerCurrency,dynamicPriceFormatting, selectedSubprojectInfo, defaultDailyUsageConfig } = useSelector((state) => state.globalReducer);
  let estimatedValue =  estimatedValueNew?.responseData;
  let engagementSummarydata = engagementSummary?.responseData;

  let retailerCurrencySign = retailerCurrency === undefined || retailerCurrency === '' || retailerCurrency === 'USD' ? selectedRetailerCurrency : '$'; 

  let responseHandler = (res, rej) => {
    setTimeout(() => setLoading(false), 1500);
  } 

  let requestObject = () => {
    return {
      userNames: adoptionSelectedUsers ? adoptionSelectedUsers : [],
      strategies: adoptionSelectedStrategies ? adoptionSelectedStrategies : [],
      dropDownSelectionPeriod: selectedDateRange
        ? selectedDateRange
        : "Prior Week",
      additionalParametersInterval: adoptionCustomDates
        ? adoptionCustomDates
        : {},
      additionalParametersPoint: adoptionAdditionalParamaters,
      useCase: adoptionSelectedUseCases,
      currency: retailerCurrency === undefined || retailerCurrency === '' ? 'USD' : retailerCurrency,
      customerKey: selectedCustomerKey,
      defaultDailyUsageConfig:defaultDailyUsageConfig
    };
  };
 
  useEffect(() => {   
    setLoading(true);
    if (selectedDateRange) {
      if(getexistingRetailers().includes(subproject)) { 
        dispatch(getAdoptionEstimatedValueNew(requestObject(), responseHandler));  
      } 
      dispatch(getEngagementSummary(requestObject(), responseHandler));
    }   
  }, [
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedStrategies,
    retailerCurrency,
    adoptionSelectedUseCases,
    adoptionSelectedStrategies
  ]);

  useEffect(() => {
    addCommonActivityCaptureDetail({page:'Impact',strategyFilters:selectedStrategies})    
  }, []);

  const dynamicEstimateValue = (estimatevalue) => {
    return (
      <>{estimatevalue && dynamicPriceFormatting && dynamicPriceFormatting === 'dot' && estimatevalue != 'NaN%' ? dynamicRetailerimpactPriceFormat(estimatevalue) : estimatevalue != 'NaN%' ? estimatevalue : 0}</>
    )
  }

  const popyoyValue = (val)=> {   
    if(val !== undefined && val !== null && val !== 'NaN' && val !== 'NA') {
      let finalVal = parseFloat(val).toFixed(2);
      let finalValfixed = parseFloat(val).toFixed(0);
      if(finalVal == 0) {
        return <span className="clr-grn"><CarretIconUp />{finalValfixed}%</span>
      } else if(finalVal > 0) {
        return <span className="clr-grn"><CarretIconUp />{finalVal}%</span>
      }  else {
        return  <span className="clr-rd"><CarretIconDown />{finalVal}%</span>
      }     
    } else {
      return  <span className="clr-grn"><CarretIconUp />0%</span>
    }     
  }

  const estiMatedRevenueSummary = () => {
    if(getexistingRetailers().includes(subproject) && estimatedValue !== undefined) { 
    return (
      <>
      <div className='font-weight-bold mb-2'>Estimated Revenue Summary</div>
      <div className='estimate-border bg-white'> 
      <div className="row m-2">
        <div className="col-md font-weight-bold"> New Alerts</div>
        <div className="col-md font-weight-bold"> Actions Taken</div>
        <div className="col-md font-weight-bold"> Unresolved Actions</div>
        <div className="col-md font-weight-bold"> Resolved Actions</div>
        <div className="col-md font-weight-bold"> Sales Impact</div>
      </div>  
      <div className="row alert_impact ml-4 mr-1">
        <div className="col border-estimateval progress-bar-layout">
          {retailerCurrencySign}{dynamicEstimateValue(estimatedValue?.newAlerts)}
          <div className='popyoycls'><span>PoP: {popyoyValue(estimatedValue?.newAlertsPOP)}</span><span>YoY: {popyoyValue(estimatedValue?.newAlertsYOY)}</span></div>
        </div>
        <div className="col border-estimateval progress-bar-layout">
          {retailerCurrencySign}{dynamicEstimateValue(estimatedValue?.actionsTaken)} 
          <div className='popyoycls'><span>PoP: {popyoyValue(estimatedValue?.actionsTakenPOP)}</span><span>YoY: {popyoyValue(estimatedValue?.actionsTakenYOY)}</span></div>         
        </div>
        <div className="col border-estimateval progress-bar-layout">
          {retailerCurrencySign}{dynamicEstimateValue(estimatedValue?.unresolvedActions)}
          <div className='popyoycls'><span>PoP: {popyoyValue(estimatedValue?.unresolvedActionsPOP)}</span><span>YoY: {popyoyValue(estimatedValue?.unresolvedActionsYOY)}</span></div>
        </div>
        <div className="col border-estimateval progress-bar-layout">
          {retailerCurrencySign}{dynamicEstimateValue(estimatedValue?.resolvedActions)}
          <div className='popyoycls'><span>PoP: {popyoyValue(estimatedValue?.resolvedActionsPOP)}</span><span>YoY: {popyoyValue(estimatedValue?.resolvedActionsYOY)}</span></div>
        </div> 
        <div className="col border-estimateval progress-bar-layout">
          {retailerCurrencySign}{dynamicEstimateValue(estimatedValue?.salesImpact)}
          <div className='popyoycls'><span>PoP: {popyoyValue(estimatedValue?.salesImpactPOP)}</span><span>YoY: {popyoyValue(estimatedValue?.salesImpactYOY)}</span></div>
        </div>         
      </div>
    </div>
    </>    
    )
    }
  }

  if (loading) {
    return (
      <div id="no-data-available" style={{ marginTop: "5%", height: '80px' }}>
        Loading....
      </div>
    );
  }
  return (
    <>
    {estiMatedRevenueSummary()}
    {engagementSummarydata !== undefined &&
    <>
    <div className='font-weight-bold mb-2 mt-5'>Engagement Summary</div>
      <div className='estimate-border bg-white'>
      <div className="row m-2">
        <div className="col-md font-weight-bold"> Actioned % vs. Triggered</div>
        <div className="col-md font-weight-bold"> Effectiveness % vs. Triggered</div>
        <div className="col-md font-weight-bold"> Effectiveness % vs. Actioned</div>
        <div className="col-md font-weight-bold"> Usage</div>
      </div>
        <div className="row alert_impact ml-4 engagementsummary_cl">
          <div className="col border-estimateval progress-bar-layout">
          {dynamicEstimateValue(engagementSummarydata?.actionedVTriggered)}
          <div className='popyoycls'><span>PoP: {popyoyValue(engagementSummarydata?.actionedVTriggeredPOP)}</span><span>YoY: {popyoyValue(engagementSummarydata?.actionedVTriggeredYOY)}</span></div>
          </div>
          <div className="col border-estimateval progress-bar-layout">
          {dynamicEstimateValue(engagementSummarydata?.effectivenessVTriggered)}
          <div className='popyoycls'><span>PoP: {popyoyValue(engagementSummarydata?.effectivenessVTriggeredPOP)}</span><span>YoY: {popyoyValue(engagementSummarydata?.effectivenessVTriggeredYOY)}</span></div>
          </div>
          <div className="col border-estimateval progress-bar-layout">
          {dynamicEstimateValue(engagementSummarydata?.effectivenessVActioned)}
          <div className='popyoycls'><span>PoP: {popyoyValue(engagementSummarydata?.effectivenessVActionedPOP)}</span><span>YoY: {popyoyValue(engagementSummarydata?.effectivenessVActionedYOY)}</span></div>
          </div>
          <div className="col border-estimateval progress-bar-layout">
          {dynamicEstimateValue(engagementSummarydata?.usage)}
          <div className='popyoycls'><span>PoP: {popyoyValue(engagementSummarydata?.usagePOP)}</span><span>YoY: {popyoyValue(engagementSummarydata?.usageYOY)}</span></div>
          </div>
        </div>
    </div></> }
    </>
  );
};

export default ThreeColumn;
