import React, { useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch } from "react-redux";
import {getDetailsForUsers, updateUserListData,getDetailsForAllUsers} from "../actions/index"
import {  toast } from 'react-toastify';

import Modal from 'react-bootstrap/Modal';

function HandleUserListModal(props){

    const [loading, setLoading] = useState(true);
    const [searchString, setSearchString] = useState("");
    const [userData, setUserData] = useState([]);
    const [checkboxChangeUserData, setCheckboxChangeUserData] = useState([]);
    const dispatch = useDispatch()

      useEffect(() => {      
          dispatch(getDetailsForUsers()).then((data)=>{
            setUserData(data.data.responseData)
            setLoading(false);
          })
          .catch((error)=>{
            
          })       
      }, []);

      function handleCheckboxClick(option, index, checked) {
        let user_data = cloneDeep(userData);
        if(checked){
          user_data[index].userFlag =  true
        }else
        user_data[index].userFlag =  false
         
          let count = false;
          let tempArray = cloneDeep(checkboxChangeUserData);
         
          for (var i in tempArray) {
            if (tempArray[i].userName == option.userName) {
               count= true;
               tempArray[i].userFlag = checked;
               break; 
            }
          }
        if(!count){
           option.userFlag = checked;
           
           tempArray.push(option)
        }
        setUserData(user_data)
        setCheckboxChangeUserData(tempArray)
      }

     let handleSaveButton= ()=>{
      props.displayUserListModalOff()
        dispatch(updateUserListData({updatedUserDetails: checkboxChangeUserData})).then((data)=> {
          if (data.data.responseType === "success") {
            dispatch(getDetailsForAllUsers())
            toast.success(data.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
          } else {
            toast.error(data.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
          }

        })
      }
      
  
      function renderBody(){
          let data = userData;
          if (data) {
            return(
            <div>          
            <div id="user-list-info">
               Select those users who should'nt appear in the User Filter <br/> dropdown in the impact page</div>
            <ul class="list-group " style={{overflowY: "auto", maxHeight:"400px"}}>
              { 
              userData.map((option, index) => 
              <li class="list-group-item d-flex" style={{lineHeight: 1.3, padding: "5px"}}>
              <input class="form-check-input" type="checkbox" style={{marginLeft: "4px", marginTop: "11px"}} 
              checked= {option.userFlag}
              onChange={(e) => {
                    handleCheckboxClick(option,index,e.target.checked);
                  }}
              />
              <div class=" text-break" style={{marginLeft: "28px"}}>
               <div class="fw-bold">{option.userName}</div>
               <div style={{fontSize: "12px", color: "#a6a8a8"}}>{option.userEmail}</div>
             </div>
               </li>    
            )}
              </ul>
            </div>)            
          } else {
            return null;
          }
      }
      
    return (
        <Modal  show={props.displayUserListModal} onHide={props.displayUserListModalOff} id="" >
           <Modal.Header closeButton>
                <Modal.Title>User List</Modal.Title>
            </Modal.Header>
             <Modal.Body className="bg-gray py-4">
           {loading ? <div style={{height: "400px",  textAlign: "center"}}>Loading user data...</div> :  renderBody()}
            </Modal.Body>          
          {!loading &&<Modal.Footer>                   
                  <button
                    className={`btn btn-apply  my-2`}
                    onClick= {handleSaveButton}
                    style={{marginRight: "4px"}}
                  >
                  <i className="fas fa-save mr-2"></i>  Save
                  </button>
                </Modal.Footer> }  
        </Modal>
    )
}


export default HandleUserListModal;