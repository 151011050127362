import React, { useEffect, useState } from 'react';
import {
  Switch, Route, BrowserRouter as Router, useHistory,
  useLocation
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import './components/Main.scss';
import ComingSoon from './components/ComingSoon';
import Recommendations from './containers/Recommendations/Recommendations';
import Admin from './containers/Admin/Admin';
import Insights from './containers/Insights/Insights';
import NewInsights from './containers/Insights/NewInsights';
import Strategies from './containers/Strategies/Strategies';
import Details from './containers/Details/Details';
import Susbscriptions from './containers/Subscriptions/Subscriptions';
import Navbar from './components/Navbar';
import ProjectsPage from './components/ProjectsPage';
import PageNotFound from './components/PageNotFound';
import SubprojectsPage from './components/SubprojectsPage';
import Explorer from './containers/Explorer/Explorer';
import FirstComponent from './components/FirstComponent'
import GridLayout from './containers/Adoption/GridLayout'
import { useDispatch, useSelector } from 'react-redux';
import Updates from './components/Updates';
import Selfservice from './containers/Selfservice/Selfservice';
import {
  getAllProjectAndSubprojects,
  getDetailsForAllStrategies,
  setProject, setSubproject, setSubprojectInfo, setProjectInfo,
  getGlobalFilter
} from './actions';
import NewRecommendationPage from './containers/NewRecommendation/NewRecommendationPage';


/*
When the page first loads, we get the project from URL, then to get the list
of subprojects, we make an API call. Once we get the list of all subprojects, then we
assign a subproject from local storage, if it exists in his permitted subprojects all fine,
else we assign the topmost subproject to the user and set it in local storage
*/
const App: any = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [currentSubproject, setCurrentSubproject] = useState(() => {
    return window.localStorage.getItem('subproject') || ''
  });

  const [currentProject, setCurrentProject] = useState(() => {
    return window.localStorage.getItem('project') || ''
  });
  useEffect(() => {
    if (currentProject) {
      const currentPath: string = location.pathname;
      let locationArr: Array<string> = currentPath.split("/");
      locationArr = locationArr.filter((str) => {
        return str !== "";
      });
      if (locationArr.length === 0) {
        if (currentSubproject) {
          history.push(`/${currentProject}/${currentSubproject}`);
        } else {
          history.push(`/${currentProject}`);
        }
      }
    }
  }, []);
  const configForOkta: object = {
    clientId: process.env.REACT_APP_CLIENTID,
    issuer: process.env.REACT_APP_ISSUER,
    responseType: 'id_token',
    redirectUri: `${window.location.origin}/callback`,
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: false,
  };
  interface Subproject {
    name: string,
    logo: string,
    oktaGroupAdmin: string,
    oktaGroupRead: string
  }
  interface Project {
    name: string,
    oktaGroupAdmin: string,
    subProjectInfo: Array<Subproject>
  }
  let projectAndSubprojects: Array<Project> = useSelector((state: any) => state.globalReducer.projectAndSubprojects);
  let project: string = useSelector((state: any) => state.globalReducer.project);
  let subproject: string = useSelector((state: any) => state.globalReducer.subproject);

  // we get list of all the projects and the sub-projects which the user has access to
  useEffect(() => {
    setLoading(true);
    dispatch(getDetailsForAllStrategies({ strategyStatus: "active" }));
    dispatch(getAllProjectAndSubprojects()).then((res: PromiseFulfilledResult<object>, rej: PromiseRejectedResult) => {
      if (res) {
        setLoading(false);
      } else {
        setLoading(false);
      }

    });
  }, []);


  /**
   * Lets say the project name is Dash, it will appear in lower-case in the URL.
   * e.g. localhost:3000/ecompass/walmart-ogp
   * Now to manage routes, we need to convert our project and sub-project from 
   * redux store to be converted to
   * lower case as well
   */

  let formattedProject: string = project ? project.toLocaleLowerCase() : "";

  let formattedSubProject: string = subproject ? subproject.toLocaleLowerCase() : "";

  /**
   * We need to check if user has permission to access a particular project/sub-project.
   * We give priority to URL params entered by user, if they aren't present then we
   * pick from LS or it is "". Let this project be projectName, we check inside projectAndSubprojects
   * array if this project exists. If yes, all fine, we set this in redux, if not,
   * we pick the first project inside projectAndSubprojects array and set that as project.
   * Whenever we set a project/sub-project in redux, we also need to set it in LS so we
   * can remember it later.
   */

  useEffect(() => {
    if (projectAndSubprojects && projectAndSubprojects.length > 0) {
      const currentPath: string = location.pathname;
      let locationArr: Array<string> = currentPath.split("/");
      locationArr = locationArr.filter((str) => {
        return str !== "";
      });
      let projectName: string;
      let subprojectName: string;
      if (locationArr.length > 0) {
        if (locationArr.length === 1) {
          projectName = locationArr[0];
          subprojectName = "";
        } else {
          projectName = locationArr[0];
          subprojectName = locationArr[1];
        }
      } else {
        projectName = currentProject;
        subprojectName = currentSubproject;
      }

      projectName = project ? project : projectName;
      subprojectName = subproject ? subproject : subprojectName;
      let projectInfo: Array<Project | any> = [];
      let projectExists = false;

      projectAndSubprojects.map((project) => {
        let name = project.name.toLowerCase();
        if (name === projectName.toLowerCase()) {

          localStorage.setItem("project", project.name);
          setCurrentProject(project.name);
          dispatch(setProject({ project: project.name }));
          dispatch(setProjectInfo(project));
          projectExists = true;
        }
      });

      if (!projectExists) {

        projectInfo = [projectAndSubprojects[0]];
        dispatch(setProject({ project: projectAndSubprojects[0].name }));
        dispatch(setProjectInfo(projectAndSubprojects[0]));
        localStorage.setItem("project", projectAndSubprojects[0].name);

      } else {

        projectInfo = projectAndSubprojects.filter((project) => {
          let name = project.name.toLowerCase();
          return name === projectName.toLowerCase();
        });
      }

      let subprojects: Array<Subproject> = projectInfo[0].subProjectInfo;

      let subprojectExists = false;
      if (subprojects.length > 0) {
        subprojects.map((subproject) => {
          if (subprojectName && subproject.name.toLocaleLowerCase() === subprojectName.toLocaleLowerCase()) {
            dispatch(setSubproject({ subproject: subproject.name }));
            dispatch(setSubprojectInfo(subproject));
            dispatch(getGlobalFilter());
            subprojectExists = true;
          }
        });
      }

      if (!subprojectExists && subprojects.length > 0) {
        setCurrentSubproject(subprojects[0].name);
        dispatch(setSubproject({ subproject: subprojects[0].name }));
        dispatch(setSubprojectInfo({ subprojectInfo: subprojects[0] }));
        dispatch(getGlobalFilter());
        localStorage.setItem("subproject", subprojects[0].name);
      }
    }
  });

  return (
    <>
      {loading ? <div>Loading ...</div> :
        <Router>
          <Security {...configForOkta}>
            <Navbar />
            <Switch>

              <SecureRoute exact path="/" component={ProjectsPage} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/alerts`} component={NewRecommendationPage} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/recommendations`} component={Recommendations} />

              <SecureRoute exact path={`/dash/${formattedSubProject}/`} component={Recommendations} />
              {/* <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/`} component={Insights} /> */}
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/`} component={formattedProject === "ecompass" ? NewInsights : Insights} />
              /**
              * Admin will have many sub-routes, so no exact keyword used
              */
              {/* <SecureRoute path={`/${formattedProject}/${formattedSubProject}/newInsights/admin`} component={Admin} /> */}
              <SecureRoute path={`/${formattedProject}/${formattedSubProject}/admin`} component={Admin} />
              <SecureRoute exact path={`/${formattedProject}`} component={SubprojectsPage} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/insights`} component={formattedProject === "ecompass" ? FirstComponent(NewInsights) : FirstComponent(Insights)} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/strategies`} component={Strategies} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/faqs`} component={Strategies} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/details`} component={Details} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/subscriptions`} component={Susbscriptions} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/scorecard`} component={Explorer} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/coming_soon`} component={ComingSoon} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/adoption`} component={GridLayout} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/impact`} component={GridLayout} />
              <SecureRoute exact path={`/${formattedProject}/${formattedSubProject}/Self-Service`} component={Selfservice} />
              <SecureRoute exact path={`/${formattedProject}/updates`} component={Updates} />
              <Route path="/callback" component={LoginCallback} />
              <SecureRoute path="/" component={PageNotFound} />
            </Switch>
          </Security>
        </Router>
      }
    </>
  )
};

export default App;
